import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useStateBoolean } from "../../../../shared/hooks/useStateBoolean";
import { Button, HStack, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FSaveButton from "../../../../components/FormElements/FSaveButton";
import FSaveAndExitButton from "../../../../components/FormElements/FSaveAndExitButton";
import { IPlatformAddress } from "./types";
import { NUMBER_MGS, REQUIRED_MGS } from "../../../../components/FormElements/validationMessages";
import itemsApi from "../../../../shared/api/itemsApi";
import { PLATFORMS_PATH } from "../../../../config/paths";
import { toastFailure, toastSuccess } from "../../../../shared/lib/toast";
import { ItemsPlatformAddresses } from "../../../../generated/axios";
import { validSelectedOption } from "../../../../shared/lib/validation-utils";
import { EDIT, PA_PATH } from "../../../../shared/lib/path-utils";
import GeolocalizedAddressFields from "./GeolocalizedAddressFields";
import { toId } from "../../../../shared/lib/id-utils";
import DeletedAlert from "../../../../components/DeletedAlert";

const schema = yup.object().shape({
  street: yup.string().required(REQUIRED_MGS),
  city: yup.string().required(REQUIRED_MGS),
  postal_code: yup.string().required(REQUIRED_MGS),
  province_id: yup
    .object()
    .typeError(REQUIRED_MGS)
    .required(REQUIRED_MGS)
    .test("province_id_required", REQUIRED_MGS, validSelectedOption),
  latitude: yup.number().typeError(NUMBER_MGS).required(REQUIRED_MGS),
  longitude: yup.number().typeError(NUMBER_MGS).required(REQUIRED_MGS),
});

// Need for resetting nesting object!
const resetValue = (item: IPlatformAddress) => {
  return { ...item };
};

interface IProps {
  id: number;
  id_address: string;
  item: IPlatformAddress;
}

const PlatformAdddressEditForm: React.FC<IProps> = ({ id, id_address, item }) => {
  const [exit, setExit] = useStateBoolean(false);
  const toast = useToast();
  const history = useHistory();
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IPlatformAddress>({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  const onSubmit = (values: IPlatformAddress) => {
    const item: ItemsPlatformAddresses = {
      status: values.status,
      platform_id: id,
      street: values.street,
      civic_number: values.civic_number,
      city: values.city,
      postal_code: values.postal_code,
      province_id: values.province_id ? toId(values.province_id) : undefined,
      latitude: values.latitude === null ? undefined : values.latitude,
      longitude: values.longitude === null ? undefined : values.longitude,
    };
    const apiCall =
      id_address === "new"
        ? itemsApi.createItemsPlatformAddresses(undefined, item)
        : itemsApi.updateSingleItemsPlatformAddresses(
            Number(id_address),
            ["street", "civic_number", "city", "postal_code", "province_id", "latitude", "longitude"],
            undefined,
            item
          );
    return apiCall
      .then((response) => {
        toast(toastSuccess("Indirizzo della piattaforma salvato con successo"));
        if (exit) history.push(EDIT(PLATFORMS_PATH, String(id)));
        else if (id_address === "new") {
          const responseId = (response.data.data as unknown as ItemsPlatformAddresses)?.id;
          responseId && history.push(EDIT(PA_PATH(String(id)), String(responseId)));
        }
      })
      .catch((e: any) => {
        toast(toastFailure("Errore nel salvataggio dell'indirizzo della piattaforma", String(e)));
      });
  };

  return (
    <>
      <DeletedAlert status={item.status}>Indirizzo cancellato!</DeletedAlert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeolocalizedAddressFields control={control} errors={errors} getValues={getValues} setValue={setValue} />

        <HStack mt="6">
          <Button onClick={() => reset(resetValue(item))}>Reset</Button>
          <FSaveButton disabled={isSubmitting && exit} isLoading={isSubmitting && !exit} />
          <FSaveAndExitButton disabled={isSubmitting && !exit} isLoading={isSubmitting && exit} onClick={setExit} />
        </HStack>
      </form>
    </>
  );
};

export default PlatformAdddressEditForm;
