import { CSSObject } from "@emotion/styled";
import { SingleValueProps } from "react-select/src/components/SingleValue";
import { MultiValueProps } from "react-select/src/components/MultiValue";

export const fSelectCustomStyle = {
  singleValue: (style: CSSObject, { data, options }: SingleValueProps<any, any>) => {
    const isDisabled = Boolean(options.find((o) => o.value === data.value)?.isDisabled);
    return { ...style, color: isDisabled ? "red" : "black" };
  },
  multiValueLabel: (style: CSSObject, { data, options }: MultiValueProps<any, any>) => {
    const isDisabled = Boolean(options.find((o) => o.value === data.value)?.isDisabled);
    return { ...style, color: isDisabled ? "red" : "black" };
  },
};
