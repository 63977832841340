import React, { ErrorInfo } from "react";

interface IProps {}

interface IState {
  error?: Error;
  hasError: boolean;
  info?: ErrorInfo;
}

class ErrorHandler extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      error: undefined,
      hasError: false,
      info: undefined,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error("componentDidCatch", error, info);
    this.setState({ error, info });
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  render() {
    return this.state.hasError ? <h2>Something went wrong.</h2> : this.props.children;
  }
}

export default ErrorHandler;
