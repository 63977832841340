import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Content, Crumbs, PageTitle } from "../Layout";
import { PRODUCERS_PATH } from "../../../config/paths";
import { EDIT } from "../../../shared/lib/path-utils";
import breadcrumbs from "./breadcrumbs";
import ProducerEditForm from "./ProducerEditForm";
import itemsApi from "../../../shared/api/itemsApi";
import Loading from "../../../components/Loading";
import { IAddress, IProducer } from "./types";
import {
  ItemsAddresses,
  ItemsCertifications,
  ItemsPlatforms,
  ItemsProducers,
  ItemsProducersCertifications,
  ItemsProducersPlatforms,
} from "../../../generated/axios";
import toOption from "../../../shared/lib/toOption";
import { FaIndustry } from "react-icons/fa";
import { Icon } from "@chakra-ui/react";

const newSede: IAddress = {
  status: "published",
  street: "",
  civic_number: "",
  city: "",
  province_id: null,
  postal_code: "",
  latitude: null,
  longitude: null,
  is_registered_office: true,
  is_production: false,
  // is_production_default: false,
};

const newSedeProduzione: IAddress = {
  status: "published",
  street: "",
  civic_number: "",
  city: "",
  province_id: null,
  postal_code: "",
  latitude: null,
  longitude: null,
  is_registered_office: false,
  is_production: true,
  // is_production_default: false,
};

const newItem: IProducer = {
  name: "",
  status: "published",
  phone: "",
  website: "",
  referent_name: "",
  referent_email: "",
  is_wholesaler: false,
  direct_delivery: false,
  note: "",
  addresses: [newSede, newSedeProduzione],
  certifications: [],
  platforms: [],
};

interface IRouteParams {
  id: string;
}

interface IProps {}

const Producer: React.FC<IProps> = () => {
  const [item, setItem] = useState<IProducer | undefined>(undefined);

  const [error, setError] = useState("");
  const {
    params: { id },
  } = useRouteMatch<IRouteParams>();
  const isNew = id === "new";
  const title = isNew ? "Nuovo produttore" : "Modifica produttore";
  const crumbs = {
    ...breadcrumbs,
    [title]: EDIT(PRODUCERS_PATH, id),
  };

  useEffect(() => {
    if (id === "new") {
      setItem(newItem);
    } else {
      itemsApi
        .readSingleItemsProducers(Number(id), [
          "name",
          "status",
          "phone",
          "website",
          "referent_name",
          "referent_email",
          "is_wholesaler",
          "direct_delivery",
          "note",
          "addresses.id",
          "addresses.status",
          "addresses.street",
          "addresses.civic_number",
          "addresses.city",
          "addresses.province_id.id",
          "addresses.province_id.name",
          "addresses.postal_code",
          "addresses.latitude",
          "addresses.longitude",
          "addresses.is_registered_office",
          "addresses.is_production",
          "platforms.id",
          "platforms.platforms_id.id",
          "platforms.platforms_id.name",
          "certifications.id",
          "certifications.certification_id.id",
          "certifications.certification_id.name",
        ])
        .then((response) => {
          const data = (response.data.data || {}) as ItemsProducers;
          const item = data as IProducer;
          item.platforms =
            data.platforms?.map((obj) => {
              const platform = (obj as ItemsProducersPlatforms).platforms_id as ItemsPlatforms;
              return { ...toOption(platform), id: (obj as ItemsProducersPlatforms).id };
            }) || [];
          item.certifications =
            data.certifications?.map((obj) => {
              const certification = (obj as ItemsProducersCertifications).certification_id as ItemsCertifications;
              return { ...toOption(certification), id: (obj as ItemsProducersCertifications).id };
            }) || [];

          // address[0] è l'indirizzo della sede legale (is_registered_office === true).
          // address[1] è l'indirizzo della sede di produzione (is_production === true).
          // Se non c'è ne creo una vuota.
          const addresses: IAddress[] = ((data.addresses as ItemsAddresses[]) || []).map(
            (address) =>
              ({
                ...address,
                province_id: address.province_id ? toOption(address.province_id) : null,
              } as IAddress)
          );
          const sede = addresses.find((address) => address.is_registered_office) || newSede;
          const produzione = addresses.find((address) => address.is_production) || newSedeProduzione;
          item.addresses = [sede, produzione];
          setItem(item);
        })
        .catch((e) => {
          setItem(newItem); // End loading
          setError(String(e));
        });
    }
  }, [id, setItem, setError]);

  return (
    <>
      <Crumbs path={crumbs} />
      <Content>
        <PageTitle>
          <Icon as={FaIndustry} className="inline-icon" /> {title}
        </PageTitle>
        <Loading loading={!item} error={error}>
          <ProducerEditForm id={id} item={item!} />
        </Loading>
      </Content>
    </>
  );
};

export default Producer;
