import React from "react";
import { Button, Icon, Tooltip } from "@chakra-ui/react";
import { FaTrash } from "react-icons/all";

interface IProps<T> {
  value: T;
  onClick: (record: T) => void;
}

function DeleteButton<T>({ value, onClick }: IProps<T>) {
  return (
    <Tooltip label="Elimina" aria-label="Elimina">
      <Button onClick={() => onClick(value)} variant="delete">
        <Icon as={FaTrash} />
      </Button>
    </Tooltip>
  );
}

export default DeleteButton;
