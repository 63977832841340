import React, { useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import GuardedRoute from "./components/GuardedRoute";
import history from "./shared/api/history";
import { LOGIN_PATH, ADMIN_PATH } from "./config/paths";
import { ChakraProvider } from "@chakra-ui/react";
import AuthContext from "./context/AuthContext";
import Admin from "./containers/Admin/Admin";
import CheckAuth from "./containers/Auth/CheckAuth";
import ErrorHandler from "./components/ErrorHandler";
import Login from "./containers/Auth/Login";
import "@fontsource/roboto-condensed/700.css";
import theme from "./theme";
import "./App.css";

const App: React.FC = () => {
  const [auth, setAuth] = useState<boolean>(Boolean(sessionStorage.getItem("access_token")));
  return (
    <ChakraProvider theme={theme}>
      <ErrorHandler>
        <AuthContext.Provider value={{ auth, setAuth }}>
          <Router history={history}>
            <Switch>
              <Route path={LOGIN_PATH}>
                <Login />
              </Route>
              <GuardedRoute path={ADMIN_PATH} auth={auth}>
                <Admin />
              </GuardedRoute>
              <Route path={"/"} exact>
                <CheckAuth auth={auth} />
              </Route>
            </Switch>
          </Router>
        </AuthContext.Provider>
      </ErrorHandler>
    </ChakraProvider>
  );
};

export default App;
