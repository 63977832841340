import React, { useCallback, useMemo, useRef, useState } from "react";
import { Content, Crumbs, PageTitle } from "../Layout";
import itemsApi from "../../../shared/api/itemsApi";
import {
  BooleanColumnFilter,
  CheckboxCell,
  DataTable,
  DataTableCallbackRef,
  DefaultColumnFilter,
  DeleteButton,
  DeleteModal,
  EditButton,
  FetchDataParams,
  Pagination,
} from "../../../components/DataTable";
import { Column, useAsyncDebounce } from "react-table";
import breadcrumbs from "./breadcrumbs";
import { PRODUCERS_PATH } from "../../../config/paths";
import { NEW } from "../../../shared/lib/path-utils";
import { serializeFilters } from "../../../shared/lib/serializeFilters";
import { WithMeta } from "../../../types/WithMeta";
import { ItemsProducers } from "../../../generated/axios";
import { AxiosResponse } from "axios";
import { HStack, Icon } from "@chakra-ui/react";
import { formatDateTimeStr } from "../../../config/intl";
import { FaIndustry } from "react-icons/fa";
import Address from "../../../components/Address";
import { IBasicAddress } from "../../../types/IBasicAddress";

interface IRecord {
  id: number;
  name?: string;
  referent_name?: string;
  referent_email?: string;
  phone?: string;
  is_wholesaler?: boolean;
  direct_delivery?: boolean;
  addresses: Array<IBasicAddress & { is_production: boolean }>;
  date_created: string;
  date_updated?: string;
}

const columns: Column<IRecord>[] = [
  {
    Header: "Id",
    accessor: "id", // accessor is the "key" in the data
    Filter: DefaultColumnFilter,
    width: "85px",
  },
  {
    Header: "Ragione Sociale",
    accessor: "name",
    Filter: DefaultColumnFilter,
    width: undefined,
  },
  {
    Header: "Nome Referente",
    accessor: "referent_name",
    Filter: DefaultColumnFilter,
    width: undefined,
  },
  {
    Header: "Telefono",
    accessor: "phone",
    Filter: DefaultColumnFilter,
    width: undefined,
  },
  {
    Header: "Utilizza Piattaforma",
    accessor: "is_wholesaler",
    Filter: BooleanColumnFilter,
    Cell: ({ value }: { value: boolean }) => <CheckboxCell value={value} />,
    width: "95px",
  },
  {
    Header: "Consegna diretta",
    accessor: "direct_delivery",
    Filter: BooleanColumnFilter,
    Cell: ({ value }: { value: boolean }) => <CheckboxCell value={value} />,
    width: "95px",
  },
  {
    Header: "Indirizzo",
    accessor: (row) => {
      const address = row.addresses.find((a) => a.is_production);
      return address ? <Address address={address} /> : null;
    },
    disableFilters: true,
    width: undefined,
  },
  {
    id: "date_created",
    Header: "Data creazione",
    accessor: (row) => formatDateTimeStr(row.date_created),
    disableFilters: true,
    width: "165px",
  },
  {
    id: "date_updated",
    Header: "Data modifica",
    accessor: (row) => formatDateTimeStr(row.date_updated),
    disableFilters: true,
    width: "165px",
  },
  {
    id: "actions",
    Header: "Azioni",
    disableFilters: true,
    disableSortBy: true,
    accessor: (row) => row,
    width: "150px",
  },
];

const newButtonPath = NEW(PRODUCERS_PATH);

const doDelete = (id: number) => itemsApi.updateSingleItemsProducers(id, ["status"], undefined, { status: "archived" });

interface IProps {}

const Producers: React.FC<IProps> = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [toDelete, setToDelete] = useState<IRecord | undefined>(undefined);

  const memoizedColumns = useMemo(() => {
    const actionColumn = columns.find((column) => column.id === "actions");
    if (actionColumn) {
      // @ts-ignore
      actionColumn.Cell = ({ value }: { value: IRecord }) => (
        <HStack spacing="20px">
          <EditButton id={value.id} path={PRODUCERS_PATH} />
          <DeleteButton value={value} onClick={setToDelete} />
        </HStack>
      );
    }
    return columns;
  }, [setToDelete]);

  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    numPages: 0,
    totalRecords: 0,
  });

  const fetchData = useCallback(
    ({ filters, globalFilter, pageIndex, pageSize, sortBy }: FetchDataParams<IRecord>) => {
      const sort = sortBy.map((c) => `${c.desc ? "-" : ""}${c.id}`);
      setLoading(true);
      itemsApi
        .readItemsProducers(
          [
            "id",
            "name",
            "referent_name",
            "phone",
            "is_wholesaler",
            "direct_delivery",
            "addresses.id",
            "addresses.is_production",
            "addresses.street",
            "addresses.civic_number",
            "addresses.postal_code",
            "addresses.city",
            "addresses.province_id.id",
            "addresses.province_id.name",
            "addresses.province_id.code",
            "date_created",
            "date_updated",
          ],
          pageSize,
          "filter_count",
          pageIndex * pageSize,
          sort,
          serializeFilters({ status: "published" }, filters, {
            id: "_eq",
            is_wholesaler: "_eq",
            direct_delivery: "_eq",
          }),
          globalFilter
        )
        .then((response: AxiosResponse<WithMeta<ItemsProducers>>) => {
          const data = response.data.data;
          const totalRecords = response.data.meta?.filter_count || 0;
          setData(data || []);
          setPagination({
            page: pageIndex,
            numPages: Math.ceil(totalRecords / pageSize),
            totalRecords,
          });
        })
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
    },
    [setData, setPagination, setLoading]
  );
  const fetchDataDebounced = useAsyncDebounce(fetchData, 100);

  const closeDeleteModal = useCallback(() => setToDelete(undefined), [setToDelete]);

  const ref = useRef<DataTableCallbackRef>(null);

  return (
    <>
      <Crumbs path={breadcrumbs} />
      <Content>
        <PageTitle>
          <Icon as={FaIndustry} className="inline-icon" /> Lista produttori
        </PageTitle>
        <DataTable
          callbacksRef={ref}
          columns={memoizedColumns}
          data={data}
          labelButtonNew={"Nuovo Produttore"}
          fetchData={fetchDataDebounced}
          loading={loading}
          newButtonPath={newButtonPath}
          pagination={pagination}
        />
      </Content>
      <DeleteModal
        afterDelete={ref.current?.fetchData}
        close={closeDeleteModal}
        doDelete={doDelete}
        id={toDelete?.id || 0}
        title={"Elimina produttore"}
      >
        Vuoi eliminare il produttore {toDelete?.name || ""}?
      </DeleteModal>
    </>
  );
};

export default Producers;
