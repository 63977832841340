import { FSelectOption } from "../../components/FormElements/types";

export const URL_REGEX =
  /^$|^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;

export const validSelectedOption = (c: Partial<FSelectOption<number>>) =>
  Boolean(c && (c.value ?? false) && (c.label ?? false));

export function emptyToNull(currentValue: any, originalValue: any) {
  return originalValue === "" ? null : currentValue;
}
