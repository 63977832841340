import { ICoords, IGoogleCoords } from "../../types/ICoords";

function rad(x: number): number {
  return (x * Math.PI) / 180;
}

export function getDistance(center: IGoogleCoords, latitude?: number, longitude?: number): number {
  if (latitude === undefined || longitude === undefined) return Infinity;
  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(center.lat - latitude);
  const dLong = rad(center.lng - longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(latitude)) * Math.cos(rad(center.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // returns the distance in meter
}

type InsideFunction = (address: ICoords) => boolean;

export const insideFunctionFactory = (center: IGoogleCoords, km?: number): InsideFunction => {
  if (center !== undefined && km !== undefined) {
    return (address: ICoords) => getDistance(center, address.latitude, address.longitude) <= km * 1000;
  } else {
    return () => true;
  }
};
