import React from "react";
import { Container, Flex } from "@chakra-ui/react";
import Nav from "./Nav";
import Footer from "./Footer";

import "./Layout.css";
import ErrorHandler from "../../../components/ErrorHandler";

interface IProps {
  isFixedNav?: boolean;
}

const Layout: React.FC<IProps> = ({ children, isFixedNav = true }) => {
  return (
    <Flex
      bg="brand.background"
      minHeight="100%"
      width="100%"
      alignItems="center"
      justifyContent="top"
      flexDirection="column"
      paddingTop={isFixedNav ? { md: "4rem" } : "0"}
    >
      <ErrorHandler>
        <Nav />
      </ErrorHandler>

      <Container maxW="xxl" px="0" paddingTop="3rem" pb="60px">
        <ErrorHandler>{children}</ErrorHandler>
      </Container>
      <Footer />
    </Flex>
  );
};

export default Layout;
