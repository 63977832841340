import { useCallback, useState } from "react";

type Result = [flag: boolean, setOn: () => void, setOff: () => void];

export function useStateBoolean(initialState: boolean): Result {
  const [flag, set] = useState<boolean>(initialState);
  const setOn = useCallback(() => set(true), [set]);
  const setOff = useCallback(() => set(false), [set]);
  return [flag, setOn, setOff];
}
