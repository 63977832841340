import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStateBoolean } from "../../../shared/hooks/useStateBoolean";
import { Alert, AlertIcon, Button, HStack, SimpleGrid, useToast } from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FInput from "../../../components/FormElements/FInput";
import FSaveButton from "../../../components/FormElements/FSaveButton";
import FSaveAndExitButton from "../../../components/FormElements/FSaveAndExitButton";
import { IUser } from "./types";
import { INAVALID_EMAIL_MSG, MIN_LENGTH_MSG, REQUIRED_MGS } from "../../../components/FormElements/validationMessages";
import usersApi from "../../../shared/api/usersApi";
import rolesApi from "../../../shared/api/rolesApi";
import { USERS_PATH } from "../../../config/paths";
import { toastFailure, toastSuccess } from "../../../shared/lib/toast";
import { FSelectOption } from "../../../components/FormElements/types";
import FSelect from "../../../components/FormElements/FSelect";
import toOption from "../../../shared/lib/toOption";
import { Users, UsersStatusEnum } from "../../../generated/axios";
import { EDIT } from "../../../shared/lib/path-utils";

const schema = yup.object().shape({
  first_name: yup.string().required(REQUIRED_MGS),
  last_name: yup.string().required(REQUIRED_MGS),
  email: yup.string().required(REQUIRED_MGS).email(INAVALID_EMAIL_MSG),
  role: yup.string().required(REQUIRED_MGS),
  password: yup.lazy((value) =>
    value !== ""
      ? yup
          .string()
          .min(8, MIN_LENGTH_MSG)
          .matches(/[A-Z]/, "Almeno una lettera maiuscola")
          .matches(/[a-z]/, "Almeno una lettera minuscola")
          .matches(/\d/, "Almeno un numero")
      : yup.string()
  ),
});

interface IProps {
  id: string;
  item: IUser;
}

const UserEditForm: React.FC<IProps> = ({ id, item }) => {
  const [exit, setExit] = useStateBoolean(false);
  const toast = useToast();
  const history = useHistory();
  const [roles, setRoles] = useState<FSelectOption<string>[]>([]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<IUser>({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  const onSubmit = (user: IUser) => {
    const fields = ["fist_name", "last_name", "email", "role"];
    if (user.password === "" || user.password === null) {
      delete user.password;
    }
    const apiCall =
      id === "new" ? usersApi.createUser(undefined, user) : usersApi.updateUser(id, fields, undefined, user);
    return apiCall
      .then((response) => {
        toast(toastSuccess("Utente salvato con successo"));
        if (exit) history.push(USERS_PATH);
        else if (id === "new") {
          const responseId = (response.data.data as unknown as Users)?.id;
          responseId && history.push(EDIT(USERS_PATH, String(responseId)));
        }
      })
      .catch((e: any) => {
        toast(toastFailure("Errore nel salvataggio dell'utente", String(e)));
      });
  };

  const loadRoles = useCallback(() => {
    rolesApi
      .getRoles(["id", "name"], -1, 0, undefined, ["name"])
      .then((response) => {
        const allRoles: FSelectOption<string>[] = response.data.data?.map(toOption) || [];
        setRoles(allRoles);
      })
      .catch((e) => {
        toast(toastFailure("Errore caricamento ruoli", String(e)));
      });
  }, [setRoles, toast]);

  useEffect(loadRoles, [loadRoles]);

  return (
    <>
      {item.status === UsersStatusEnum.Deleted ? (
        <Alert status="warning" mb={4}>
          <AlertIcon />
          Utente cancellato!
        </Alert>
      ) : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={[1, null, 2]} spacing={"8px"}>
          <Controller
            name={"first_name"}
            control={control}
            render={({ field }) => <FInput label={"Nome"} error={errors.first_name} required={true} {...field} />}
          />

          <Controller
            name={"last_name"}
            control={control}
            render={({ field }) => <FInput label={"Cognome"} error={errors.last_name} required={true} {...field} />}
          />
        </SimpleGrid>

        <SimpleGrid columns={[1, null, 2]} spacing={"8px"}>
          <Controller
            name={"email"}
            control={control}
            render={({ field }) => <FInput label={"Email"} error={errors.email} required={true} {...field} />}
          />

          <Controller
            name={"password"}
            control={control}
            render={({ field }) => <FInput label={"Password"} error={errors.password} {...field} />}
          />
        </SimpleGrid>

        <SimpleGrid columns={[1, null, 2]} spacing={"8px"}>
          <Controller
            name={"role"}
            control={control}
            render={({ field }) => (
              <FSelect label={"Ruolo"} error={errors.role} options={roles} required={true} {...field} />
            )}
          />
        </SimpleGrid>

        <HStack mt={6}>
          <Button onClick={() => reset()}>Reset</Button>
          {id !== "new" ? <FSaveButton disabled={isSubmitting && exit} isLoading={isSubmitting && !exit} /> : null}
          <FSaveAndExitButton disabled={isSubmitting && !exit} isLoading={isSubmitting && exit} onClick={setExit} />
        </HStack>
      </form>
    </>
  );
};

export default UserEditForm;
