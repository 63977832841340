import { FilterValue, IdType } from "react-table";

type OperatorType = "_eq" | "_gt" | "_gte" | "_lt" | "_lte" | "_contains" | "_in";

export type ExtendedFilters<D extends object> = Array<{ id: IdType<D>; value: FilterValue; operator?: OperatorType }>;

/**
 * Costruzione della stringa di filtro partendo da array di filters
 * preFilters (utilizzano sempre l'operatore "_eq", non serve passarlo tra gli operators)
 * Es: { status: "published" }
 *
 * filters (se non diversamente indicato utilizzano l'operatore "_contains")
 * Es: [{id: "category_id.name", value: "valore"}]
 * Es: [{id: "price", value: 12, operator: "_gte"}]
 *
 * operators (possono essere indicati anche come ultimo parametro)
 * Es: { "category_id.name": "_contains" }
 *
 * return
 * { "status": { "_eq": "published" }, "category_id": { "name": { "_contains": "valore" } } }
 */
export function serializeFilters<D extends object>(
  preFilters: Record<string, string | number | boolean | null>, // utilizzano sempre l'operatore _eq
  filters: ExtendedFilters<D>,
  operators?: Record<string, OperatorType>
): string | undefined {
  const _filters = [...filters];
  const _operators = operators ? operators : {};
  for (const [id, value] of Object.entries(preFilters)) {
    _filters.unshift({ id, value, operator: "_eq" });
  }

  if (_filters.length > 0) {
    const filterArray = _filters.map((f) => {
      const operator: OperatorType = f.operator || _operators[f.id] || "_contains";
      let result: any = { [operator]: f.value };
      const parts = f.id.split(".").reverse();
      for (let i = 0; i < parts.length; i++) {
        result = { [parts[i]]: result };
      }
      return result;
    });
    return JSON.stringify(filterArray.length === 1 ? filterArray[0] : { _and: filterArray });
  }
  return undefined;
}
