import { ISearch, ISearchData } from "../types";
import { IGoogleCoords } from "../../../../types/ICoords";
import { searchTypeOptions } from "../SearchTypeOptions";

export function toSearchData(values: ISearch & { center?: IGoogleCoords }): ISearchData[] {
  const center = values.center;
  const searchTypeOption = searchTypeOptions.find((o) => o.value === values.search_type);
  return [
    ["Prodotto", "=", values.product_id?.label, values.product_id?.value],
    ["Categoria merceologica", "=", values.category_id?.label, values.category_id?.value],
    ["Nome prodotto", "contiene", "", values.product],
    ["Prezzo da", ">=", "", values.price_from?.toFixed(4)],
    ["Prezzo a", "<=", "", values.price_to?.toFixed(4)],
    ["Biologico", "=", values.is_biologic ? "Sì" : "", values.is_biologic ? "true" : ""],
    ["DOP", "=", values.is_dop ? "Sì" : "", values.is_dop ? "true" : ""],
    ["IGP", "=", values.is_igp ? "Sì" : "", values.is_igp ? "true" : ""],
    ["STG", "=", values.is_stg ? "Sì" : "", values.is_stg ? "true" : ""],
    ["Lotta integrata", "=", values.is_integrated_fight ? "Sì" : "", values.is_integrated_fight ? "true" : ""],
    ["Cooperativa sociale", "=", values.is_social_cooperative ? "Sì" : "", values.is_social_cooperative ? "true" : ""],
    ["Equosolidale", "=", values.is_equosolidal ? "Sì" : "", values.is_equosolidal ? "true" : ""],
    ["MSC", "=", values.is_msc ? "Sì" : "", values.is_msc ? "true" : ""],
    ["AQUA", "=", values.is_aqua ? "Sì" : "", values.is_aqua ? "true" : ""],
    ["Prod. locale", "=", values.is_local ? "Sì" : "", values.is_local ? "true" : ""],
    ["PAT", "=", values.is_pat ? "Sì" : "", values.is_pat ? "true" : ""],
    ["Halal", "=", values.is_halal ? "Sì" : "", values.is_halal ? "true" : ""],
    ["Sociale", "=", values.is_social ? "Sì" : "", values.is_social ? "true" : ""],
    ["FAO 37/27", "=", values.is_fao_37_27 ? "Sì" : "", values.is_fao_37_27 ? "true" : ""],
    ["Kosher", "=", values.is_kosher ? "Sì" : "", values.is_kosher ? "true" : ""],
    [
      "Certificazioni",
      "=",
      values.certifications?.map((c) => c.label).join("; "),
      values.certifications?.map((c) => c.value).join("; "),
    ],
    ["Piattaforma", "=", values.platform_id?.label, values.platform_id?.value],
    ["Fonte", "=", values.source_id?.label, values.source_id?.value],
    ["Utilizza Piattaforma", "=", values.is_wholesaler ? `Sì` : "", values.is_wholesaler ? `true` : ""],
    ["Consegna diretta", "=", values.direct_delivery ? `Sì` : "", values.direct_delivery ? `true` : ""],
    ["Provincia", "=", values.province_id?.label, values.province_id?.value],
    ["Regione", "=", values.region_id?.label, values.region_id?.value],
    ["Ricerca per", "=", searchTypeOption?.label, searchTypeOption?.value],
    ["Indirizzo di ricerca", "=", values.address, center ? `(${center.lat}; ${center.lng})` : ""],
    ["Raggio (in km)", "<=", "", values.km ?? undefined],
  ];
}
