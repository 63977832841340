import React from "react";
import { Button, ModalBody, ModalFooter, useToast } from "@chakra-ui/react";
import { Controller, useForm, UseFormGetValues } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REQUIRED_MGS } from "../../../components/FormElements/validationMessages";
import itemsApi from "../../../shared/api/itemsApi";
import { toastFailure, toastSuccess } from "../../../shared/lib/toast";
import FInput from "../../../components/FormElements/FInput";
import FSaveButton from "../../../components/FormElements/FSaveButton";
import { UseFormSetValue } from "react-hook-form/dist/types/form";
import { IProducer } from "./types";
import toOption from "../../../shared/lib/toOption";
import { ItemsPlatforms } from "../../../generated/axios";

interface IFormValues {
  status?: string;
  name?: string;
}

const schema = yup.object().shape({
  name: yup.string().required(REQUIRED_MGS),
});

interface IProps {
  getValues: UseFormGetValues<IProducer>;
  onClose: () => void;
  setValue: UseFormSetValue<IProducer>;
}

export const NewPlatformModalForm: React.FC<IProps> = ({ getValues, onClose, setValue }) => {
  const toast = useToast();
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: { name: "", status: "published" },
  });

  const onSubmit = (values: IFormValues) => {
    return itemsApi
      .createItemsPlatforms(undefined, values)
      .then((response) => {
        const platform = toOption<number>(response.data.data! as unknown as ItemsPlatforms);
        const platforms = getValues("platforms");
        setValue("platforms", [...platforms, platform]);
        onClose();
        toast(toastSuccess("Piattaforma creata con successo"));
      })
      .catch((e: any) => {
        toast(toastFailure("Errore nella creazione della piattaforma", String(e)));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody pb={6}>
        <Controller
          name={"name"}
          control={control}
          render={({ field }) => (
            <FInput autoFocus={true} label={"Piattaforma"} error={errors.name} required={true} {...field} />
          )}
        />
      </ModalBody>

      <ModalFooter>
        <Button onClick={onClose} mr={3}>
          Annulla
        </Button>
        <FSaveButton isLoading={isSubmitting} />
      </ModalFooter>
    </form>
  );
};

export default NewPlatformModalForm;
