import React, { useCallback, useEffect, useState } from "react";
import { useStateBoolean } from "../../../shared/hooks/useStateBoolean";
import { useHistory } from "react-router-dom";
import { Button, HStack, SimpleGrid, useToast } from "@chakra-ui/react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { IImport } from "./types";
import { API_SERVER } from "../../../config/constants";
import { IMPORTS_PATH } from "../../../config/paths";
import { REQUIRED_MGS } from "../../../components/FormElements/validationMessages";
import { toastFailure, toastSuccess } from "../../../shared/lib/toast";
import toOption from "../../../shared/lib/toOption";
import { FSelectOption } from "../../../components/FormElements/types";
import FSaveAndExitButton from "../../../components/FormElements/FSaveAndExitButton";
import FSaveButton from "../../../components/FormElements/FSaveButton";
import FSelectS from "../../../components/FormElements/FSelectS";
import FUpload from "../../../components/FormElements/FUpload";
import itemsApi from "../../../shared/api/itemsApi";

import { FaFileExcel } from "react-icons/all";
import axios from "axios";
import { ItemsImports } from "../../../generated/axios";
import { validSelectedOption } from "../../../shared/lib/validation-utils";

const newImport: IImport = {
  type_id: null,
  import_file: "",
  status: "published",
};
const schema = yup.object().shape({
  // type_id: yup.array().required(REQUIRED_MGS),
  import_file: yup.string().required(REQUIRED_MGS),
  type_id: yup
    .object()
    .typeError(REQUIRED_MGS)
    .required(REQUIRED_MGS)
    .test("type_id_required", REQUIRED_MGS, validSelectedOption),
});

interface IProps {}

const ImportNewForm: React.FC<IProps> = () => {
  const id = "new";
  const [exit, setExit] = useStateBoolean(false);
  const toast = useToast();
  const history = useHistory();
  const [importTypes, setImportTypes] = useState<FSelectOption<number>[]>([]);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<IImport>({
    resolver: yupResolver(schema),
    defaultValues: newImport,
  });

  const onSubmit = (values: IImport) => {
    const field = document.querySelector("input[name='import_file']") as HTMLInputElement;
    const files = field?.files;
    if (files && files.length) {
      const file: File = files[0];
      const formData = new FormData();
      formData.append("title", file.name);
      formData.append("file", file);
      return axios
        .post(`${API_SERVER}/files`, formData)
        .then((response) => {
          const importFileId = response.data.data.id;
          const itemImport: ItemsImports = {
            type_id: values.type_id?.value,
            import_file: importFileId,
            status: "published",
          };
          return itemsApi
            .createItemsImports(undefined, itemImport)
            .then(() => {
              toast(toastSuccess("Nuovo import creato con successo"));
              history.push(IMPORTS_PATH);
            })
            .catch((e: any) => {
              console.error(e);
              toast(toastFailure("Errore nel salvataggio dell'import", String(e)));
            });
        })
        .catch((e: any) => {
          console.error(e);
          toast(toastFailure("Errore nel caricamento del file Excel", String(e)));
        });
    }
  };

  const loadTypes = useCallback(() => {
    itemsApi
      .readItemsImportTypes(["id", "name"], -1, undefined, 0, ["name"])
      .then((response) => {
        const types: FSelectOption<number>[] = response.data.data?.map(toOption) || [];
        setImportTypes(types);
      })
      .catch((e) => {
        toast(toastFailure("Errore caricamento dei tipi importazione disponibili", String(e)));
      });
  }, [setImportTypes, toast]);

  useEffect(loadTypes, [loadTypes]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={[1, null, 2]} spacing={"8px"}>
          <Controller
            name={"type_id"}
            control={control}
            render={({ field }) => (
              <FSelectS
                {...field}
                label={"Tipo import"}
                error={errors.type_id as FieldError | undefined}
                options={importTypes}
                required={true}
              />
            )}
          />
          <Controller
            name={"import_file"}
            control={control}
            render={({ field }) => (
              <FUpload
                acceptedFileTypes={".xlsx"}
                placeholder={"Seleziona un file Excel"}
                label={"Import file"}
                error={errors.import_file as FieldError | undefined}
                required={true}
                iconLeft={FaFileExcel}
                {...field}
              />
            )}
          />
        </SimpleGrid>

        <HStack mt={6}>
          <Button onClick={() => reset()}>Reset</Button>
          {id !== "new" ? <FSaveButton disabled={isSubmitting && exit} isLoading={isSubmitting && !exit} /> : null}
          <FSaveAndExitButton disabled={isSubmitting && !exit} isLoading={isSubmitting && exit} onClick={setExit} />
        </HStack>
      </form>
    </>
  );
};

export default ImportNewForm;
