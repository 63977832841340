import React from "react";
import { Select } from "@chakra-ui/react";

interface IProps {
  column: {
    filterValue: string;
    setFilter: (value?: string) => void;
  };
  gotoPage: (pageIndex: number) => void;
}

const BooleanColumnFilter: React.FC<IProps> = ({ column: { filterValue, setFilter }, gotoPage }) => {
  return (
    <Select
      value={filterValue || ""}
      onChange={(e) => {
        gotoPage(0);
        setFilter(e.target.value || undefined);
      }}
    >
      <option key="" value="" />
      <option key="true" value={"true"}>
        Sì
      </option>
      <option key="false" value={"false"}>
        No
      </option>
    </Select>
  );
};

export default BooleanColumnFilter;
