import React from "react";
import { Container, Text } from "@chakra-ui/react";
import { APP_NAME } from "../../../config/constants";
import { version } from "../../../../package.json";

interface IProps {}

const Footer: React.FC<IProps> = () => {
  const year = new Date().getFullYear();

  return (
    <Container
        maxW="xxl"
        marginTop="auto"
        py="4"
        bg="brand.mainContainerBgColor"
        boxShadow="dark-lg"
        position={{ md: "fixed" }}
        bottom="0"
    >
        <Text color="secondary.link" textAlign="center">
          &copy; {year} Dussmann &mdash; All rights reserved &mdash; {APP_NAME} versione {version}
        </Text>
    </Container>
  );
};

export default Footer;
