import { useCallback, useState } from "react";

type Result<T> = [data: T, setData: (value: T) => void, loading: boolean];

export function useStateLoading<T>(initialState: T): Result<T> {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T>(initialState);
  const setDataCallback = useCallback(
    (value: T) => {
      setData(value);
      setLoading(false);
    },
    [setData, setLoading]
  );
  return [data, setDataCallback, loading];
}
