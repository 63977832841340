/* tslint:disable */
/* eslint-disable */
/**
 * Dynamic API Specification
 * This is a dynamically generated API specification for all endpoints existing on the current project.
 *
 * The version of the OpenAPI document: 9.0.0-rc.89
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * Unique identifier for the object.
     * @type {number}
     * @memberof Activity
     */
    id?: number;
    /**
     * Action that was performed.
     * @type {string}
     * @memberof Activity
     */
    action?: ActivityActionEnum;
    /**
     * The user who performed this action.
     * @type {string | Users}
     * @memberof Activity
     */
    user?: string | Users | null;
    /**
     * When the action happened.
     * @type {string}
     * @memberof Activity
     */
    timestamp?: string;
    /**
     * The IP address of the user at the time the action took place.
     * @type {string}
     * @memberof Activity
     */
    ip?: string;
    /**
     * User agent string of the browser the user used when the action took place.
     * @type {string}
     * @memberof Activity
     */
    user_agent?: string;
    /**
     * Collection identifier in which the item resides.
     * @type {string | Collections}
     * @memberof Activity
     */
    collection?: string | Collections;
    /**
     * Unique identifier for the item the action applied to. This is always a string, even for integer primary keys.
     * @type {string}
     * @memberof Activity
     */
    item?: string;
    /**
     * User comment. This will store the comments that show up in the right sidebar of the item edit page in the admin app.
     * @type {string}
     * @memberof Activity
     */
    comment?: string | null;
    /**
     * 
     * @type {Array<number | Revisions>}
     * @memberof Activity
     */
    revisions?: Array<number | Revisions>;
}

/**
    * @export
    * @enum {string}
    */
export enum ActivityActionEnum {
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
    Authenticate = 'authenticate'
}

/**
 * 
 * @export
 * @interface Collections
 */
export interface Collections {
    /**
     * The collection key.
     * @type {string}
     * @memberof Collections
     */
    collection?: string;
    /**
     * 
     * @type {string}
     * @memberof Collections
     */
    icon?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Collections
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Collections
     */
    display_template?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Collections
     */
    hidden?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Collections
     */
    singleton?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Collections
     */
    translations?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Collections
     */
    archive_field?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Collections
     */
    archive_app_filter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Collections
     */
    archive_value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Collections
     */
    unarchive_value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Collections
     */
    sort_field?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Collections
     */
    accountability?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Collections
     */
    color?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Collections
     */
    item_duplication_fields?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Collections
     */
    collection_divider?: string;
    /**
     * 
     * @type {string}
     * @memberof Collections
     */
    archive_divider?: string;
    /**
     * 
     * @type {string}
     * @memberof Collections
     */
    sort_divider?: string;
    /**
     * 
     * @type {string}
     * @memberof Collections
     */
    accountability_divider?: string;
    /**
     * 
     * @type {string}
     * @memberof Collections
     */
    duplication_divider?: string;
}
/**
 * Metadata of the collection.
 * @export
 * @interface CollectionsIdMeta
 */
export interface CollectionsIdMeta {
    /**
     * Name of a Google Material Design Icon that\'s assigned to this collection.
     * @type {string}
     * @memberof CollectionsIdMeta
     */
    icon?: string | null;
    /**
     * Choose the color for the icon assigned to this collection.
     * @type {string}
     * @memberof CollectionsIdMeta
     */
    color?: string | null;
    /**
     * A note describing the collection.
     * @type {string}
     * @memberof CollectionsIdMeta
     */
    note?: string | null;
    /**
     * Text representation of how items from this collection are shown across the system.
     * @type {string}
     * @memberof CollectionsIdMeta
     */
    display_template?: string | null;
    /**
     * Whether or not the collection is hidden from the navigation in the admin app.
     * @type {boolean}
     * @memberof CollectionsIdMeta
     */
    hidden?: boolean;
    /**
     * Whether or not the collection is treated as a single object.
     * @type {boolean}
     * @memberof CollectionsIdMeta
     */
    singleton?: boolean;
    /**
     * Key value pairs of how to show this collection\'s name in different languages in the admin app.
     * @type {string}
     * @memberof CollectionsIdMeta
     */
    translation?: string | null;
    /**
     * What field holds the archive value.
     * @type {string}
     * @memberof CollectionsIdMeta
     */
    archive_field?: string | null;
    /**
     * What value to use for \"archived\" items.
     * @type {string}
     * @memberof CollectionsIdMeta
     */
    archive_app_filter?: string | null;
    /**
     * What value to use to \"unarchive\" items.
     * @type {string}
     * @memberof CollectionsIdMeta
     */
    archive_value?: string | null;
    /**
     * Whether or not to show the \"archived\" filter.
     * @type {string}
     * @memberof CollectionsIdMeta
     */
    unarchive_value?: string | null;
    /**
     * The sort field in the collection.
     * @type {string}
     * @memberof CollectionsIdMeta
     */
    sort_field?: string | null;
}
/**
 * 
 * @export
 * @interface Fields
 */
export interface Fields {
    /**
     * 
     * @type {number}
     * @memberof Fields
     */
    id?: number;
    /**
     * Unique name of the collection this field is in.
     * @type {string}
     * @memberof Fields
     */
    collection?: string;
    /**
     * Unique name of the field. Field name is unique within the collection.
     * @type {string}
     * @memberof Fields
     */
    field?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Fields
     */
    special?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Fields
     */
    _interface?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Fields
     */
    options?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Fields
     */
    display?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Fields
     */
    display_options?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Fields
     */
    readonly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Fields
     */
    hidden?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Fields
     */
    sort?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Fields
     */
    width?: string | null;
    /**
     * 
     * @type {number | Fields}
     * @memberof Fields
     */
    group?: number | Fields | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Fields
     */
    translations?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Fields
     */
    note?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Fields
     */
    conditions?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof Fields
     */
    required?: number | null;
}
/**
 * The meta info.
 * @export
 * @interface FieldsCollectionMeta
 */
export interface FieldsCollectionMeta {
    /**
     * Unique identifier for the field in the `directus_fields` collection.
     * @type {number}
     * @memberof FieldsCollectionMeta
     */
    id?: number;
    /**
     * Unique name of the collection this field is in.
     * @type {string}
     * @memberof FieldsCollectionMeta
     */
    collection?: string;
    /**
     * Unique name of the field. Field name is unique within the collection.
     * @type {string}
     * @memberof FieldsCollectionMeta
     */
    field?: string;
    /**
     * Transformation flag for field
     * @type {Array<string>}
     * @memberof FieldsCollectionMeta
     */
    special?: Array<string> | null;
    /**
     * What interface is used in the admin app to edit the value for this field.
     * @type {string}
     * @memberof FieldsCollectionMeta
     */
    system_interface?: string | null;
    /**
     * Options for the interface that\'s used. This format is based on the individual interface.
     * @type {object}
     * @memberof FieldsCollectionMeta
     */
    options?: object | null;
    /**
     * What display is used in the admin app to display the value for this field.
     * @type {string}
     * @memberof FieldsCollectionMeta
     */
    display?: string | null;
    /**
     * Options for the display that\'s used. This format is based on the individual display.
     * @type {object}
     * @memberof FieldsCollectionMeta
     */
    display_options?: object | null;
    /**
     * If the field can be altered by the end user. Directus system fields have this value set to `true`.
     * @type {boolean}
     * @memberof FieldsCollectionMeta
     */
    locked?: boolean;
    /**
     * Prevents the user from editing the value in the field.
     * @type {boolean}
     * @memberof FieldsCollectionMeta
     */
    readonly?: boolean;
    /**
     * If this field should be hidden.
     * @type {boolean}
     * @memberof FieldsCollectionMeta
     */
    hidden?: boolean;
    /**
     * Sort order of this field on the edit page of the admin app.
     * @type {number}
     * @memberof FieldsCollectionMeta
     */
    sort?: number | null;
    /**
     * Width of the field on the edit form.
     * @type {string}
     * @memberof FieldsCollectionMeta
     */
    width?: FieldsCollectionMetaWidthEnum;
    /**
     * What field group this field is part of.
     * @type {number}
     * @memberof FieldsCollectionMeta
     */
    group?: number | null;
    /**
     * Key value pair of `<language>: <translation>` that allows the user to change the displayed name of the field in the admin app.
     * @type {object}
     * @memberof FieldsCollectionMeta
     */
    translation?: object | null;
    /**
     * A user provided note for the field. Will be rendered alongside the interface on the edit page.
     * @type {string}
     * @memberof FieldsCollectionMeta
     */
    note?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum FieldsCollectionMetaWidthEnum {
    Half = 'half',
    HalfLeft = 'half-left',
    HalfRight = 'half-right',
    Full = 'full',
    Fill = 'fill',
    Null = 'null'
}

/**
 * The schema info.
 * @export
 * @interface FieldsCollectionSchema
 */
export interface FieldsCollectionSchema {
    /**
     * The name of the field.
     * @type {string}
     * @memberof FieldsCollectionSchema
     */
    name?: string;
    /**
     * The collection of the field.
     * @type {string}
     * @memberof FieldsCollectionSchema
     */
    table?: string;
    /**
     * The type of the field.
     * @type {string}
     * @memberof FieldsCollectionSchema
     */
    type?: string;
    /**
     * The default value of the field.
     * @type {string}
     * @memberof FieldsCollectionSchema
     */
    default_value?: string | null;
    /**
     * The max length of the field.
     * @type {number}
     * @memberof FieldsCollectionSchema
     */
    max_length?: number | null;
    /**
     * If the field is nullable.
     * @type {boolean}
     * @memberof FieldsCollectionSchema
     */
    is_nullable?: boolean;
    /**
     * If the field is primary key.
     * @type {boolean}
     * @memberof FieldsCollectionSchema
     */
    is_primary_key?: boolean;
    /**
     * If the field has auto increment.
     * @type {boolean}
     * @memberof FieldsCollectionSchema
     */
    has_auto_increment?: boolean;
    /**
     * Related column from the foreign key constraint.
     * @type {string}
     * @memberof FieldsCollectionSchema
     */
    foreign_key_column?: string | null;
    /**
     * Related table from the foreign key constraint.
     * @type {string}
     * @memberof FieldsCollectionSchema
     */
    foreign_key_table?: string | null;
    /**
     * Comment as saved in the database.
     * @type {string}
     * @memberof FieldsCollectionSchema
     */
    comment?: string | null;
    /**
     * Database schema (pg only).
     * @type {string}
     * @memberof FieldsCollectionSchema
     */
    schema?: string;
    /**
     * Related schema from the foreign key constraint (pg only).
     * @type {string}
     * @memberof FieldsCollectionSchema
     */
    foreign_key_schema?: string | null;
}
/**
 * 
 * @export
 * @interface Files
 */
export interface Files {
    /**
     * Unique identifier for the file.
     * @type {string}
     * @memberof Files
     */
    id?: string;
    /**
     * Where the file is stored. Either `local` for the local filesystem or the name of the storage adapter (for example `s3`).
     * @type {string}
     * @memberof Files
     */
    storage?: string;
    /**
     * Name of the file on disk. By default, Directus uses a random hash for the filename.
     * @type {string}
     * @memberof Files
     */
    filename_disk?: string;
    /**
     * How you want to the file to be named when it\'s being downloaded.
     * @type {string}
     * @memberof Files
     */
    filename_download?: string;
    /**
     * Title for the file. Is extracted from the filename on upload, but can be edited by the user.
     * @type {string}
     * @memberof Files
     */
    title?: string;
    /**
     * MIME type of the file.
     * @type {string}
     * @memberof Files
     */
    type?: string;
    /**
     * Virtual folder where this file resides in.
     * @type {string | Folders}
     * @memberof Files
     */
    folder?: string | Folders | null;
    /**
     * Who uploaded the file.
     * @type {string | Users}
     * @memberof Files
     */
    uploaded_by?: string | Users;
    /**
     * When the file was uploaded.
     * @type {string}
     * @memberof Files
     */
    uploaded_on?: string;
    /**
     * 
     * @type {string | Users}
     * @memberof Files
     */
    modified_by?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof Files
     */
    modified_on?: string;
    /**
     * Character set of the file.
     * @type {string}
     * @memberof Files
     */
    charset?: string | null;
    /**
     * Size of the file in bytes.
     * @type {number}
     * @memberof Files
     */
    filesize?: number;
    /**
     * Width of the file in pixels. Only applies to images.
     * @type {number}
     * @memberof Files
     */
    width?: number | null;
    /**
     * Height of the file in pixels. Only applies to images.
     * @type {number}
     * @memberof Files
     */
    height?: number | null;
    /**
     * Duration of the file in seconds. Only applies to audio and video.
     * @type {number}
     * @memberof Files
     */
    duration?: number | null;
    /**
     * Where the file was embedded from.
     * @type {string}
     * @memberof Files
     */
    embed?: string | null;
    /**
     * Description for the file.
     * @type {string}
     * @memberof Files
     */
    description?: string | null;
    /**
     * Where the file was created. Is automatically populated based on EXIF data for images.
     * @type {string}
     * @memberof Files
     */
    location?: string | null;
    /**
     * Tags for the file. Is automatically populated based on EXIF data for images.
     * @type {Array<string>}
     * @memberof Files
     */
    tags?: Array<string> | null;
    /**
     * IPTC, EXIF, and ICC metadata extracted from file
     * @type {object}
     * @memberof Files
     */
    metadata?: object | null;
    /**
     * 
     * @type {string}
     * @memberof Files
     */
    storage_divider?: string;
}
/**
 * 
 * @export
 * @interface Folders
 */
export interface Folders {
    /**
     * Unique identifier for the folder.
     * @type {string}
     * @memberof Folders
     */
    id?: string;
    /**
     * Name of the folder.
     * @type {string}
     * @memberof Folders
     */
    name?: string;
    /**
     * Unique identifier of the parent folder. This allows for nested folders.
     * @type {string | Folders}
     * @memberof Folders
     */
    parent?: string | Folders | null;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * Email address of the user you\'re retrieving the access token for.
     * @type {string}
     * @memberof InlineObject
     */
    email: string;
    /**
     * Password of the user.
     * @type {string}
     * @memberof InlineObject
     */
    password: string;
    /**
     * Choose between retrieving the token as a string, or setting it as a cookie.
     * @type {string}
     * @memberof InlineObject
     */
    mode?: InlineObjectModeEnum;
    /**
     * If 2FA is enabled, you need to pass the one time password.
     * @type {string}
     * @memberof InlineObject
     */
    otp?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineObjectModeEnum {
    Json = 'json',
    Cookie = 'cookie'
}

/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * JWT access token you want to refresh. This token can\'t be expired.
     * @type {string}
     * @memberof InlineObject1
     */
    refresh_token?: string;
}
/**
 * 
 * @export
 * @interface InlineObject10
 */
export interface InlineObject10 {
    /**
     * Unique name of the field. Field name is unique within the collection.
     * @type {string}
     * @memberof InlineObject10
     */
    field?: string;
    /**
     * Directus specific data type. Used to cast values in the API.
     * @type {string}
     * @memberof InlineObject10
     */
    type?: string;
    /**
     * 
     * @type {FieldsCollectionSchema}
     * @memberof InlineObject10
     */
    schema?: FieldsCollectionSchema;
    /**
     * 
     * @type {FieldsCollectionMeta}
     * @memberof InlineObject10
     */
    meta?: FieldsCollectionMeta | null;
}
/**
 * 
 * @export
 * @interface InlineObject11
 */
export interface InlineObject11 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject11
     */
    data?: string;
}
/**
 * 
 * @export
 * @interface InlineObject12
 */
export interface InlineObject12 {
    /**
     * Name of the folder.
     * @type {string}
     * @memberof InlineObject12
     */
    name: string;
    /**
     * Unique identifier of the parent folder. This allows for nested folders.
     * @type {number}
     * @memberof InlineObject12
     */
    parent?: number;
}
/**
 * 
 * @export
 * @interface InlineObject13
 */
export interface InlineObject13 {
    /**
     * Name of the folder. Can\'t be null or empty.
     * @type {string}
     * @memberof InlineObject13
     */
    name?: string;
    /**
     * Unique identifier of the parent folder. This allows for nested folders.
     * @type {number}
     * @memberof InlineObject13
     */
    parent?: number;
}
/**
 * 
 * @export
 * @interface InlineObject14
 */
export interface InlineObject14 {
    /**
     * What collection this permission applies to.
     * @type {string}
     * @memberof InlineObject14
     */
    collection?: string;
    /**
     * If the user can post comments.
     * @type {string}
     * @memberof InlineObject14
     */
    comment?: InlineObject14CommentEnum;
    /**
     * If the user can create items.
     * @type {string}
     * @memberof InlineObject14
     */
    create?: InlineObject14CreateEnum;
    /**
     * If the user can update items.
     * @type {string}
     * @memberof InlineObject14
     */
    _delete?: InlineObject14DeleteEnum;
    /**
     * If the user is required to leave a comment explaining what was changed.
     * @type {string}
     * @memberof InlineObject14
     */
    explain?: InlineObject14ExplainEnum;
    /**
     * If the user can read items.
     * @type {string}
     * @memberof InlineObject14
     */
    read?: InlineObject14ReadEnum;
    /**
     * Unique identifier of the role this permission applies to.
     * @type {number}
     * @memberof InlineObject14
     */
    role?: number;
    /**
     * Explicitly denies read access for specific fields.
     * @type {Array<string>}
     * @memberof InlineObject14
     */
    read_field_blacklist?: Array<string>;
    /**
     * What status this permission applies to.
     * @type {string}
     * @memberof InlineObject14
     */
    status?: string;
    /**
     * Explicitly denies specific statuses to be used.
     * @type {Array<string>}
     * @memberof InlineObject14
     */
    status_blacklist?: Array<string>;
    /**
     * If the user can update items.
     * @type {string}
     * @memberof InlineObject14
     */
    update?: InlineObject14UpdateEnum;
    /**
     * Explicitly denies write access for specific fields.
     * @type {Array<string>}
     * @memberof InlineObject14
     */
    write_field_blacklist?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineObject14CommentEnum {
    None = 'none',
    Create = 'create',
    Update = 'update',
    Full = 'full'
}
/**
    * @export
    * @enum {string}
    */
export enum InlineObject14CreateEnum {
    None = 'none',
    Full = 'full'
}
/**
    * @export
    * @enum {string}
    */
export enum InlineObject14DeleteEnum {
    None = 'none',
    Mine = 'mine',
    Role = 'role',
    Full = 'full'
}
/**
    * @export
    * @enum {string}
    */
export enum InlineObject14ExplainEnum {
    None = 'none',
    Create = 'create',
    Update = 'update',
    Always = 'always'
}
/**
    * @export
    * @enum {string}
    */
export enum InlineObject14ReadEnum {
    None = 'none',
    Mine = 'mine',
    Role = 'role',
    Full = 'full'
}
/**
    * @export
    * @enum {string}
    */
export enum InlineObject14UpdateEnum {
    None = 'none',
    Mine = 'mine',
    Role = 'role',
    Full = 'full'
}

/**
 * 
 * @export
 * @interface InlineObject15
 */
export interface InlineObject15 {
    /**
     * What collection this permission applies to.
     * @type {object}
     * @memberof InlineObject15
     */
    collection?: object;
    /**
     * If the user can post comments. `full`.
     * @type {string}
     * @memberof InlineObject15
     */
    comment?: InlineObject15CommentEnum;
    /**
     * If the user can create items.
     * @type {string}
     * @memberof InlineObject15
     */
    create?: InlineObject15CreateEnum;
    /**
     * If the user can update items.
     * @type {string}
     * @memberof InlineObject15
     */
    _delete?: InlineObject15DeleteEnum;
    /**
     * If the user is required to leave a comment explaining what was changed.
     * @type {string}
     * @memberof InlineObject15
     */
    explain?: InlineObject15ExplainEnum;
    /**
     * If the user can read items.
     * @type {string}
     * @memberof InlineObject15
     */
    read?: InlineObject15ReadEnum;
    /**
     * Explicitly denies read access for specific fields.
     * @type {object}
     * @memberof InlineObject15
     */
    read_field_blacklist?: object;
    /**
     * Unique identifier of the role this permission applies to.
     * @type {object}
     * @memberof InlineObject15
     */
    role?: object;
    /**
     * What status this permission applies to.
     * @type {object}
     * @memberof InlineObject15
     */
    status?: object;
    /**
     * Explicitly denies specific statuses to be used.
     * @type {object}
     * @memberof InlineObject15
     */
    status_blacklist?: object;
    /**
     * If the user can update items.
     * @type {string}
     * @memberof InlineObject15
     */
    update?: InlineObject15UpdateEnum;
    /**
     * Explicitly denies write access for specific fields.
     * @type {object}
     * @memberof InlineObject15
     */
    write_field_blacklist?: object;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineObject15CommentEnum {
    None = 'none',
    Create = 'create',
    Update = 'update'
}
/**
    * @export
    * @enum {string}
    */
export enum InlineObject15CreateEnum {
    None = 'none',
    Full = 'full'
}
/**
    * @export
    * @enum {string}
    */
export enum InlineObject15DeleteEnum {
    None = 'none',
    Mine = 'mine',
    Role = 'role',
    Full = 'full'
}
/**
    * @export
    * @enum {string}
    */
export enum InlineObject15ExplainEnum {
    None = 'none',
    Create = 'create',
    Update = 'update',
    Always = 'always'
}
/**
    * @export
    * @enum {string}
    */
export enum InlineObject15ReadEnum {
    None = 'none',
    Mine = 'mine',
    Role = 'role',
    Full = 'full'
}
/**
    * @export
    * @enum {string}
    */
export enum InlineObject15UpdateEnum {
    None = 'none',
    Mine = 'mine',
    Role = 'role',
    Full = 'full'
}

/**
 * 
 * @export
 * @interface InlineObject16
 */
export interface InlineObject16 {
    /**
     * What collection this collection preset is used for.
     * @type {string}
     * @memberof InlineObject16
     */
    collection: string;
    /**
     * Name for the bookmark. If this is set, the collection preset will be considered to be a bookmark.
     * @type {string}
     * @memberof InlineObject16
     */
    title?: string;
    /**
     * The unique identifier of a role in the platform. If user is null, this will be used to apply the collection preset or bookmark for all users in the role.
     * @type {string}
     * @memberof InlineObject16
     */
    role?: string;
    /**
     * What the user searched for in search/filter in the header bar.
     * @type {string}
     * @memberof InlineObject16
     */
    search?: string;
    /**
     * 
     * @type {Array<PresetsFilters>}
     * @memberof InlineObject16
     */
    filters?: Array<PresetsFilters>;
    /**
     * Name of the view type that is used.
     * @type {string}
     * @memberof InlineObject16
     */
    layout?: string;
    /**
     * Layout query that\'s saved per layout type. Controls what data is fetched on load. These follow the same format as the JS SDK parameters.
     * @type {string}
     * @memberof InlineObject16
     */
    layout_query?: string;
    /**
     * Options of the views. The properties in here are controlled by the layout.
     * @type {string}
     * @memberof InlineObject16
     */
    layout_options?: string;
}
/**
 * 
 * @export
 * @interface InlineObject17
 */
export interface InlineObject17 {
    /**
     * What collection this collection preset is used for.
     * @type {string}
     * @memberof InlineObject17
     */
    collection: string;
    /**
     * Name for the bookmark. If this is set, the collection preset will be considered to be a bookmark.
     * @type {string}
     * @memberof InlineObject17
     */
    title?: string;
    /**
     * The unique identifier of a role in the platform. If user is null, this will be used to apply the collection preset or bookmark for all users in the role.
     * @type {number}
     * @memberof InlineObject17
     */
    role?: number;
    /**
     * What the user searched for in search/filter in the header bar.
     * @type {string}
     * @memberof InlineObject17
     */
    search_query?: string;
    /**
     * 
     * @type {Array<PresetsIdFilters>}
     * @memberof InlineObject17
     */
    filters?: Array<PresetsIdFilters>;
    /**
     * Name of the view type that is used. Defaults to tabular.
     * @type {string}
     * @memberof InlineObject17
     */
    view_type?: string;
    /**
     * View query that\'s saved per view type. Controls what data is fetched on load. These follow the same format as the JS SDK parameters.
     * @type {string}
     * @memberof InlineObject17
     */
    view_query?: string;
    /**
     * Options of the views. The properties in here are controlled by the layout.
     * @type {string}
     * @memberof InlineObject17
     */
    view_options?: string;
    /**
     * Key value pair of language-translation. Can be used to translate the bookmark title in multiple languages.
     * @type {object}
     * @memberof InlineObject17
     */
    translation?: object;
}
/**
 * 
 * @export
 * @interface InlineObject18
 */
export interface InlineObject18 {
    /**
     * Collection that has the field that holds the foreign key.
     * @type {string}
     * @memberof InlineObject18
     */
    collection_many?: string;
    /**
     * Collection on the _one_ side of the relationship.
     * @type {string}
     * @memberof InlineObject18
     */
    collection_one?: string;
    /**
     * Foreign key. Field that holds the primary key of the related collection.
     * @type {string}
     * @memberof InlineObject18
     */
    field_many?: string;
    /**
     * Alias column that serves as the _one_ side of the relationship.
     * @type {string}
     * @memberof InlineObject18
     */
    field_one?: string;
    /**
     * Field on the junction table that holds the primary key of the related collection.
     * @type {string}
     * @memberof InlineObject18
     */
    junction_field?: string;
}
/**
 * 
 * @export
 * @interface InlineObject19
 */
export interface InlineObject19 {
    /**
     * Collection that has the field that holds the foreign key.
     * @type {string}
     * @memberof InlineObject19
     */
    collection_many?: string;
    /**
     * Collection on the _one_ side of the relationship.
     * @type {string}
     * @memberof InlineObject19
     */
    collection_one?: string;
    /**
     * Foreign key. Field that holds the primary key of the related collection.
     * @type {string}
     * @memberof InlineObject19
     */
    field_many?: string;
    /**
     * Alias column that serves as the _one_ side of the relationship.
     * @type {string}
     * @memberof InlineObject19
     */
    field_one?: string;
    /**
     * Field on the junction table that holds the primary key of the related collection.
     * @type {string}
     * @memberof InlineObject19
     */
    junction_field?: string;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * JWT access token you want to logout.
     * @type {string}
     * @memberof InlineObject2
     */
    refresh_token?: string;
}
/**
 * 
 * @export
 * @interface InlineObject20
 */
export interface InlineObject20 {
    /**
     * Custom override for the admin app collection navigation.
     * @type {string}
     * @memberof InlineObject20
     */
    collection_listing?: string;
    /**
     * Description of the role.
     * @type {string}
     * @memberof InlineObject20
     */
    description?: string;
    /**
     * Whether or not this role enforces the use of 2FA.
     * @type {boolean}
     * @memberof InlineObject20
     */
    enforce_2fa?: boolean;
    /**
     * ID used with external services in SCIM.
     * @type {string}
     * @memberof InlineObject20
     */
    external_id?: string;
    /**
     * Array of IP addresses that are allowed to connect to the API as a user of this role.
     * @type {Array<string>}
     * @memberof InlineObject20
     */
    ip_whitelist?: Array<string>;
    /**
     * Custom override for the admin app module bar navigation.
     * @type {string}
     * @memberof InlineObject20
     */
    module_listing?: string;
    /**
     * Name of the role.
     * @type {string}
     * @memberof InlineObject20
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface InlineObject21
 */
export interface InlineObject21 {
    /**
     * Custom override for the admin app collection navigation.
     * @type {string}
     * @memberof InlineObject21
     */
    collection_listing?: string;
    /**
     * Description of the role.
     * @type {string}
     * @memberof InlineObject21
     */
    description?: string;
    /**
     * Whether or not this role enforces the use of 2FA.
     * @type {boolean}
     * @memberof InlineObject21
     */
    enforce_2fa?: boolean;
    /**
     * ID used with external services in SCIM.
     * @type {string}
     * @memberof InlineObject21
     */
    external_id?: string;
    /**
     * Array of IP addresses that are allowed to connect to the API as a user of this role.
     * @type {Array<string>}
     * @memberof InlineObject21
     */
    ip_whitelist?: Array<string>;
    /**
     * Custom override for the admin app module bar navigation.
     * @type {string}
     * @memberof InlineObject21
     */
    module_listing?: string;
    /**
     * Name of the role.
     * @type {string}
     * @memberof InlineObject21
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface InlineObject22
 */
export interface InlineObject22 {
    /**
     * Email address or array of email addresses of the to-be-invited user(s).
     * @type {string}
     * @memberof InlineObject22
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface InlineObject23
 */
export interface InlineObject23 {
    /**
     * Accept invite token.
     * @type {string}
     * @memberof InlineObject23
     */
    token?: string;
    /**
     * Password of the user.
     * @type {string}
     * @memberof InlineObject23
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface InlineObject24
 */
export interface InlineObject24 {
    /**
     * Path of the page you used last.
     * @type {string}
     * @memberof InlineObject24
     */
    last_page?: string;
}
/**
 * 
 * @export
 * @interface InlineObject25
 */
export interface InlineObject25 {
    /**
     * The name of the webhook.
     * @type {string}
     * @memberof InlineObject25
     */
    name?: string;
    /**
     * Method used in the webhook.
     * @type {string}
     * @memberof InlineObject25
     */
    method?: string;
    /**
     * The url of the webhook.
     * @type {string}
     * @memberof InlineObject25
     */
    url?: string;
    /**
     * The status of the webhook.
     * @type {string}
     * @memberof InlineObject25
     */
    status?: string;
    /**
     * If yes, send the content of what was done
     * @type {boolean}
     * @memberof InlineObject25
     */
    data?: boolean;
    /**
     * The actions that triggers this webhook.
     * @type {any}
     * @memberof InlineObject25
     */
    actions?: any | null;
    /**
     * The collections that triggers this webhook.
     * @type {any}
     * @memberof InlineObject25
     */
    system_collections?: any | null;
}
/**
 * 
 * @export
 * @interface InlineObject26
 */
export interface InlineObject26 {
    /**
     * The name of the webhook.
     * @type {string}
     * @memberof InlineObject26
     */
    name?: string;
    /**
     * Method used in the webhook.
     * @type {string}
     * @memberof InlineObject26
     */
    method?: string;
    /**
     * The url of the webhook.
     * @type {string}
     * @memberof InlineObject26
     */
    url?: string;
    /**
     * The status of the webhook.
     * @type {string}
     * @memberof InlineObject26
     */
    status?: string;
    /**
     * If yes, send the content of what was done
     * @type {boolean}
     * @memberof InlineObject26
     */
    data?: boolean;
    /**
     * The actions that triggers this webhook.
     * @type {any}
     * @memberof InlineObject26
     */
    actions?: any | null;
    /**
     * The collections that triggers this webhook.
     * @type {any}
     * @memberof InlineObject26
     */
    system_collections?: any | null;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * Email address of the user you\'re requesting a reset for.
     * @type {string}
     * @memberof InlineObject3
     */
    email: string;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * One-time use JWT token that is used to verify the user.
     * @type {string}
     * @memberof InlineObject4
     */
    token: string;
    /**
     * New password for the user.
     * @type {string}
     * @memberof InlineObject4
     */
    password: string;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    collection: string;
    /**
     * 
     * @type {number}
     * @memberof InlineObject5
     */
    item: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface InlineObject7
 */
export interface InlineObject7 {
    /**
     * Unique name of the collection.
     * @type {string}
     * @memberof InlineObject7
     */
    collection: string;
    /**
     * The fields contained in this collection. See the fields reference for more information. Each individual field requires field, type, and interface to be provided.
     * @type {Array<object>}
     * @memberof InlineObject7
     */
    fields: Array<object>;
    /**
     * Name of a Google Material Design Icon that\'s assigned to this collection.
     * @type {string}
     * @memberof InlineObject7
     */
    icon?: string | null;
    /**
     * A note describing the collection.
     * @type {string}
     * @memberof InlineObject7
     */
    note?: string | null;
    /**
     * Text representation of how items from this collection are shown across the system.
     * @type {string}
     * @memberof InlineObject7
     */
    display_template?: string | null;
    /**
     * Whether or not the collection is hidden from the navigation in the admin app.
     * @type {boolean}
     * @memberof InlineObject7
     */
    hidden?: boolean;
    /**
     * Whether or not the collection is treated as a single object.
     * @type {boolean}
     * @memberof InlineObject7
     */
    singleton?: boolean;
    /**
     * Key value pairs of how to show this collection\'s name in different languages in the admin app.
     * @type {string}
     * @memberof InlineObject7
     */
    translation?: string | null;
    /**
     * What field holds the archive value.
     * @type {string}
     * @memberof InlineObject7
     */
    archive_field?: string | null;
    /**
     * What value to use for \"archived\" items.
     * @type {string}
     * @memberof InlineObject7
     */
    archive_app_filter?: string | null;
    /**
     * What value to use to \"unarchive\" items.
     * @type {string}
     * @memberof InlineObject7
     */
    archive_value?: string | null;
    /**
     * Whether or not to show the \"archived\" filter.
     * @type {string}
     * @memberof InlineObject7
     */
    unarchive_value?: string | null;
    /**
     * The sort field in the collection.
     * @type {string}
     * @memberof InlineObject7
     */
    sort_field?: string | null;
}
/**
 * 
 * @export
 * @interface InlineObject8
 */
export interface InlineObject8 {
    /**
     * 
     * @type {CollectionsIdMeta}
     * @memberof InlineObject8
     */
    meta?: CollectionsIdMeta;
}
/**
 * 
 * @export
 * @interface InlineObject9
 */
export interface InlineObject9 {
    /**
     * Unique name of the field. Field name is unique within the collection.
     * @type {string}
     * @memberof InlineObject9
     */
    field: string;
    /**
     * Directus specific data type. Used to cast values in the API.
     * @type {string}
     * @memberof InlineObject9
     */
    type: string;
    /**
     * 
     * @type {FieldsCollectionSchema}
     * @memberof InlineObject9
     */
    schema?: FieldsCollectionSchema;
    /**
     * 
     * @type {FieldsCollectionMeta}
     * @memberof InlineObject9
     */
    meta?: FieldsCollectionMeta | null;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {InlineResponse200Data}
     * @memberof InlineResponse200
     */
    data?: InlineResponse200Data;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {InlineResponse2001Data}
     * @memberof InlineResponse2001
     */
    data?: InlineResponse2001Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     * 
     * @type {Activity}
     * @memberof InlineResponse20010
     */
    data?: Activity;
}
/**
 * 
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
    /**
     * 
     * @type {Array<Collections>}
     * @memberof InlineResponse20011
     */
    data?: Array<Collections>;
}
/**
 * 
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
    /**
     * 
     * @type {Collections}
     * @memberof InlineResponse20012
     */
    data?: Collections;
}
/**
 * 
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
    /**
     * 
     * @type {Array<Fields>}
     * @memberof InlineResponse20013
     */
    data?: Array<Fields>;
}
/**
 * 
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
    /**
     * 
     * @type {Fields}
     * @memberof InlineResponse20014
     */
    data?: Fields;
}
/**
 * 
 * @export
 * @interface InlineResponse20015
 */
export interface InlineResponse20015 {
    /**
     * 
     * @type {Array<Files>}
     * @memberof InlineResponse20015
     */
    data?: Array<Files>;
}
/**
 * 
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
    /**
     * 
     * @type {Files}
     * @memberof InlineResponse20016
     */
    data?: Files;
}
/**
 * 
 * @export
 * @interface InlineResponse20017
 */
export interface InlineResponse20017 {
    /**
     * 
     * @type {Array<Folders>}
     * @memberof InlineResponse20017
     */
    data?: Array<Folders>;
}
/**
 * 
 * @export
 * @interface InlineResponse20018
 */
export interface InlineResponse20018 {
    /**
     * 
     * @type {Folders}
     * @memberof InlineResponse20018
     */
    data?: Folders;
}
/**
 * 
 * @export
 * @interface InlineResponse20019
 */
export interface InlineResponse20019 {
    /**
     * 
     * @type {Array<Permissions>}
     * @memberof InlineResponse20019
     */
    data?: Array<Permissions>;
}
/**
 * 
 * @export
 * @interface InlineResponse2001Data
 */
export interface InlineResponse2001Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001Data
     */
    access_token?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2001Data
     */
    expires?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001Data
     */
    refresh_token?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse2002
     */
    _public?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineResponse2002
     */
    data?: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineResponse20020
 */
export interface InlineResponse20020 {
    /**
     * 
     * @type {Permissions}
     * @memberof InlineResponse20020
     */
    data?: Permissions;
}
/**
 * 
 * @export
 * @interface InlineResponse20021
 */
export interface InlineResponse20021 {
    /**
     * 
     * @type {Array<Presets>}
     * @memberof InlineResponse20021
     */
    data?: Array<Presets>;
}
/**
 * 
 * @export
 * @interface InlineResponse20022
 */
export interface InlineResponse20022 {
    /**
     * 
     * @type {Presets}
     * @memberof InlineResponse20022
     */
    data?: Presets;
}
/**
 * 
 * @export
 * @interface InlineResponse20023
 */
export interface InlineResponse20023 {
    /**
     * 
     * @type {Array<Relations>}
     * @memberof InlineResponse20023
     */
    data?: Array<Relations>;
}
/**
 * 
 * @export
 * @interface InlineResponse20024
 */
export interface InlineResponse20024 {
    /**
     * 
     * @type {Relations}
     * @memberof InlineResponse20024
     */
    data?: Relations;
}
/**
 * 
 * @export
 * @interface InlineResponse20025
 */
export interface InlineResponse20025 {
    /**
     * 
     * @type {Array<Revisions>}
     * @memberof InlineResponse20025
     */
    data?: Array<Revisions>;
}
/**
 * 
 * @export
 * @interface InlineResponse20026
 */
export interface InlineResponse20026 {
    /**
     * 
     * @type {Revisions}
     * @memberof InlineResponse20026
     */
    data?: Revisions;
}
/**
 * 
 * @export
 * @interface InlineResponse20027
 */
export interface InlineResponse20027 {
    /**
     * 
     * @type {Array<Roles>}
     * @memberof InlineResponse20027
     */
    data?: Array<Roles>;
}
/**
 * 
 * @export
 * @interface InlineResponse20028
 */
export interface InlineResponse20028 {
    /**
     * 
     * @type {Roles}
     * @memberof InlineResponse20028
     */
    data?: Roles;
}
/**
 * 
 * @export
 * @interface InlineResponse20029
 */
export interface InlineResponse20029 {
    /**
     * 
     * @type {Settings}
     * @memberof InlineResponse20029
     */
    data?: Settings;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse2003
     */
    _public?: boolean;
    /**
     * 
     * @type {InlineResponse2003Data}
     * @memberof InlineResponse2003
     */
    data?: InlineResponse2003Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20030
 */
export interface InlineResponse20030 {
    /**
     * 
     * @type {Array<Users>}
     * @memberof InlineResponse20030
     */
    data?: Array<Users>;
}
/**
 * 
 * @export
 * @interface InlineResponse20031
 */
export interface InlineResponse20031 {
    /**
     * 
     * @type {Users}
     * @memberof InlineResponse20031
     */
    data?: Users;
}
/**
 * 
 * @export
 * @interface InlineResponse20032
 */
export interface InlineResponse20032 {
    /**
     * 
     * @type {Webhooks}
     * @memberof InlineResponse20032
     */
    data?: Webhooks;
}
/**
 * 
 * @export
 * @interface InlineResponse20033
 */
export interface InlineResponse20033 {
    /**
     * 
     * @type {Array<ItemsImportTypes>}
     * @memberof InlineResponse20033
     */
    data?: Array<ItemsImportTypes>;
}
/**
 * 
 * @export
 * @interface InlineResponse20034
 */
export interface InlineResponse20034 {
    /**
     * 
     * @type {Array<ItemsImports>}
     * @memberof InlineResponse20034
     */
    data?: Array<ItemsImports>;
}
/**
 * 
 * @export
 * @interface InlineResponse20035
 */
export interface InlineResponse20035 {
    /**
     * 
     * @type {Array<ItemsPlatformAddresses>}
     * @memberof InlineResponse20035
     */
    data?: Array<ItemsPlatformAddresses>;
}
/**
 * 
 * @export
 * @interface InlineResponse20036
 */
export interface InlineResponse20036 {
    /**
     * 
     * @type {Array<ItemsPlatforms>}
     * @memberof InlineResponse20036
     */
    data?: Array<ItemsPlatforms>;
}
/**
 * 
 * @export
 * @interface InlineResponse20037
 */
export interface InlineResponse20037 {
    /**
     * 
     * @type {Array<ItemsProducers>}
     * @memberof InlineResponse20037
     */
    data?: Array<ItemsProducers>;
}
/**
 * 
 * @export
 * @interface InlineResponse20038
 */
export interface InlineResponse20038 {
    /**
     * 
     * @type {Array<ItemsProducersCertifications>}
     * @memberof InlineResponse20038
     */
    data?: Array<ItemsProducersCertifications>;
}
/**
 * 
 * @export
 * @interface InlineResponse20039
 */
export interface InlineResponse20039 {
    /**
     * 
     * @type {Array<ItemsProducersPlatforms>}
     * @memberof InlineResponse20039
     */
    data?: Array<ItemsProducersPlatforms>;
}
/**
 * 
 * @export
 * @interface InlineResponse2003Data
 */
export interface InlineResponse2003Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003Data
     */
    token?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {Array<object>}
     * @memberof InlineResponse2004
     */
    data?: Array<object>;
}
/**
 * 
 * @export
 * @interface InlineResponse20040
 */
export interface InlineResponse20040 {
    /**
     * 
     * @type {Array<ItemsProductCategories>}
     * @memberof InlineResponse20040
     */
    data?: Array<ItemsProductCategories>;
}
/**
 * 
 * @export
 * @interface InlineResponse20041
 */
export interface InlineResponse20041 {
    /**
     * 
     * @type {Array<ItemsProducts>}
     * @memberof InlineResponse20041
     */
    data?: Array<ItemsProducts>;
}
/**
 * 
 * @export
 * @interface InlineResponse20042
 */
export interface InlineResponse20042 {
    /**
     * 
     * @type {Array<ItemsProductsProducersAddresses>}
     * @memberof InlineResponse20042
     */
    data?: Array<ItemsProductsProducersAddresses>;
}
/**
 * 
 * @export
 * @interface InlineResponse20043
 */
export interface InlineResponse20043 {
    /**
     * 
     * @type {Array<ItemsProvinces>}
     * @memberof InlineResponse20043
     */
    data?: Array<ItemsProvinces>;
}
/**
 * 
 * @export
 * @interface InlineResponse20044
 */
export interface InlineResponse20044 {
    /**
     * 
     * @type {Array<ItemsRegions>}
     * @memberof InlineResponse20044
     */
    data?: Array<ItemsRegions>;
}
/**
 * 
 * @export
 * @interface InlineResponse20045
 */
export interface InlineResponse20045 {
    /**
     * 
     * @type {Array<ItemsSources>}
     * @memberof InlineResponse20045
     */
    data?: Array<ItemsSources>;
}
/**
 * 
 * @export
 * @interface InlineResponse20046
 */
export interface InlineResponse20046 {
    /**
     * 
     * @type {Array<ItemsUnits>}
     * @memberof InlineResponse20046
     */
    data?: Array<ItemsUnits>;
}
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {object}
     * @memberof InlineResponse2005
     */
    data?: object;
}
/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2006
     */
    data?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     * 
     * @type {Array<ItemsAddresses>}
     * @memberof InlineResponse2007
     */
    data?: Array<ItemsAddresses>;
}
/**
 * 
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     * 
     * @type {Array<ItemsCertifications>}
     * @memberof InlineResponse2008
     */
    data?: Array<ItemsCertifications>;
}
/**
 * 
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     * 
     * @type {Array<Activity>}
     * @memberof InlineResponse2009
     */
    data?: Array<Activity>;
}
/**
 * 
 * @export
 * @interface InlineResponse200Data
 */
export interface InlineResponse200Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Data
     */
    access_token?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200Data
     */
    expires?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Data
     */
    refresh_token?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse404
 */
export interface InlineResponse404 {
    /**
     * 
     * @type {InlineResponse404Error}
     * @memberof InlineResponse404
     */
    error?: InlineResponse404Error;
}
/**
 * 
 * @export
 * @interface InlineResponse404Error
 */
export interface InlineResponse404Error {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse404Error
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse404Error
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface ItemsAddresses
 */
export interface ItemsAddresses {
    /**
     * 
     * @type {number}
     * @memberof ItemsAddresses
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemsAddresses
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemsAddresses
     */
    sort?: number | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsAddresses
     */
    user_created?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsAddresses
     */
    date_created?: string | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsAddresses
     */
    user_updated?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsAddresses
     */
    date_updated?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsAddresses
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemsAddresses
     */
    civic_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsAddresses
     */
    city?: string;
    /**
     * 
     * @type {number | ItemsProvinces}
     * @memberof ItemsAddresses
     */
    province_id?: number | ItemsProvinces | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsAddresses
     */
    postal_code?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemsAddresses
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemsAddresses
     */
    longitude?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsAddresses
     */
    is_registered_office?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsAddresses
     */
    is_production?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsAddresses
     */
    is_production_default?: boolean;
    /**
     * 
     * @type {number | ItemsProducers}
     * @memberof ItemsAddresses
     */
    producer_id?: number | ItemsProducers | null;
    /**
     * 
     * @type {Array<number | ItemsProductsProducersAddresses>}
     * @memberof ItemsAddresses
     */
    products_producers_addresses?: Array<number | ItemsProductsProducersAddresses>;
}
/**
 * 
 * @export
 * @interface ItemsCertifications
 */
export interface ItemsCertifications {
    /**
     * 
     * @type {number}
     * @memberof ItemsCertifications
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemsCertifications
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemsCertifications
     */
    sort?: number | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsCertifications
     */
    user_created?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsCertifications
     */
    date_created?: string | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsCertifications
     */
    user_updated?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsCertifications
     */
    date_updated?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsCertifications
     */
    name?: string;
    /**
     * 
     * @type {Array<number | ItemsProducersCertifications>}
     * @memberof ItemsCertifications
     */
    producers?: Array<number | ItemsProducersCertifications>;
}
/**
 * 
 * @export
 * @interface ItemsImportTypes
 */
export interface ItemsImportTypes {
    /**
     * 
     * @type {string}
     * @memberof ItemsImportTypes
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemsImportTypes
     */
    date_created?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsImportTypes
     */
    date_updated?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ItemsImportTypes
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemsImportTypes
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemsImportTypes
     */
    sort?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsImportTypes
     */
    status?: string;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsImportTypes
     */
    user_created?: string | Users | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsImportTypes
     */
    user_updated?: string | Users | null;
    /**
     * 
     * @type {Array<number | ItemsImports>}
     * @memberof ItemsImportTypes
     */
    imports?: Array<number | ItemsImports>;
}
/**
 * 
 * @export
 * @interface ItemsImports
 */
export interface ItemsImports {
    /**
     * 
     * @type {string}
     * @memberof ItemsImports
     */
    date_created?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsImports
     */
    date_updated?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ItemsImports
     */
    id?: number;
    /**
     * 
     * @type {string | Files}
     * @memberof ItemsImports
     */
    import_file?: string | Files;
    /**
     * 
     * @type {string | Files}
     * @memberof ItemsImports
     */
    log_file?: string | Files | null;
    /**
     * 
     * @type {number}
     * @memberof ItemsImports
     */
    sort?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsImports
     */
    status?: string;
    /**
     * 
     * @type {number | ItemsImportTypes}
     * @memberof ItemsImports
     */
    type_id?: number | ItemsImportTypes | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsImports
     */
    user_created?: string | Users | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsImports
     */
    user_updated?: string | Users | null;
}
/**
 * 
 * @export
 * @interface ItemsPlatformAddresses
 */
export interface ItemsPlatformAddresses {
    /**
     * 
     * @type {number}
     * @memberof ItemsPlatformAddresses
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemsPlatformAddresses
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemsPlatformAddresses
     */
    sort?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsPlatformAddresses
     */
    user_created?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsPlatformAddresses
     */
    date_created?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsPlatformAddresses
     */
    user_updated?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsPlatformAddresses
     */
    date_updated?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsPlatformAddresses
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemsPlatformAddresses
     */
    civic_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsPlatformAddresses
     */
    city?: string;
    /**
     * 
     * @type {number | ItemsProvinces}
     * @memberof ItemsPlatformAddresses
     */
    province_id?: number | ItemsProvinces | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsPlatformAddresses
     */
    postal_code?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemsPlatformAddresses
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemsPlatformAddresses
     */
    longitude?: number;
    /**
     * 
     * @type {number | ItemsPlatforms}
     * @memberof ItemsPlatformAddresses
     */
    platform_id?: number | ItemsPlatforms | null;
}
/**
 * 
 * @export
 * @interface ItemsPlatforms
 */
export interface ItemsPlatforms {
    /**
     * 
     * @type {number}
     * @memberof ItemsPlatforms
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemsPlatforms
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemsPlatforms
     */
    sort?: number | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsPlatforms
     */
    user_created?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsPlatforms
     */
    date_created?: string | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsPlatforms
     */
    user_updated?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsPlatforms
     */
    date_updated?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsPlatforms
     */
    name?: string;
    /**
     * 
     * @type {Array<number | ItemsProducersPlatforms>}
     * @memberof ItemsPlatforms
     */
    producers?: Array<number | ItemsProducersPlatforms>;
    /**
     * 
     * @type {Array<number | ItemsProductsProducersAddresses>}
     * @memberof ItemsPlatforms
     */
    products_producers_addresses?: Array<number | ItemsProductsProducersAddresses>;
    /**
     * 
     * @type {Array<number | ItemsPlatformAddresses>}
     * @memberof ItemsPlatforms
     */
    platform_addresses?: Array<number | ItemsPlatformAddresses>;
}
/**
 * 
 * @export
 * @interface ItemsProducers
 */
export interface ItemsProducers {
    /**
     * 
     * @type {number}
     * @memberof ItemsProducers
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemsProducers
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemsProducers
     */
    sort?: number | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsProducers
     */
    user_created?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProducers
     */
    date_created?: string | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsProducers
     */
    user_updated?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProducers
     */
    date_updated?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProducers
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemsProducers
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProducers
     */
    website?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProducers
     */
    referent_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProducers
     */
    referent_email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProducers
     */
    is_wholesaler?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProducers
     */
    direct_delivery?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItemsProducers
     */
    external_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProducers
     */
    note?: string | null;
    /**
     * 
     * @type {Array<number | ItemsAddresses>}
     * @memberof ItemsProducers
     */
    addresses?: Array<number | ItemsAddresses>;
    /**
     * 
     * @type {Array<number | ItemsProducersCertifications>}
     * @memberof ItemsProducers
     */
    certifications?: Array<number | ItemsProducersCertifications>;
    /**
     * 
     * @type {Array<number | ItemsProducersPlatforms>}
     * @memberof ItemsProducers
     */
    platforms?: Array<number | ItemsProducersPlatforms>;
    /**
     * 
     * @type {Array<number | ItemsProductsProducersAddresses>}
     * @memberof ItemsProducers
     */
    products_producers_addresses?: Array<number | ItemsProductsProducersAddresses>;
}
/**
 * 
 * @export
 * @interface ItemsProducersCertifications
 */
export interface ItemsProducersCertifications {
    /**
     * 
     * @type {number}
     * @memberof ItemsProducersCertifications
     */
    id?: number;
    /**
     * 
     * @type {number | ItemsProducers}
     * @memberof ItemsProducersCertifications
     */
    producer_id?: number | ItemsProducers | null;
    /**
     * 
     * @type {number | ItemsCertifications}
     * @memberof ItemsProducersCertifications
     */
    certification_id?: number | ItemsCertifications | null;
}
/**
 * 
 * @export
 * @interface ItemsProducersPlatforms
 */
export interface ItemsProducersPlatforms {
    /**
     * 
     * @type {number}
     * @memberof ItemsProducersPlatforms
     */
    id?: number;
    /**
     * 
     * @type {number | ItemsProducers}
     * @memberof ItemsProducersPlatforms
     */
    producers_id?: number | ItemsProducers | null;
    /**
     * 
     * @type {number | ItemsPlatforms}
     * @memberof ItemsProducersPlatforms
     */
    platforms_id?: number | ItemsPlatforms | null;
}
/**
 * 
 * @export
 * @interface ItemsProductCategories
 */
export interface ItemsProductCategories {
    /**
     * 
     * @type {number}
     * @memberof ItemsProductCategories
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemsProductCategories
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemsProductCategories
     */
    sort?: number | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsProductCategories
     */
    user_created?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProductCategories
     */
    date_created?: string | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsProductCategories
     */
    user_updated?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProductCategories
     */
    date_updated?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProductCategories
     */
    name?: string;
    /**
     * 
     * @type {Array<number | ItemsProducts>}
     * @memberof ItemsProductCategories
     */
    products?: Array<number | ItemsProducts>;
}
/**
 * 
 * @export
 * @interface ItemsProducts
 */
export interface ItemsProducts {
    /**
     * 
     * @type {number}
     * @memberof ItemsProducts
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemsProducts
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemsProducts
     */
    sort?: number | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsProducts
     */
    user_created?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProducts
     */
    date_created?: string | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsProducts
     */
    user_updated?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProducts
     */
    date_updated?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProducts
     */
    name?: string;
    /**
     * 
     * @type {number | ItemsProductCategories}
     * @memberof ItemsProducts
     */
    category_id?: number | ItemsProductCategories | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProducts
     */
    note?: string | null;
    /**
     * 
     * @type {Array<number | ItemsProductsProducersAddresses>}
     * @memberof ItemsProducts
     */
    products_producers_addresses?: Array<number | ItemsProductsProducersAddresses>;
}
/**
 * 
 * @export
 * @interface ItemsProductsProducersAddresses
 */
export interface ItemsProductsProducersAddresses {
    /**
     * 
     * @type {number}
     * @memberof ItemsProductsProducersAddresses
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemsProductsProducersAddresses
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemsProductsProducersAddresses
     */
    sort?: number | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsProductsProducersAddresses
     */
    user_created?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProductsProducersAddresses
     */
    date_created?: string | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsProductsProducersAddresses
     */
    user_updated?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProductsProducersAddresses
     */
    date_updated?: string | null;
    /**
     * 
     * @type {number | ItemsProducts}
     * @memberof ItemsProductsProducersAddresses
     */
    product_id?: number | ItemsProducts | null;
    /**
     * 
     * @type {number | ItemsProducers}
     * @memberof ItemsProductsProducersAddresses
     */
    producer_id?: number | ItemsProducers | null;
    /**
     * 
     * @type {number | ItemsAddresses}
     * @memberof ItemsProductsProducersAddresses
     */
    address_id?: number | ItemsAddresses | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProductsProducersAddresses
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProductsProducersAddresses
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemsProductsProducersAddresses
     */
    price?: number | null;
    /**
     * 
     * @type {number | ItemsUnits}
     * @memberof ItemsProductsProducersAddresses
     */
    unit_id?: number | ItemsUnits | null;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_biologic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_dop?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_igp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_integrated_fight?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_social_cooperative?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_equosolidal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_msc?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_aqua?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_local?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_pat?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_halal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_social?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_fao_37_27?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_short_chain?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_km0?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_stg?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsProductsProducersAddresses
     */
    is_kosher?: boolean;
    /**
     * 
     * @type {number | ItemsSources}
     * @memberof ItemsProductsProducersAddresses
     */
    source_id?: number | ItemsSources | null;
    /**
     * 
     * @type {number}
     * @memberof ItemsProductsProducersAddresses
     */
    production_capacity?: number | null;
    /**
     * 
     * @type {number | ItemsPlatforms}
     * @memberof ItemsProductsProducersAddresses
     */
    platform_id?: number | ItemsPlatforms | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProductsProducersAddresses
     */
    file_url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProductsProducersAddresses
     */
    external_id?: string | null;
    /**
     * 
     * @type {number | ItemsUnits}
     * @memberof ItemsProductsProducersAddresses
     */
    production_capacity_unit_id?: number | ItemsUnits | null;
}
/**
 * 
 * @export
 * @interface ItemsProvinces
 */
export interface ItemsProvinces {
    /**
     * 
     * @type {number}
     * @memberof ItemsProvinces
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemsProvinces
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemsProvinces
     */
    sort?: number | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsProvinces
     */
    user_created?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProvinces
     */
    date_created?: string | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsProvinces
     */
    user_updated?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProvinces
     */
    date_updated?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsProvinces
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemsProvinces
     */
    code?: string;
    /**
     * 
     * @type {number | ItemsRegions}
     * @memberof ItemsProvinces
     */
    region_id?: number | ItemsRegions | null;
    /**
     * 
     * @type {Array<number | ItemsAddresses>}
     * @memberof ItemsProvinces
     */
    addresses?: Array<number | ItemsAddresses>;
    /**
     * 
     * @type {Array<number | ItemsPlatformAddresses>}
     * @memberof ItemsProvinces
     */
    platform_addresses?: Array<number | ItemsPlatformAddresses>;
}
/**
 * 
 * @export
 * @interface ItemsRegions
 */
export interface ItemsRegions {
    /**
     * 
     * @type {number}
     * @memberof ItemsRegions
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemsRegions
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemsRegions
     */
    sort?: number | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsRegions
     */
    user_created?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsRegions
     */
    date_created?: string | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsRegions
     */
    user_updated?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsRegions
     */
    date_updated?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsRegions
     */
    name?: string;
    /**
     * 
     * @type {Array<number | ItemsProvinces>}
     * @memberof ItemsRegions
     */
    provinces?: Array<number | ItemsProvinces>;
}
/**
 * 
 * @export
 * @interface ItemsSources
 */
export interface ItemsSources {
    /**
     * 
     * @type {number}
     * @memberof ItemsSources
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemsSources
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemsSources
     */
    sort?: number | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsSources
     */
    user_created?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsSources
     */
    date_created?: string | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsSources
     */
    user_updated?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsSources
     */
    date_updated?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsSources
     */
    name?: string;
    /**
     * 
     * @type {Array<number | ItemsProductsProducersAddresses>}
     * @memberof ItemsSources
     */
    products_producers_addresses?: Array<number | ItemsProductsProducersAddresses>;
}
/**
 * 
 * @export
 * @interface ItemsUnits
 */
export interface ItemsUnits {
    /**
     * 
     * @type {number}
     * @memberof ItemsUnits
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemsUnits
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemsUnits
     */
    sort?: number | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsUnits
     */
    user_created?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsUnits
     */
    date_created?: string | null;
    /**
     * 
     * @type {string | Users}
     * @memberof ItemsUnits
     */
    user_updated?: string | Users | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsUnits
     */
    date_updated?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemsUnits
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemsUnits
     */
    code?: string;
    /**
     * 
     * @type {Array<number | ItemsProductsProducersAddresses>}
     * @memberof ItemsUnits
     */
    products_producers_addresses?: Array<number | ItemsProductsProducersAddresses>;
    /**
     * 
     * @type {Array<number | ItemsProductsProducersAddresses>}
     * @memberof ItemsUnits
     */
    production_capacity_products_producers_addresses?: Array<number | ItemsProductsProducersAddresses>;
}
/**
 * 
 * @export
 * @interface Permissions
 */
export interface Permissions {
    /**
     * Unique identifier for the permission.
     * @type {number}
     * @memberof Permissions
     */
    id?: number;
    /**
     * Unique identifier of the role this permission applies to.
     * @type {string}
     * @memberof Permissions
     */
    role?: string | null;
    /**
     * What collection this permission applies to.
     * @type {string}
     * @memberof Permissions
     */
    collection?: string;
    /**
     * What action this permission applies to.
     * @type {string}
     * @memberof Permissions
     */
    action?: PermissionsActionEnum;
    /**
     * JSON structure containing the permissions checks for this permission.
     * @type {object}
     * @memberof Permissions
     */
    permissions?: object | null;
    /**
     * JSON structure containing the validation checks for this permission.
     * @type {object}
     * @memberof Permissions
     */
    validation?: object | null;
    /**
     * JSON structure containing the preset value for created/updated items.
     * @type {object}
     * @memberof Permissions
     */
    presets?: object | null;
    /**
     * CSV of fields that the user is allowed to interact with.
     * @type {Array<string>}
     * @memberof Permissions
     */
    fields?: Array<string> | null;
    /**
     * Maximum amount of items the user can interact with at a time.
     * @type {number}
     * @memberof Permissions
     */
    limit?: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum PermissionsActionEnum {
    Create = 'create',
    Read = 'read',
    Update = 'update',
    Delete = 'delete'
}

/**
 * 
 * @export
 * @interface Presets
 */
export interface Presets {
    /**
     * Unique identifier for this single collection preset.
     * @type {number}
     * @memberof Presets
     */
    id?: number;
    /**
     * Name for the bookmark. If this is set, the preset will be considered a bookmark.
     * @type {string}
     * @memberof Presets
     */
    bookmark?: string | null;
    /**
     * The unique identifier of the user to whom this collection preset applies.
     * @type {string | Users}
     * @memberof Presets
     */
    user?: string | Users | null;
    /**
     * The unique identifier of a role in the platform. If `user` is null, this will be used to apply the collection preset or bookmark for all users in the role.
     * @type {string | Roles}
     * @memberof Presets
     */
    role?: string | Roles | null;
    /**
     * What collection this collection preset is used for.
     * @type {string | Collections}
     * @memberof Presets
     */
    collection?: string | Collections;
    /**
     * Search query.
     * @type {string}
     * @memberof Presets
     */
    search?: string | null;
    /**
     * The filters that the user applied.
     * @type {Array<object>}
     * @memberof Presets
     */
    filters?: Array<object> | null;
    /**
     * Key of the layout that is used.
     * @type {string}
     * @memberof Presets
     */
    layout?: string;
    /**
     * Layout query that\'s saved per layout type. Controls what data is fetched on load. These follow the same format as the JS SDK parameters.
     * @type {any}
     * @memberof Presets
     */
    layout_query?: any | null;
    /**
     * Options of the views. The properties in here are controlled by the layout.
     * @type {any}
     * @memberof Presets
     */
    layout_options?: any | null;
    /**
     * 
     * @type {number}
     * @memberof Presets
     */
    refresh_interval?: number | null;
}
/**
 * 
 * @export
 * @interface PresetsFilters
 */
export interface PresetsFilters {
    /**
     * 
     * @type {string}
     * @memberof PresetsFilters
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof PresetsFilters
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof PresetsFilters
     */
    operator?: string;
    /**
     * 
     * @type {number}
     * @memberof PresetsFilters
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface PresetsIdFilters
 */
export interface PresetsIdFilters {
    /**
     * 
     * @type {string}
     * @memberof PresetsIdFilters
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof PresetsIdFilters
     */
    operator?: string;
    /**
     * 
     * @type {number}
     * @memberof PresetsIdFilters
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface Relations
 */
export interface Relations {
    /**
     * Unique identifier for the relation.
     * @type {number}
     * @memberof Relations
     */
    id?: number;
    /**
     * Collection that has the field that holds the foreign key.
     * @type {string}
     * @memberof Relations
     */
    many_collection?: string;
    /**
     * Foreign key. Field that holds the primary key of the related collection.
     * @type {string}
     * @memberof Relations
     */
    many_field?: string;
    /**
     * Collection on the _one_ side of the relationship.
     * @type {string}
     * @memberof Relations
     */
    one_collection?: string;
    /**
     * Alias column that serves as the _one_ side of the relationship.
     * @type {string}
     * @memberof Relations
     */
    one_field?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Relations
     */
    one_collection_field?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Relations
     */
    one_allowed_collections?: Array<string> | null;
    /**
     * Field on the junction table that holds the many field of the related relation.
     * @type {string}
     * @memberof Relations
     */
    junction_field?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Relations
     */
    sort_field?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Relations
     */
    one_deselect_action?: string;
}
/**
 * 
 * @export
 * @interface Revisions
 */
export interface Revisions {
    /**
     * Unique identifier for the revision.
     * @type {number}
     * @memberof Revisions
     */
    id?: number;
    /**
     * Unique identifier for the activity record.
     * @type {number | Activity}
     * @memberof Revisions
     */
    activity?: number | Activity;
    /**
     * Collection of the updated item.
     * @type {string | Collections}
     * @memberof Revisions
     */
    collection?: string | Collections;
    /**
     * Primary key of updated item.
     * @type {string}
     * @memberof Revisions
     */
    item?: string;
    /**
     * Copy of item state at time of update.
     * @type {object}
     * @memberof Revisions
     */
    data?: object | null;
    /**
     * Changes between the previous and the current revision.
     * @type {object}
     * @memberof Revisions
     */
    delta?: object;
    /**
     * If the current item was updated relationally, this is the id of the parent revision record
     * @type {number}
     * @memberof Revisions
     */
    parent?: number | null;
}
/**
 * 
 * @export
 * @interface Roles
 */
export interface Roles {
    /**
     * Unique identifier for the role.
     * @type {string}
     * @memberof Roles
     */
    id?: string;
    /**
     * Name of the role.
     * @type {string}
     * @memberof Roles
     */
    name?: string;
    /**
     * The role\'s icon.
     * @type {string}
     * @memberof Roles
     */
    icon?: string;
    /**
     * Description of the role.
     * @type {string}
     * @memberof Roles
     */
    description?: string | null;
    /**
     * Array of IP addresses that are allowed to connect to the API as a user of this role.
     * @type {Array<string>}
     * @memberof Roles
     */
    ip_access?: Array<string>;
    /**
     * Whether or not this role enforces the use of 2FA.
     * @type {boolean}
     * @memberof Roles
     */
    enforce_tfa?: boolean;
    /**
     * Custom override for the admin app module bar navigation.
     * @type {Array<object>}
     * @memberof Roles
     */
    module_list?: Array<object> | null;
    /**
     * Custom override for the admin app collection navigation.
     * @type {object}
     * @memberof Roles
     */
    collection_list?: object | null;
    /**
     * Admin role. If true, skips all permission checks.
     * @type {boolean}
     * @memberof Roles
     */
    admin_access?: boolean;
    /**
     * The users in the role are allowed to use the app.
     * @type {boolean}
     * @memberof Roles
     */
    app_access?: boolean;
    /**
     * 
     * @type {Array<string | Users>}
     * @memberof Roles
     */
    users?: Array<string | Users>;
}
/**
 * 
 * @export
 * @interface Settings
 */
export interface Settings {
    /**
     * Unique identifier for the setting.
     * @type {number}
     * @memberof Settings
     */
    id?: number;
    /**
     * The name of the project.
     * @type {string}
     * @memberof Settings
     */
    project_name?: string;
    /**
     * The url of the project.
     * @type {string}
     * @memberof Settings
     */
    project_url?: string | null;
    /**
     * The brand color of the project.
     * @type {string}
     * @memberof Settings
     */
    project_color?: string | null;
    /**
     * The logo of the project.
     * @type {string}
     * @memberof Settings
     */
    project_logo?: string | null;
    /**
     * The foreground of the project.
     * @type {string}
     * @memberof Settings
     */
    public_foreground?: string | null;
    /**
     * The background of the project.
     * @type {string}
     * @memberof Settings
     */
    public_background?: string | null;
    /**
     * Note rendered on the public pages of the app.
     * @type {string}
     * @memberof Settings
     */
    public_note?: string | null;
    /**
     * Allowed authentication login attempts before the user\'s status is set to blocked.
     * @type {number}
     * @memberof Settings
     */
    auth_login_attempts?: number;
    /**
     * Authentication password policy.
     * @type {string}
     * @memberof Settings
     */
    auth_password_policy?: string | null;
    /**
     * What transformations are allowed in the assets endpoint.
     * @type {string}
     * @memberof Settings
     */
    storage_asset_transform?: SettingsStorageAssetTransformEnum;
    /**
     * Array of allowed
     * @type {Array<SettingsStorageAssetPresets>}
     * @memberof Settings
     */
    storage_asset_presets?: Array<SettingsStorageAssetPresets> | null;
    /**
     * 
     * @type {string}
     * @memberof Settings
     */
    custom_css?: string | null;
    /**
     * Default folder to place files
     * @type {string}
     * @memberof Settings
     */
    storage_default_folder?: string;
    /**
     * 
     * @type {string}
     * @memberof Settings
     */
    public_divider?: string;
    /**
     * 
     * @type {string}
     * @memberof Settings
     */
    security_divider?: string;
    /**
     * 
     * @type {string}
     * @memberof Settings
     */
    files_divider?: string;
    /**
     * 
     * @type {string}
     * @memberof Settings
     */
    overrides_divider?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SettingsStorageAssetTransformEnum {
    All = 'all',
    None = 'none',
    Presets = 'presets'
}

/**
 * 
 * @export
 * @interface SettingsArguments
 */
export interface SettingsArguments {
    /**
     * A JSON representation of the argument value
     * @type {string}
     * @memberof SettingsArguments
     */
    argument?: string;
}
/**
 * 
 * @export
 * @interface SettingsStorageAssetPresets
 */
export interface SettingsStorageAssetPresets {
    /**
     * Key for the asset. Used in the assets endpoint.
     * @type {string}
     * @memberof SettingsStorageAssetPresets
     */
    key?: string;
    /**
     * Whether to crop the thumbnail to match the size, or maintain the aspect ratio.
     * @type {string}
     * @memberof SettingsStorageAssetPresets
     */
    fit?: SettingsStorageAssetPresetsFitEnum;
    /**
     * Width of the thumbnail.
     * @type {number}
     * @memberof SettingsStorageAssetPresets
     */
    width?: number;
    /**
     * Height of the thumbnail.
     * @type {number}
     * @memberof SettingsStorageAssetPresets
     */
    height?: number;
    /**
     * No image upscale
     * @type {boolean}
     * @memberof SettingsStorageAssetPresets
     */
    withoutEnlargement?: boolean;
    /**
     * Quality of the compression used.
     * @type {number}
     * @memberof SettingsStorageAssetPresets
     */
    quality?: number;
    /**
     * Reformat output image
     * @type {string}
     * @memberof SettingsStorageAssetPresets
     */
    format?: SettingsStorageAssetPresetsFormatEnum;
    /**
     * Additional transformations to apply
     * @type {Array<SettingsTransforms>}
     * @memberof SettingsStorageAssetPresets
     */
    transforms?: Array<SettingsTransforms> | null;
}

/**
    * @export
    * @enum {string}
    */
export enum SettingsStorageAssetPresetsFitEnum {
    Cover = 'cover',
    Contain = 'contain',
    Inside = 'inside',
    Outside = 'outside'
}
/**
    * @export
    * @enum {string}
    */
export enum SettingsStorageAssetPresetsFormatEnum {
    Empty = '',
    Jpeg = 'jpeg',
    Png = 'png',
    Webp = 'webp',
    Tiff = 'tiff'
}

/**
 * 
 * @export
 * @interface SettingsTransforms
 */
export interface SettingsTransforms {
    /**
     * The Sharp method name
     * @type {string}
     * @memberof SettingsTransforms
     */
    method?: string;
    /**
     * A list of arguments to pass to the Sharp method
     * @type {Array<SettingsArguments>}
     * @memberof SettingsTransforms
     */
    arguments?: Array<SettingsArguments> | null;
}
/**
 * 
 * @export
 * @interface Users
 */
export interface Users {
    /**
     * Unique identifier for the user.
     * @type {string}
     * @memberof Users
     */
    id?: string;
    /**
     * First name of the user.
     * @type {string}
     * @memberof Users
     */
    first_name?: string;
    /**
     * Last name of the user.
     * @type {string}
     * @memberof Users
     */
    last_name?: string;
    /**
     * Unique email address for the user.
     * @type {string}
     * @memberof Users
     */
    email?: string;
    /**
     * Password of the user.
     * @type {string}
     * @memberof Users
     */
    password?: string;
    /**
     * The user\'s location.
     * @type {string}
     * @memberof Users
     */
    location?: string | null;
    /**
     * The user\'s title.
     * @type {string}
     * @memberof Users
     */
    title?: string | null;
    /**
     * The user\'s description.
     * @type {string}
     * @memberof Users
     */
    description?: string | null;
    /**
     * The user\'s tags.
     * @type {Array<string>}
     * @memberof Users
     */
    tags?: Array<string> | null;
    /**
     * The user\'s avatar.
     * @type {string | Files}
     * @memberof Users
     */
    avatar?: string | Files | null;
    /**
     * The user\'s language used in Directus.
     * @type {string}
     * @memberof Users
     */
    language?: string;
    /**
     * What theme the user is using.
     * @type {string}
     * @memberof Users
     */
    theme?: UsersThemeEnum;
    /**
     * The 2FA secret string that\'s used to generate one time passwords.
     * @type {string}
     * @memberof Users
     */
    tfa_secret?: string | null;
    /**
     * Status of the user.
     * @type {string}
     * @memberof Users
     */
    status?: UsersStatusEnum;
    /**
     * Unique identifier of the role of this user.
     * @type {string | Roles}
     * @memberof Users
     */
    role?: string | Roles;
    /**
     * Static token for the user.
     * @type {string}
     * @memberof Users
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    last_access?: string;
    /**
     * Last page that the user was on.
     * @type {string}
     * @memberof Users
     */
    last_page?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    preferences_divider?: string;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    admin_divider?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UsersThemeEnum {
    Light = 'light',
    Dark = 'dark',
    Auto = 'auto'
}
/**
    * @export
    * @enum {string}
    */
export enum UsersStatusEnum {
    Active = 'active',
    Invited = 'invited',
    Draft = 'draft',
    Suspended = 'suspended',
    Deleted = 'deleted'
}

/**
 * 
 * @export
 * @interface Webhooks
 */
export interface Webhooks {
    /**
     * The index of the webhook.
     * @type {number}
     * @memberof Webhooks
     */
    id?: number;
    /**
     * The name of the webhook.
     * @type {string}
     * @memberof Webhooks
     */
    name?: string;
    /**
     * Method used in the webhook.
     * @type {string}
     * @memberof Webhooks
     */
    method?: string;
    /**
     * The url of the webhook.
     * @type {string}
     * @memberof Webhooks
     */
    url?: string | null;
    /**
     * The status of the webhook.
     * @type {string}
     * @memberof Webhooks
     */
    status?: string;
    /**
     * If yes, send the content of what was done
     * @type {boolean}
     * @memberof Webhooks
     */
    data?: boolean;
    /**
     * The actions that triggers this webhook.
     * @type {Array<string>}
     * @memberof Webhooks
     */
    actions?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Webhooks
     */
    collections?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Webhooks
     */
    triggers_divider?: string;
}

/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new comment.
         * @summary Create a Comment
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject5} [inlineObject5] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment: async (meta?: string, inlineObject5?: InlineObject5, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/activity/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject5, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing comment. Deleted comments can not be retrieved.
         * @summary Delete a Comment
         * @param {number} id Index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteComment', 'id', id)
            const localVarPath = `/activity/comment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of activity actions.
         * @summary List Activity Actions
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivities: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the details of an existing activity action. Provide the primary key of the activity action and Directus will return the corresponding information.
         * @summary Retrieve an Activity Action
         * @param {number} id Index
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivity: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivity', 'id', id)
            const localVarPath = `/activity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the content of an existing comment.
         * @summary Update a Comment
         * @param {number} id Index
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject6} [inlineObject6] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment: async (id: number, meta?: string, inlineObject6?: InlineObject6, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateComment', 'id', id)
            const localVarPath = `/activity/comment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject6, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new comment.
         * @summary Create a Comment
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject5} [inlineObject5] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComment(meta?: string, inlineObject5?: InlineObject5, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComment(meta, inlineObject5, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing comment. Deleted comments can not be retrieved.
         * @summary Delete a Comment
         * @param {number} id Index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of activity actions.
         * @summary List Activity Actions
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivities(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivities(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the details of an existing activity action. Provide the primary key of the activity action and Directus will return the corresponding information.
         * @summary Retrieve an Activity Action
         * @param {number} id Index
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivity(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivity(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the content of an existing comment.
         * @summary Update a Comment
         * @param {number} id Index
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject6} [inlineObject6] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateComment(id: number, meta?: string, inlineObject6?: InlineObject6, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateComment(id, meta, inlineObject6, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityApiFp(configuration)
    return {
        /**
         * Creates a new comment.
         * @summary Create a Comment
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject5} [inlineObject5] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(meta?: string, inlineObject5?: InlineObject5, options?: any): AxiosPromise<InlineResponse20010> {
            return localVarFp.createComment(meta, inlineObject5, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing comment. Deleted comments can not be retrieved.
         * @summary Delete a Comment
         * @param {number} id Index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteComment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of activity actions.
         * @summary List Activity Actions
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivities(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse2009> {
            return localVarFp.getActivities(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the details of an existing activity action. Provide the primary key of the activity action and Directus will return the corresponding information.
         * @summary Retrieve an Activity Action
         * @param {number} id Index
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivity(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20010> {
            return localVarFp.getActivity(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the content of an existing comment.
         * @summary Update a Comment
         * @param {number} id Index
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject6} [inlineObject6] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment(id: number, meta?: string, inlineObject6?: InlineObject6, options?: any): AxiosPromise<InlineResponse20010> {
            return localVarFp.updateComment(id, meta, inlineObject6, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * Creates a new comment.
     * @summary Create a Comment
     * @param {string} [meta] What metadata to return in the response.
     * @param {InlineObject5} [inlineObject5] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public createComment(meta?: string, inlineObject5?: InlineObject5, options?: any) {
        return ActivityApiFp(this.configuration).createComment(meta, inlineObject5, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing comment. Deleted comments can not be retrieved.
     * @summary Delete a Comment
     * @param {number} id Index
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public deleteComment(id: number, options?: any) {
        return ActivityApiFp(this.configuration).deleteComment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of activity actions.
     * @summary List Activity Actions
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getActivities(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ActivityApiFp(this.configuration).getActivities(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the details of an existing activity action. Provide the primary key of the activity action and Directus will return the corresponding information.
     * @summary Retrieve an Activity Action
     * @param {number} id Index
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getActivity(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ActivityApiFp(this.configuration).getActivity(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the content of an existing comment.
     * @summary Update a Comment
     * @param {number} id Index
     * @param {string} [meta] What metadata to return in the response.
     * @param {InlineObject6} [inlineObject6] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public updateComment(id: number, meta?: string, inlineObject6?: InlineObject6, options?: any) {
        return ActivityApiFp(this.configuration).updateComment(id, meta, inlineObject6, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Image typed files can be dynamically resized and transformed to fit any need.
         * @summary Get an Asset
         * @param {string} id The id of the file.
         * @param {string} [key] The key of the asset size configured in settings.
         * @param {string} [transforms] A JSON array of image transformations
         * @param {boolean} [download] Download the asset to your computer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset: async (id: string, key?: string, transforms?: string, download?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAsset', 'id', id)
            const localVarPath = `/assets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (transforms !== undefined) {
                localVarQueryParameter['transforms'] = transforms;
            }

            if (download !== undefined) {
                localVarQueryParameter['download'] = download;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * Image typed files can be dynamically resized and transformed to fit any need.
         * @summary Get an Asset
         * @param {string} id The id of the file.
         * @param {string} [key] The key of the asset size configured in settings.
         * @param {string} [transforms] A JSON array of image transformations
         * @param {boolean} [download] Download the asset to your computer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAsset(id: string, key?: string, transforms?: string, download?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAsset(id, key, transforms, download, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetsApiFp(configuration)
    return {
        /**
         * Image typed files can be dynamically resized and transformed to fit any need.
         * @summary Get an Asset
         * @param {string} id The id of the file.
         * @param {string} [key] The key of the asset size configured in settings.
         * @param {string} [transforms] A JSON array of image transformations
         * @param {boolean} [download] Download the asset to your computer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset(id: string, key?: string, transforms?: string, download?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.getAsset(id, key, transforms, download, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI {
    /**
     * Image typed files can be dynamically resized and transformed to fit any need.
     * @summary Get an Asset
     * @param {string} id The id of the file.
     * @param {string} [key] The key of the asset size configured in settings.
     * @param {string} [transforms] A JSON array of image transformations
     * @param {boolean} [download] Download the asset to your computer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public getAsset(id: string, key?: string, transforms?: string, download?: boolean, options?: any) {
        return AssetsApiFp(this.configuration).getAsset(id, key, transforms, download, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve a Temporary Access Token
         * @summary Retrieve a Temporary Access Token
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (inlineObject?: InlineObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Log Out
         * @summary Log Out
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (inlineObject2?: InlineObject2, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List configured OAuth providers.
         * @summary List OAuth Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauth: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/oauth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Start OAuth flow using the specified provider
         * @summary Authenticated using an OAuth provider
         * @param {string} provider Key of the activated OAuth provider.
         * @param {string} [redirect] Where to redirect on successful login.&lt;br/&gt;If set the authentication details are set inside cookies otherwise a JSON is returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthProvider: async (provider: string, redirect?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('oauthProvider', 'provider', provider)
            const localVarPath = `/auth/oauth/{provider}`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (redirect !== undefined) {
                localVarQueryParameter['redirect'] = redirect;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request a reset password email to be send.
         * @summary Request a Password Reset
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRequest: async (inlineObject3?: InlineObject3, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/password/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The request a password reset endpoint sends an email with a link to the admin app which in turn uses this endpoint to allow the user to reset their password.
         * @summary Reset a Password
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordReset: async (inlineObject4?: InlineObject4, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Refresh a Temporary Access Token.
         * @summary Refresh Token
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: async (inlineObject1?: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve a Temporary Access Token
         * @summary Retrieve a Temporary Access Token
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(inlineObject?: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Log Out
         * @summary Log Out
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(inlineObject2?: InlineObject2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List configured OAuth providers.
         * @summary List OAuth Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauth(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Start OAuth flow using the specified provider
         * @summary Authenticated using an OAuth provider
         * @param {string} provider Key of the activated OAuth provider.
         * @param {string} [redirect] Where to redirect on successful login.&lt;br/&gt;If set the authentication details are set inside cookies otherwise a JSON is returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthProvider(provider: string, redirect?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthProvider(provider, redirect, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Request a reset password email to be send.
         * @summary Request a Password Reset
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordRequest(inlineObject3?: InlineObject3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordRequest(inlineObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The request a password reset endpoint sends an email with a link to the admin app which in turn uses this endpoint to allow the user to reset their password.
         * @summary Reset a Password
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordReset(inlineObject4?: InlineObject4, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordReset(inlineObject4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Refresh a Temporary Access Token.
         * @summary Refresh Token
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refresh(inlineObject1?: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refresh(inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * Retrieve a Temporary Access Token
         * @summary Retrieve a Temporary Access Token
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(inlineObject?: InlineObject, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.login(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * Log Out
         * @summary Log Out
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(inlineObject2?: InlineObject2, options?: any): AxiosPromise<void> {
            return localVarFp.logout(inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * List configured OAuth providers.
         * @summary List OAuth Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauth(options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.oauth(options).then((request) => request(axios, basePath));
        },
        /**
         * Start OAuth flow using the specified provider
         * @summary Authenticated using an OAuth provider
         * @param {string} provider Key of the activated OAuth provider.
         * @param {string} [redirect] Where to redirect on successful login.&lt;br/&gt;If set the authentication details are set inside cookies otherwise a JSON is returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthProvider(provider: string, redirect?: string, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.oauthProvider(provider, redirect, options).then((request) => request(axios, basePath));
        },
        /**
         * Request a reset password email to be send.
         * @summary Request a Password Reset
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRequest(inlineObject3?: InlineObject3, options?: any): AxiosPromise<void> {
            return localVarFp.passwordRequest(inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * The request a password reset endpoint sends an email with a link to the admin app which in turn uses this endpoint to allow the user to reset their password.
         * @summary Reset a Password
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordReset(inlineObject4?: InlineObject4, options?: any): AxiosPromise<void> {
            return localVarFp.passwordReset(inlineObject4, options).then((request) => request(axios, basePath));
        },
        /**
         * Refresh a Temporary Access Token.
         * @summary Refresh Token
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(inlineObject1?: InlineObject1, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.refresh(inlineObject1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * Retrieve a Temporary Access Token
     * @summary Retrieve a Temporary Access Token
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public login(inlineObject?: InlineObject, options?: any) {
        return AuthenticationApiFp(this.configuration).login(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Log Out
     * @summary Log Out
     * @param {InlineObject2} [inlineObject2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public logout(inlineObject2?: InlineObject2, options?: any) {
        return AuthenticationApiFp(this.configuration).logout(inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List configured OAuth providers.
     * @summary List OAuth Providers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public oauth(options?: any) {
        return AuthenticationApiFp(this.configuration).oauth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Start OAuth flow using the specified provider
     * @summary Authenticated using an OAuth provider
     * @param {string} provider Key of the activated OAuth provider.
     * @param {string} [redirect] Where to redirect on successful login.&lt;br/&gt;If set the authentication details are set inside cookies otherwise a JSON is returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public oauthProvider(provider: string, redirect?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).oauthProvider(provider, redirect, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request a reset password email to be send.
     * @summary Request a Password Reset
     * @param {InlineObject3} [inlineObject3] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public passwordRequest(inlineObject3?: InlineObject3, options?: any) {
        return AuthenticationApiFp(this.configuration).passwordRequest(inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The request a password reset endpoint sends an email with a link to the admin app which in turn uses this endpoint to allow the user to reset their password.
     * @summary Reset a Password
     * @param {InlineObject4} [inlineObject4] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public passwordReset(inlineObject4?: InlineObject4, options?: any) {
        return AuthenticationApiFp(this.configuration).passwordReset(inlineObject4, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Refresh a Temporary Access Token.
     * @summary Refresh Token
     * @param {InlineObject1} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public refresh(inlineObject1?: InlineObject1, options?: any) {
        return AuthenticationApiFp(this.configuration).refresh(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CollectionsApi - axios parameter creator
 * @export
 */
export const CollectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new collection in Directus.
         * @summary Create a Collection
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject7} [inlineObject7] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCollection: async (meta?: string, inlineObject7?: InlineObject7, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject7, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing collection. Warning: This will delete the whole collection, including the items within. Proceed with caution.
         * @summary Delete a Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollection: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCollection', 'id', id)
            const localVarPath = `/collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the details of a single collection.
         * @summary Retrieve a Collection
         * @param {number} id 
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollection: async (id: number, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCollection', 'id', id)
            const localVarPath = `/collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of the collections available in the project.
         * @summary List Collections
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollections: async (offset?: number, meta?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing collection.
         * @summary Update a Collection
         * @param {number} id 
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject8} [inlineObject8] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCollection: async (id: number, meta?: string, inlineObject8?: InlineObject8, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCollection', 'id', id)
            const localVarPath = `/collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject8, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CollectionsApi - functional programming interface
 * @export
 */
export const CollectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CollectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new collection in Directus.
         * @summary Create a Collection
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject7} [inlineObject7] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCollection(meta?: string, inlineObject7?: InlineObject7, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20012>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCollection(meta, inlineObject7, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing collection. Warning: This will delete the whole collection, including the items within. Proceed with caution.
         * @summary Delete a Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollection(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollection(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the details of a single collection.
         * @summary Retrieve a Collection
         * @param {number} id 
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollection(id: number, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20012>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollection(id, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of the collections available in the project.
         * @summary List Collections
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollections(offset?: number, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20011>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollections(offset, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing collection.
         * @summary Update a Collection
         * @param {number} id 
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject8} [inlineObject8] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCollection(id: number, meta?: string, inlineObject8?: InlineObject8, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20012>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCollection(id, meta, inlineObject8, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CollectionsApi - factory interface
 * @export
 */
export const CollectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CollectionsApiFp(configuration)
    return {
        /**
         * Create a new collection in Directus.
         * @summary Create a Collection
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject7} [inlineObject7] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCollection(meta?: string, inlineObject7?: InlineObject7, options?: any): AxiosPromise<InlineResponse20012> {
            return localVarFp.createCollection(meta, inlineObject7, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing collection. Warning: This will delete the whole collection, including the items within. Proceed with caution.
         * @summary Delete a Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollection(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCollection(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the details of a single collection.
         * @summary Retrieve a Collection
         * @param {number} id 
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollection(id: number, meta?: string, options?: any): AxiosPromise<InlineResponse20012> {
            return localVarFp.getCollection(id, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of the collections available in the project.
         * @summary List Collections
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollections(offset?: number, meta?: string, options?: any): AxiosPromise<InlineResponse20011> {
            return localVarFp.getCollections(offset, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing collection.
         * @summary Update a Collection
         * @param {number} id 
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject8} [inlineObject8] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCollection(id: number, meta?: string, inlineObject8?: InlineObject8, options?: any): AxiosPromise<InlineResponse20012> {
            return localVarFp.updateCollection(id, meta, inlineObject8, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CollectionsApi - object-oriented interface
 * @export
 * @class CollectionsApi
 * @extends {BaseAPI}
 */
export class CollectionsApi extends BaseAPI {
    /**
     * Create a new collection in Directus.
     * @summary Create a Collection
     * @param {string} [meta] What metadata to return in the response.
     * @param {InlineObject7} [inlineObject7] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionsApi
     */
    public createCollection(meta?: string, inlineObject7?: InlineObject7, options?: any) {
        return CollectionsApiFp(this.configuration).createCollection(meta, inlineObject7, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing collection. Warning: This will delete the whole collection, including the items within. Proceed with caution.
     * @summary Delete a Collection
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionsApi
     */
    public deleteCollection(id: number, options?: any) {
        return CollectionsApiFp(this.configuration).deleteCollection(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the details of a single collection.
     * @summary Retrieve a Collection
     * @param {number} id 
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionsApi
     */
    public getCollection(id: number, meta?: string, options?: any) {
        return CollectionsApiFp(this.configuration).getCollection(id, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of the collections available in the project.
     * @summary List Collections
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionsApi
     */
    public getCollections(offset?: number, meta?: string, options?: any) {
        return CollectionsApiFp(this.configuration).getCollections(offset, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing collection.
     * @summary Update a Collection
     * @param {number} id 
     * @param {string} [meta] What metadata to return in the response.
     * @param {InlineObject8} [inlineObject8] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionsApi
     */
    public updateCollection(id: number, meta?: string, inlineObject8?: InlineObject8, options?: any) {
        return CollectionsApiFp(this.configuration).updateCollection(id, meta, inlineObject8, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExtensionsApi - axios parameter creator
 * @export
 */
export const ExtensionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List all installed custom displays.
         * @summary List Displays
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisplays: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/extensions/displays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all installed custom interfaces.
         * @summary List Interfaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterfaces: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/extensions/interfaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all installed custom layouts.
         * @summary List Layouts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayouts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/extensions/layouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all installed custom modules.
         * @summary List Modules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModules: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/extensions/modules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExtensionsApi - functional programming interface
 * @export
 */
export const ExtensionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExtensionsApiAxiosParamCreator(configuration)
    return {
        /**
         * List all installed custom displays.
         * @summary List Displays
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDisplays(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDisplays(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all installed custom interfaces.
         * @summary List Interfaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterfaces(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterfaces(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all installed custom layouts.
         * @summary List Layouts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLayouts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLayouts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all installed custom modules.
         * @summary List Modules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModules(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModules(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExtensionsApi - factory interface
 * @export
 */
export const ExtensionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExtensionsApiFp(configuration)
    return {
        /**
         * List all installed custom displays.
         * @summary List Displays
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisplays(options?: any): AxiosPromise<InlineResponse2004> {
            return localVarFp.getDisplays(options).then((request) => request(axios, basePath));
        },
        /**
         * List all installed custom interfaces.
         * @summary List Interfaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterfaces(options?: any): AxiosPromise<InlineResponse2004> {
            return localVarFp.getInterfaces(options).then((request) => request(axios, basePath));
        },
        /**
         * List all installed custom layouts.
         * @summary List Layouts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayouts(options?: any): AxiosPromise<InlineResponse2004> {
            return localVarFp.getLayouts(options).then((request) => request(axios, basePath));
        },
        /**
         * List all installed custom modules.
         * @summary List Modules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModules(options?: any): AxiosPromise<InlineResponse2004> {
            return localVarFp.getModules(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExtensionsApi - object-oriented interface
 * @export
 * @class ExtensionsApi
 * @extends {BaseAPI}
 */
export class ExtensionsApi extends BaseAPI {
    /**
     * List all installed custom displays.
     * @summary List Displays
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionsApi
     */
    public getDisplays(options?: any) {
        return ExtensionsApiFp(this.configuration).getDisplays(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all installed custom interfaces.
     * @summary List Interfaces
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionsApi
     */
    public getInterfaces(options?: any) {
        return ExtensionsApiFp(this.configuration).getInterfaces(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all installed custom layouts.
     * @summary List Layouts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionsApi
     */
    public getLayouts(options?: any) {
        return ExtensionsApiFp(this.configuration).getLayouts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all installed custom modules.
     * @summary List Modules
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionsApi
     */
    public getModules(options?: any) {
        return ExtensionsApiFp(this.configuration).getModules(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FieldsApi - axios parameter creator
 * @export
 */
export const FieldsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new field in a given collection.
         * @summary Create Field in Collection
         * @param {string} collection 
         * @param {InlineObject9} [inlineObject9] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createField: async (collection: string, inlineObject9?: InlineObject9, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'collection' is not null or undefined
            assertParamExists('createField', 'collection', collection)
            const localVarPath = `/fields/{collection}`
                .replace(`{${"collection"}}`, encodeURIComponent(String(collection)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject9, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing field.
         * @summary Delete a Field
         * @param {string} collection 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteField: async (collection: string, id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'collection' is not null or undefined
            assertParamExists('deleteField', 'collection', collection)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteField', 'id', id)
            const localVarPath = `/fields/{collection}/{id}`
                .replace(`{${"collection"}}`, encodeURIComponent(String(collection)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the details of a single field in a given collection.
         * @summary Retrieve a Field
         * @param {string} collection 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionField: async (collection: string, id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'collection' is not null or undefined
            assertParamExists('getCollectionField', 'collection', collection)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCollectionField', 'id', id)
            const localVarPath = `/fields/{collection}/{id}`
                .replace(`{${"collection"}}`, encodeURIComponent(String(collection)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of the fields available in the given collection.
         * @summary List Fields in Collection
         * @param {string} collection 
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionFields: async (collection: string, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'collection' is not null or undefined
            assertParamExists('getCollectionFields', 'collection', collection)
            const localVarPath = `/fields/{collection}`
                .replace(`{${"collection"}}`, encodeURIComponent(String(collection)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of the fields available in the project.
         * @summary List All Fields
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFields: async (limit?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing field.
         * @summary Update a Field
         * @param {string} collection 
         * @param {number} id 
         * @param {InlineObject10} [inlineObject10] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateField: async (collection: string, id: number, inlineObject10?: InlineObject10, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'collection' is not null or undefined
            assertParamExists('updateField', 'collection', collection)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateField', 'id', id)
            const localVarPath = `/fields/{collection}/{id}`
                .replace(`{${"collection"}}`, encodeURIComponent(String(collection)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject10, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FieldsApi - functional programming interface
 * @export
 */
export const FieldsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FieldsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new field in a given collection.
         * @summary Create Field in Collection
         * @param {string} collection 
         * @param {InlineObject9} [inlineObject9] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createField(collection: string, inlineObject9?: InlineObject9, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20014>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createField(collection, inlineObject9, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing field.
         * @summary Delete a Field
         * @param {string} collection 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteField(collection: string, id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteField(collection, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the details of a single field in a given collection.
         * @summary Retrieve a Field
         * @param {string} collection 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectionField(collection: string, id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20014>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectionField(collection, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of the fields available in the given collection.
         * @summary List Fields in Collection
         * @param {string} collection 
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectionFields(collection: string, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectionFields(collection, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of the fields available in the project.
         * @summary List All Fields
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFields(limit?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFields(limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing field.
         * @summary Update a Field
         * @param {string} collection 
         * @param {number} id 
         * @param {InlineObject10} [inlineObject10] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateField(collection: string, id: number, inlineObject10?: InlineObject10, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20014>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateField(collection, id, inlineObject10, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FieldsApi - factory interface
 * @export
 */
export const FieldsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FieldsApiFp(configuration)
    return {
        /**
         * Create a new field in a given collection.
         * @summary Create Field in Collection
         * @param {string} collection 
         * @param {InlineObject9} [inlineObject9] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createField(collection: string, inlineObject9?: InlineObject9, options?: any): AxiosPromise<InlineResponse20014> {
            return localVarFp.createField(collection, inlineObject9, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing field.
         * @summary Delete a Field
         * @param {string} collection 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteField(collection: string, id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteField(collection, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the details of a single field in a given collection.
         * @summary Retrieve a Field
         * @param {string} collection 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionField(collection: string, id: number, options?: any): AxiosPromise<InlineResponse20014> {
            return localVarFp.getCollectionField(collection, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of the fields available in the given collection.
         * @summary List Fields in Collection
         * @param {string} collection 
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionFields(collection: string, sort?: Array<string>, options?: any): AxiosPromise<InlineResponse20013> {
            return localVarFp.getCollectionFields(collection, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of the fields available in the project.
         * @summary List All Fields
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFields(limit?: number, sort?: Array<string>, options?: any): AxiosPromise<InlineResponse20013> {
            return localVarFp.getFields(limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing field.
         * @summary Update a Field
         * @param {string} collection 
         * @param {number} id 
         * @param {InlineObject10} [inlineObject10] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateField(collection: string, id: number, inlineObject10?: InlineObject10, options?: any): AxiosPromise<InlineResponse20014> {
            return localVarFp.updateField(collection, id, inlineObject10, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FieldsApi - object-oriented interface
 * @export
 * @class FieldsApi
 * @extends {BaseAPI}
 */
export class FieldsApi extends BaseAPI {
    /**
     * Create a new field in a given collection.
     * @summary Create Field in Collection
     * @param {string} collection 
     * @param {InlineObject9} [inlineObject9] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsApi
     */
    public createField(collection: string, inlineObject9?: InlineObject9, options?: any) {
        return FieldsApiFp(this.configuration).createField(collection, inlineObject9, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing field.
     * @summary Delete a Field
     * @param {string} collection 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsApi
     */
    public deleteField(collection: string, id: number, options?: any) {
        return FieldsApiFp(this.configuration).deleteField(collection, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the details of a single field in a given collection.
     * @summary Retrieve a Field
     * @param {string} collection 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsApi
     */
    public getCollectionField(collection: string, id: number, options?: any) {
        return FieldsApiFp(this.configuration).getCollectionField(collection, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of the fields available in the given collection.
     * @summary List Fields in Collection
     * @param {string} collection 
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsApi
     */
    public getCollectionFields(collection: string, sort?: Array<string>, options?: any) {
        return FieldsApiFp(this.configuration).getCollectionFields(collection, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of the fields available in the project.
     * @summary List All Fields
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsApi
     */
    public getFields(limit?: number, sort?: Array<string>, options?: any) {
        return FieldsApiFp(this.configuration).getFields(limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing field.
     * @summary Update a Field
     * @param {string} collection 
     * @param {number} id 
     * @param {InlineObject10} [inlineObject10] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsApi
     */
    public updateField(collection: string, id: number, inlineObject10?: InlineObject10, options?: any) {
        return FieldsApiFp(this.configuration).updateField(collection, id, inlineObject10, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new file
         * @summary Create a File
         * @param {InlineObject11} [inlineObject11] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile: async (inlineObject11?: InlineObject11, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject11, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing file.
         * @summary Delete a File
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFile', 'id', id)
            const localVarPath = `/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single file by unique identifier.
         * @summary Retrieve a Files
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFile', 'id', id)
            const localVarPath = `/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the files.
         * @summary List Files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing file.
         * @summary Update a File
         * @param {number} id 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFile: async (id: number, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFile', 'id', id)
            const localVarPath = `/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new file
         * @summary Create a File
         * @param {InlineObject11} [inlineObject11] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFile(inlineObject11?: InlineObject11, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20016>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFile(inlineObject11, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing file.
         * @summary Delete a File
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFile(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single file by unique identifier.
         * @summary Retrieve a Files
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFile(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20016>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the files.
         * @summary List Files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFiles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20015>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing file.
         * @summary Update a File
         * @param {number} id 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFile(id: number, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20016>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFile(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesApiFp(configuration)
    return {
        /**
         * Create a new file
         * @summary Create a File
         * @param {InlineObject11} [inlineObject11] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile(inlineObject11?: InlineObject11, options?: any): AxiosPromise<InlineResponse20016> {
            return localVarFp.createFile(inlineObject11, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing file.
         * @summary Delete a File
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single file by unique identifier.
         * @summary Retrieve a Files
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(id: number, options?: any): AxiosPromise<InlineResponse20016> {
            return localVarFp.getFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the files.
         * @summary List Files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles(options?: any): AxiosPromise<InlineResponse20015> {
            return localVarFp.getFiles(options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing file.
         * @summary Update a File
         * @param {number} id 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFile(id: number, body?: object, options?: any): AxiosPromise<InlineResponse20016> {
            return localVarFp.updateFile(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     * Create a new file
     * @summary Create a File
     * @param {InlineObject11} [inlineObject11] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public createFile(inlineObject11?: InlineObject11, options?: any) {
        return FilesApiFp(this.configuration).createFile(inlineObject11, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing file.
     * @summary Delete a File
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public deleteFile(id: number, options?: any) {
        return FilesApiFp(this.configuration).deleteFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single file by unique identifier.
     * @summary Retrieve a Files
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public getFile(id: number, options?: any) {
        return FilesApiFp(this.configuration).getFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the files.
     * @summary List Files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public getFiles(options?: any) {
        return FilesApiFp(this.configuration).getFiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing file.
     * @summary Update a File
     * @param {number} id 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public updateFile(id: number, body?: object, options?: any) {
        return FilesApiFp(this.configuration).updateFile(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FoldersApi - axios parameter creator
 * @export
 */
export const FoldersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new folder.
         * @summary Create a Folder
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject12} [inlineObject12] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder: async (fields?: Array<string>, meta?: string, inlineObject12?: InlineObject12, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/folders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject12, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing folder
         * @summary Delete a Folder
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolder: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFolder', 'id', id)
            const localVarPath = `/folders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single folder by unique identifier.
         * @summary Retrieve a Folder
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolder: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFolder', 'id', id)
            const localVarPath = `/folders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the folders.
         * @summary List Folders
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolders: async (fields?: Array<string>, limit?: number, offset?: number, sort?: Array<string>, filter?: string, search?: string, meta?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/folders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing folder
         * @summary Update a Folder
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject13} [inlineObject13] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolder: async (id: number, fields?: Array<string>, meta?: string, inlineObject13?: InlineObject13, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFolder', 'id', id)
            const localVarPath = `/folders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject13, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FoldersApi - functional programming interface
 * @export
 */
export const FoldersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FoldersApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new folder.
         * @summary Create a Folder
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject12} [inlineObject12] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFolder(fields?: Array<string>, meta?: string, inlineObject12?: InlineObject12, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20018>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFolder(fields, meta, inlineObject12, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing folder
         * @summary Delete a Folder
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFolder(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFolder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single folder by unique identifier.
         * @summary Retrieve a Folder
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolder(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20018>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolder(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the folders.
         * @summary List Folders
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolders(fields?: Array<string>, limit?: number, offset?: number, sort?: Array<string>, filter?: string, search?: string, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20017>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolders(fields, limit, offset, sort, filter, search, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing folder
         * @summary Update a Folder
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject13} [inlineObject13] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFolder(id: number, fields?: Array<string>, meta?: string, inlineObject13?: InlineObject13, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20018>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFolder(id, fields, meta, inlineObject13, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FoldersApi - factory interface
 * @export
 */
export const FoldersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FoldersApiFp(configuration)
    return {
        /**
         * Create a new folder.
         * @summary Create a Folder
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject12} [inlineObject12] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder(fields?: Array<string>, meta?: string, inlineObject12?: InlineObject12, options?: any): AxiosPromise<InlineResponse20018> {
            return localVarFp.createFolder(fields, meta, inlineObject12, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing folder
         * @summary Delete a Folder
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolder(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFolder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single folder by unique identifier.
         * @summary Retrieve a Folder
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolder(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20018> {
            return localVarFp.getFolder(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * List the folders.
         * @summary List Folders
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolders(fields?: Array<string>, limit?: number, offset?: number, sort?: Array<string>, filter?: string, search?: string, meta?: string, options?: any): AxiosPromise<InlineResponse20017> {
            return localVarFp.getFolders(fields, limit, offset, sort, filter, search, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing folder
         * @summary Update a Folder
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject13} [inlineObject13] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolder(id: number, fields?: Array<string>, meta?: string, inlineObject13?: InlineObject13, options?: any): AxiosPromise<InlineResponse20018> {
            return localVarFp.updateFolder(id, fields, meta, inlineObject13, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FoldersApi - object-oriented interface
 * @export
 * @class FoldersApi
 * @extends {BaseAPI}
 */
export class FoldersApi extends BaseAPI {
    /**
     * Create a new folder.
     * @summary Create a Folder
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {InlineObject12} [inlineObject12] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public createFolder(fields?: Array<string>, meta?: string, inlineObject12?: InlineObject12, options?: any) {
        return FoldersApiFp(this.configuration).createFolder(fields, meta, inlineObject12, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing folder
     * @summary Delete a Folder
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public deleteFolder(id: number, options?: any) {
        return FoldersApiFp(this.configuration).deleteFolder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single folder by unique identifier.
     * @summary Retrieve a Folder
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public getFolder(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return FoldersApiFp(this.configuration).getFolder(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the folders.
     * @summary List Folders
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public getFolders(fields?: Array<string>, limit?: number, offset?: number, sort?: Array<string>, filter?: string, search?: string, meta?: string, options?: any) {
        return FoldersApiFp(this.configuration).getFolders(fields, limit, offset, sort, filter, search, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing folder
     * @summary Update a Folder
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {InlineObject13} [inlineObject13] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public updateFolder(id: number, fields?: Array<string>, meta?: string, inlineObject13?: InlineObject13, options?: any) {
        return FoldersApiFp(this.configuration).updateFolder(id, fields, meta, inlineObject13, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsApi - axios parameter creator
 * @export
 */
export const ItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsAddresses> | ItemsAddresses} [arrayItemsAddressesItemsAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsAddresses: async (meta?: string, arrayItemsAddressesItemsAddresses?: Array<ItemsAddresses> | ItemsAddresses, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsAddressesItemsAddresses, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new certifications item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsCertifications> | ItemsCertifications} [arrayItemsCertificationsItemsCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsCertifications: async (meta?: string, arrayItemsCertificationsItemsCertifications?: Array<ItemsCertifications> | ItemsCertifications, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/certifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsCertificationsItemsCertifications, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new import_types item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsImportTypes> | ItemsImportTypes} [arrayItemsImportTypesItemsImportTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsImportTypes: async (meta?: string, arrayItemsImportTypesItemsImportTypes?: Array<ItemsImportTypes> | ItemsImportTypes, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/import_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsImportTypesItemsImportTypes, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new imports item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsImports> | ItemsImports} [arrayItemsImportsItemsImports] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsImports: async (meta?: string, arrayItemsImportsItemsImports?: Array<ItemsImports> | ItemsImports, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/imports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsImportsItemsImports, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new platform_addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsPlatformAddresses> | ItemsPlatformAddresses} [arrayItemsPlatformAddressesItemsPlatformAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsPlatformAddresses: async (meta?: string, arrayItemsPlatformAddressesItemsPlatformAddresses?: Array<ItemsPlatformAddresses> | ItemsPlatformAddresses, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/platform_addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsPlatformAddressesItemsPlatformAddresses, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new platforms item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsPlatforms> | ItemsPlatforms} [arrayItemsPlatformsItemsPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsPlatforms: async (meta?: string, arrayItemsPlatformsItemsPlatforms?: Array<ItemsPlatforms> | ItemsPlatforms, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/platforms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsPlatformsItemsPlatforms, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new producers item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducers> | ItemsProducers} [arrayItemsProducersItemsProducers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProducers: async (meta?: string, arrayItemsProducersItemsProducers?: Array<ItemsProducers> | ItemsProducers, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/producers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsProducersItemsProducers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new producers_certifications item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducersCertifications> | ItemsProducersCertifications} [arrayItemsProducersCertificationsItemsProducersCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProducersCertifications: async (meta?: string, arrayItemsProducersCertificationsItemsProducersCertifications?: Array<ItemsProducersCertifications> | ItemsProducersCertifications, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/producers_certifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsProducersCertificationsItemsProducersCertifications, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new producers_platforms item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducersPlatforms> | ItemsProducersPlatforms} [arrayItemsProducersPlatformsItemsProducersPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProducersPlatforms: async (meta?: string, arrayItemsProducersPlatformsItemsProducersPlatforms?: Array<ItemsProducersPlatforms> | ItemsProducersPlatforms, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/producers_platforms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsProducersPlatformsItemsProducersPlatforms, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new product_categories item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProductCategories> | ItemsProductCategories} [arrayItemsProductCategoriesItemsProductCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProductCategories: async (meta?: string, arrayItemsProductCategoriesItemsProductCategories?: Array<ItemsProductCategories> | ItemsProductCategories, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/product_categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsProductCategoriesItemsProductCategories, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new products item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducts> | ItemsProducts} [arrayItemsProductsItemsProducts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProducts: async (meta?: string, arrayItemsProductsItemsProducts?: Array<ItemsProducts> | ItemsProducts, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsProductsItemsProducts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new products_producers_addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProductsProducersAddresses> | ItemsProductsProducersAddresses} [arrayItemsProductsProducersAddressesItemsProductsProducersAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProductsProducersAddresses: async (meta?: string, arrayItemsProductsProducersAddressesItemsProductsProducersAddresses?: Array<ItemsProductsProducersAddresses> | ItemsProductsProducersAddresses, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/products_producers_addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsProductsProducersAddressesItemsProductsProducersAddresses, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new provinces item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProvinces> | ItemsProvinces} [arrayItemsProvincesItemsProvinces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProvinces: async (meta?: string, arrayItemsProvincesItemsProvinces?: Array<ItemsProvinces> | ItemsProvinces, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/provinces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsProvincesItemsProvinces, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new regions item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsRegions> | ItemsRegions} [arrayItemsRegionsItemsRegions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsRegions: async (meta?: string, arrayItemsRegionsItemsRegions?: Array<ItemsRegions> | ItemsRegions, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsRegionsItemsRegions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new sources item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsSources> | ItemsSources} [arrayItemsSourcesItemsSources] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsSources: async (meta?: string, arrayItemsSourcesItemsSources?: Array<ItemsSources> | ItemsSources, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsSourcesItemsSources, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new units item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsUnits> | ItemsUnits} [arrayItemsUnitsItemsUnits] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsUnits: async (meta?: string, arrayItemsUnitsItemsUnits?: Array<ItemsUnits> | ItemsUnits, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsUnitsItemsUnits, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsAddresses: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsAddresses', 'id', id)
            const localVarPath = `/items/addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing certifications item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsCertifications: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsCertifications', 'id', id)
            const localVarPath = `/items/certifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing import_types item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsImportTypes: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsImportTypes', 'id', id)
            const localVarPath = `/items/import_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing imports item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsImports: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsImports', 'id', id)
            const localVarPath = `/items/imports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing platform_addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsPlatformAddresses: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsPlatformAddresses', 'id', id)
            const localVarPath = `/items/platform_addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing platforms item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsPlatforms: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsPlatforms', 'id', id)
            const localVarPath = `/items/platforms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing producers item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProducers: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsProducers', 'id', id)
            const localVarPath = `/items/producers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing producers_certifications item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProducersCertifications: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsProducersCertifications', 'id', id)
            const localVarPath = `/items/producers_certifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing producers_platforms item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProducersPlatforms: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsProducersPlatforms', 'id', id)
            const localVarPath = `/items/producers_platforms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing product_categories item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProductCategories: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsProductCategories', 'id', id)
            const localVarPath = `/items/product_categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing products item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProducts: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsProducts', 'id', id)
            const localVarPath = `/items/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing products_producers_addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProductsProducersAddresses: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsProductsProducersAddresses', 'id', id)
            const localVarPath = `/items/products_producers_addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing provinces item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProvinces: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsProvinces', 'id', id)
            const localVarPath = `/items/provinces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing regions item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsRegions: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsRegions', 'id', id)
            const localVarPath = `/items/regions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing sources item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsSources: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsSources', 'id', id)
            const localVarPath = `/items/sources/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing units item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsUnits: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsUnits', 'id', id)
            const localVarPath = `/items/units/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsAddresses: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the certifications items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsCertifications: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/certifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the import_types items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsImportTypes: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/import_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the imports items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsImports: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/imports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the platform_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPlatformAddresses: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/platform_addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the platforms items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPlatforms: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/platforms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the producers items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProducers: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/producers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the producers_certifications items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProducersCertifications: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/producers_certifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the producers_platforms items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProducersPlatforms: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/producers_platforms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the product_categories items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProductCategories: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/product_categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the products items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProducts: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the products_producers_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProductsProducersAddresses: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/products_producers_addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the provinces items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProvinces: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/provinces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the regions items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsRegions: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the sources items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsSources: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the units items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsUnits: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsAddresses: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsAddresses', 'id', id)
            const localVarPath = `/items/addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single certifications item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsCertifications: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsCertifications', 'id', id)
            const localVarPath = `/items/certifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single import_types item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsImportTypes: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsImportTypes', 'id', id)
            const localVarPath = `/items/import_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single imports item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsImports: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsImports', 'id', id)
            const localVarPath = `/items/imports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single platform_addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPlatformAddresses: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsPlatformAddresses', 'id', id)
            const localVarPath = `/items/platform_addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single platforms item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPlatforms: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsPlatforms', 'id', id)
            const localVarPath = `/items/platforms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single producers item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProducers: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsProducers', 'id', id)
            const localVarPath = `/items/producers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single producers_certifications item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProducersCertifications: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsProducersCertifications', 'id', id)
            const localVarPath = `/items/producers_certifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single producers_platforms item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProducersPlatforms: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsProducersPlatforms', 'id', id)
            const localVarPath = `/items/producers_platforms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single product_categories item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProductCategories: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsProductCategories', 'id', id)
            const localVarPath = `/items/product_categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single products item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProducts: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsProducts', 'id', id)
            const localVarPath = `/items/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single products_producers_addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProductsProducersAddresses: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsProductsProducersAddresses', 'id', id)
            const localVarPath = `/items/products_producers_addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single provinces item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProvinces: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsProvinces', 'id', id)
            const localVarPath = `/items/provinces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single regions item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsRegions: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsRegions', 'id', id)
            const localVarPath = `/items/regions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single sources item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsSources: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsSources', 'id', id)
            const localVarPath = `/items/sources/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single units item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsUnits: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsUnits', 'id', id)
            const localVarPath = `/items/units/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the products_producers_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [latitude] Filter by latitude.
         * @param {number} [longitude] Filter by longitude.
         * @param {number} [km] Filter by km.
         * @param {'standard' | 'shipping'} [searchType] Define if search type is based on \&quot;shipping\&quot; or \&quot;proximity\&quot; address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchItemsProductsProducersAddresses: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, latitude?: number, longitude?: number, km?: number, searchType?: 'standard' | 'shipping', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (km !== undefined) {
                localVarQueryParameter['km'] = km;
            }

            if (searchType !== undefined) {
                localVarQueryParameter['search_type'] = searchType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsAddresses} [itemsAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsAddresses: async (id: number, fields?: Array<string>, meta?: string, itemsAddresses?: ItemsAddresses, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsAddresses', 'id', id)
            const localVarPath = `/items/addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsAddresses, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing certifications item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsCertifications} [itemsCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsCertifications: async (id: number, fields?: Array<string>, meta?: string, itemsCertifications?: ItemsCertifications, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsCertifications', 'id', id)
            const localVarPath = `/items/certifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsCertifications, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing import_types item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsImportTypes} [itemsImportTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsImportTypes: async (id: number, fields?: Array<string>, meta?: string, itemsImportTypes?: ItemsImportTypes, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsImportTypes', 'id', id)
            const localVarPath = `/items/import_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsImportTypes, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing imports item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsImports} [itemsImports] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsImports: async (id: number, fields?: Array<string>, meta?: string, itemsImports?: ItemsImports, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsImports', 'id', id)
            const localVarPath = `/items/imports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsImports, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing platform_addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsPlatformAddresses} [itemsPlatformAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsPlatformAddresses: async (id: number, fields?: Array<string>, meta?: string, itemsPlatformAddresses?: ItemsPlatformAddresses, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsPlatformAddresses', 'id', id)
            const localVarPath = `/items/platform_addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsPlatformAddresses, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing platforms item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsPlatforms} [itemsPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsPlatforms: async (id: number, fields?: Array<string>, meta?: string, itemsPlatforms?: ItemsPlatforms, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsPlatforms', 'id', id)
            const localVarPath = `/items/platforms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsPlatforms, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing producers item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducers} [itemsProducers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProducers: async (id: number, fields?: Array<string>, meta?: string, itemsProducers?: ItemsProducers, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsProducers', 'id', id)
            const localVarPath = `/items/producers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsProducers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing producers_certifications item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducersCertifications} [itemsProducersCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProducersCertifications: async (id: number, fields?: Array<string>, meta?: string, itemsProducersCertifications?: ItemsProducersCertifications, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsProducersCertifications', 'id', id)
            const localVarPath = `/items/producers_certifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsProducersCertifications, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing producers_platforms item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducersPlatforms} [itemsProducersPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProducersPlatforms: async (id: number, fields?: Array<string>, meta?: string, itemsProducersPlatforms?: ItemsProducersPlatforms, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsProducersPlatforms', 'id', id)
            const localVarPath = `/items/producers_platforms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsProducersPlatforms, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing product_categories item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProductCategories} [itemsProductCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProductCategories: async (id: number, fields?: Array<string>, meta?: string, itemsProductCategories?: ItemsProductCategories, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsProductCategories', 'id', id)
            const localVarPath = `/items/product_categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsProductCategories, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing products item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducts} [itemsProducts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProducts: async (id: number, fields?: Array<string>, meta?: string, itemsProducts?: ItemsProducts, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsProducts', 'id', id)
            const localVarPath = `/items/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsProducts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing products_producers_addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProductsProducersAddresses} [itemsProductsProducersAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProductsProducersAddresses: async (id: number, fields?: Array<string>, meta?: string, itemsProductsProducersAddresses?: ItemsProductsProducersAddresses, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsProductsProducersAddresses', 'id', id)
            const localVarPath = `/items/products_producers_addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsProductsProducersAddresses, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing provinces item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProvinces} [itemsProvinces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProvinces: async (id: number, fields?: Array<string>, meta?: string, itemsProvinces?: ItemsProvinces, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsProvinces', 'id', id)
            const localVarPath = `/items/provinces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsProvinces, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing regions item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsRegions} [itemsRegions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsRegions: async (id: number, fields?: Array<string>, meta?: string, itemsRegions?: ItemsRegions, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsRegions', 'id', id)
            const localVarPath = `/items/regions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsRegions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing sources item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsSources} [itemsSources] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsSources: async (id: number, fields?: Array<string>, meta?: string, itemsSources?: ItemsSources, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsSources', 'id', id)
            const localVarPath = `/items/sources/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsSources, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing units item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsUnits} [itemsUnits] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsUnits: async (id: number, fields?: Array<string>, meta?: string, itemsUnits?: ItemsUnits, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsUnits', 'id', id)
            const localVarPath = `/items/units/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsUnits, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsApi - functional programming interface
 * @export
 */
export const ItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsAddresses> | ItemsAddresses} [arrayItemsAddressesItemsAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsAddresses(meta?: string, arrayItemsAddressesItemsAddresses?: Array<ItemsAddresses> | ItemsAddresses, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsAddresses(meta, arrayItemsAddressesItemsAddresses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new certifications item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsCertifications> | ItemsCertifications} [arrayItemsCertificationsItemsCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsCertifications(meta?: string, arrayItemsCertificationsItemsCertifications?: Array<ItemsCertifications> | ItemsCertifications, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsCertifications(meta, arrayItemsCertificationsItemsCertifications, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new import_types item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsImportTypes> | ItemsImportTypes} [arrayItemsImportTypesItemsImportTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsImportTypes(meta?: string, arrayItemsImportTypesItemsImportTypes?: Array<ItemsImportTypes> | ItemsImportTypes, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20033>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsImportTypes(meta, arrayItemsImportTypesItemsImportTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new imports item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsImports> | ItemsImports} [arrayItemsImportsItemsImports] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsImports(meta?: string, arrayItemsImportsItemsImports?: Array<ItemsImports> | ItemsImports, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20034>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsImports(meta, arrayItemsImportsItemsImports, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new platform_addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsPlatformAddresses> | ItemsPlatformAddresses} [arrayItemsPlatformAddressesItemsPlatformAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsPlatformAddresses(meta?: string, arrayItemsPlatformAddressesItemsPlatformAddresses?: Array<ItemsPlatformAddresses> | ItemsPlatformAddresses, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20035>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsPlatformAddresses(meta, arrayItemsPlatformAddressesItemsPlatformAddresses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new platforms item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsPlatforms> | ItemsPlatforms} [arrayItemsPlatformsItemsPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsPlatforms(meta?: string, arrayItemsPlatformsItemsPlatforms?: Array<ItemsPlatforms> | ItemsPlatforms, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20036>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsPlatforms(meta, arrayItemsPlatformsItemsPlatforms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new producers item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducers> | ItemsProducers} [arrayItemsProducersItemsProducers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsProducers(meta?: string, arrayItemsProducersItemsProducers?: Array<ItemsProducers> | ItemsProducers, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20037>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsProducers(meta, arrayItemsProducersItemsProducers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new producers_certifications item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducersCertifications> | ItemsProducersCertifications} [arrayItemsProducersCertificationsItemsProducersCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsProducersCertifications(meta?: string, arrayItemsProducersCertificationsItemsProducersCertifications?: Array<ItemsProducersCertifications> | ItemsProducersCertifications, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20038>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsProducersCertifications(meta, arrayItemsProducersCertificationsItemsProducersCertifications, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new producers_platforms item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducersPlatforms> | ItemsProducersPlatforms} [arrayItemsProducersPlatformsItemsProducersPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsProducersPlatforms(meta?: string, arrayItemsProducersPlatformsItemsProducersPlatforms?: Array<ItemsProducersPlatforms> | ItemsProducersPlatforms, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20039>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsProducersPlatforms(meta, arrayItemsProducersPlatformsItemsProducersPlatforms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new product_categories item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProductCategories> | ItemsProductCategories} [arrayItemsProductCategoriesItemsProductCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsProductCategories(meta?: string, arrayItemsProductCategoriesItemsProductCategories?: Array<ItemsProductCategories> | ItemsProductCategories, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20040>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsProductCategories(meta, arrayItemsProductCategoriesItemsProductCategories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new products item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducts> | ItemsProducts} [arrayItemsProductsItemsProducts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsProducts(meta?: string, arrayItemsProductsItemsProducts?: Array<ItemsProducts> | ItemsProducts, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20041>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsProducts(meta, arrayItemsProductsItemsProducts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new products_producers_addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProductsProducersAddresses> | ItemsProductsProducersAddresses} [arrayItemsProductsProducersAddressesItemsProductsProducersAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsProductsProducersAddresses(meta?: string, arrayItemsProductsProducersAddressesItemsProductsProducersAddresses?: Array<ItemsProductsProducersAddresses> | ItemsProductsProducersAddresses, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20042>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsProductsProducersAddresses(meta, arrayItemsProductsProducersAddressesItemsProductsProducersAddresses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new provinces item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProvinces> | ItemsProvinces} [arrayItemsProvincesItemsProvinces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsProvinces(meta?: string, arrayItemsProvincesItemsProvinces?: Array<ItemsProvinces> | ItemsProvinces, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20043>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsProvinces(meta, arrayItemsProvincesItemsProvinces, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new regions item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsRegions> | ItemsRegions} [arrayItemsRegionsItemsRegions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsRegions(meta?: string, arrayItemsRegionsItemsRegions?: Array<ItemsRegions> | ItemsRegions, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20044>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsRegions(meta, arrayItemsRegionsItemsRegions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new sources item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsSources> | ItemsSources} [arrayItemsSourcesItemsSources] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsSources(meta?: string, arrayItemsSourcesItemsSources?: Array<ItemsSources> | ItemsSources, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20045>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsSources(meta, arrayItemsSourcesItemsSources, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new units item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsUnits> | ItemsUnits} [arrayItemsUnitsItemsUnits] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsUnits(meta?: string, arrayItemsUnitsItemsUnits?: Array<ItemsUnits> | ItemsUnits, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20046>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsUnits(meta, arrayItemsUnitsItemsUnits, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsAddresses(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsAddresses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing certifications item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsCertifications(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsCertifications(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing import_types item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsImportTypes(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsImportTypes(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing imports item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsImports(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsImports(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing platform_addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsPlatformAddresses(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsPlatformAddresses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing platforms item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsPlatforms(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsPlatforms(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing producers item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsProducers(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsProducers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing producers_certifications item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsProducersCertifications(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsProducersCertifications(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing producers_platforms item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsProducersPlatforms(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsProducersPlatforms(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing product_categories item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsProductCategories(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsProductCategories(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing products item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsProducts(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsProducts(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing products_producers_addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsProductsProducersAddresses(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsProductsProducersAddresses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing provinces item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsProvinces(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsProvinces(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing regions item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsRegions(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsRegions(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing sources item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsSources(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsSources(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing units item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsUnits(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsUnits(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsAddresses(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the certifications items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsCertifications(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsCertifications(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the import_types items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsImportTypes(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20033>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsImportTypes(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the imports items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsImports(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20034>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsImports(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the platform_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsPlatformAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20035>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsPlatformAddresses(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the platforms items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsPlatforms(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20036>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsPlatforms(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the producers items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsProducers(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20037>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsProducers(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the producers_certifications items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsProducersCertifications(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20038>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsProducersCertifications(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the producers_platforms items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsProducersPlatforms(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20039>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsProducersPlatforms(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the product_categories items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsProductCategories(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20040>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsProductCategories(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the products items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsProducts(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20041>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsProducts(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the products_producers_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsProductsProducersAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20042>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsProductsProducersAddresses(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the provinces items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsProvinces(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20043>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsProvinces(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the regions items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsRegions(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20044>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsRegions(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the sources items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsSources(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20045>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsSources(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the units items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsUnits(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20046>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsUnits(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsAddresses(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsAddresses(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single certifications item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsCertifications(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsCertifications(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single import_types item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsImportTypes(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20033>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsImportTypes(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single imports item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsImports(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20034>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsImports(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single platform_addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsPlatformAddresses(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20035>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsPlatformAddresses(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single platforms item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsPlatforms(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20036>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsPlatforms(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single producers item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsProducers(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20037>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsProducers(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single producers_certifications item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsProducersCertifications(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20038>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsProducersCertifications(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single producers_platforms item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsProducersPlatforms(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20039>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsProducersPlatforms(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single product_categories item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsProductCategories(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20040>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsProductCategories(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single products item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsProducts(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20041>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsProducts(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single products_producers_addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsProductsProducersAddresses(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20042>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsProductsProducersAddresses(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single provinces item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsProvinces(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20043>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsProvinces(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single regions item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsRegions(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20044>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsRegions(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single sources item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsSources(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20045>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsSources(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single units item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsUnits(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20046>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsUnits(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the products_producers_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [latitude] Filter by latitude.
         * @param {number} [longitude] Filter by longitude.
         * @param {number} [km] Filter by km.
         * @param {'standard' | 'shipping'} [searchType] Define if search type is based on \&quot;shipping\&quot; or \&quot;proximity\&quot; address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchItemsProductsProducersAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, latitude?: number, longitude?: number, km?: number, searchType?: 'standard' | 'shipping', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20042>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchItemsProductsProducersAddresses(fields, limit, meta, offset, sort, filter, search, latitude, longitude, km, searchType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsAddresses} [itemsAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsAddresses(id: number, fields?: Array<string>, meta?: string, itemsAddresses?: ItemsAddresses, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsAddresses(id, fields, meta, itemsAddresses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing certifications item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsCertifications} [itemsCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsCertifications(id: number, fields?: Array<string>, meta?: string, itemsCertifications?: ItemsCertifications, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsCertifications(id, fields, meta, itemsCertifications, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing import_types item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsImportTypes} [itemsImportTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsImportTypes(id: number, fields?: Array<string>, meta?: string, itemsImportTypes?: ItemsImportTypes, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20033>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsImportTypes(id, fields, meta, itemsImportTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing imports item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsImports} [itemsImports] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsImports(id: number, fields?: Array<string>, meta?: string, itemsImports?: ItemsImports, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20034>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsImports(id, fields, meta, itemsImports, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing platform_addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsPlatformAddresses} [itemsPlatformAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsPlatformAddresses(id: number, fields?: Array<string>, meta?: string, itemsPlatformAddresses?: ItemsPlatformAddresses, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20035>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsPlatformAddresses(id, fields, meta, itemsPlatformAddresses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing platforms item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsPlatforms} [itemsPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsPlatforms(id: number, fields?: Array<string>, meta?: string, itemsPlatforms?: ItemsPlatforms, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20036>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsPlatforms(id, fields, meta, itemsPlatforms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing producers item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducers} [itemsProducers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsProducers(id: number, fields?: Array<string>, meta?: string, itemsProducers?: ItemsProducers, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20037>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsProducers(id, fields, meta, itemsProducers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing producers_certifications item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducersCertifications} [itemsProducersCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsProducersCertifications(id: number, fields?: Array<string>, meta?: string, itemsProducersCertifications?: ItemsProducersCertifications, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20038>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsProducersCertifications(id, fields, meta, itemsProducersCertifications, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing producers_platforms item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducersPlatforms} [itemsProducersPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsProducersPlatforms(id: number, fields?: Array<string>, meta?: string, itemsProducersPlatforms?: ItemsProducersPlatforms, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20039>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsProducersPlatforms(id, fields, meta, itemsProducersPlatforms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing product_categories item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProductCategories} [itemsProductCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsProductCategories(id: number, fields?: Array<string>, meta?: string, itemsProductCategories?: ItemsProductCategories, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20040>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsProductCategories(id, fields, meta, itemsProductCategories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing products item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducts} [itemsProducts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsProducts(id: number, fields?: Array<string>, meta?: string, itemsProducts?: ItemsProducts, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20041>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsProducts(id, fields, meta, itemsProducts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing products_producers_addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProductsProducersAddresses} [itemsProductsProducersAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsProductsProducersAddresses(id: number, fields?: Array<string>, meta?: string, itemsProductsProducersAddresses?: ItemsProductsProducersAddresses, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20042>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsProductsProducersAddresses(id, fields, meta, itemsProductsProducersAddresses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing provinces item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProvinces} [itemsProvinces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsProvinces(id: number, fields?: Array<string>, meta?: string, itemsProvinces?: ItemsProvinces, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20043>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsProvinces(id, fields, meta, itemsProvinces, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing regions item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsRegions} [itemsRegions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsRegions(id: number, fields?: Array<string>, meta?: string, itemsRegions?: ItemsRegions, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20044>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsRegions(id, fields, meta, itemsRegions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing sources item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsSources} [itemsSources] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsSources(id: number, fields?: Array<string>, meta?: string, itemsSources?: ItemsSources, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20045>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsSources(id, fields, meta, itemsSources, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing units item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsUnits} [itemsUnits] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsUnits(id: number, fields?: Array<string>, meta?: string, itemsUnits?: ItemsUnits, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20046>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsUnits(id, fields, meta, itemsUnits, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsApi - factory interface
 * @export
 */
export const ItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsApiFp(configuration)
    return {
        /**
         * Create a new addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsAddresses> | ItemsAddresses} [arrayItemsAddressesItemsAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsAddresses(meta?: string, arrayItemsAddressesItemsAddresses?: Array<ItemsAddresses> | ItemsAddresses, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.createItemsAddresses(meta, arrayItemsAddressesItemsAddresses, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new certifications item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsCertifications> | ItemsCertifications} [arrayItemsCertificationsItemsCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsCertifications(meta?: string, arrayItemsCertificationsItemsCertifications?: Array<ItemsCertifications> | ItemsCertifications, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.createItemsCertifications(meta, arrayItemsCertificationsItemsCertifications, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new import_types item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsImportTypes> | ItemsImportTypes} [arrayItemsImportTypesItemsImportTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsImportTypes(meta?: string, arrayItemsImportTypesItemsImportTypes?: Array<ItemsImportTypes> | ItemsImportTypes, options?: any): AxiosPromise<InlineResponse20033> {
            return localVarFp.createItemsImportTypes(meta, arrayItemsImportTypesItemsImportTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new imports item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsImports> | ItemsImports} [arrayItemsImportsItemsImports] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsImports(meta?: string, arrayItemsImportsItemsImports?: Array<ItemsImports> | ItemsImports, options?: any): AxiosPromise<InlineResponse20034> {
            return localVarFp.createItemsImports(meta, arrayItemsImportsItemsImports, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new platform_addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsPlatformAddresses> | ItemsPlatformAddresses} [arrayItemsPlatformAddressesItemsPlatformAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsPlatformAddresses(meta?: string, arrayItemsPlatformAddressesItemsPlatformAddresses?: Array<ItemsPlatformAddresses> | ItemsPlatformAddresses, options?: any): AxiosPromise<InlineResponse20035> {
            return localVarFp.createItemsPlatformAddresses(meta, arrayItemsPlatformAddressesItemsPlatformAddresses, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new platforms item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsPlatforms> | ItemsPlatforms} [arrayItemsPlatformsItemsPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsPlatforms(meta?: string, arrayItemsPlatformsItemsPlatforms?: Array<ItemsPlatforms> | ItemsPlatforms, options?: any): AxiosPromise<InlineResponse20036> {
            return localVarFp.createItemsPlatforms(meta, arrayItemsPlatformsItemsPlatforms, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new producers item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducers> | ItemsProducers} [arrayItemsProducersItemsProducers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProducers(meta?: string, arrayItemsProducersItemsProducers?: Array<ItemsProducers> | ItemsProducers, options?: any): AxiosPromise<InlineResponse20037> {
            return localVarFp.createItemsProducers(meta, arrayItemsProducersItemsProducers, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new producers_certifications item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducersCertifications> | ItemsProducersCertifications} [arrayItemsProducersCertificationsItemsProducersCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProducersCertifications(meta?: string, arrayItemsProducersCertificationsItemsProducersCertifications?: Array<ItemsProducersCertifications> | ItemsProducersCertifications, options?: any): AxiosPromise<InlineResponse20038> {
            return localVarFp.createItemsProducersCertifications(meta, arrayItemsProducersCertificationsItemsProducersCertifications, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new producers_platforms item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducersPlatforms> | ItemsProducersPlatforms} [arrayItemsProducersPlatformsItemsProducersPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProducersPlatforms(meta?: string, arrayItemsProducersPlatformsItemsProducersPlatforms?: Array<ItemsProducersPlatforms> | ItemsProducersPlatforms, options?: any): AxiosPromise<InlineResponse20039> {
            return localVarFp.createItemsProducersPlatforms(meta, arrayItemsProducersPlatformsItemsProducersPlatforms, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new product_categories item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProductCategories> | ItemsProductCategories} [arrayItemsProductCategoriesItemsProductCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProductCategories(meta?: string, arrayItemsProductCategoriesItemsProductCategories?: Array<ItemsProductCategories> | ItemsProductCategories, options?: any): AxiosPromise<InlineResponse20040> {
            return localVarFp.createItemsProductCategories(meta, arrayItemsProductCategoriesItemsProductCategories, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new products item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducts> | ItemsProducts} [arrayItemsProductsItemsProducts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProducts(meta?: string, arrayItemsProductsItemsProducts?: Array<ItemsProducts> | ItemsProducts, options?: any): AxiosPromise<InlineResponse20041> {
            return localVarFp.createItemsProducts(meta, arrayItemsProductsItemsProducts, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new products_producers_addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProductsProducersAddresses> | ItemsProductsProducersAddresses} [arrayItemsProductsProducersAddressesItemsProductsProducersAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProductsProducersAddresses(meta?: string, arrayItemsProductsProducersAddressesItemsProductsProducersAddresses?: Array<ItemsProductsProducersAddresses> | ItemsProductsProducersAddresses, options?: any): AxiosPromise<InlineResponse20042> {
            return localVarFp.createItemsProductsProducersAddresses(meta, arrayItemsProductsProducersAddressesItemsProductsProducersAddresses, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new provinces item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProvinces> | ItemsProvinces} [arrayItemsProvincesItemsProvinces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProvinces(meta?: string, arrayItemsProvincesItemsProvinces?: Array<ItemsProvinces> | ItemsProvinces, options?: any): AxiosPromise<InlineResponse20043> {
            return localVarFp.createItemsProvinces(meta, arrayItemsProvincesItemsProvinces, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new regions item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsRegions> | ItemsRegions} [arrayItemsRegionsItemsRegions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsRegions(meta?: string, arrayItemsRegionsItemsRegions?: Array<ItemsRegions> | ItemsRegions, options?: any): AxiosPromise<InlineResponse20044> {
            return localVarFp.createItemsRegions(meta, arrayItemsRegionsItemsRegions, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new sources item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsSources> | ItemsSources} [arrayItemsSourcesItemsSources] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsSources(meta?: string, arrayItemsSourcesItemsSources?: Array<ItemsSources> | ItemsSources, options?: any): AxiosPromise<InlineResponse20045> {
            return localVarFp.createItemsSources(meta, arrayItemsSourcesItemsSources, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new units item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsUnits> | ItemsUnits} [arrayItemsUnitsItemsUnits] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsUnits(meta?: string, arrayItemsUnitsItemsUnits?: Array<ItemsUnits> | ItemsUnits, options?: any): AxiosPromise<InlineResponse20046> {
            return localVarFp.createItemsUnits(meta, arrayItemsUnitsItemsUnits, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsAddresses(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsAddresses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing certifications item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsCertifications(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsCertifications(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing import_types item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsImportTypes(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsImportTypes(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing imports item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsImports(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsImports(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing platform_addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsPlatformAddresses(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsPlatformAddresses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing platforms item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsPlatforms(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsPlatforms(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing producers item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProducers(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsProducers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing producers_certifications item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProducersCertifications(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsProducersCertifications(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing producers_platforms item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProducersPlatforms(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsProducersPlatforms(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing product_categories item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProductCategories(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsProductCategories(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing products item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProducts(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsProducts(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing products_producers_addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProductsProducersAddresses(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsProductsProducersAddresses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing provinces item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProvinces(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsProvinces(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing regions item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsRegions(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsRegions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing sources item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsSources(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsSources(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing units item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsUnits(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsUnits(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.readItemsAddresses(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the certifications items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsCertifications(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.readItemsCertifications(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the import_types items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsImportTypes(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20033> {
            return localVarFp.readItemsImportTypes(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the imports items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsImports(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20034> {
            return localVarFp.readItemsImports(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the platform_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPlatformAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20035> {
            return localVarFp.readItemsPlatformAddresses(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the platforms items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPlatforms(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20036> {
            return localVarFp.readItemsPlatforms(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the producers items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProducers(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20037> {
            return localVarFp.readItemsProducers(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the producers_certifications items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProducersCertifications(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20038> {
            return localVarFp.readItemsProducersCertifications(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the producers_platforms items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProducersPlatforms(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20039> {
            return localVarFp.readItemsProducersPlatforms(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the product_categories items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProductCategories(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20040> {
            return localVarFp.readItemsProductCategories(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the products items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProducts(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20041> {
            return localVarFp.readItemsProducts(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the products_producers_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProductsProducersAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20042> {
            return localVarFp.readItemsProductsProducersAddresses(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the provinces items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProvinces(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20043> {
            return localVarFp.readItemsProvinces(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the regions items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsRegions(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20044> {
            return localVarFp.readItemsRegions(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the sources items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsSources(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20045> {
            return localVarFp.readItemsSources(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the units items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsUnits(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20046> {
            return localVarFp.readItemsUnits(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsAddresses(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.readSingleItemsAddresses(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single certifications item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsCertifications(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.readSingleItemsCertifications(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single import_types item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsImportTypes(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20033> {
            return localVarFp.readSingleItemsImportTypes(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single imports item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsImports(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20034> {
            return localVarFp.readSingleItemsImports(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single platform_addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPlatformAddresses(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20035> {
            return localVarFp.readSingleItemsPlatformAddresses(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single platforms item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPlatforms(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20036> {
            return localVarFp.readSingleItemsPlatforms(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single producers item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProducers(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20037> {
            return localVarFp.readSingleItemsProducers(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single producers_certifications item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProducersCertifications(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20038> {
            return localVarFp.readSingleItemsProducersCertifications(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single producers_platforms item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProducersPlatforms(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20039> {
            return localVarFp.readSingleItemsProducersPlatforms(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single product_categories item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProductCategories(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20040> {
            return localVarFp.readSingleItemsProductCategories(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single products item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProducts(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20041> {
            return localVarFp.readSingleItemsProducts(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single products_producers_addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProductsProducersAddresses(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20042> {
            return localVarFp.readSingleItemsProductsProducersAddresses(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single provinces item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProvinces(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20043> {
            return localVarFp.readSingleItemsProvinces(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single regions item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsRegions(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20044> {
            return localVarFp.readSingleItemsRegions(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single sources item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsSources(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20045> {
            return localVarFp.readSingleItemsSources(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single units item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsUnits(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20046> {
            return localVarFp.readSingleItemsUnits(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * List the products_producers_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [latitude] Filter by latitude.
         * @param {number} [longitude] Filter by longitude.
         * @param {number} [km] Filter by km.
         * @param {'standard' | 'shipping'} [searchType] Define if search type is based on \&quot;shipping\&quot; or \&quot;proximity\&quot; address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchItemsProductsProducersAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, latitude?: number, longitude?: number, km?: number, searchType?: 'standard' | 'shipping', options?: any): AxiosPromise<InlineResponse20042> {
            return localVarFp.searchItemsProductsProducersAddresses(fields, limit, meta, offset, sort, filter, search, latitude, longitude, km, searchType, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsAddresses} [itemsAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsAddresses(id: number, fields?: Array<string>, meta?: string, itemsAddresses?: ItemsAddresses, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.updateSingleItemsAddresses(id, fields, meta, itemsAddresses, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing certifications item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsCertifications} [itemsCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsCertifications(id: number, fields?: Array<string>, meta?: string, itemsCertifications?: ItemsCertifications, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.updateSingleItemsCertifications(id, fields, meta, itemsCertifications, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing import_types item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsImportTypes} [itemsImportTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsImportTypes(id: number, fields?: Array<string>, meta?: string, itemsImportTypes?: ItemsImportTypes, options?: any): AxiosPromise<InlineResponse20033> {
            return localVarFp.updateSingleItemsImportTypes(id, fields, meta, itemsImportTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing imports item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsImports} [itemsImports] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsImports(id: number, fields?: Array<string>, meta?: string, itemsImports?: ItemsImports, options?: any): AxiosPromise<InlineResponse20034> {
            return localVarFp.updateSingleItemsImports(id, fields, meta, itemsImports, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing platform_addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsPlatformAddresses} [itemsPlatformAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsPlatformAddresses(id: number, fields?: Array<string>, meta?: string, itemsPlatformAddresses?: ItemsPlatformAddresses, options?: any): AxiosPromise<InlineResponse20035> {
            return localVarFp.updateSingleItemsPlatformAddresses(id, fields, meta, itemsPlatformAddresses, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing platforms item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsPlatforms} [itemsPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsPlatforms(id: number, fields?: Array<string>, meta?: string, itemsPlatforms?: ItemsPlatforms, options?: any): AxiosPromise<InlineResponse20036> {
            return localVarFp.updateSingleItemsPlatforms(id, fields, meta, itemsPlatforms, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing producers item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducers} [itemsProducers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProducers(id: number, fields?: Array<string>, meta?: string, itemsProducers?: ItemsProducers, options?: any): AxiosPromise<InlineResponse20037> {
            return localVarFp.updateSingleItemsProducers(id, fields, meta, itemsProducers, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing producers_certifications item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducersCertifications} [itemsProducersCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProducersCertifications(id: number, fields?: Array<string>, meta?: string, itemsProducersCertifications?: ItemsProducersCertifications, options?: any): AxiosPromise<InlineResponse20038> {
            return localVarFp.updateSingleItemsProducersCertifications(id, fields, meta, itemsProducersCertifications, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing producers_platforms item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducersPlatforms} [itemsProducersPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProducersPlatforms(id: number, fields?: Array<string>, meta?: string, itemsProducersPlatforms?: ItemsProducersPlatforms, options?: any): AxiosPromise<InlineResponse20039> {
            return localVarFp.updateSingleItemsProducersPlatforms(id, fields, meta, itemsProducersPlatforms, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing product_categories item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProductCategories} [itemsProductCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProductCategories(id: number, fields?: Array<string>, meta?: string, itemsProductCategories?: ItemsProductCategories, options?: any): AxiosPromise<InlineResponse20040> {
            return localVarFp.updateSingleItemsProductCategories(id, fields, meta, itemsProductCategories, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing products item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducts} [itemsProducts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProducts(id: number, fields?: Array<string>, meta?: string, itemsProducts?: ItemsProducts, options?: any): AxiosPromise<InlineResponse20041> {
            return localVarFp.updateSingleItemsProducts(id, fields, meta, itemsProducts, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing products_producers_addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProductsProducersAddresses} [itemsProductsProducersAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProductsProducersAddresses(id: number, fields?: Array<string>, meta?: string, itemsProductsProducersAddresses?: ItemsProductsProducersAddresses, options?: any): AxiosPromise<InlineResponse20042> {
            return localVarFp.updateSingleItemsProductsProducersAddresses(id, fields, meta, itemsProductsProducersAddresses, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing provinces item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProvinces} [itemsProvinces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProvinces(id: number, fields?: Array<string>, meta?: string, itemsProvinces?: ItemsProvinces, options?: any): AxiosPromise<InlineResponse20043> {
            return localVarFp.updateSingleItemsProvinces(id, fields, meta, itemsProvinces, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing regions item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsRegions} [itemsRegions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsRegions(id: number, fields?: Array<string>, meta?: string, itemsRegions?: ItemsRegions, options?: any): AxiosPromise<InlineResponse20044> {
            return localVarFp.updateSingleItemsRegions(id, fields, meta, itemsRegions, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing sources item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsSources} [itemsSources] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsSources(id: number, fields?: Array<string>, meta?: string, itemsSources?: ItemsSources, options?: any): AxiosPromise<InlineResponse20045> {
            return localVarFp.updateSingleItemsSources(id, fields, meta, itemsSources, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing units item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsUnits} [itemsUnits] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsUnits(id: number, fields?: Array<string>, meta?: string, itemsUnits?: ItemsUnits, options?: any): AxiosPromise<InlineResponse20046> {
            return localVarFp.updateSingleItemsUnits(id, fields, meta, itemsUnits, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsApi - object-oriented interface
 * @export
 * @class ItemsApi
 * @extends {BaseAPI}
 */
export class ItemsApi extends BaseAPI {
    /**
     * Create a new addresses item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsAddresses> | ItemsAddresses} [arrayItemsAddressesItemsAddresses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsAddresses(meta?: string, arrayItemsAddressesItemsAddresses?: Array<ItemsAddresses> | ItemsAddresses, options?: any) {
        return ItemsApiFp(this.configuration).createItemsAddresses(meta, arrayItemsAddressesItemsAddresses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new certifications item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsCertifications> | ItemsCertifications} [arrayItemsCertificationsItemsCertifications] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsCertifications(meta?: string, arrayItemsCertificationsItemsCertifications?: Array<ItemsCertifications> | ItemsCertifications, options?: any) {
        return ItemsApiFp(this.configuration).createItemsCertifications(meta, arrayItemsCertificationsItemsCertifications, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new import_types item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsImportTypes> | ItemsImportTypes} [arrayItemsImportTypesItemsImportTypes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsImportTypes(meta?: string, arrayItemsImportTypesItemsImportTypes?: Array<ItemsImportTypes> | ItemsImportTypes, options?: any) {
        return ItemsApiFp(this.configuration).createItemsImportTypes(meta, arrayItemsImportTypesItemsImportTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new imports item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsImports> | ItemsImports} [arrayItemsImportsItemsImports] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsImports(meta?: string, arrayItemsImportsItemsImports?: Array<ItemsImports> | ItemsImports, options?: any) {
        return ItemsApiFp(this.configuration).createItemsImports(meta, arrayItemsImportsItemsImports, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new platform_addresses item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsPlatformAddresses> | ItemsPlatformAddresses} [arrayItemsPlatformAddressesItemsPlatformAddresses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsPlatformAddresses(meta?: string, arrayItemsPlatformAddressesItemsPlatformAddresses?: Array<ItemsPlatformAddresses> | ItemsPlatformAddresses, options?: any) {
        return ItemsApiFp(this.configuration).createItemsPlatformAddresses(meta, arrayItemsPlatformAddressesItemsPlatformAddresses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new platforms item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsPlatforms> | ItemsPlatforms} [arrayItemsPlatformsItemsPlatforms] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsPlatforms(meta?: string, arrayItemsPlatformsItemsPlatforms?: Array<ItemsPlatforms> | ItemsPlatforms, options?: any) {
        return ItemsApiFp(this.configuration).createItemsPlatforms(meta, arrayItemsPlatformsItemsPlatforms, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new producers item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsProducers> | ItemsProducers} [arrayItemsProducersItemsProducers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsProducers(meta?: string, arrayItemsProducersItemsProducers?: Array<ItemsProducers> | ItemsProducers, options?: any) {
        return ItemsApiFp(this.configuration).createItemsProducers(meta, arrayItemsProducersItemsProducers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new producers_certifications item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsProducersCertifications> | ItemsProducersCertifications} [arrayItemsProducersCertificationsItemsProducersCertifications] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsProducersCertifications(meta?: string, arrayItemsProducersCertificationsItemsProducersCertifications?: Array<ItemsProducersCertifications> | ItemsProducersCertifications, options?: any) {
        return ItemsApiFp(this.configuration).createItemsProducersCertifications(meta, arrayItemsProducersCertificationsItemsProducersCertifications, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new producers_platforms item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsProducersPlatforms> | ItemsProducersPlatforms} [arrayItemsProducersPlatformsItemsProducersPlatforms] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsProducersPlatforms(meta?: string, arrayItemsProducersPlatformsItemsProducersPlatforms?: Array<ItemsProducersPlatforms> | ItemsProducersPlatforms, options?: any) {
        return ItemsApiFp(this.configuration).createItemsProducersPlatforms(meta, arrayItemsProducersPlatformsItemsProducersPlatforms, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new product_categories item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsProductCategories> | ItemsProductCategories} [arrayItemsProductCategoriesItemsProductCategories] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsProductCategories(meta?: string, arrayItemsProductCategoriesItemsProductCategories?: Array<ItemsProductCategories> | ItemsProductCategories, options?: any) {
        return ItemsApiFp(this.configuration).createItemsProductCategories(meta, arrayItemsProductCategoriesItemsProductCategories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new products item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsProducts> | ItemsProducts} [arrayItemsProductsItemsProducts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsProducts(meta?: string, arrayItemsProductsItemsProducts?: Array<ItemsProducts> | ItemsProducts, options?: any) {
        return ItemsApiFp(this.configuration).createItemsProducts(meta, arrayItemsProductsItemsProducts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new products_producers_addresses item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsProductsProducersAddresses> | ItemsProductsProducersAddresses} [arrayItemsProductsProducersAddressesItemsProductsProducersAddresses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsProductsProducersAddresses(meta?: string, arrayItemsProductsProducersAddressesItemsProductsProducersAddresses?: Array<ItemsProductsProducersAddresses> | ItemsProductsProducersAddresses, options?: any) {
        return ItemsApiFp(this.configuration).createItemsProductsProducersAddresses(meta, arrayItemsProductsProducersAddressesItemsProductsProducersAddresses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new provinces item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsProvinces> | ItemsProvinces} [arrayItemsProvincesItemsProvinces] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsProvinces(meta?: string, arrayItemsProvincesItemsProvinces?: Array<ItemsProvinces> | ItemsProvinces, options?: any) {
        return ItemsApiFp(this.configuration).createItemsProvinces(meta, arrayItemsProvincesItemsProvinces, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new regions item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsRegions> | ItemsRegions} [arrayItemsRegionsItemsRegions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsRegions(meta?: string, arrayItemsRegionsItemsRegions?: Array<ItemsRegions> | ItemsRegions, options?: any) {
        return ItemsApiFp(this.configuration).createItemsRegions(meta, arrayItemsRegionsItemsRegions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new sources item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsSources> | ItemsSources} [arrayItemsSourcesItemsSources] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsSources(meta?: string, arrayItemsSourcesItemsSources?: Array<ItemsSources> | ItemsSources, options?: any) {
        return ItemsApiFp(this.configuration).createItemsSources(meta, arrayItemsSourcesItemsSources, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new units item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsUnits> | ItemsUnits} [arrayItemsUnitsItemsUnits] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsUnits(meta?: string, arrayItemsUnitsItemsUnits?: Array<ItemsUnits> | ItemsUnits, options?: any) {
        return ItemsApiFp(this.configuration).createItemsUnits(meta, arrayItemsUnitsItemsUnits, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing addresses item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSingleItemsAddresses(id: number, options?: any) {
        return ItemsApiFp(this.configuration).deleteSingleItemsAddresses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing certifications item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSingleItemsCertifications(id: number, options?: any) {
        return ItemsApiFp(this.configuration).deleteSingleItemsCertifications(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing import_types item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSingleItemsImportTypes(id: number, options?: any) {
        return ItemsApiFp(this.configuration).deleteSingleItemsImportTypes(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing imports item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSingleItemsImports(id: number, options?: any) {
        return ItemsApiFp(this.configuration).deleteSingleItemsImports(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing platform_addresses item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSingleItemsPlatformAddresses(id: number, options?: any) {
        return ItemsApiFp(this.configuration).deleteSingleItemsPlatformAddresses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing platforms item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSingleItemsPlatforms(id: number, options?: any) {
        return ItemsApiFp(this.configuration).deleteSingleItemsPlatforms(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing producers item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSingleItemsProducers(id: number, options?: any) {
        return ItemsApiFp(this.configuration).deleteSingleItemsProducers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing producers_certifications item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSingleItemsProducersCertifications(id: number, options?: any) {
        return ItemsApiFp(this.configuration).deleteSingleItemsProducersCertifications(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing producers_platforms item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSingleItemsProducersPlatforms(id: number, options?: any) {
        return ItemsApiFp(this.configuration).deleteSingleItemsProducersPlatforms(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing product_categories item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSingleItemsProductCategories(id: number, options?: any) {
        return ItemsApiFp(this.configuration).deleteSingleItemsProductCategories(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing products item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSingleItemsProducts(id: number, options?: any) {
        return ItemsApiFp(this.configuration).deleteSingleItemsProducts(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing products_producers_addresses item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSingleItemsProductsProducersAddresses(id: number, options?: any) {
        return ItemsApiFp(this.configuration).deleteSingleItemsProductsProducersAddresses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing provinces item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSingleItemsProvinces(id: number, options?: any) {
        return ItemsApiFp(this.configuration).deleteSingleItemsProvinces(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing regions item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSingleItemsRegions(id: number, options?: any) {
        return ItemsApiFp(this.configuration).deleteSingleItemsRegions(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing sources item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSingleItemsSources(id: number, options?: any) {
        return ItemsApiFp(this.configuration).deleteSingleItemsSources(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing units item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSingleItemsUnits(id: number, options?: any) {
        return ItemsApiFp(this.configuration).deleteSingleItemsUnits(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the addresses items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsApiFp(this.configuration).readItemsAddresses(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the certifications items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsCertifications(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsApiFp(this.configuration).readItemsCertifications(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the import_types items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsImportTypes(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsApiFp(this.configuration).readItemsImportTypes(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the imports items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsImports(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsApiFp(this.configuration).readItemsImports(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the platform_addresses items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsPlatformAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsApiFp(this.configuration).readItemsPlatformAddresses(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the platforms items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsPlatforms(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsApiFp(this.configuration).readItemsPlatforms(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the producers items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsProducers(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsApiFp(this.configuration).readItemsProducers(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the producers_certifications items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsProducersCertifications(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsApiFp(this.configuration).readItemsProducersCertifications(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the producers_platforms items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsProducersPlatforms(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsApiFp(this.configuration).readItemsProducersPlatforms(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the product_categories items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsProductCategories(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsApiFp(this.configuration).readItemsProductCategories(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the products items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsProducts(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsApiFp(this.configuration).readItemsProducts(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the products_producers_addresses items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsProductsProducersAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsApiFp(this.configuration).readItemsProductsProducersAddresses(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the provinces items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsProvinces(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsApiFp(this.configuration).readItemsProvinces(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the regions items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsRegions(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsApiFp(this.configuration).readItemsRegions(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the sources items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsSources(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsApiFp(this.configuration).readItemsSources(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the units items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsUnits(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsApiFp(this.configuration).readItemsUnits(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single addresses item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSingleItemsAddresses(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsApiFp(this.configuration).readSingleItemsAddresses(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single certifications item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSingleItemsCertifications(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsApiFp(this.configuration).readSingleItemsCertifications(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single import_types item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSingleItemsImportTypes(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsApiFp(this.configuration).readSingleItemsImportTypes(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single imports item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSingleItemsImports(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsApiFp(this.configuration).readSingleItemsImports(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single platform_addresses item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSingleItemsPlatformAddresses(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsApiFp(this.configuration).readSingleItemsPlatformAddresses(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single platforms item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSingleItemsPlatforms(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsApiFp(this.configuration).readSingleItemsPlatforms(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single producers item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSingleItemsProducers(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsApiFp(this.configuration).readSingleItemsProducers(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single producers_certifications item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSingleItemsProducersCertifications(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsApiFp(this.configuration).readSingleItemsProducersCertifications(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single producers_platforms item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSingleItemsProducersPlatforms(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsApiFp(this.configuration).readSingleItemsProducersPlatforms(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single product_categories item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSingleItemsProductCategories(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsApiFp(this.configuration).readSingleItemsProductCategories(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single products item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSingleItemsProducts(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsApiFp(this.configuration).readSingleItemsProducts(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single products_producers_addresses item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSingleItemsProductsProducersAddresses(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsApiFp(this.configuration).readSingleItemsProductsProducersAddresses(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single provinces item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSingleItemsProvinces(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsApiFp(this.configuration).readSingleItemsProvinces(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single regions item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSingleItemsRegions(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsApiFp(this.configuration).readSingleItemsRegions(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single sources item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSingleItemsSources(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsApiFp(this.configuration).readSingleItemsSources(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single units item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSingleItemsUnits(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsApiFp(this.configuration).readSingleItemsUnits(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the products_producers_addresses items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {number} [latitude] Filter by latitude.
     * @param {number} [longitude] Filter by longitude.
     * @param {number} [km] Filter by km.
     * @param {'standard' | 'shipping'} [searchType] Define if search type is based on \&quot;shipping\&quot; or \&quot;proximity\&quot; address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public searchItemsProductsProducersAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, latitude?: number, longitude?: number, km?: number, searchType?: 'standard' | 'shipping', options?: any) {
        return ItemsApiFp(this.configuration).searchItemsProductsProducersAddresses(fields, limit, meta, offset, sort, filter, search, latitude, longitude, km, searchType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing addresses item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsAddresses} [itemsAddresses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateSingleItemsAddresses(id: number, fields?: Array<string>, meta?: string, itemsAddresses?: ItemsAddresses, options?: any) {
        return ItemsApiFp(this.configuration).updateSingleItemsAddresses(id, fields, meta, itemsAddresses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing certifications item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsCertifications} [itemsCertifications] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateSingleItemsCertifications(id: number, fields?: Array<string>, meta?: string, itemsCertifications?: ItemsCertifications, options?: any) {
        return ItemsApiFp(this.configuration).updateSingleItemsCertifications(id, fields, meta, itemsCertifications, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing import_types item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsImportTypes} [itemsImportTypes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateSingleItemsImportTypes(id: number, fields?: Array<string>, meta?: string, itemsImportTypes?: ItemsImportTypes, options?: any) {
        return ItemsApiFp(this.configuration).updateSingleItemsImportTypes(id, fields, meta, itemsImportTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing imports item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsImports} [itemsImports] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateSingleItemsImports(id: number, fields?: Array<string>, meta?: string, itemsImports?: ItemsImports, options?: any) {
        return ItemsApiFp(this.configuration).updateSingleItemsImports(id, fields, meta, itemsImports, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing platform_addresses item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsPlatformAddresses} [itemsPlatformAddresses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateSingleItemsPlatformAddresses(id: number, fields?: Array<string>, meta?: string, itemsPlatformAddresses?: ItemsPlatformAddresses, options?: any) {
        return ItemsApiFp(this.configuration).updateSingleItemsPlatformAddresses(id, fields, meta, itemsPlatformAddresses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing platforms item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsPlatforms} [itemsPlatforms] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateSingleItemsPlatforms(id: number, fields?: Array<string>, meta?: string, itemsPlatforms?: ItemsPlatforms, options?: any) {
        return ItemsApiFp(this.configuration).updateSingleItemsPlatforms(id, fields, meta, itemsPlatforms, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing producers item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsProducers} [itemsProducers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateSingleItemsProducers(id: number, fields?: Array<string>, meta?: string, itemsProducers?: ItemsProducers, options?: any) {
        return ItemsApiFp(this.configuration).updateSingleItemsProducers(id, fields, meta, itemsProducers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing producers_certifications item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsProducersCertifications} [itemsProducersCertifications] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateSingleItemsProducersCertifications(id: number, fields?: Array<string>, meta?: string, itemsProducersCertifications?: ItemsProducersCertifications, options?: any) {
        return ItemsApiFp(this.configuration).updateSingleItemsProducersCertifications(id, fields, meta, itemsProducersCertifications, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing producers_platforms item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsProducersPlatforms} [itemsProducersPlatforms] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateSingleItemsProducersPlatforms(id: number, fields?: Array<string>, meta?: string, itemsProducersPlatforms?: ItemsProducersPlatforms, options?: any) {
        return ItemsApiFp(this.configuration).updateSingleItemsProducersPlatforms(id, fields, meta, itemsProducersPlatforms, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing product_categories item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsProductCategories} [itemsProductCategories] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateSingleItemsProductCategories(id: number, fields?: Array<string>, meta?: string, itemsProductCategories?: ItemsProductCategories, options?: any) {
        return ItemsApiFp(this.configuration).updateSingleItemsProductCategories(id, fields, meta, itemsProductCategories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing products item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsProducts} [itemsProducts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateSingleItemsProducts(id: number, fields?: Array<string>, meta?: string, itemsProducts?: ItemsProducts, options?: any) {
        return ItemsApiFp(this.configuration).updateSingleItemsProducts(id, fields, meta, itemsProducts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing products_producers_addresses item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsProductsProducersAddresses} [itemsProductsProducersAddresses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateSingleItemsProductsProducersAddresses(id: number, fields?: Array<string>, meta?: string, itemsProductsProducersAddresses?: ItemsProductsProducersAddresses, options?: any) {
        return ItemsApiFp(this.configuration).updateSingleItemsProductsProducersAddresses(id, fields, meta, itemsProductsProducersAddresses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing provinces item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsProvinces} [itemsProvinces] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateSingleItemsProvinces(id: number, fields?: Array<string>, meta?: string, itemsProvinces?: ItemsProvinces, options?: any) {
        return ItemsApiFp(this.configuration).updateSingleItemsProvinces(id, fields, meta, itemsProvinces, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing regions item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsRegions} [itemsRegions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateSingleItemsRegions(id: number, fields?: Array<string>, meta?: string, itemsRegions?: ItemsRegions, options?: any) {
        return ItemsApiFp(this.configuration).updateSingleItemsRegions(id, fields, meta, itemsRegions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing sources item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsSources} [itemsSources] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateSingleItemsSources(id: number, fields?: Array<string>, meta?: string, itemsSources?: ItemsSources, options?: any) {
        return ItemsApiFp(this.configuration).updateSingleItemsSources(id, fields, meta, itemsSources, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing units item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsUnits} [itemsUnits] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateSingleItemsUnits(id: number, fields?: Array<string>, meta?: string, itemsUnits?: ItemsUnits, options?: any) {
        return ItemsApiFp(this.configuration).updateSingleItemsUnits(id, fields, meta, itemsUnits, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsAddressesApi - axios parameter creator
 * @export
 */
export const ItemsAddressesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsAddresses> | ItemsAddresses} [arrayItemsAddressesItemsAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsAddresses: async (meta?: string, arrayItemsAddressesItemsAddresses?: Array<ItemsAddresses> | ItemsAddresses, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsAddressesItemsAddresses, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsAddresses: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsAddresses', 'id', id)
            const localVarPath = `/items/addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsAddresses: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsAddresses: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsAddresses', 'id', id)
            const localVarPath = `/items/addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsAddresses} [itemsAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsAddresses: async (id: number, fields?: Array<string>, meta?: string, itemsAddresses?: ItemsAddresses, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsAddresses', 'id', id)
            const localVarPath = `/items/addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsAddresses, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsAddressesApi - functional programming interface
 * @export
 */
export const ItemsAddressesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsAddressesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsAddresses> | ItemsAddresses} [arrayItemsAddressesItemsAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsAddresses(meta?: string, arrayItemsAddressesItemsAddresses?: Array<ItemsAddresses> | ItemsAddresses, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsAddresses(meta, arrayItemsAddressesItemsAddresses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsAddresses(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsAddresses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsAddresses(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsAddresses(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsAddresses(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsAddresses} [itemsAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsAddresses(id: number, fields?: Array<string>, meta?: string, itemsAddresses?: ItemsAddresses, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsAddresses(id, fields, meta, itemsAddresses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsAddressesApi - factory interface
 * @export
 */
export const ItemsAddressesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsAddressesApiFp(configuration)
    return {
        /**
         * Create a new addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsAddresses> | ItemsAddresses} [arrayItemsAddressesItemsAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsAddresses(meta?: string, arrayItemsAddressesItemsAddresses?: Array<ItemsAddresses> | ItemsAddresses, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.createItemsAddresses(meta, arrayItemsAddressesItemsAddresses, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsAddresses(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsAddresses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.readItemsAddresses(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsAddresses(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.readSingleItemsAddresses(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsAddresses} [itemsAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsAddresses(id: number, fields?: Array<string>, meta?: string, itemsAddresses?: ItemsAddresses, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.updateSingleItemsAddresses(id, fields, meta, itemsAddresses, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsAddressesApi - object-oriented interface
 * @export
 * @class ItemsAddressesApi
 * @extends {BaseAPI}
 */
export class ItemsAddressesApi extends BaseAPI {
    /**
     * Create a new addresses item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsAddresses> | ItemsAddresses} [arrayItemsAddressesItemsAddresses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsAddressesApi
     */
    public createItemsAddresses(meta?: string, arrayItemsAddressesItemsAddresses?: Array<ItemsAddresses> | ItemsAddresses, options?: any) {
        return ItemsAddressesApiFp(this.configuration).createItemsAddresses(meta, arrayItemsAddressesItemsAddresses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing addresses item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsAddressesApi
     */
    public deleteSingleItemsAddresses(id: number, options?: any) {
        return ItemsAddressesApiFp(this.configuration).deleteSingleItemsAddresses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the addresses items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsAddressesApi
     */
    public readItemsAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsAddressesApiFp(this.configuration).readItemsAddresses(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single addresses item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsAddressesApi
     */
    public readSingleItemsAddresses(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsAddressesApiFp(this.configuration).readSingleItemsAddresses(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing addresses item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsAddresses} [itemsAddresses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsAddressesApi
     */
    public updateSingleItemsAddresses(id: number, fields?: Array<string>, meta?: string, itemsAddresses?: ItemsAddresses, options?: any) {
        return ItemsAddressesApiFp(this.configuration).updateSingleItemsAddresses(id, fields, meta, itemsAddresses, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsCertificationsApi - axios parameter creator
 * @export
 */
export const ItemsCertificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new certifications item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsCertifications> | ItemsCertifications} [arrayItemsCertificationsItemsCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsCertifications: async (meta?: string, arrayItemsCertificationsItemsCertifications?: Array<ItemsCertifications> | ItemsCertifications, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/certifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsCertificationsItemsCertifications, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing certifications item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsCertifications: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsCertifications', 'id', id)
            const localVarPath = `/items/certifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the certifications items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsCertifications: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/certifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single certifications item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsCertifications: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsCertifications', 'id', id)
            const localVarPath = `/items/certifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing certifications item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsCertifications} [itemsCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsCertifications: async (id: number, fields?: Array<string>, meta?: string, itemsCertifications?: ItemsCertifications, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsCertifications', 'id', id)
            const localVarPath = `/items/certifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsCertifications, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsCertificationsApi - functional programming interface
 * @export
 */
export const ItemsCertificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsCertificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new certifications item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsCertifications> | ItemsCertifications} [arrayItemsCertificationsItemsCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsCertifications(meta?: string, arrayItemsCertificationsItemsCertifications?: Array<ItemsCertifications> | ItemsCertifications, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsCertifications(meta, arrayItemsCertificationsItemsCertifications, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing certifications item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsCertifications(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsCertifications(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the certifications items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsCertifications(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsCertifications(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single certifications item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsCertifications(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsCertifications(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing certifications item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsCertifications} [itemsCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsCertifications(id: number, fields?: Array<string>, meta?: string, itemsCertifications?: ItemsCertifications, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsCertifications(id, fields, meta, itemsCertifications, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsCertificationsApi - factory interface
 * @export
 */
export const ItemsCertificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsCertificationsApiFp(configuration)
    return {
        /**
         * Create a new certifications item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsCertifications> | ItemsCertifications} [arrayItemsCertificationsItemsCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsCertifications(meta?: string, arrayItemsCertificationsItemsCertifications?: Array<ItemsCertifications> | ItemsCertifications, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.createItemsCertifications(meta, arrayItemsCertificationsItemsCertifications, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing certifications item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsCertifications(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsCertifications(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the certifications items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsCertifications(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.readItemsCertifications(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single certifications item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsCertifications(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.readSingleItemsCertifications(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing certifications item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsCertifications} [itemsCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsCertifications(id: number, fields?: Array<string>, meta?: string, itemsCertifications?: ItemsCertifications, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.updateSingleItemsCertifications(id, fields, meta, itemsCertifications, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsCertificationsApi - object-oriented interface
 * @export
 * @class ItemsCertificationsApi
 * @extends {BaseAPI}
 */
export class ItemsCertificationsApi extends BaseAPI {
    /**
     * Create a new certifications item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsCertifications> | ItemsCertifications} [arrayItemsCertificationsItemsCertifications] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsCertificationsApi
     */
    public createItemsCertifications(meta?: string, arrayItemsCertificationsItemsCertifications?: Array<ItemsCertifications> | ItemsCertifications, options?: any) {
        return ItemsCertificationsApiFp(this.configuration).createItemsCertifications(meta, arrayItemsCertificationsItemsCertifications, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing certifications item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsCertificationsApi
     */
    public deleteSingleItemsCertifications(id: number, options?: any) {
        return ItemsCertificationsApiFp(this.configuration).deleteSingleItemsCertifications(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the certifications items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsCertificationsApi
     */
    public readItemsCertifications(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsCertificationsApiFp(this.configuration).readItemsCertifications(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single certifications item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsCertificationsApi
     */
    public readSingleItemsCertifications(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsCertificationsApiFp(this.configuration).readSingleItemsCertifications(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing certifications item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsCertifications} [itemsCertifications] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsCertificationsApi
     */
    public updateSingleItemsCertifications(id: number, fields?: Array<string>, meta?: string, itemsCertifications?: ItemsCertifications, options?: any) {
        return ItemsCertificationsApiFp(this.configuration).updateSingleItemsCertifications(id, fields, meta, itemsCertifications, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsImportTypesApi - axios parameter creator
 * @export
 */
export const ItemsImportTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new import_types item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsImportTypes> | ItemsImportTypes} [arrayItemsImportTypesItemsImportTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsImportTypes: async (meta?: string, arrayItemsImportTypesItemsImportTypes?: Array<ItemsImportTypes> | ItemsImportTypes, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/import_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsImportTypesItemsImportTypes, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing import_types item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsImportTypes: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsImportTypes', 'id', id)
            const localVarPath = `/items/import_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the import_types items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsImportTypes: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/import_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single import_types item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsImportTypes: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsImportTypes', 'id', id)
            const localVarPath = `/items/import_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing import_types item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsImportTypes} [itemsImportTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsImportTypes: async (id: number, fields?: Array<string>, meta?: string, itemsImportTypes?: ItemsImportTypes, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsImportTypes', 'id', id)
            const localVarPath = `/items/import_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsImportTypes, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsImportTypesApi - functional programming interface
 * @export
 */
export const ItemsImportTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsImportTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new import_types item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsImportTypes> | ItemsImportTypes} [arrayItemsImportTypesItemsImportTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsImportTypes(meta?: string, arrayItemsImportTypesItemsImportTypes?: Array<ItemsImportTypes> | ItemsImportTypes, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20033>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsImportTypes(meta, arrayItemsImportTypesItemsImportTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing import_types item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsImportTypes(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsImportTypes(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the import_types items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsImportTypes(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20033>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsImportTypes(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single import_types item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsImportTypes(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20033>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsImportTypes(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing import_types item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsImportTypes} [itemsImportTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsImportTypes(id: number, fields?: Array<string>, meta?: string, itemsImportTypes?: ItemsImportTypes, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20033>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsImportTypes(id, fields, meta, itemsImportTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsImportTypesApi - factory interface
 * @export
 */
export const ItemsImportTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsImportTypesApiFp(configuration)
    return {
        /**
         * Create a new import_types item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsImportTypes> | ItemsImportTypes} [arrayItemsImportTypesItemsImportTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsImportTypes(meta?: string, arrayItemsImportTypesItemsImportTypes?: Array<ItemsImportTypes> | ItemsImportTypes, options?: any): AxiosPromise<InlineResponse20033> {
            return localVarFp.createItemsImportTypes(meta, arrayItemsImportTypesItemsImportTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing import_types item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsImportTypes(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsImportTypes(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the import_types items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsImportTypes(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20033> {
            return localVarFp.readItemsImportTypes(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single import_types item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsImportTypes(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20033> {
            return localVarFp.readSingleItemsImportTypes(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing import_types item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsImportTypes} [itemsImportTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsImportTypes(id: number, fields?: Array<string>, meta?: string, itemsImportTypes?: ItemsImportTypes, options?: any): AxiosPromise<InlineResponse20033> {
            return localVarFp.updateSingleItemsImportTypes(id, fields, meta, itemsImportTypes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsImportTypesApi - object-oriented interface
 * @export
 * @class ItemsImportTypesApi
 * @extends {BaseAPI}
 */
export class ItemsImportTypesApi extends BaseAPI {
    /**
     * Create a new import_types item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsImportTypes> | ItemsImportTypes} [arrayItemsImportTypesItemsImportTypes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsImportTypesApi
     */
    public createItemsImportTypes(meta?: string, arrayItemsImportTypesItemsImportTypes?: Array<ItemsImportTypes> | ItemsImportTypes, options?: any) {
        return ItemsImportTypesApiFp(this.configuration).createItemsImportTypes(meta, arrayItemsImportTypesItemsImportTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing import_types item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsImportTypesApi
     */
    public deleteSingleItemsImportTypes(id: number, options?: any) {
        return ItemsImportTypesApiFp(this.configuration).deleteSingleItemsImportTypes(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the import_types items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsImportTypesApi
     */
    public readItemsImportTypes(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsImportTypesApiFp(this.configuration).readItemsImportTypes(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single import_types item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsImportTypesApi
     */
    public readSingleItemsImportTypes(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsImportTypesApiFp(this.configuration).readSingleItemsImportTypes(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing import_types item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsImportTypes} [itemsImportTypes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsImportTypesApi
     */
    public updateSingleItemsImportTypes(id: number, fields?: Array<string>, meta?: string, itemsImportTypes?: ItemsImportTypes, options?: any) {
        return ItemsImportTypesApiFp(this.configuration).updateSingleItemsImportTypes(id, fields, meta, itemsImportTypes, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsImportsApi - axios parameter creator
 * @export
 */
export const ItemsImportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new imports item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsImports> | ItemsImports} [arrayItemsImportsItemsImports] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsImports: async (meta?: string, arrayItemsImportsItemsImports?: Array<ItemsImports> | ItemsImports, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/imports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsImportsItemsImports, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing imports item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsImports: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsImports', 'id', id)
            const localVarPath = `/items/imports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the imports items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsImports: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/imports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single imports item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsImports: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsImports', 'id', id)
            const localVarPath = `/items/imports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing imports item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsImports} [itemsImports] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsImports: async (id: number, fields?: Array<string>, meta?: string, itemsImports?: ItemsImports, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsImports', 'id', id)
            const localVarPath = `/items/imports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsImports, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsImportsApi - functional programming interface
 * @export
 */
export const ItemsImportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsImportsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new imports item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsImports> | ItemsImports} [arrayItemsImportsItemsImports] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsImports(meta?: string, arrayItemsImportsItemsImports?: Array<ItemsImports> | ItemsImports, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20034>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsImports(meta, arrayItemsImportsItemsImports, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing imports item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsImports(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsImports(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the imports items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsImports(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20034>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsImports(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single imports item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsImports(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20034>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsImports(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing imports item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsImports} [itemsImports] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsImports(id: number, fields?: Array<string>, meta?: string, itemsImports?: ItemsImports, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20034>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsImports(id, fields, meta, itemsImports, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsImportsApi - factory interface
 * @export
 */
export const ItemsImportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsImportsApiFp(configuration)
    return {
        /**
         * Create a new imports item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsImports> | ItemsImports} [arrayItemsImportsItemsImports] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsImports(meta?: string, arrayItemsImportsItemsImports?: Array<ItemsImports> | ItemsImports, options?: any): AxiosPromise<InlineResponse20034> {
            return localVarFp.createItemsImports(meta, arrayItemsImportsItemsImports, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing imports item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsImports(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsImports(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the imports items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsImports(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20034> {
            return localVarFp.readItemsImports(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single imports item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsImports(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20034> {
            return localVarFp.readSingleItemsImports(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing imports item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsImports} [itemsImports] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsImports(id: number, fields?: Array<string>, meta?: string, itemsImports?: ItemsImports, options?: any): AxiosPromise<InlineResponse20034> {
            return localVarFp.updateSingleItemsImports(id, fields, meta, itemsImports, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsImportsApi - object-oriented interface
 * @export
 * @class ItemsImportsApi
 * @extends {BaseAPI}
 */
export class ItemsImportsApi extends BaseAPI {
    /**
     * Create a new imports item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsImports> | ItemsImports} [arrayItemsImportsItemsImports] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsImportsApi
     */
    public createItemsImports(meta?: string, arrayItemsImportsItemsImports?: Array<ItemsImports> | ItemsImports, options?: any) {
        return ItemsImportsApiFp(this.configuration).createItemsImports(meta, arrayItemsImportsItemsImports, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing imports item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsImportsApi
     */
    public deleteSingleItemsImports(id: number, options?: any) {
        return ItemsImportsApiFp(this.configuration).deleteSingleItemsImports(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the imports items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsImportsApi
     */
    public readItemsImports(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsImportsApiFp(this.configuration).readItemsImports(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single imports item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsImportsApi
     */
    public readSingleItemsImports(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsImportsApiFp(this.configuration).readSingleItemsImports(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing imports item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsImports} [itemsImports] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsImportsApi
     */
    public updateSingleItemsImports(id: number, fields?: Array<string>, meta?: string, itemsImports?: ItemsImports, options?: any) {
        return ItemsImportsApiFp(this.configuration).updateSingleItemsImports(id, fields, meta, itemsImports, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsPlatformAddressesApi - axios parameter creator
 * @export
 */
export const ItemsPlatformAddressesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new platform_addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsPlatformAddresses> | ItemsPlatformAddresses} [arrayItemsPlatformAddressesItemsPlatformAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsPlatformAddresses: async (meta?: string, arrayItemsPlatformAddressesItemsPlatformAddresses?: Array<ItemsPlatformAddresses> | ItemsPlatformAddresses, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/platform_addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsPlatformAddressesItemsPlatformAddresses, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing platform_addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsPlatformAddresses: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsPlatformAddresses', 'id', id)
            const localVarPath = `/items/platform_addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the platform_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPlatformAddresses: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/platform_addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single platform_addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPlatformAddresses: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsPlatformAddresses', 'id', id)
            const localVarPath = `/items/platform_addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing platform_addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsPlatformAddresses} [itemsPlatformAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsPlatformAddresses: async (id: number, fields?: Array<string>, meta?: string, itemsPlatformAddresses?: ItemsPlatformAddresses, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsPlatformAddresses', 'id', id)
            const localVarPath = `/items/platform_addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsPlatformAddresses, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsPlatformAddressesApi - functional programming interface
 * @export
 */
export const ItemsPlatformAddressesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsPlatformAddressesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new platform_addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsPlatformAddresses> | ItemsPlatformAddresses} [arrayItemsPlatformAddressesItemsPlatformAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsPlatformAddresses(meta?: string, arrayItemsPlatformAddressesItemsPlatformAddresses?: Array<ItemsPlatformAddresses> | ItemsPlatformAddresses, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20035>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsPlatformAddresses(meta, arrayItemsPlatformAddressesItemsPlatformAddresses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing platform_addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsPlatformAddresses(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsPlatformAddresses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the platform_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsPlatformAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20035>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsPlatformAddresses(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single platform_addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsPlatformAddresses(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20035>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsPlatformAddresses(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing platform_addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsPlatformAddresses} [itemsPlatformAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsPlatformAddresses(id: number, fields?: Array<string>, meta?: string, itemsPlatformAddresses?: ItemsPlatformAddresses, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20035>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsPlatformAddresses(id, fields, meta, itemsPlatformAddresses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsPlatformAddressesApi - factory interface
 * @export
 */
export const ItemsPlatformAddressesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsPlatformAddressesApiFp(configuration)
    return {
        /**
         * Create a new platform_addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsPlatformAddresses> | ItemsPlatformAddresses} [arrayItemsPlatformAddressesItemsPlatformAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsPlatformAddresses(meta?: string, arrayItemsPlatformAddressesItemsPlatformAddresses?: Array<ItemsPlatformAddresses> | ItemsPlatformAddresses, options?: any): AxiosPromise<InlineResponse20035> {
            return localVarFp.createItemsPlatformAddresses(meta, arrayItemsPlatformAddressesItemsPlatformAddresses, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing platform_addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsPlatformAddresses(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsPlatformAddresses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the platform_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPlatformAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20035> {
            return localVarFp.readItemsPlatformAddresses(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single platform_addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPlatformAddresses(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20035> {
            return localVarFp.readSingleItemsPlatformAddresses(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing platform_addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsPlatformAddresses} [itemsPlatformAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsPlatformAddresses(id: number, fields?: Array<string>, meta?: string, itemsPlatformAddresses?: ItemsPlatformAddresses, options?: any): AxiosPromise<InlineResponse20035> {
            return localVarFp.updateSingleItemsPlatformAddresses(id, fields, meta, itemsPlatformAddresses, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsPlatformAddressesApi - object-oriented interface
 * @export
 * @class ItemsPlatformAddressesApi
 * @extends {BaseAPI}
 */
export class ItemsPlatformAddressesApi extends BaseAPI {
    /**
     * Create a new platform_addresses item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsPlatformAddresses> | ItemsPlatformAddresses} [arrayItemsPlatformAddressesItemsPlatformAddresses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsPlatformAddressesApi
     */
    public createItemsPlatformAddresses(meta?: string, arrayItemsPlatformAddressesItemsPlatformAddresses?: Array<ItemsPlatformAddresses> | ItemsPlatformAddresses, options?: any) {
        return ItemsPlatformAddressesApiFp(this.configuration).createItemsPlatformAddresses(meta, arrayItemsPlatformAddressesItemsPlatformAddresses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing platform_addresses item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsPlatformAddressesApi
     */
    public deleteSingleItemsPlatformAddresses(id: number, options?: any) {
        return ItemsPlatformAddressesApiFp(this.configuration).deleteSingleItemsPlatformAddresses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the platform_addresses items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsPlatformAddressesApi
     */
    public readItemsPlatformAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsPlatformAddressesApiFp(this.configuration).readItemsPlatformAddresses(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single platform_addresses item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsPlatformAddressesApi
     */
    public readSingleItemsPlatformAddresses(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsPlatformAddressesApiFp(this.configuration).readSingleItemsPlatformAddresses(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing platform_addresses item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsPlatformAddresses} [itemsPlatformAddresses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsPlatformAddressesApi
     */
    public updateSingleItemsPlatformAddresses(id: number, fields?: Array<string>, meta?: string, itemsPlatformAddresses?: ItemsPlatformAddresses, options?: any) {
        return ItemsPlatformAddressesApiFp(this.configuration).updateSingleItemsPlatformAddresses(id, fields, meta, itemsPlatformAddresses, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsPlatformsApi - axios parameter creator
 * @export
 */
export const ItemsPlatformsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new platforms item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsPlatforms> | ItemsPlatforms} [arrayItemsPlatformsItemsPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsPlatforms: async (meta?: string, arrayItemsPlatformsItemsPlatforms?: Array<ItemsPlatforms> | ItemsPlatforms, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/platforms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsPlatformsItemsPlatforms, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing platforms item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsPlatforms: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsPlatforms', 'id', id)
            const localVarPath = `/items/platforms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the platforms items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPlatforms: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/platforms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single platforms item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPlatforms: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsPlatforms', 'id', id)
            const localVarPath = `/items/platforms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing platforms item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsPlatforms} [itemsPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsPlatforms: async (id: number, fields?: Array<string>, meta?: string, itemsPlatforms?: ItemsPlatforms, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsPlatforms', 'id', id)
            const localVarPath = `/items/platforms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsPlatforms, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsPlatformsApi - functional programming interface
 * @export
 */
export const ItemsPlatformsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsPlatformsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new platforms item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsPlatforms> | ItemsPlatforms} [arrayItemsPlatformsItemsPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsPlatforms(meta?: string, arrayItemsPlatformsItemsPlatforms?: Array<ItemsPlatforms> | ItemsPlatforms, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20036>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsPlatforms(meta, arrayItemsPlatformsItemsPlatforms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing platforms item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsPlatforms(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsPlatforms(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the platforms items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsPlatforms(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20036>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsPlatforms(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single platforms item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsPlatforms(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20036>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsPlatforms(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing platforms item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsPlatforms} [itemsPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsPlatforms(id: number, fields?: Array<string>, meta?: string, itemsPlatforms?: ItemsPlatforms, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20036>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsPlatforms(id, fields, meta, itemsPlatforms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsPlatformsApi - factory interface
 * @export
 */
export const ItemsPlatformsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsPlatformsApiFp(configuration)
    return {
        /**
         * Create a new platforms item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsPlatforms> | ItemsPlatforms} [arrayItemsPlatformsItemsPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsPlatforms(meta?: string, arrayItemsPlatformsItemsPlatforms?: Array<ItemsPlatforms> | ItemsPlatforms, options?: any): AxiosPromise<InlineResponse20036> {
            return localVarFp.createItemsPlatforms(meta, arrayItemsPlatformsItemsPlatforms, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing platforms item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsPlatforms(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsPlatforms(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the platforms items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPlatforms(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20036> {
            return localVarFp.readItemsPlatforms(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single platforms item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPlatforms(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20036> {
            return localVarFp.readSingleItemsPlatforms(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing platforms item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsPlatforms} [itemsPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsPlatforms(id: number, fields?: Array<string>, meta?: string, itemsPlatforms?: ItemsPlatforms, options?: any): AxiosPromise<InlineResponse20036> {
            return localVarFp.updateSingleItemsPlatforms(id, fields, meta, itemsPlatforms, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsPlatformsApi - object-oriented interface
 * @export
 * @class ItemsPlatformsApi
 * @extends {BaseAPI}
 */
export class ItemsPlatformsApi extends BaseAPI {
    /**
     * Create a new platforms item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsPlatforms> | ItemsPlatforms} [arrayItemsPlatformsItemsPlatforms] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsPlatformsApi
     */
    public createItemsPlatforms(meta?: string, arrayItemsPlatformsItemsPlatforms?: Array<ItemsPlatforms> | ItemsPlatforms, options?: any) {
        return ItemsPlatformsApiFp(this.configuration).createItemsPlatforms(meta, arrayItemsPlatformsItemsPlatforms, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing platforms item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsPlatformsApi
     */
    public deleteSingleItemsPlatforms(id: number, options?: any) {
        return ItemsPlatformsApiFp(this.configuration).deleteSingleItemsPlatforms(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the platforms items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsPlatformsApi
     */
    public readItemsPlatforms(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsPlatformsApiFp(this.configuration).readItemsPlatforms(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single platforms item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsPlatformsApi
     */
    public readSingleItemsPlatforms(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsPlatformsApiFp(this.configuration).readSingleItemsPlatforms(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing platforms item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsPlatforms} [itemsPlatforms] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsPlatformsApi
     */
    public updateSingleItemsPlatforms(id: number, fields?: Array<string>, meta?: string, itemsPlatforms?: ItemsPlatforms, options?: any) {
        return ItemsPlatformsApiFp(this.configuration).updateSingleItemsPlatforms(id, fields, meta, itemsPlatforms, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsProducersApi - axios parameter creator
 * @export
 */
export const ItemsProducersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new producers item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducers> | ItemsProducers} [arrayItemsProducersItemsProducers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProducers: async (meta?: string, arrayItemsProducersItemsProducers?: Array<ItemsProducers> | ItemsProducers, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/producers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsProducersItemsProducers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing producers item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProducers: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsProducers', 'id', id)
            const localVarPath = `/items/producers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the producers items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProducers: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/producers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single producers item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProducers: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsProducers', 'id', id)
            const localVarPath = `/items/producers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing producers item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducers} [itemsProducers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProducers: async (id: number, fields?: Array<string>, meta?: string, itemsProducers?: ItemsProducers, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsProducers', 'id', id)
            const localVarPath = `/items/producers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsProducers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsProducersApi - functional programming interface
 * @export
 */
export const ItemsProducersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsProducersApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new producers item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducers> | ItemsProducers} [arrayItemsProducersItemsProducers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsProducers(meta?: string, arrayItemsProducersItemsProducers?: Array<ItemsProducers> | ItemsProducers, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20037>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsProducers(meta, arrayItemsProducersItemsProducers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing producers item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsProducers(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsProducers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the producers items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsProducers(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20037>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsProducers(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single producers item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsProducers(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20037>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsProducers(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing producers item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducers} [itemsProducers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsProducers(id: number, fields?: Array<string>, meta?: string, itemsProducers?: ItemsProducers, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20037>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsProducers(id, fields, meta, itemsProducers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsProducersApi - factory interface
 * @export
 */
export const ItemsProducersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsProducersApiFp(configuration)
    return {
        /**
         * Create a new producers item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducers> | ItemsProducers} [arrayItemsProducersItemsProducers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProducers(meta?: string, arrayItemsProducersItemsProducers?: Array<ItemsProducers> | ItemsProducers, options?: any): AxiosPromise<InlineResponse20037> {
            return localVarFp.createItemsProducers(meta, arrayItemsProducersItemsProducers, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing producers item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProducers(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsProducers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the producers items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProducers(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20037> {
            return localVarFp.readItemsProducers(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single producers item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProducers(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20037> {
            return localVarFp.readSingleItemsProducers(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing producers item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducers} [itemsProducers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProducers(id: number, fields?: Array<string>, meta?: string, itemsProducers?: ItemsProducers, options?: any): AxiosPromise<InlineResponse20037> {
            return localVarFp.updateSingleItemsProducers(id, fields, meta, itemsProducers, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsProducersApi - object-oriented interface
 * @export
 * @class ItemsProducersApi
 * @extends {BaseAPI}
 */
export class ItemsProducersApi extends BaseAPI {
    /**
     * Create a new producers item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsProducers> | ItemsProducers} [arrayItemsProducersItemsProducers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProducersApi
     */
    public createItemsProducers(meta?: string, arrayItemsProducersItemsProducers?: Array<ItemsProducers> | ItemsProducers, options?: any) {
        return ItemsProducersApiFp(this.configuration).createItemsProducers(meta, arrayItemsProducersItemsProducers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing producers item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProducersApi
     */
    public deleteSingleItemsProducers(id: number, options?: any) {
        return ItemsProducersApiFp(this.configuration).deleteSingleItemsProducers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the producers items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProducersApi
     */
    public readItemsProducers(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsProducersApiFp(this.configuration).readItemsProducers(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single producers item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProducersApi
     */
    public readSingleItemsProducers(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsProducersApiFp(this.configuration).readSingleItemsProducers(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing producers item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsProducers} [itemsProducers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProducersApi
     */
    public updateSingleItemsProducers(id: number, fields?: Array<string>, meta?: string, itemsProducers?: ItemsProducers, options?: any) {
        return ItemsProducersApiFp(this.configuration).updateSingleItemsProducers(id, fields, meta, itemsProducers, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsProducersCertificationsApi - axios parameter creator
 * @export
 */
export const ItemsProducersCertificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new producers_certifications item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducersCertifications> | ItemsProducersCertifications} [arrayItemsProducersCertificationsItemsProducersCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProducersCertifications: async (meta?: string, arrayItemsProducersCertificationsItemsProducersCertifications?: Array<ItemsProducersCertifications> | ItemsProducersCertifications, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/producers_certifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsProducersCertificationsItemsProducersCertifications, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing producers_certifications item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProducersCertifications: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsProducersCertifications', 'id', id)
            const localVarPath = `/items/producers_certifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the producers_certifications items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProducersCertifications: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/producers_certifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single producers_certifications item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProducersCertifications: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsProducersCertifications', 'id', id)
            const localVarPath = `/items/producers_certifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing producers_certifications item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducersCertifications} [itemsProducersCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProducersCertifications: async (id: number, fields?: Array<string>, meta?: string, itemsProducersCertifications?: ItemsProducersCertifications, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsProducersCertifications', 'id', id)
            const localVarPath = `/items/producers_certifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsProducersCertifications, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsProducersCertificationsApi - functional programming interface
 * @export
 */
export const ItemsProducersCertificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsProducersCertificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new producers_certifications item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducersCertifications> | ItemsProducersCertifications} [arrayItemsProducersCertificationsItemsProducersCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsProducersCertifications(meta?: string, arrayItemsProducersCertificationsItemsProducersCertifications?: Array<ItemsProducersCertifications> | ItemsProducersCertifications, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20038>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsProducersCertifications(meta, arrayItemsProducersCertificationsItemsProducersCertifications, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing producers_certifications item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsProducersCertifications(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsProducersCertifications(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the producers_certifications items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsProducersCertifications(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20038>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsProducersCertifications(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single producers_certifications item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsProducersCertifications(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20038>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsProducersCertifications(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing producers_certifications item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducersCertifications} [itemsProducersCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsProducersCertifications(id: number, fields?: Array<string>, meta?: string, itemsProducersCertifications?: ItemsProducersCertifications, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20038>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsProducersCertifications(id, fields, meta, itemsProducersCertifications, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsProducersCertificationsApi - factory interface
 * @export
 */
export const ItemsProducersCertificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsProducersCertificationsApiFp(configuration)
    return {
        /**
         * Create a new producers_certifications item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducersCertifications> | ItemsProducersCertifications} [arrayItemsProducersCertificationsItemsProducersCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProducersCertifications(meta?: string, arrayItemsProducersCertificationsItemsProducersCertifications?: Array<ItemsProducersCertifications> | ItemsProducersCertifications, options?: any): AxiosPromise<InlineResponse20038> {
            return localVarFp.createItemsProducersCertifications(meta, arrayItemsProducersCertificationsItemsProducersCertifications, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing producers_certifications item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProducersCertifications(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsProducersCertifications(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the producers_certifications items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProducersCertifications(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20038> {
            return localVarFp.readItemsProducersCertifications(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single producers_certifications item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProducersCertifications(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20038> {
            return localVarFp.readSingleItemsProducersCertifications(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing producers_certifications item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducersCertifications} [itemsProducersCertifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProducersCertifications(id: number, fields?: Array<string>, meta?: string, itemsProducersCertifications?: ItemsProducersCertifications, options?: any): AxiosPromise<InlineResponse20038> {
            return localVarFp.updateSingleItemsProducersCertifications(id, fields, meta, itemsProducersCertifications, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsProducersCertificationsApi - object-oriented interface
 * @export
 * @class ItemsProducersCertificationsApi
 * @extends {BaseAPI}
 */
export class ItemsProducersCertificationsApi extends BaseAPI {
    /**
     * Create a new producers_certifications item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsProducersCertifications> | ItemsProducersCertifications} [arrayItemsProducersCertificationsItemsProducersCertifications] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProducersCertificationsApi
     */
    public createItemsProducersCertifications(meta?: string, arrayItemsProducersCertificationsItemsProducersCertifications?: Array<ItemsProducersCertifications> | ItemsProducersCertifications, options?: any) {
        return ItemsProducersCertificationsApiFp(this.configuration).createItemsProducersCertifications(meta, arrayItemsProducersCertificationsItemsProducersCertifications, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing producers_certifications item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProducersCertificationsApi
     */
    public deleteSingleItemsProducersCertifications(id: number, options?: any) {
        return ItemsProducersCertificationsApiFp(this.configuration).deleteSingleItemsProducersCertifications(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the producers_certifications items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProducersCertificationsApi
     */
    public readItemsProducersCertifications(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsProducersCertificationsApiFp(this.configuration).readItemsProducersCertifications(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single producers_certifications item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProducersCertificationsApi
     */
    public readSingleItemsProducersCertifications(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsProducersCertificationsApiFp(this.configuration).readSingleItemsProducersCertifications(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing producers_certifications item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsProducersCertifications} [itemsProducersCertifications] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProducersCertificationsApi
     */
    public updateSingleItemsProducersCertifications(id: number, fields?: Array<string>, meta?: string, itemsProducersCertifications?: ItemsProducersCertifications, options?: any) {
        return ItemsProducersCertificationsApiFp(this.configuration).updateSingleItemsProducersCertifications(id, fields, meta, itemsProducersCertifications, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsProducersPlatformsApi - axios parameter creator
 * @export
 */
export const ItemsProducersPlatformsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new producers_platforms item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducersPlatforms> | ItemsProducersPlatforms} [arrayItemsProducersPlatformsItemsProducersPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProducersPlatforms: async (meta?: string, arrayItemsProducersPlatformsItemsProducersPlatforms?: Array<ItemsProducersPlatforms> | ItemsProducersPlatforms, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/producers_platforms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsProducersPlatformsItemsProducersPlatforms, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing producers_platforms item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProducersPlatforms: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsProducersPlatforms', 'id', id)
            const localVarPath = `/items/producers_platforms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the producers_platforms items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProducersPlatforms: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/producers_platforms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single producers_platforms item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProducersPlatforms: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsProducersPlatforms', 'id', id)
            const localVarPath = `/items/producers_platforms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing producers_platforms item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducersPlatforms} [itemsProducersPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProducersPlatforms: async (id: number, fields?: Array<string>, meta?: string, itemsProducersPlatforms?: ItemsProducersPlatforms, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsProducersPlatforms', 'id', id)
            const localVarPath = `/items/producers_platforms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsProducersPlatforms, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsProducersPlatformsApi - functional programming interface
 * @export
 */
export const ItemsProducersPlatformsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsProducersPlatformsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new producers_platforms item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducersPlatforms> | ItemsProducersPlatforms} [arrayItemsProducersPlatformsItemsProducersPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsProducersPlatforms(meta?: string, arrayItemsProducersPlatformsItemsProducersPlatforms?: Array<ItemsProducersPlatforms> | ItemsProducersPlatforms, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20039>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsProducersPlatforms(meta, arrayItemsProducersPlatformsItemsProducersPlatforms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing producers_platforms item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsProducersPlatforms(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsProducersPlatforms(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the producers_platforms items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsProducersPlatforms(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20039>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsProducersPlatforms(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single producers_platforms item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsProducersPlatforms(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20039>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsProducersPlatforms(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing producers_platforms item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducersPlatforms} [itemsProducersPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsProducersPlatforms(id: number, fields?: Array<string>, meta?: string, itemsProducersPlatforms?: ItemsProducersPlatforms, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20039>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsProducersPlatforms(id, fields, meta, itemsProducersPlatforms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsProducersPlatformsApi - factory interface
 * @export
 */
export const ItemsProducersPlatformsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsProducersPlatformsApiFp(configuration)
    return {
        /**
         * Create a new producers_platforms item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducersPlatforms> | ItemsProducersPlatforms} [arrayItemsProducersPlatformsItemsProducersPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProducersPlatforms(meta?: string, arrayItemsProducersPlatformsItemsProducersPlatforms?: Array<ItemsProducersPlatforms> | ItemsProducersPlatforms, options?: any): AxiosPromise<InlineResponse20039> {
            return localVarFp.createItemsProducersPlatforms(meta, arrayItemsProducersPlatformsItemsProducersPlatforms, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing producers_platforms item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProducersPlatforms(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsProducersPlatforms(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the producers_platforms items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProducersPlatforms(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20039> {
            return localVarFp.readItemsProducersPlatforms(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single producers_platforms item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProducersPlatforms(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20039> {
            return localVarFp.readSingleItemsProducersPlatforms(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing producers_platforms item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducersPlatforms} [itemsProducersPlatforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProducersPlatforms(id: number, fields?: Array<string>, meta?: string, itemsProducersPlatforms?: ItemsProducersPlatforms, options?: any): AxiosPromise<InlineResponse20039> {
            return localVarFp.updateSingleItemsProducersPlatforms(id, fields, meta, itemsProducersPlatforms, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsProducersPlatformsApi - object-oriented interface
 * @export
 * @class ItemsProducersPlatformsApi
 * @extends {BaseAPI}
 */
export class ItemsProducersPlatformsApi extends BaseAPI {
    /**
     * Create a new producers_platforms item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsProducersPlatforms> | ItemsProducersPlatforms} [arrayItemsProducersPlatformsItemsProducersPlatforms] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProducersPlatformsApi
     */
    public createItemsProducersPlatforms(meta?: string, arrayItemsProducersPlatformsItemsProducersPlatforms?: Array<ItemsProducersPlatforms> | ItemsProducersPlatforms, options?: any) {
        return ItemsProducersPlatformsApiFp(this.configuration).createItemsProducersPlatforms(meta, arrayItemsProducersPlatformsItemsProducersPlatforms, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing producers_platforms item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProducersPlatformsApi
     */
    public deleteSingleItemsProducersPlatforms(id: number, options?: any) {
        return ItemsProducersPlatformsApiFp(this.configuration).deleteSingleItemsProducersPlatforms(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the producers_platforms items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProducersPlatformsApi
     */
    public readItemsProducersPlatforms(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsProducersPlatformsApiFp(this.configuration).readItemsProducersPlatforms(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single producers_platforms item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProducersPlatformsApi
     */
    public readSingleItemsProducersPlatforms(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsProducersPlatformsApiFp(this.configuration).readSingleItemsProducersPlatforms(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing producers_platforms item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsProducersPlatforms} [itemsProducersPlatforms] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProducersPlatformsApi
     */
    public updateSingleItemsProducersPlatforms(id: number, fields?: Array<string>, meta?: string, itemsProducersPlatforms?: ItemsProducersPlatforms, options?: any) {
        return ItemsProducersPlatformsApiFp(this.configuration).updateSingleItemsProducersPlatforms(id, fields, meta, itemsProducersPlatforms, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsProductCategoriesApi - axios parameter creator
 * @export
 */
export const ItemsProductCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new product_categories item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProductCategories> | ItemsProductCategories} [arrayItemsProductCategoriesItemsProductCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProductCategories: async (meta?: string, arrayItemsProductCategoriesItemsProductCategories?: Array<ItemsProductCategories> | ItemsProductCategories, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/product_categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsProductCategoriesItemsProductCategories, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing product_categories item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProductCategories: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsProductCategories', 'id', id)
            const localVarPath = `/items/product_categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the product_categories items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProductCategories: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/product_categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single product_categories item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProductCategories: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsProductCategories', 'id', id)
            const localVarPath = `/items/product_categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing product_categories item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProductCategories} [itemsProductCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProductCategories: async (id: number, fields?: Array<string>, meta?: string, itemsProductCategories?: ItemsProductCategories, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsProductCategories', 'id', id)
            const localVarPath = `/items/product_categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsProductCategories, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsProductCategoriesApi - functional programming interface
 * @export
 */
export const ItemsProductCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsProductCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new product_categories item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProductCategories> | ItemsProductCategories} [arrayItemsProductCategoriesItemsProductCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsProductCategories(meta?: string, arrayItemsProductCategoriesItemsProductCategories?: Array<ItemsProductCategories> | ItemsProductCategories, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20040>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsProductCategories(meta, arrayItemsProductCategoriesItemsProductCategories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing product_categories item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsProductCategories(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsProductCategories(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the product_categories items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsProductCategories(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20040>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsProductCategories(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single product_categories item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsProductCategories(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20040>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsProductCategories(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing product_categories item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProductCategories} [itemsProductCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsProductCategories(id: number, fields?: Array<string>, meta?: string, itemsProductCategories?: ItemsProductCategories, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20040>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsProductCategories(id, fields, meta, itemsProductCategories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsProductCategoriesApi - factory interface
 * @export
 */
export const ItemsProductCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsProductCategoriesApiFp(configuration)
    return {
        /**
         * Create a new product_categories item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProductCategories> | ItemsProductCategories} [arrayItemsProductCategoriesItemsProductCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProductCategories(meta?: string, arrayItemsProductCategoriesItemsProductCategories?: Array<ItemsProductCategories> | ItemsProductCategories, options?: any): AxiosPromise<InlineResponse20040> {
            return localVarFp.createItemsProductCategories(meta, arrayItemsProductCategoriesItemsProductCategories, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing product_categories item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProductCategories(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsProductCategories(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the product_categories items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProductCategories(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20040> {
            return localVarFp.readItemsProductCategories(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single product_categories item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProductCategories(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20040> {
            return localVarFp.readSingleItemsProductCategories(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing product_categories item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProductCategories} [itemsProductCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProductCategories(id: number, fields?: Array<string>, meta?: string, itemsProductCategories?: ItemsProductCategories, options?: any): AxiosPromise<InlineResponse20040> {
            return localVarFp.updateSingleItemsProductCategories(id, fields, meta, itemsProductCategories, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsProductCategoriesApi - object-oriented interface
 * @export
 * @class ItemsProductCategoriesApi
 * @extends {BaseAPI}
 */
export class ItemsProductCategoriesApi extends BaseAPI {
    /**
     * Create a new product_categories item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsProductCategories> | ItemsProductCategories} [arrayItemsProductCategoriesItemsProductCategories] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProductCategoriesApi
     */
    public createItemsProductCategories(meta?: string, arrayItemsProductCategoriesItemsProductCategories?: Array<ItemsProductCategories> | ItemsProductCategories, options?: any) {
        return ItemsProductCategoriesApiFp(this.configuration).createItemsProductCategories(meta, arrayItemsProductCategoriesItemsProductCategories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing product_categories item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProductCategoriesApi
     */
    public deleteSingleItemsProductCategories(id: number, options?: any) {
        return ItemsProductCategoriesApiFp(this.configuration).deleteSingleItemsProductCategories(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the product_categories items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProductCategoriesApi
     */
    public readItemsProductCategories(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsProductCategoriesApiFp(this.configuration).readItemsProductCategories(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single product_categories item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProductCategoriesApi
     */
    public readSingleItemsProductCategories(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsProductCategoriesApiFp(this.configuration).readSingleItemsProductCategories(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing product_categories item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsProductCategories} [itemsProductCategories] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProductCategoriesApi
     */
    public updateSingleItemsProductCategories(id: number, fields?: Array<string>, meta?: string, itemsProductCategories?: ItemsProductCategories, options?: any) {
        return ItemsProductCategoriesApiFp(this.configuration).updateSingleItemsProductCategories(id, fields, meta, itemsProductCategories, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsProductsApi - axios parameter creator
 * @export
 */
export const ItemsProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new products item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducts> | ItemsProducts} [arrayItemsProductsItemsProducts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProducts: async (meta?: string, arrayItemsProductsItemsProducts?: Array<ItemsProducts> | ItemsProducts, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsProductsItemsProducts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing products item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProducts: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsProducts', 'id', id)
            const localVarPath = `/items/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the products items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProducts: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single products item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProducts: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsProducts', 'id', id)
            const localVarPath = `/items/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing products item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducts} [itemsProducts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProducts: async (id: number, fields?: Array<string>, meta?: string, itemsProducts?: ItemsProducts, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsProducts', 'id', id)
            const localVarPath = `/items/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsProducts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsProductsApi - functional programming interface
 * @export
 */
export const ItemsProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new products item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducts> | ItemsProducts} [arrayItemsProductsItemsProducts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsProducts(meta?: string, arrayItemsProductsItemsProducts?: Array<ItemsProducts> | ItemsProducts, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20041>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsProducts(meta, arrayItemsProductsItemsProducts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing products item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsProducts(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsProducts(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the products items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsProducts(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20041>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsProducts(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single products item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsProducts(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20041>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsProducts(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing products item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducts} [itemsProducts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsProducts(id: number, fields?: Array<string>, meta?: string, itemsProducts?: ItemsProducts, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20041>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsProducts(id, fields, meta, itemsProducts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsProductsApi - factory interface
 * @export
 */
export const ItemsProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsProductsApiFp(configuration)
    return {
        /**
         * Create a new products item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProducts> | ItemsProducts} [arrayItemsProductsItemsProducts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProducts(meta?: string, arrayItemsProductsItemsProducts?: Array<ItemsProducts> | ItemsProducts, options?: any): AxiosPromise<InlineResponse20041> {
            return localVarFp.createItemsProducts(meta, arrayItemsProductsItemsProducts, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing products item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProducts(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsProducts(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the products items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProducts(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20041> {
            return localVarFp.readItemsProducts(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single products item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProducts(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20041> {
            return localVarFp.readSingleItemsProducts(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing products item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProducts} [itemsProducts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProducts(id: number, fields?: Array<string>, meta?: string, itemsProducts?: ItemsProducts, options?: any): AxiosPromise<InlineResponse20041> {
            return localVarFp.updateSingleItemsProducts(id, fields, meta, itemsProducts, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsProductsApi - object-oriented interface
 * @export
 * @class ItemsProductsApi
 * @extends {BaseAPI}
 */
export class ItemsProductsApi extends BaseAPI {
    /**
     * Create a new products item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsProducts> | ItemsProducts} [arrayItemsProductsItemsProducts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProductsApi
     */
    public createItemsProducts(meta?: string, arrayItemsProductsItemsProducts?: Array<ItemsProducts> | ItemsProducts, options?: any) {
        return ItemsProductsApiFp(this.configuration).createItemsProducts(meta, arrayItemsProductsItemsProducts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing products item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProductsApi
     */
    public deleteSingleItemsProducts(id: number, options?: any) {
        return ItemsProductsApiFp(this.configuration).deleteSingleItemsProducts(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the products items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProductsApi
     */
    public readItemsProducts(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsProductsApiFp(this.configuration).readItemsProducts(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single products item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProductsApi
     */
    public readSingleItemsProducts(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsProductsApiFp(this.configuration).readSingleItemsProducts(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing products item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsProducts} [itemsProducts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProductsApi
     */
    public updateSingleItemsProducts(id: number, fields?: Array<string>, meta?: string, itemsProducts?: ItemsProducts, options?: any) {
        return ItemsProductsApiFp(this.configuration).updateSingleItemsProducts(id, fields, meta, itemsProducts, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsProductsProducersAddressesApi - axios parameter creator
 * @export
 */
export const ItemsProductsProducersAddressesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new products_producers_addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProductsProducersAddresses> | ItemsProductsProducersAddresses} [arrayItemsProductsProducersAddressesItemsProductsProducersAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProductsProducersAddresses: async (meta?: string, arrayItemsProductsProducersAddressesItemsProductsProducersAddresses?: Array<ItemsProductsProducersAddresses> | ItemsProductsProducersAddresses, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/products_producers_addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsProductsProducersAddressesItemsProductsProducersAddresses, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing products_producers_addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProductsProducersAddresses: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsProductsProducersAddresses', 'id', id)
            const localVarPath = `/items/products_producers_addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the products_producers_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProductsProducersAddresses: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/products_producers_addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single products_producers_addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProductsProducersAddresses: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsProductsProducersAddresses', 'id', id)
            const localVarPath = `/items/products_producers_addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the products_producers_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [latitude] Filter by latitude.
         * @param {number} [longitude] Filter by longitude.
         * @param {number} [km] Filter by km.
         * @param {'standard' | 'shipping'} [searchType] Define if search type is based on \&quot;shipping\&quot; or \&quot;proximity\&quot; address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchItemsProductsProducersAddresses: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, latitude?: number, longitude?: number, km?: number, searchType?: 'standard' | 'shipping', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (km !== undefined) {
                localVarQueryParameter['km'] = km;
            }

            if (searchType !== undefined) {
                localVarQueryParameter['search_type'] = searchType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing products_producers_addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProductsProducersAddresses} [itemsProductsProducersAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProductsProducersAddresses: async (id: number, fields?: Array<string>, meta?: string, itemsProductsProducersAddresses?: ItemsProductsProducersAddresses, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsProductsProducersAddresses', 'id', id)
            const localVarPath = `/items/products_producers_addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsProductsProducersAddresses, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsProductsProducersAddressesApi - functional programming interface
 * @export
 */
export const ItemsProductsProducersAddressesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsProductsProducersAddressesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new products_producers_addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProductsProducersAddresses> | ItemsProductsProducersAddresses} [arrayItemsProductsProducersAddressesItemsProductsProducersAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsProductsProducersAddresses(meta?: string, arrayItemsProductsProducersAddressesItemsProductsProducersAddresses?: Array<ItemsProductsProducersAddresses> | ItemsProductsProducersAddresses, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20042>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsProductsProducersAddresses(meta, arrayItemsProductsProducersAddressesItemsProductsProducersAddresses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing products_producers_addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsProductsProducersAddresses(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsProductsProducersAddresses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the products_producers_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsProductsProducersAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20042>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsProductsProducersAddresses(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single products_producers_addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsProductsProducersAddresses(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20042>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsProductsProducersAddresses(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the products_producers_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [latitude] Filter by latitude.
         * @param {number} [longitude] Filter by longitude.
         * @param {number} [km] Filter by km.
         * @param {'standard' | 'shipping'} [searchType] Define if search type is based on \&quot;shipping\&quot; or \&quot;proximity\&quot; address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchItemsProductsProducersAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, latitude?: number, longitude?: number, km?: number, searchType?: 'standard' | 'shipping', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20042>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchItemsProductsProducersAddresses(fields, limit, meta, offset, sort, filter, search, latitude, longitude, km, searchType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing products_producers_addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProductsProducersAddresses} [itemsProductsProducersAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsProductsProducersAddresses(id: number, fields?: Array<string>, meta?: string, itemsProductsProducersAddresses?: ItemsProductsProducersAddresses, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20042>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsProductsProducersAddresses(id, fields, meta, itemsProductsProducersAddresses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsProductsProducersAddressesApi - factory interface
 * @export
 */
export const ItemsProductsProducersAddressesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsProductsProducersAddressesApiFp(configuration)
    return {
        /**
         * Create a new products_producers_addresses item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProductsProducersAddresses> | ItemsProductsProducersAddresses} [arrayItemsProductsProducersAddressesItemsProductsProducersAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProductsProducersAddresses(meta?: string, arrayItemsProductsProducersAddressesItemsProductsProducersAddresses?: Array<ItemsProductsProducersAddresses> | ItemsProductsProducersAddresses, options?: any): AxiosPromise<InlineResponse20042> {
            return localVarFp.createItemsProductsProducersAddresses(meta, arrayItemsProductsProducersAddressesItemsProductsProducersAddresses, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing products_producers_addresses item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProductsProducersAddresses(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsProductsProducersAddresses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the products_producers_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProductsProducersAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20042> {
            return localVarFp.readItemsProductsProducersAddresses(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single products_producers_addresses item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProductsProducersAddresses(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20042> {
            return localVarFp.readSingleItemsProductsProducersAddresses(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * List the products_producers_addresses items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [latitude] Filter by latitude.
         * @param {number} [longitude] Filter by longitude.
         * @param {number} [km] Filter by km.
         * @param {'standard' | 'shipping'} [searchType] Define if search type is based on \&quot;shipping\&quot; or \&quot;proximity\&quot; address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchItemsProductsProducersAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, latitude?: number, longitude?: number, km?: number, searchType?: 'standard' | 'shipping', options?: any): AxiosPromise<InlineResponse20042> {
            return localVarFp.searchItemsProductsProducersAddresses(fields, limit, meta, offset, sort, filter, search, latitude, longitude, km, searchType, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing products_producers_addresses item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProductsProducersAddresses} [itemsProductsProducersAddresses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProductsProducersAddresses(id: number, fields?: Array<string>, meta?: string, itemsProductsProducersAddresses?: ItemsProductsProducersAddresses, options?: any): AxiosPromise<InlineResponse20042> {
            return localVarFp.updateSingleItemsProductsProducersAddresses(id, fields, meta, itemsProductsProducersAddresses, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsProductsProducersAddressesApi - object-oriented interface
 * @export
 * @class ItemsProductsProducersAddressesApi
 * @extends {BaseAPI}
 */
export class ItemsProductsProducersAddressesApi extends BaseAPI {
    /**
     * Create a new products_producers_addresses item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsProductsProducersAddresses> | ItemsProductsProducersAddresses} [arrayItemsProductsProducersAddressesItemsProductsProducersAddresses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProductsProducersAddressesApi
     */
    public createItemsProductsProducersAddresses(meta?: string, arrayItemsProductsProducersAddressesItemsProductsProducersAddresses?: Array<ItemsProductsProducersAddresses> | ItemsProductsProducersAddresses, options?: any) {
        return ItemsProductsProducersAddressesApiFp(this.configuration).createItemsProductsProducersAddresses(meta, arrayItemsProductsProducersAddressesItemsProductsProducersAddresses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing products_producers_addresses item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProductsProducersAddressesApi
     */
    public deleteSingleItemsProductsProducersAddresses(id: number, options?: any) {
        return ItemsProductsProducersAddressesApiFp(this.configuration).deleteSingleItemsProductsProducersAddresses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the products_producers_addresses items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProductsProducersAddressesApi
     */
    public readItemsProductsProducersAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsProductsProducersAddressesApiFp(this.configuration).readItemsProductsProducersAddresses(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single products_producers_addresses item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProductsProducersAddressesApi
     */
    public readSingleItemsProductsProducersAddresses(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsProductsProducersAddressesApiFp(this.configuration).readSingleItemsProductsProducersAddresses(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the products_producers_addresses items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {number} [latitude] Filter by latitude.
     * @param {number} [longitude] Filter by longitude.
     * @param {number} [km] Filter by km.
     * @param {'standard' | 'shipping'} [searchType] Define if search type is based on \&quot;shipping\&quot; or \&quot;proximity\&quot; address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProductsProducersAddressesApi
     */
    public searchItemsProductsProducersAddresses(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, latitude?: number, longitude?: number, km?: number, searchType?: 'standard' | 'shipping', options?: any) {
        return ItemsProductsProducersAddressesApiFp(this.configuration).searchItemsProductsProducersAddresses(fields, limit, meta, offset, sort, filter, search, latitude, longitude, km, searchType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing products_producers_addresses item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsProductsProducersAddresses} [itemsProductsProducersAddresses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProductsProducersAddressesApi
     */
    public updateSingleItemsProductsProducersAddresses(id: number, fields?: Array<string>, meta?: string, itemsProductsProducersAddresses?: ItemsProductsProducersAddresses, options?: any) {
        return ItemsProductsProducersAddressesApiFp(this.configuration).updateSingleItemsProductsProducersAddresses(id, fields, meta, itemsProductsProducersAddresses, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsProvincesApi - axios parameter creator
 * @export
 */
export const ItemsProvincesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new provinces item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProvinces> | ItemsProvinces} [arrayItemsProvincesItemsProvinces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProvinces: async (meta?: string, arrayItemsProvincesItemsProvinces?: Array<ItemsProvinces> | ItemsProvinces, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/provinces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsProvincesItemsProvinces, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing provinces item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProvinces: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsProvinces', 'id', id)
            const localVarPath = `/items/provinces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the provinces items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProvinces: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/provinces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single provinces item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProvinces: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsProvinces', 'id', id)
            const localVarPath = `/items/provinces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing provinces item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProvinces} [itemsProvinces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProvinces: async (id: number, fields?: Array<string>, meta?: string, itemsProvinces?: ItemsProvinces, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsProvinces', 'id', id)
            const localVarPath = `/items/provinces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsProvinces, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsProvincesApi - functional programming interface
 * @export
 */
export const ItemsProvincesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsProvincesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new provinces item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProvinces> | ItemsProvinces} [arrayItemsProvincesItemsProvinces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsProvinces(meta?: string, arrayItemsProvincesItemsProvinces?: Array<ItemsProvinces> | ItemsProvinces, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20043>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsProvinces(meta, arrayItemsProvincesItemsProvinces, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing provinces item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsProvinces(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsProvinces(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the provinces items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsProvinces(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20043>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsProvinces(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single provinces item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsProvinces(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20043>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsProvinces(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing provinces item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProvinces} [itemsProvinces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsProvinces(id: number, fields?: Array<string>, meta?: string, itemsProvinces?: ItemsProvinces, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20043>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsProvinces(id, fields, meta, itemsProvinces, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsProvincesApi - factory interface
 * @export
 */
export const ItemsProvincesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsProvincesApiFp(configuration)
    return {
        /**
         * Create a new provinces item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsProvinces> | ItemsProvinces} [arrayItemsProvincesItemsProvinces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsProvinces(meta?: string, arrayItemsProvincesItemsProvinces?: Array<ItemsProvinces> | ItemsProvinces, options?: any): AxiosPromise<InlineResponse20043> {
            return localVarFp.createItemsProvinces(meta, arrayItemsProvincesItemsProvinces, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing provinces item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsProvinces(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsProvinces(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the provinces items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsProvinces(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20043> {
            return localVarFp.readItemsProvinces(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single provinces item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsProvinces(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20043> {
            return localVarFp.readSingleItemsProvinces(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing provinces item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsProvinces} [itemsProvinces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsProvinces(id: number, fields?: Array<string>, meta?: string, itemsProvinces?: ItemsProvinces, options?: any): AxiosPromise<InlineResponse20043> {
            return localVarFp.updateSingleItemsProvinces(id, fields, meta, itemsProvinces, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsProvincesApi - object-oriented interface
 * @export
 * @class ItemsProvincesApi
 * @extends {BaseAPI}
 */
export class ItemsProvincesApi extends BaseAPI {
    /**
     * Create a new provinces item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsProvinces> | ItemsProvinces} [arrayItemsProvincesItemsProvinces] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProvincesApi
     */
    public createItemsProvinces(meta?: string, arrayItemsProvincesItemsProvinces?: Array<ItemsProvinces> | ItemsProvinces, options?: any) {
        return ItemsProvincesApiFp(this.configuration).createItemsProvinces(meta, arrayItemsProvincesItemsProvinces, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing provinces item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProvincesApi
     */
    public deleteSingleItemsProvinces(id: number, options?: any) {
        return ItemsProvincesApiFp(this.configuration).deleteSingleItemsProvinces(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the provinces items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProvincesApi
     */
    public readItemsProvinces(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsProvincesApiFp(this.configuration).readItemsProvinces(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single provinces item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProvincesApi
     */
    public readSingleItemsProvinces(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsProvincesApiFp(this.configuration).readSingleItemsProvinces(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing provinces item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsProvinces} [itemsProvinces] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsProvincesApi
     */
    public updateSingleItemsProvinces(id: number, fields?: Array<string>, meta?: string, itemsProvinces?: ItemsProvinces, options?: any) {
        return ItemsProvincesApiFp(this.configuration).updateSingleItemsProvinces(id, fields, meta, itemsProvinces, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsRegionsApi - axios parameter creator
 * @export
 */
export const ItemsRegionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new regions item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsRegions> | ItemsRegions} [arrayItemsRegionsItemsRegions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsRegions: async (meta?: string, arrayItemsRegionsItemsRegions?: Array<ItemsRegions> | ItemsRegions, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsRegionsItemsRegions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing regions item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsRegions: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsRegions', 'id', id)
            const localVarPath = `/items/regions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the regions items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsRegions: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single regions item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsRegions: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsRegions', 'id', id)
            const localVarPath = `/items/regions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing regions item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsRegions} [itemsRegions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsRegions: async (id: number, fields?: Array<string>, meta?: string, itemsRegions?: ItemsRegions, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsRegions', 'id', id)
            const localVarPath = `/items/regions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsRegions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsRegionsApi - functional programming interface
 * @export
 */
export const ItemsRegionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsRegionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new regions item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsRegions> | ItemsRegions} [arrayItemsRegionsItemsRegions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsRegions(meta?: string, arrayItemsRegionsItemsRegions?: Array<ItemsRegions> | ItemsRegions, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20044>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsRegions(meta, arrayItemsRegionsItemsRegions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing regions item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsRegions(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsRegions(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the regions items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsRegions(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20044>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsRegions(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single regions item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsRegions(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20044>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsRegions(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing regions item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsRegions} [itemsRegions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsRegions(id: number, fields?: Array<string>, meta?: string, itemsRegions?: ItemsRegions, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20044>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsRegions(id, fields, meta, itemsRegions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsRegionsApi - factory interface
 * @export
 */
export const ItemsRegionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsRegionsApiFp(configuration)
    return {
        /**
         * Create a new regions item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsRegions> | ItemsRegions} [arrayItemsRegionsItemsRegions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsRegions(meta?: string, arrayItemsRegionsItemsRegions?: Array<ItemsRegions> | ItemsRegions, options?: any): AxiosPromise<InlineResponse20044> {
            return localVarFp.createItemsRegions(meta, arrayItemsRegionsItemsRegions, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing regions item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsRegions(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsRegions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the regions items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsRegions(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20044> {
            return localVarFp.readItemsRegions(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single regions item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsRegions(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20044> {
            return localVarFp.readSingleItemsRegions(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing regions item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsRegions} [itemsRegions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsRegions(id: number, fields?: Array<string>, meta?: string, itemsRegions?: ItemsRegions, options?: any): AxiosPromise<InlineResponse20044> {
            return localVarFp.updateSingleItemsRegions(id, fields, meta, itemsRegions, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsRegionsApi - object-oriented interface
 * @export
 * @class ItemsRegionsApi
 * @extends {BaseAPI}
 */
export class ItemsRegionsApi extends BaseAPI {
    /**
     * Create a new regions item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsRegions> | ItemsRegions} [arrayItemsRegionsItemsRegions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsRegionsApi
     */
    public createItemsRegions(meta?: string, arrayItemsRegionsItemsRegions?: Array<ItemsRegions> | ItemsRegions, options?: any) {
        return ItemsRegionsApiFp(this.configuration).createItemsRegions(meta, arrayItemsRegionsItemsRegions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing regions item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsRegionsApi
     */
    public deleteSingleItemsRegions(id: number, options?: any) {
        return ItemsRegionsApiFp(this.configuration).deleteSingleItemsRegions(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the regions items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsRegionsApi
     */
    public readItemsRegions(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsRegionsApiFp(this.configuration).readItemsRegions(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single regions item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsRegionsApi
     */
    public readSingleItemsRegions(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsRegionsApiFp(this.configuration).readSingleItemsRegions(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing regions item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsRegions} [itemsRegions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsRegionsApi
     */
    public updateSingleItemsRegions(id: number, fields?: Array<string>, meta?: string, itemsRegions?: ItemsRegions, options?: any) {
        return ItemsRegionsApiFp(this.configuration).updateSingleItemsRegions(id, fields, meta, itemsRegions, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsSourcesApi - axios parameter creator
 * @export
 */
export const ItemsSourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new sources item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsSources> | ItemsSources} [arrayItemsSourcesItemsSources] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsSources: async (meta?: string, arrayItemsSourcesItemsSources?: Array<ItemsSources> | ItemsSources, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsSourcesItemsSources, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing sources item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsSources: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsSources', 'id', id)
            const localVarPath = `/items/sources/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the sources items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsSources: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single sources item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsSources: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsSources', 'id', id)
            const localVarPath = `/items/sources/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing sources item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsSources} [itemsSources] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsSources: async (id: number, fields?: Array<string>, meta?: string, itemsSources?: ItemsSources, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsSources', 'id', id)
            const localVarPath = `/items/sources/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsSources, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsSourcesApi - functional programming interface
 * @export
 */
export const ItemsSourcesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsSourcesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new sources item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsSources> | ItemsSources} [arrayItemsSourcesItemsSources] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsSources(meta?: string, arrayItemsSourcesItemsSources?: Array<ItemsSources> | ItemsSources, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20045>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsSources(meta, arrayItemsSourcesItemsSources, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing sources item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsSources(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsSources(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the sources items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsSources(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20045>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsSources(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single sources item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsSources(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20045>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsSources(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing sources item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsSources} [itemsSources] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsSources(id: number, fields?: Array<string>, meta?: string, itemsSources?: ItemsSources, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20045>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsSources(id, fields, meta, itemsSources, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsSourcesApi - factory interface
 * @export
 */
export const ItemsSourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsSourcesApiFp(configuration)
    return {
        /**
         * Create a new sources item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsSources> | ItemsSources} [arrayItemsSourcesItemsSources] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsSources(meta?: string, arrayItemsSourcesItemsSources?: Array<ItemsSources> | ItemsSources, options?: any): AxiosPromise<InlineResponse20045> {
            return localVarFp.createItemsSources(meta, arrayItemsSourcesItemsSources, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing sources item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsSources(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsSources(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the sources items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsSources(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20045> {
            return localVarFp.readItemsSources(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single sources item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsSources(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20045> {
            return localVarFp.readSingleItemsSources(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing sources item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsSources} [itemsSources] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsSources(id: number, fields?: Array<string>, meta?: string, itemsSources?: ItemsSources, options?: any): AxiosPromise<InlineResponse20045> {
            return localVarFp.updateSingleItemsSources(id, fields, meta, itemsSources, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsSourcesApi - object-oriented interface
 * @export
 * @class ItemsSourcesApi
 * @extends {BaseAPI}
 */
export class ItemsSourcesApi extends BaseAPI {
    /**
     * Create a new sources item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsSources> | ItemsSources} [arrayItemsSourcesItemsSources] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsSourcesApi
     */
    public createItemsSources(meta?: string, arrayItemsSourcesItemsSources?: Array<ItemsSources> | ItemsSources, options?: any) {
        return ItemsSourcesApiFp(this.configuration).createItemsSources(meta, arrayItemsSourcesItemsSources, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing sources item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsSourcesApi
     */
    public deleteSingleItemsSources(id: number, options?: any) {
        return ItemsSourcesApiFp(this.configuration).deleteSingleItemsSources(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the sources items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsSourcesApi
     */
    public readItemsSources(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsSourcesApiFp(this.configuration).readItemsSources(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single sources item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsSourcesApi
     */
    public readSingleItemsSources(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsSourcesApiFp(this.configuration).readSingleItemsSources(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing sources item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsSources} [itemsSources] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsSourcesApi
     */
    public updateSingleItemsSources(id: number, fields?: Array<string>, meta?: string, itemsSources?: ItemsSources, options?: any) {
        return ItemsSourcesApiFp(this.configuration).updateSingleItemsSources(id, fields, meta, itemsSources, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsUnitsApi - axios parameter creator
 * @export
 */
export const ItemsUnitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new units item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsUnits> | ItemsUnits} [arrayItemsUnitsItemsUnits] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsUnits: async (meta?: string, arrayItemsUnitsItemsUnits?: Array<ItemsUnits> | ItemsUnits, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayItemsUnitsItemsUnits, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing units item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsUnits: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSingleItemsUnits', 'id', id)
            const localVarPath = `/items/units/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the units items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsUnits: async (fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single units item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsUnits: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSingleItemsUnits', 'id', id)
            const localVarPath = `/items/units/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing units item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsUnits} [itemsUnits] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsUnits: async (id: number, fields?: Array<string>, meta?: string, itemsUnits?: ItemsUnits, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSingleItemsUnits', 'id', id)
            const localVarPath = `/items/units/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemsUnits, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsUnitsApi - functional programming interface
 * @export
 */
export const ItemsUnitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsUnitsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new units item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsUnits> | ItemsUnits} [arrayItemsUnitsItemsUnits] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsUnits(meta?: string, arrayItemsUnitsItemsUnits?: Array<ItemsUnits> | ItemsUnits, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20046>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsUnits(meta, arrayItemsUnitsItemsUnits, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing units item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleItemsUnits(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleItemsUnits(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the units items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsUnits(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20046>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsUnits(fields, limit, meta, offset, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single units item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSingleItemsUnits(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20046>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSingleItemsUnits(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing units item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsUnits} [itemsUnits] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleItemsUnits(id: number, fields?: Array<string>, meta?: string, itemsUnits?: ItemsUnits, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20046>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleItemsUnits(id, fields, meta, itemsUnits, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsUnitsApi - factory interface
 * @export
 */
export const ItemsUnitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsUnitsApiFp(configuration)
    return {
        /**
         * Create a new units item.
         * @summary Create an Item
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<ItemsUnits> | ItemsUnits} [arrayItemsUnitsItemsUnits] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsUnits(meta?: string, arrayItemsUnitsItemsUnits?: Array<ItemsUnits> | ItemsUnits, options?: any): AxiosPromise<InlineResponse20046> {
            return localVarFp.createItemsUnits(meta, arrayItemsUnitsItemsUnits, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing units item.
         * @summary Delete an Item
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleItemsUnits(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSingleItemsUnits(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the units items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsUnits(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20046> {
            return localVarFp.readItemsUnits(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single units item by unique identifier.
         * @summary Retrieve an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsUnits(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20046> {
            return localVarFp.readSingleItemsUnits(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing units item.
         * @summary Update an Item
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {ItemsUnits} [itemsUnits] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleItemsUnits(id: number, fields?: Array<string>, meta?: string, itemsUnits?: ItemsUnits, options?: any): AxiosPromise<InlineResponse20046> {
            return localVarFp.updateSingleItemsUnits(id, fields, meta, itemsUnits, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsUnitsApi - object-oriented interface
 * @export
 * @class ItemsUnitsApi
 * @extends {BaseAPI}
 */
export class ItemsUnitsApi extends BaseAPI {
    /**
     * Create a new units item.
     * @summary Create an Item
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<ItemsUnits> | ItemsUnits} [arrayItemsUnitsItemsUnits] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsUnitsApi
     */
    public createItemsUnits(meta?: string, arrayItemsUnitsItemsUnits?: Array<ItemsUnits> | ItemsUnits, options?: any) {
        return ItemsUnitsApiFp(this.configuration).createItemsUnits(meta, arrayItemsUnitsItemsUnits, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing units item.
     * @summary Delete an Item
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsUnitsApi
     */
    public deleteSingleItemsUnits(id: number, options?: any) {
        return ItemsUnitsApiFp(this.configuration).deleteSingleItemsUnits(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the units items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsUnitsApi
     */
    public readItemsUnits(fields?: Array<string>, limit?: number, meta?: string, offset?: number, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return ItemsUnitsApiFp(this.configuration).readItemsUnits(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single units item by unique identifier.
     * @summary Retrieve an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsUnitsApi
     */
    public readSingleItemsUnits(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return ItemsUnitsApiFp(this.configuration).readSingleItemsUnits(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing units item.
     * @summary Update an Item
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {ItemsUnits} [itemsUnits] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsUnitsApi
     */
    public updateSingleItemsUnits(id: number, fields?: Array<string>, meta?: string, itemsUnits?: ItemsUnits, options?: any) {
        return ItemsUnitsApiFp(this.configuration).updateSingleItemsUnits(id, fields, meta, itemsUnits, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PermissionsApi - axios parameter creator
 * @export
 */
export const PermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new permission.
         * @summary Create a Permission
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject14} [inlineObject14] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPermission: async (meta?: string, inlineObject14?: InlineObject14, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject14, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing permission
         * @summary Delete a Permission
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermission: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePermission', 'id', id)
            const localVarPath = `/permissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the permissions that apply to the current user.
         * @summary List My Permissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPermissions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single permissions object by unique identifier.
         * @summary Retrieve a Permission
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermission: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPermission', 'id', id)
            const localVarPath = `/permissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all permissions.
         * @summary List Permissions
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissions: async (fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing permission
         * @summary Update a Permission
         * @param {number} id 
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject15} [inlineObject15] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePermission: async (id: number, meta?: string, inlineObject15?: InlineObject15, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePermission', 'id', id)
            const localVarPath = `/permissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject15, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermissionsApi - functional programming interface
 * @export
 */
export const PermissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PermissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new permission.
         * @summary Create a Permission
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject14} [inlineObject14] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPermission(meta?: string, inlineObject14?: InlineObject14, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20020>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPermission(meta, inlineObject14, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing permission
         * @summary Delete a Permission
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePermission(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePermission(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the permissions that apply to the current user.
         * @summary List My Permissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyPermissions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20019>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyPermissions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single permissions object by unique identifier.
         * @summary Retrieve a Permission
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermission(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20020>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermission(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all permissions.
         * @summary List Permissions
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissions(fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20019>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissions(fields, limit, offset, meta, sort, filter, search, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing permission
         * @summary Update a Permission
         * @param {number} id 
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject15} [inlineObject15] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePermission(id: number, meta?: string, inlineObject15?: InlineObject15, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20020>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePermission(id, meta, inlineObject15, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PermissionsApi - factory interface
 * @export
 */
export const PermissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PermissionsApiFp(configuration)
    return {
        /**
         * Create a new permission.
         * @summary Create a Permission
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject14} [inlineObject14] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPermission(meta?: string, inlineObject14?: InlineObject14, options?: any): AxiosPromise<InlineResponse20020> {
            return localVarFp.createPermission(meta, inlineObject14, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing permission
         * @summary Delete a Permission
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermission(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePermission(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the permissions that apply to the current user.
         * @summary List My Permissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPermissions(options?: any): AxiosPromise<InlineResponse20019> {
            return localVarFp.getMyPermissions(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single permissions object by unique identifier.
         * @summary Retrieve a Permission
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermission(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20020> {
            return localVarFp.getPermission(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * List all permissions.
         * @summary List Permissions
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissions(fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, page?: number, options?: any): AxiosPromise<InlineResponse20019> {
            return localVarFp.getPermissions(fields, limit, offset, meta, sort, filter, search, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing permission
         * @summary Update a Permission
         * @param {number} id 
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject15} [inlineObject15] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePermission(id: number, meta?: string, inlineObject15?: InlineObject15, options?: any): AxiosPromise<InlineResponse20020> {
            return localVarFp.updatePermission(id, meta, inlineObject15, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PermissionsApi - object-oriented interface
 * @export
 * @class PermissionsApi
 * @extends {BaseAPI}
 */
export class PermissionsApi extends BaseAPI {
    /**
     * Create a new permission.
     * @summary Create a Permission
     * @param {string} [meta] What metadata to return in the response.
     * @param {InlineObject14} [inlineObject14] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public createPermission(meta?: string, inlineObject14?: InlineObject14, options?: any) {
        return PermissionsApiFp(this.configuration).createPermission(meta, inlineObject14, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing permission
     * @summary Delete a Permission
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public deletePermission(id: number, options?: any) {
        return PermissionsApiFp(this.configuration).deletePermission(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the permissions that apply to the current user.
     * @summary List My Permissions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public getMyPermissions(options?: any) {
        return PermissionsApiFp(this.configuration).getMyPermissions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single permissions object by unique identifier.
     * @summary Retrieve a Permission
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public getPermission(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return PermissionsApiFp(this.configuration).getPermission(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all permissions.
     * @summary List Permissions
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public getPermissions(fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, page?: number, options?: any) {
        return PermissionsApiFp(this.configuration).getPermissions(fields, limit, offset, meta, sort, filter, search, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing permission
     * @summary Update a Permission
     * @param {number} id 
     * @param {string} [meta] What metadata to return in the response.
     * @param {InlineObject15} [inlineObject15] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public updatePermission(id: number, meta?: string, inlineObject15?: InlineObject15, options?: any) {
        return PermissionsApiFp(this.configuration).updatePermission(id, meta, inlineObject15, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PresetsApi - axios parameter creator
 * @export
 */
export const PresetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new preset.
         * @summary Create a Preset
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject16} [inlineObject16] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreset: async (fields?: Array<string>, meta?: string, inlineObject16?: InlineObject16, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/presets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject16, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing preset.
         * @summary Delete a Preset
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePreset: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePreset', 'id', id)
            const localVarPath = `/presets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single preset by unique identifier.
         * @summary Retrieve a Preset
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreset: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPreset', 'id', id)
            const localVarPath = `/presets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the presets.
         * @summary List Presets
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresets: async (fields?: Array<string>, limit?: number, offset?: number, page?: number, sort?: Array<string>, filter?: string, search?: string, meta?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/presets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing preset.
         * @summary Update a Preset
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject17} [inlineObject17] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreset: async (id: number, fields?: Array<string>, meta?: string, inlineObject17?: InlineObject17, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePreset', 'id', id)
            const localVarPath = `/presets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject17, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PresetsApi - functional programming interface
 * @export
 */
export const PresetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PresetsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new preset.
         * @summary Create a Preset
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject16} [inlineObject16] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPreset(fields?: Array<string>, meta?: string, inlineObject16?: InlineObject16, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20022>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPreset(fields, meta, inlineObject16, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing preset.
         * @summary Delete a Preset
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePreset(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePreset(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single preset by unique identifier.
         * @summary Retrieve a Preset
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreset(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20022>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreset(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the presets.
         * @summary List Presets
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresets(fields?: Array<string>, limit?: number, offset?: number, page?: number, sort?: Array<string>, filter?: string, search?: string, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20021>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresets(fields, limit, offset, page, sort, filter, search, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing preset.
         * @summary Update a Preset
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject17} [inlineObject17] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePreset(id: number, fields?: Array<string>, meta?: string, inlineObject17?: InlineObject17, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20022>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePreset(id, fields, meta, inlineObject17, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PresetsApi - factory interface
 * @export
 */
export const PresetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PresetsApiFp(configuration)
    return {
        /**
         * Create a new preset.
         * @summary Create a Preset
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject16} [inlineObject16] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreset(fields?: Array<string>, meta?: string, inlineObject16?: InlineObject16, options?: any): AxiosPromise<InlineResponse20022> {
            return localVarFp.createPreset(fields, meta, inlineObject16, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing preset.
         * @summary Delete a Preset
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePreset(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePreset(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single preset by unique identifier.
         * @summary Retrieve a Preset
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreset(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20022> {
            return localVarFp.getPreset(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * List the presets.
         * @summary List Presets
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresets(fields?: Array<string>, limit?: number, offset?: number, page?: number, sort?: Array<string>, filter?: string, search?: string, meta?: string, options?: any): AxiosPromise<InlineResponse20021> {
            return localVarFp.getPresets(fields, limit, offset, page, sort, filter, search, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing preset.
         * @summary Update a Preset
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject17} [inlineObject17] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreset(id: number, fields?: Array<string>, meta?: string, inlineObject17?: InlineObject17, options?: any): AxiosPromise<InlineResponse20022> {
            return localVarFp.updatePreset(id, fields, meta, inlineObject17, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PresetsApi - object-oriented interface
 * @export
 * @class PresetsApi
 * @extends {BaseAPI}
 */
export class PresetsApi extends BaseAPI {
    /**
     * Create a new preset.
     * @summary Create a Preset
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {InlineObject16} [inlineObject16] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public createPreset(fields?: Array<string>, meta?: string, inlineObject16?: InlineObject16, options?: any) {
        return PresetsApiFp(this.configuration).createPreset(fields, meta, inlineObject16, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing preset.
     * @summary Delete a Preset
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public deletePreset(id: number, options?: any) {
        return PresetsApiFp(this.configuration).deletePreset(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single preset by unique identifier.
     * @summary Retrieve a Preset
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public getPreset(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return PresetsApiFp(this.configuration).getPreset(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the presets.
     * @summary List Presets
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public getPresets(fields?: Array<string>, limit?: number, offset?: number, page?: number, sort?: Array<string>, filter?: string, search?: string, meta?: string, options?: any) {
        return PresetsApiFp(this.configuration).getPresets(fields, limit, offset, page, sort, filter, search, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing preset.
     * @summary Update a Preset
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {InlineObject17} [inlineObject17] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public updatePreset(id: number, fields?: Array<string>, meta?: string, inlineObject17?: InlineObject17, options?: any) {
        return PresetsApiFp(this.configuration).updatePreset(id, fields, meta, inlineObject17, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RelationsApi - axios parameter creator
 * @export
 */
export const RelationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new relation.
         * @summary Create a Relation
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject18} [inlineObject18] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRelation: async (fields?: Array<string>, meta?: string, inlineObject18?: InlineObject18, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/relations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject18, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing relation.
         * @summary Delete a Relation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRelation: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRelation', 'id', id)
            const localVarPath = `/relations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single relation by unique identifier.
         * @summary Retrieve a Relation
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelation: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRelation', 'id', id)
            const localVarPath = `/relations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the relations.
         * @summary List Relations
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelations: async (fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/relations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing relation
         * @summary Update a Relation
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject19} [inlineObject19] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRelation: async (id: number, fields?: Array<string>, meta?: string, inlineObject19?: InlineObject19, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRelation', 'id', id)
            const localVarPath = `/relations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject19, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RelationsApi - functional programming interface
 * @export
 */
export const RelationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RelationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new relation.
         * @summary Create a Relation
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject18} [inlineObject18] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRelation(fields?: Array<string>, meta?: string, inlineObject18?: InlineObject18, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20024>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRelation(fields, meta, inlineObject18, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing relation.
         * @summary Delete a Relation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRelation(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRelation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single relation by unique identifier.
         * @summary Retrieve a Relation
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelation(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20024>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelation(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the relations.
         * @summary List Relations
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelations(fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20023>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelations(fields, limit, offset, meta, sort, filter, search, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing relation
         * @summary Update a Relation
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject19} [inlineObject19] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRelation(id: number, fields?: Array<string>, meta?: string, inlineObject19?: InlineObject19, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20024>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRelation(id, fields, meta, inlineObject19, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RelationsApi - factory interface
 * @export
 */
export const RelationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RelationsApiFp(configuration)
    return {
        /**
         * Create a new relation.
         * @summary Create a Relation
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject18} [inlineObject18] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRelation(fields?: Array<string>, meta?: string, inlineObject18?: InlineObject18, options?: any): AxiosPromise<InlineResponse20024> {
            return localVarFp.createRelation(fields, meta, inlineObject18, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing relation.
         * @summary Delete a Relation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRelation(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRelation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single relation by unique identifier.
         * @summary Retrieve a Relation
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelation(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20024> {
            return localVarFp.getRelation(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * List the relations.
         * @summary List Relations
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelations(fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, page?: number, options?: any): AxiosPromise<InlineResponse20023> {
            return localVarFp.getRelations(fields, limit, offset, meta, sort, filter, search, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing relation
         * @summary Update a Relation
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject19} [inlineObject19] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRelation(id: number, fields?: Array<string>, meta?: string, inlineObject19?: InlineObject19, options?: any): AxiosPromise<InlineResponse20024> {
            return localVarFp.updateRelation(id, fields, meta, inlineObject19, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RelationsApi - object-oriented interface
 * @export
 * @class RelationsApi
 * @extends {BaseAPI}
 */
export class RelationsApi extends BaseAPI {
    /**
     * Create a new relation.
     * @summary Create a Relation
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {InlineObject18} [inlineObject18] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationsApi
     */
    public createRelation(fields?: Array<string>, meta?: string, inlineObject18?: InlineObject18, options?: any) {
        return RelationsApiFp(this.configuration).createRelation(fields, meta, inlineObject18, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing relation.
     * @summary Delete a Relation
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationsApi
     */
    public deleteRelation(id: number, options?: any) {
        return RelationsApiFp(this.configuration).deleteRelation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single relation by unique identifier.
     * @summary Retrieve a Relation
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationsApi
     */
    public getRelation(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return RelationsApiFp(this.configuration).getRelation(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the relations.
     * @summary List Relations
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationsApi
     */
    public getRelations(fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, page?: number, options?: any) {
        return RelationsApiFp(this.configuration).getRelations(fields, limit, offset, meta, sort, filter, search, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing relation
     * @summary Update a Relation
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {InlineObject19} [inlineObject19] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationsApi
     */
    public updateRelation(id: number, fields?: Array<string>, meta?: string, inlineObject19?: InlineObject19, options?: any) {
        return RelationsApiFp(this.configuration).updateRelation(id, fields, meta, inlineObject19, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RevisionsApi - axios parameter creator
 * @export
 */
export const RevisionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve a single revision by unique identifier.
         * @summary Retrieve a Revision
         * @param {number} id Index
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevision: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRevision', 'id', id)
            const localVarPath = `/revisions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the revisions.
         * @summary List Revisions
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevisions: async (fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/revisions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RevisionsApi - functional programming interface
 * @export
 */
export const RevisionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RevisionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve a single revision by unique identifier.
         * @summary Retrieve a Revision
         * @param {number} id Index
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRevision(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20026>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRevision(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the revisions.
         * @summary List Revisions
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRevisions(fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20025>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRevisions(fields, limit, offset, meta, sort, filter, search, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RevisionsApi - factory interface
 * @export
 */
export const RevisionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RevisionsApiFp(configuration)
    return {
        /**
         * Retrieve a single revision by unique identifier.
         * @summary Retrieve a Revision
         * @param {number} id Index
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevision(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20026> {
            return localVarFp.getRevision(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * List the revisions.
         * @summary List Revisions
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevisions(fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, page?: number, options?: any): AxiosPromise<InlineResponse20025> {
            return localVarFp.getRevisions(fields, limit, offset, meta, sort, filter, search, page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RevisionsApi - object-oriented interface
 * @export
 * @class RevisionsApi
 * @extends {BaseAPI}
 */
export class RevisionsApi extends BaseAPI {
    /**
     * Retrieve a single revision by unique identifier.
     * @summary Retrieve a Revision
     * @param {number} id Index
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevisionsApi
     */
    public getRevision(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return RevisionsApiFp(this.configuration).getRevision(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the revisions.
     * @summary List Revisions
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevisionsApi
     */
    public getRevisions(fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, page?: number, options?: any) {
        return RevisionsApiFp(this.configuration).getRevisions(fields, limit, offset, meta, sort, filter, search, page, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new role.
         * @summary Create a Role
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject20} [inlineObject20] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole: async (fields?: Array<string>, meta?: string, inlineObject20?: InlineObject20, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject20, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing role
         * @summary Delete a Role
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRole', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single role by unique identifier.
         * @summary Retrieve a Role
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole: async (id: number, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRole', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the roles.
         * @summary List Roles
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing role
         * @summary Update a Role
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject21} [inlineObject21] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole: async (id: number, fields?: Array<string>, meta?: string, inlineObject21?: InlineObject21, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRole', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject21, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new role.
         * @summary Create a Role
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject20} [inlineObject20] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRole(fields?: Array<string>, meta?: string, inlineObject20?: InlineObject20, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20028>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRole(fields, meta, inlineObject20, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing role
         * @summary Delete a Role
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRole(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRole(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single role by unique identifier.
         * @summary Retrieve a Role
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRole(id: number, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20028>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRole(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the roles.
         * @summary List Roles
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20027>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(fields, limit, offset, meta, sort, filter, search, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing role
         * @summary Update a Role
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject21} [inlineObject21] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRole(id: number, fields?: Array<string>, meta?: string, inlineObject21?: InlineObject21, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20028>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRole(id, fields, meta, inlineObject21, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * Create a new role.
         * @summary Create a Role
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject20} [inlineObject20] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole(fields?: Array<string>, meta?: string, inlineObject20?: InlineObject20, options?: any): AxiosPromise<InlineResponse20028> {
            return localVarFp.createRole(fields, meta, inlineObject20, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing role
         * @summary Delete a Role
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single role by unique identifier.
         * @summary Retrieve a Role
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole(id: number, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20028> {
            return localVarFp.getRole(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * List the roles.
         * @summary List Roles
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, page?: number, options?: any): AxiosPromise<InlineResponse20027> {
            return localVarFp.getRoles(fields, limit, offset, meta, sort, filter, search, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing role
         * @summary Update a Role
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject21} [inlineObject21] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole(id: number, fields?: Array<string>, meta?: string, inlineObject21?: InlineObject21, options?: any): AxiosPromise<InlineResponse20028> {
            return localVarFp.updateRole(id, fields, meta, inlineObject21, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * Create a new role.
     * @summary Create a Role
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {InlineObject20} [inlineObject20] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public createRole(fields?: Array<string>, meta?: string, inlineObject20?: InlineObject20, options?: any) {
        return RolesApiFp(this.configuration).createRole(fields, meta, inlineObject20, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing role
     * @summary Delete a Role
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public deleteRole(id: number, options?: any) {
        return RolesApiFp(this.configuration).deleteRole(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single role by unique identifier.
     * @summary Retrieve a Role
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getRole(id: number, fields?: Array<string>, meta?: string, options?: any) {
        return RolesApiFp(this.configuration).getRole(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the roles.
     * @summary List Roles
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getRoles(fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, page?: number, options?: any) {
        return RolesApiFp(this.configuration).getRoles(fields, limit, offset, meta, sort, filter, search, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing role
     * @summary Update a Role
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {InlineObject21} [inlineObject21] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public updateRole(id: number, fields?: Array<string>, meta?: string, inlineObject21?: InlineObject21, options?: any) {
        return RolesApiFp(this.configuration).updateRole(id, fields, meta, inlineObject21, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ServerApi - axios parameter creator
 * @export
 */
export const ServerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Ping, pong. Ping.. pong.
         * @summary Ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/server/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Perform a system status check and return the options.
         * @summary System Info
         * @param {number} superAdminToken The first time you create a project, the provided token will be saved and required for subsequent project installs. It can also be found and configured in &#x60;/config/__api.json&#x60; on your server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serverInfo: async (superAdminToken: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'superAdminToken' is not null or undefined
            assertParamExists('serverInfo', 'superAdminToken', superAdminToken)
            const localVarPath = `/server/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (superAdminToken !== undefined) {
                localVarQueryParameter['super_admin_token'] = superAdminToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServerApi - functional programming interface
 * @export
 */
export const ServerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServerApiAxiosParamCreator(configuration)
    return {
        /**
         * Ping, pong. Ping.. pong.
         * @summary Ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ping(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ping(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Perform a system status check and return the options.
         * @summary System Info
         * @param {number} superAdminToken The first time you create a project, the provided token will be saved and required for subsequent project installs. It can also be found and configured in &#x60;/config/__api.json&#x60; on your server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serverInfo(superAdminToken: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serverInfo(superAdminToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServerApi - factory interface
 * @export
 */
export const ServerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServerApiFp(configuration)
    return {
        /**
         * Ping, pong. Ping.. pong.
         * @summary Ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options?: any): AxiosPromise<string> {
            return localVarFp.ping(options).then((request) => request(axios, basePath));
        },
        /**
         * Perform a system status check and return the options.
         * @summary System Info
         * @param {number} superAdminToken The first time you create a project, the provided token will be saved and required for subsequent project installs. It can also be found and configured in &#x60;/config/__api.json&#x60; on your server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serverInfo(superAdminToken: number, options?: any): AxiosPromise<InlineResponse2005> {
            return localVarFp.serverInfo(superAdminToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServerApi - object-oriented interface
 * @export
 * @class ServerApi
 * @extends {BaseAPI}
 */
export class ServerApi extends BaseAPI {
    /**
     * Ping, pong. Ping.. pong.
     * @summary Ping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServerApi
     */
    public ping(options?: any) {
        return ServerApiFp(this.configuration).ping(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Perform a system status check and return the options.
     * @summary System Info
     * @param {number} superAdminToken The first time you create a project, the provided token will be saved and required for subsequent project installs. It can also be found and configured in &#x60;/config/__api.json&#x60; on your server.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServerApi
     */
    public serverInfo(superAdminToken: number, options?: any) {
        return ServerApiFp(this.configuration).serverInfo(superAdminToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List the settings.
         * @summary Retrieve Settings
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (limit?: number, offset?: number, meta?: string, page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the settings
         * @summary Update Settings
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSetting: async (body?: object, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * List the settings.
         * @summary Retrieve Settings
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(limit?: number, offset?: number, meta?: string, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20029>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(limit, offset, meta, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the settings
         * @summary Update Settings
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSetting(body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20029>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSetting(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * List the settings.
         * @summary Retrieve Settings
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(limit?: number, offset?: number, meta?: string, page?: number, options?: any): AxiosPromise<InlineResponse20029> {
            return localVarFp.getSettings(limit, offset, meta, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the settings
         * @summary Update Settings
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSetting(body?: object, options?: any): AxiosPromise<InlineResponse20029> {
            return localVarFp.updateSetting(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * List the settings.
     * @summary Retrieve Settings
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getSettings(limit?: number, offset?: number, meta?: string, page?: number, options?: any) {
        return SettingsApiFp(this.configuration).getSettings(limit, offset, meta, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the settings
     * @summary Update Settings
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public updateSetting(body?: object, options?: any) {
        return SettingsApiFp(this.configuration).updateSetting(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Accepts and enables an invited user using a JWT invitation token.
         * @summary Accept User Invite
         * @param {InlineObject23} inlineObject23 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvite: async (inlineObject23: InlineObject23, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject23' is not null or undefined
            assertParamExists('acceptInvite', 'inlineObject23', inlineObject23)
            const localVarPath = `/users/invite/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject23, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new user.
         * @summary Create a User
         * @param {string} [meta] What metadata to return in the response.
         * @param {Users} [users] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (meta?: string, users?: Users, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(users, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing user
         * @summary Delete a User
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the currently authenticated user.
         * @summary Retrieve Current User
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single user by unique identifier.
         * @summary Retrieve a User
         * @param {string} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, fields?: Array<string>, meta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the users.
         * @summary List Users
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invites one or more users to this project. It creates a user with an invited status, and then sends an email to the user with instructions on how to activate their account.
         * @summary Invite User(s)
         * @param {InlineObject22} [inlineObject22] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invite: async (inlineObject22?: InlineObject22, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject22, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Disables two-factor authentication for the currently authenticated user.
         * @summary Disable 2FA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meTfaDisable: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/tfa/disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enables two-factor authentication for the currently authenticated user.
         * @summary Enable 2FA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meTfaEnable: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/tfa/enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the last used page field of the currently authenticated user. This is used internally to be able to open the Directus admin app from the last page you used.
         * @summary Update Last Page
         * @param {InlineObject24} [inlineObject24] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastUsedPageMe: async (inlineObject24?: InlineObject24, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/track/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject24, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the currently authenticated user.
         * @summary Update Current User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMe: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing user
         * @summary Update a User
         * @param {string} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Users} [users] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, fields?: Array<string>, meta?: string, users?: Users, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(users, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Accepts and enables an invited user using a JWT invitation token.
         * @summary Accept User Invite
         * @param {InlineObject23} inlineObject23 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptInvite(inlineObject23: InlineObject23, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20031>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptInvite(inlineObject23, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new user.
         * @summary Create a User
         * @param {string} [meta] What metadata to return in the response.
         * @param {Users} [users] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(meta?: string, users?: Users, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20031>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(meta, users, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing user
         * @summary Delete a User
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the currently authenticated user.
         * @summary Retrieve Current User
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20031>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single user by unique identifier.
         * @summary Retrieve a User
         * @param {string} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, fields?: Array<string>, meta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20031>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, fields, meta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the users.
         * @summary List Users
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20030>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(fields, limit, offset, meta, sort, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Invites one or more users to this project. It creates a user with an invited status, and then sends an email to the user with instructions on how to activate their account.
         * @summary Invite User(s)
         * @param {InlineObject22} [inlineObject22] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invite(inlineObject22?: InlineObject22, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20031>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invite(inlineObject22, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Disables two-factor authentication for the currently authenticated user.
         * @summary Disable 2FA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meTfaDisable(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meTfaDisable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enables two-factor authentication for the currently authenticated user.
         * @summary Enable 2FA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meTfaEnable(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meTfaEnable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the last used page field of the currently authenticated user. This is used internally to be able to open the Directus admin app from the last page you used.
         * @summary Update Last Page
         * @param {InlineObject24} [inlineObject24] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLastUsedPageMe(inlineObject24?: InlineObject24, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLastUsedPageMe(inlineObject24, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the currently authenticated user.
         * @summary Update Current User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMe(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20031>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing user
         * @summary Update a User
         * @param {string} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Users} [users] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, fields?: Array<string>, meta?: string, users?: Users, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, fields, meta, users, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Accepts and enables an invited user using a JWT invitation token.
         * @summary Accept User Invite
         * @param {InlineObject23} inlineObject23 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvite(inlineObject23: InlineObject23, options?: any): AxiosPromise<InlineResponse20031> {
            return localVarFp.acceptInvite(inlineObject23, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new user.
         * @summary Create a User
         * @param {string} [meta] What metadata to return in the response.
         * @param {Users} [users] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(meta?: string, users?: Users, options?: any): AxiosPromise<InlineResponse20031> {
            return localVarFp.createUser(meta, users, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing user
         * @summary Delete a User
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the currently authenticated user.
         * @summary Retrieve Current User
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20031> {
            return localVarFp.getMe(fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single user by unique identifier.
         * @summary Retrieve a User
         * @param {string} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, fields?: Array<string>, meta?: string, options?: any): AxiosPromise<InlineResponse20031> {
            return localVarFp.getUser(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * List the users.
         * @summary List Users
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
         * @param {string} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, options?: any): AxiosPromise<InlineResponse20030> {
            return localVarFp.getUsers(fields, limit, offset, meta, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Invites one or more users to this project. It creates a user with an invited status, and then sends an email to the user with instructions on how to activate their account.
         * @summary Invite User(s)
         * @param {InlineObject22} [inlineObject22] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invite(inlineObject22?: InlineObject22, options?: any): AxiosPromise<InlineResponse20031> {
            return localVarFp.invite(inlineObject22, options).then((request) => request(axios, basePath));
        },
        /**
         * Disables two-factor authentication for the currently authenticated user.
         * @summary Disable 2FA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meTfaDisable(options?: any): AxiosPromise<void> {
            return localVarFp.meTfaDisable(options).then((request) => request(axios, basePath));
        },
        /**
         * Enables two-factor authentication for the currently authenticated user.
         * @summary Enable 2FA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meTfaEnable(options?: any): AxiosPromise<void> {
            return localVarFp.meTfaEnable(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the last used page field of the currently authenticated user. This is used internally to be able to open the Directus admin app from the last page you used.
         * @summary Update Last Page
         * @param {InlineObject24} [inlineObject24] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastUsedPageMe(inlineObject24?: InlineObject24, options?: any): AxiosPromise<void> {
            return localVarFp.updateLastUsedPageMe(inlineObject24, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the currently authenticated user.
         * @summary Update Current User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMe(options?: any): AxiosPromise<InlineResponse20031> {
            return localVarFp.updateMe(options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing user
         * @summary Update a User
         * @param {string} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Users} [users] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, fields?: Array<string>, meta?: string, users?: Users, options?: any): AxiosPromise<InlineResponse2005> {
            return localVarFp.updateUser(id, fields, meta, users, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Accepts and enables an invited user using a JWT invitation token.
     * @summary Accept User Invite
     * @param {InlineObject23} inlineObject23 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public acceptInvite(inlineObject23: InlineObject23, options?: any) {
        return UsersApiFp(this.configuration).acceptInvite(inlineObject23, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new user.
     * @summary Create a User
     * @param {string} [meta] What metadata to return in the response.
     * @param {Users} [users] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(meta?: string, users?: Users, options?: any) {
        return UsersApiFp(this.configuration).createUser(meta, users, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing user
     * @summary Delete a User
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(id: string, options?: any) {
        return UsersApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the currently authenticated user.
     * @summary Retrieve Current User
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getMe(fields?: Array<string>, meta?: string, options?: any) {
        return UsersApiFp(this.configuration).getMe(fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single user by unique identifier.
     * @summary Retrieve a User
     * @param {string} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(id: string, fields?: Array<string>, meta?: string, options?: any) {
        return UsersApiFp(this.configuration).getUser(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the users.
     * @summary List Users
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly. 
     * @param {string} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(fields?: Array<string>, limit?: number, offset?: number, meta?: string, sort?: Array<string>, filter?: string, search?: string, options?: any) {
        return UsersApiFp(this.configuration).getUsers(fields, limit, offset, meta, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invites one or more users to this project. It creates a user with an invited status, and then sends an email to the user with instructions on how to activate their account.
     * @summary Invite User(s)
     * @param {InlineObject22} [inlineObject22] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public invite(inlineObject22?: InlineObject22, options?: any) {
        return UsersApiFp(this.configuration).invite(inlineObject22, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Disables two-factor authentication for the currently authenticated user.
     * @summary Disable 2FA
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public meTfaDisable(options?: any) {
        return UsersApiFp(this.configuration).meTfaDisable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enables two-factor authentication for the currently authenticated user.
     * @summary Enable 2FA
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public meTfaEnable(options?: any) {
        return UsersApiFp(this.configuration).meTfaEnable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the last used page field of the currently authenticated user. This is used internally to be able to open the Directus admin app from the last page you used.
     * @summary Update Last Page
     * @param {InlineObject24} [inlineObject24] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateLastUsedPageMe(inlineObject24?: InlineObject24, options?: any) {
        return UsersApiFp(this.configuration).updateLastUsedPageMe(inlineObject24, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the currently authenticated user.
     * @summary Update Current User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateMe(options?: any) {
        return UsersApiFp(this.configuration).updateMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing user
     * @summary Update a User
     * @param {string} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {Users} [users] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(id: string, fields?: Array<string>, meta?: string, users?: Users, options?: any) {
        return UsersApiFp(this.configuration).updateUser(id, fields, meta, users, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilitiesApi - axios parameter creator
 * @export
 */
export const UtilitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a random string of given length.
         * @summary Get a Random String
         * @param {number} [length] Length of the random string.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        random: async (length?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/utils/random/string`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (length !== undefined) {
                localVarQueryParameter['length'] = length;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilitiesApi - functional programming interface
 * @export
 */
export const UtilitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a random string of given length.
         * @summary Get a Random String
         * @param {number} [length] Length of the random string.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async random(length?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.random(length, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilitiesApi - factory interface
 * @export
 */
export const UtilitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilitiesApiFp(configuration)
    return {
        /**
         * Returns a random string of given length.
         * @summary Get a Random String
         * @param {number} [length] Length of the random string.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        random(length?: number, options?: any): AxiosPromise<InlineResponse2006> {
            return localVarFp.random(length, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilitiesApi - object-oriented interface
 * @export
 * @class UtilitiesApi
 * @extends {BaseAPI}
 */
export class UtilitiesApi extends BaseAPI {
    /**
     * Returns a random string of given length.
     * @summary Get a Random String
     * @param {number} [length] Length of the random string.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public random(length?: number, options?: any) {
        return UtilitiesApiFp(this.configuration).random(length, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new webhook.
         * @summary Create a Webhook
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject25} [inlineObject25] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebhook: async (fields?: Array<string>, meta?: string, inlineObject25?: InlineObject25, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/webhooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject25, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing webhook
         * @summary Delete a Webhook
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebhook: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWebhook', 'id', id)
            const localVarPath = `/webhooks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a single webhook by unique identifier.
         * @summary Retrieve a Webhook
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhook: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWebhook', 'id', id)
            const localVarPath = `/webhooks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all webhooks.
         * @summary List Webhooks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhooks: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/webhooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing webhook
         * @summary Update a Webhook
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject26} [inlineObject26] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebhook: async (id: number, fields?: Array<string>, meta?: string, inlineObject26?: InlineObject26, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWebhook', 'id', id)
            const localVarPath = `/webhooks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject26, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new webhook.
         * @summary Create a Webhook
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject25} [inlineObject25] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWebhook(fields?: Array<string>, meta?: string, inlineObject25?: InlineObject25, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20028>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWebhook(fields, meta, inlineObject25, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing webhook
         * @summary Delete a Webhook
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWebhook(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWebhook(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a single webhook by unique identifier.
         * @summary Retrieve a Webhook
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebhook(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20032>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebhook(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all webhooks.
         * @summary List Webhooks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebhooks(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20032>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebhooks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing webhook
         * @summary Update a Webhook
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject26} [inlineObject26] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWebhook(id: number, fields?: Array<string>, meta?: string, inlineObject26?: InlineObject26, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20028>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWebhook(id, fields, meta, inlineObject26, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         * Create a new webhook.
         * @summary Create a Webhook
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject25} [inlineObject25] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebhook(fields?: Array<string>, meta?: string, inlineObject25?: InlineObject25, options?: any): AxiosPromise<InlineResponse20028> {
            return localVarFp.createWebhook(fields, meta, inlineObject25, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing webhook
         * @summary Delete a Webhook
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebhook(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWebhook(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single webhook by unique identifier.
         * @summary Retrieve a Webhook
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhook(id: number, options?: any): AxiosPromise<InlineResponse20032> {
            return localVarFp.getWebhook(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all webhooks.
         * @summary List Webhooks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhooks(options?: any): AxiosPromise<InlineResponse20032> {
            return localVarFp.getWebhooks(options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing webhook
         * @summary Update a Webhook
         * @param {number} id 
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {InlineObject26} [inlineObject26] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebhook(id: number, fields?: Array<string>, meta?: string, inlineObject26?: InlineObject26, options?: any): AxiosPromise<InlineResponse20028> {
            return localVarFp.updateWebhook(id, fields, meta, inlineObject26, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * Create a new webhook.
     * @summary Create a Webhook
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {InlineObject25} [inlineObject25] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public createWebhook(fields?: Array<string>, meta?: string, inlineObject25?: InlineObject25, options?: any) {
        return WebhooksApiFp(this.configuration).createWebhook(fields, meta, inlineObject25, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing webhook
     * @summary Delete a Webhook
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public deleteWebhook(id: number, options?: any) {
        return WebhooksApiFp(this.configuration).deleteWebhook(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a single webhook by unique identifier.
     * @summary Retrieve a Webhook
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public getWebhook(id: number, options?: any) {
        return WebhooksApiFp(this.configuration).getWebhook(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all webhooks.
     * @summary List Webhooks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public getWebhooks(options?: any) {
        return WebhooksApiFp(this.configuration).getWebhooks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing webhook
     * @summary Update a Webhook
     * @param {number} id 
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {InlineObject26} [inlineObject26] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public updateWebhook(id: number, fields?: Array<string>, meta?: string, inlineObject26?: InlineObject26, options?: any) {
        return WebhooksApiFp(this.configuration).updateWebhook(id, fields, meta, inlineObject26, options).then((request) => request(this.axios, this.basePath));
    }
}


