import apiConfig from "./apiConfig";
import {
  ItemsAddresses,
  ItemsApi,
  ItemsPlatforms,
  ItemsProducers,
  ItemsProductCategories,
} from "../../generated/axios";
import { ExtendedFilters, serializeFilters } from "../lib/serializeFilters";
import toOption from "../lib/toOption";
import { IProducerInfo } from "../../containers/Admin/Producers/ProducerProducts/types";
import { LoadOptions } from "react-select-async-paginate/ts/types";
import { FSelectOption } from "../../components/FormElements/types";
import { IProduct } from "../../containers/Admin/Products/types";
import { IPlatformPOI } from "../../containers/Admin/Search/types";

const itemsApi = new ItemsApi(apiConfig);

export default itemsApi;

export const loadProductCategory = (product_id: FSelectOption<number>): Promise<FSelectOption<number>> => {
  const filters: ExtendedFilters<ItemsProductCategories> = [
    { id: "products.id", value: product_id.value, operator: "_eq" },
  ];
  return itemsApi
    .readItemsProductCategories(
      ["id", "name"],
      undefined,
      undefined,
      undefined,
      undefined,
      serializeFilters({ status: "published" }, filters)
    )
    .then((response) => {
      const records = response.data.data || [];
      if (records[0]) return toOption(records[0]);
      else throw new Error("Prodotto senza categoria merceologica");
    });
};

interface IAdditionalProductCategories {
  page: number;
  product_id?: FSelectOption<number>;
}

export const loadProductCategories: LoadOptions<FSelectOption<number>, unknown> = (
  value,
  loadedOptions,
  additional
) => {
  const filters: ExtendedFilters<ItemsProductCategories> = value ? [{ id: "name", value }] : [];
  const { page = 1, product_id } = (additional as IAdditionalProductCategories) ?? {};
  if (product_id) filters.push({ id: "products.id", value: product_id.value, operator: "_eq" });
  return itemsApi
    .readItemsProductCategories(
      ["id", "name"],
      10,
      undefined,
      (page - 1) * 10,
      ["name"],
      serializeFilters({ status: "published" }, filters)
    )
    .then((response) => {
      const records = response.data.data || [];
      return {
        options: records.map(toOption),
        hasMore: records.length === 10,
        additional: { page: page + 1, product_id } as unknown,
      };
    });
};

interface IAdditionalLoadProducts {
  page?: number;
  category_id?: FSelectOption<number>;
}

export const loadProducts: LoadOptions<FSelectOption<number>, unknown> = (value, loadedOptions, additional) => {
  const filters: ExtendedFilters<IProduct> = value ? [{ id: "name", value }] : [];
  const { page = 1, category_id } = (additional as IAdditionalLoadProducts) ?? {};
  if (category_id) filters.push({ id: "category_id", value: category_id.value, operator: "_eq" });
  return itemsApi
    .readItemsProducts(
      ["id", "name"],
      10,
      undefined,
      (page - 1) * 10,
      ["name"],
      serializeFilters({ status: "published" }, filters)
    )
    .then((response) => {
      const records = response.data.data || [];
      return {
        options: records.map(toOption),
        hasMore: records.length === 10,
        additional: { page: page + 1, category_id } as unknown,
      };
    });
};

export const loadPlatforms = () =>
  itemsApi.readItemsPlatforms(["id", "name", "status"], -1, undefined, 0, ["name"]).then((response) => {
    const records = response.data.data || [];
    return records.map(toOption);
  });

export const loadProducerPlatforms = (id: number) =>
  itemsApi
    .readItemsPlatforms(
      ["id", "name", "status"],
      10,
      undefined,
      undefined,
      ["name"],
      serializeFilters({}, [{ id: "producers.producers_id", value: id, operator: "_eq" }])
    )
    .then((response) => {
      const records = response.data.data || [];
      return records.map(toOption);
    });

export const getProducerProductionAddress = (id: number) =>
  itemsApi
    .readSingleItemsProducers(id, [
      "name",
      "addresses.id",
      "addresses.street",
      "addresses.civic_number",
      "addresses.postal_code",
      "addresses.city",
      "addresses.province_id.id",
      "addresses.province_id.name",
      "addresses.province_id.code",
      "addresses.is_production",
    ])
    .then((response) => {
      const data = response.data.data as unknown as ItemsProducers;
      const name = data.name || "";
      const address = ((data?.addresses || []) as ItemsAddresses[]).find((address) => address.is_production);
      if (!address?.id) {
        throw new Error("Produttore senza indirizzo di produzione");
      }
      return {
        name,
        address,
      } as IProducerInfo;
    });

export const getPlatformName = (id: number) =>
  itemsApi.readSingleItemsPlatforms(id, ["name"]).then((response) => {
    const data = response.data.data as unknown as ItemsPlatforms;
    return data.name || "";
  });

export const loadPlatformsAddresses = (ids: number[]) =>
  itemsApi
    .readItemsPlatformAddresses(
      [
        "id",
        "status",
        "street",
        "civic_number",
        "city",
        "province_id.id",
        "province_id.name",
        "province_id.code",
        "postal_code",
        "latitude",
        "longitude",
        "platform_id.id",
        "platform_id.name",
      ],
      -1,
      undefined,
      0,
      [],
      serializeFilters({ status: "published", "platform_id.status": "published" }, [
        { id: "platform_id.id", value: ids.join(","), operator: "_in" },
      ])
    )
    .then((response) => {
      const records = response.data.data || [];
      return records.map((record) => {
        return {
          ...record,
          coords: {
            lat: record.latitude,
            lng: record.longitude,
          },
        };
      }) as IPlatformPOI[];
    });
