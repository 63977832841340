import React from "react";
import { Layout } from "./Layout";
import AdminGlobalStore from "./AdminGlobalStore";
import AdminRoutes from "./AdminRoutes";

interface IProps {}

const Admin: React.FC<IProps> = () => {
  return (
    <AdminGlobalStore>
      <Layout>
        <AdminRoutes />
      </Layout>
    </AdminGlobalStore>
  );
};

export default Admin;
