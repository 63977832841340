import React from "react";
import { Text } from "@chakra-ui/react";

interface IProps {
  producers: number;
  products: number;
}

const CountResults: React.FC<IProps> = ({ producers, products }) => {
  return (
    <Text>
      Numero produttori: <strong>{producers}</strong>, Numero prodotti: <strong>{products}</strong>
    </Text>
  );
};

export default CountResults;
