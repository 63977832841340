import React from "react";
import { useHistory } from "react-router-dom";
import { useStateBoolean } from "../../../shared/hooks/useStateBoolean";
import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FInput from "../../../components/FormElements/FInput";
import FSaveButton from "../../../components/FormElements/FSaveButton";
import FSaveAndExitButton from "../../../components/FormElements/FSaveAndExitButton";
import FSelectAsync from "../../../components/FormElements/FSelectAsync";
import { IProduct } from "./types";
import { REQUIRED_MGS } from "../../../components/FormElements/validationMessages";
import itemsApi, { loadProductCategories } from "../../../shared/api/itemsApi";
import { PRODUCTS_PATH } from "../../../config/paths";
import { toastFailure, toastSuccess } from "../../../shared/lib/toast";
import NewProductCategoryModalForm from "./NewProductCategoryModalForm";
import { validSelectedOption } from "../../../shared/lib/validation-utils";
import { FaPlus } from "react-icons/all";
import { EDIT } from "../../../shared/lib/path-utils";
import { ItemsProducts } from "../../../generated/axios";
import DeletedAlert from "../../../components/DeletedAlert";

const schema = yup.object().shape({
  name: yup.string().required(REQUIRED_MGS),
  category_id: yup.object().typeError(REQUIRED_MGS).test("category_id_required", REQUIRED_MGS, validSelectedOption),
});

// Need for resetting nesting object!
const resetValue = (item: IProduct) => {
  return { ...item, category_id: item.category_id ? { ...item.category_id } : null };
};

interface IProps {
  id: string;
  item: IProduct;
}

const ProductEditForm: React.FC<IProps> = ({ id, item }) => {
  const [exit, setExit] = useStateBoolean(false);
  const toast = useToast();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IProduct>({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  const onSubmit = (values: IProduct) => {
    const item = { name: values.name, status: values.status, category_id: values.category_id?.value };
    const apiCall =
      id === "new"
        ? itemsApi.createItemsProducts(undefined, item)
        : itemsApi.updateSingleItemsProducts(Number(id), ["name", "category_id"], undefined, item);
    return apiCall
      .then((response) => {
        toast(toastSuccess("Prodotto salvato con successo"));
        if (exit) history.push(PRODUCTS_PATH);
        else if (id === "new") {
          const responseId = (response.data.data as unknown as ItemsProducts)?.id;
          responseId && history.push(EDIT(PRODUCTS_PATH, String(responseId)));
        }
      })
      .catch((e: any) => {
        toast(toastFailure("Errore nel salvataggio del prodotto", String(e)));
      });
  };

  return (
    <>
      <DeletedAlert status={item.status}>Prodotto cancellato!</DeletedAlert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={"name"}
          control={control}
          render={({ field }) => <FInput label={"Nome"} error={errors.name} required={true} {...field} />}
        />

        <HStack>
          <Controller
            name={"category_id"}
            control={control}
            render={({ field }) => (
              <FSelectAsync
                {...field}
                label={"Categoria merceologica"}
                error={errors.category_id as FieldError | undefined}
                loadOptions={loadProductCategories}
                required={true}
              />
            )}
          />

          <div className={"chakra-form-control"}>
            <Text mb={2} className={"chakra-form__label"}>
              &nbsp;
            </Text>
            <Button mb={4} leftIcon={<Icon as={FaPlus} />} onClick={onOpen} w={"22em"}>
              Crea Nuova Categoria Prodotto
            </Button>
          </div>
        </HStack>

        <HStack mt="6">
          <Button onClick={() => reset(resetValue(item))}>Reset</Button>
          <FSaveButton disabled={isSubmitting && exit} isLoading={isSubmitting && !exit} />
          <FSaveAndExitButton disabled={isSubmitting && !exit} isLoading={isSubmitting && exit} onClick={setExit} />
        </HStack>
      </form>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Aggiungi Categorie di Prodotto</ModalHeader>
          <ModalCloseButton />
          <NewProductCategoryModalForm onClose={onClose} setValue={setValue} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProductEditForm;
