export const locale = "it-IT";

const dateFormatter = new Intl.DateTimeFormat(locale, {
  year: "numeric",
  day: "2-digit",
  month: "2-digit",
});

const dateTimeFormatter = new Intl.DateTimeFormat(locale, {
  year: "numeric",
  day: "2-digit",
  month: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  // second: "2-digit",
});

export const formatDate = (date: Date): string => dateFormatter.format(date);
export const formatDateTime = (dateTime: Date): string => dateTimeFormatter.format(dateTime);

export const formatDateStr = (date?: string): string => (date ? dateFormatter.format(new Date(date)) : "");

export const formatDateTimeStr = (dateTime?: string): string =>
  dateTime ? dateTimeFormatter.format(new Date(dateTime)).replace(",", "") : "";
