import axios, { AxiosError, AxiosRequestConfig } from "axios";
import logout from "./logout";

const UNAUTHORIZED = 401;

const logoutOnUnauthorized = (error: AxiosError) => {
  if (error.response) {
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      logout(true);
    }
  }
  throw error;
};

export let NoCacheAxiosInterceptor: number;
export const createNoCacheAxiosInterceptor = () => {
  NoCacheAxiosInterceptor = axios.interceptors.request.use((config: AxiosRequestConfig) => {
    if (!config.headers) config.headers = {};
    config.headers["Cache-Control"] = `no-cache`;
    return config;
  });
};
createNoCacheAxiosInterceptor();

export let AuthAxiosInterceptor: number;
export const createAuthAxiosInterceptor = () => {
  AuthAxiosInterceptor = axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const accessToken = sessionStorage.getItem("access_token") || undefined;
    if (!config.headers) config.headers = {};
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  });
};
createAuthAxiosInterceptor();

export let UnauthorizedAxiosInterceptor: number;
export const createUnauthorizedAxiosInterceptor = () => {
  UnauthorizedAxiosInterceptor = axios.interceptors.response.use((response) => response, logoutOnUnauthorized);
};
createUnauthorizedAxiosInterceptor();
