import React from "react";
import { Alert, AlertIcon } from "@chakra-ui/react";

interface IProps {
  status?: string;
}

const DeletedAlert: React.FC<IProps> = ({ status, children }) => {
  return status === "published" ? null : (
    <Alert status="warning" mb={4}>
      <AlertIcon />
      {children}
    </Alert>
  );
};

export default DeletedAlert;
