import React, { useCallback, useContext } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  ADMIN_PATH,
  IMPORTS_PATH,
  PLATFORMS_PATH,
  PRODUCERS_PATH,
  PRODUCTS_PATH,
  SEARCH_PATH,
  USERS_PATH,
} from "../../../config/paths";
import { ADMIN_ROLE, SEARCH_ROLE } from "../../../config/roles";
import { APP_NAME } from "../../../config/constants";
import { NEW } from "../../../shared/lib/path-utils";
import {
  FaAppleAlt,
  FaChevronDown,
  FaFileImport,
  FaIndustry,
  FaProjectDiagram,
  FaSearch,
  FaSignOutAlt,
  FaUsers,
} from "react-icons/fa";
import AdminContext from "../../../context/AdminContext";
import logout from "../../../shared/api/logout";
import logo from "../../../assets/dussmann_logo.svg";

const active = { backgroundColor: "gray.200" };

interface IProps {}

const Nav: React.FC<IProps> = () => {
  const { pathname } = useLocation();
  const inPath = useCallback((path: string) => pathname.startsWith(path), [pathname]);
  const activeStyle = useCallback((path: string) => (inPath(path) ? active : undefined), [inPath]);

  const {
    loggedUser: { role },
  } = useContext(AdminContext);
  return (
    <Flex
      as="nav"
      position={{ md: "fixed" }}
      bg="#ffffff"
      minH="4rem"
      w="100%"
      marginTop={{ md: "-4rem" }}
      zIndex="99"
      borderBottom="1px"
      borderBottomColor="brand.border"
    >
      <Container maxW="xxl" paddingTop="5px" px={6}>
        <Stack direction={["column", "row"]} alignItems={["flex-end", "center"]}>
          <Link to={ADMIN_PATH}>
            <Image boxSize="54px" src={logo} alt={"Dussmann"} />
          </Link>
          <Heading as="h1" fontSize="xl" fontWeight="500">
            {APP_NAME}
          </Heading>
          <Stack direction={["column", "row"]}>
            {[ADMIN_ROLE, SEARCH_ROLE].includes(role) ? (
              <Box>
                <Button
                  as={NavLink}
                  to={SEARCH_PATH}
                  leftIcon={<Icon as={FaSearch} />}
                  isActive={inPath(SEARCH_PATH)}
                  variant="ghost"
                >
                  Ricerca
                </Button>
              </Box>
            ) : null}
            <Box>
              <Menu>
                <MenuButton
                  as={Button}
                  variant="ghost"
                  leftIcon={<Icon as={FaAppleAlt} />}
                  rightIcon={<Icon as={FaChevronDown} />}
                  sx={activeStyle(PRODUCTS_PATH)}
                >
                  Prodotti
                </MenuButton>
                <MenuList>
                  <MenuItem as={Link} to={PRODUCTS_PATH}>
                    Lista
                  </MenuItem>
                  <MenuItem as={Link} to={NEW(PRODUCTS_PATH)}>
                    Nuovo
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
            <Box>
              <Menu>
                <MenuButton
                  as={Button}
                  variant="ghost"
                  leftIcon={<Icon as={FaIndustry} />}
                  rightIcon={<Icon as={FaChevronDown} />}
                  sx={activeStyle(PRODUCERS_PATH)}
                >
                  Produttori
                </MenuButton>
                <MenuList>
                  <MenuItem as={Link} to={PRODUCERS_PATH}>
                    Lista
                  </MenuItem>
                  <MenuItem as={Link} to={NEW(PRODUCERS_PATH)}>
                    Nuovo
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
            <Box>
              <Menu>
                <MenuButton
                  as={Button}
                  variant="ghost"
                  leftIcon={<Icon as={FaProjectDiagram} />}
                  rightIcon={<Icon as={FaChevronDown} />}
                  sx={activeStyle(PLATFORMS_PATH)}
                >
                  Piattaforme
                </MenuButton>
                <MenuList>
                  <MenuItem as={Link} to={PLATFORMS_PATH}>
                    Lista
                  </MenuItem>
                  <MenuItem as={Link} to={NEW(PLATFORMS_PATH)}>
                    Nuova
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
            {role === ADMIN_ROLE ? (
              <>
                <Box>
                  <Menu>
                    <MenuButton
                      as={Button}
                      variant="ghost"
                      leftIcon={<Icon as={FaUsers} />}
                      rightIcon={<Icon as={FaChevronDown} />}
                      sx={activeStyle(USERS_PATH)}
                    >
                      Utenti
                    </MenuButton>
                    <MenuList>
                      <MenuItem as={Link} to={USERS_PATH}>
                        Lista
                      </MenuItem>
                      <MenuItem as={Link} to={NEW(USERS_PATH)}>
                        Nuovo
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
                <Box>
                  <Menu>
                    <MenuButton
                      as={Button}
                      variant="ghost"
                      leftIcon={<Icon as={FaFileImport} />}
                      rightIcon={<Icon as={FaChevronDown} />}
                      sx={activeStyle(IMPORTS_PATH)}
                    >
                      Import
                    </MenuButton>
                    <MenuList>
                      <MenuItem as={Link} to={IMPORTS_PATH}>
                        Lista
                      </MenuItem>
                      <MenuItem as={Link} to={NEW(IMPORTS_PATH)}>
                        Nuovo
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              </>
            ) : null}
          </Stack>
          <Button onClick={() => logout(true)} rightIcon={<FaSignOutAlt />} variant="ghost">
            Logout
          </Button>
        </Stack>
      </Container>
    </Flex>
  );
};

export default Nav;
