import React, { useCallback, useContext, useState } from "react";
import { useStateBoolean } from "../../../../shared/hooks/useStateBoolean";
import { Button, HStack, Icon, SimpleGrid, Text, useToast } from "@chakra-ui/react";
import { Control, Controller, FieldError, FieldErrors, UseFormGetValues } from "react-hook-form";
import AdminContext from "../../../../context/AdminContext";
import FInput from "../../../../components/FormElements/FInput";
import FNumber from "../../../../components/FormElements/FNumber";
import FSelectS from "../../../../components/FormElements/FSelectS";
import { FaEdit, FaMapMarkerAlt } from "react-icons/all";
import { composeAddressString, geolocalize } from "../../../../shared/api/geolocalization";
import { toastFailure, toastSuccess } from "../../../../shared/lib/toast";
import {
  AuthAxiosInterceptor,
  createAuthAxiosInterceptor,
  createNoCacheAxiosInterceptor,
  NoCacheAxiosInterceptor,
} from "../../../../shared/api/axiosInterceptors";
import axios from "axios";
import { UseFormSetValue } from "react-hook-form/dist/types/form";
import { FSelectOption } from "../../../../components/FormElements/types";

export interface IBasicAddressFields {
  street?: string;
  civic_number?: string | null;
  city?: string;
  postal_code?: string;
  province_id?: null | FSelectOption<number>;
  latitude?: number | null;
  longitude?: number | null;
}

interface IProps {
  control: Control<IBasicAddressFields>;
  errors: FieldErrors<IBasicAddressFields>;
  getValues: UseFormGetValues<IBasicAddressFields>;
  setValue: UseFormSetValue<IBasicAddressFields>;
}

const GeolocalizedAddressFields: React.FC<IProps> = ({ control, errors, getValues, setValue }) => {
  const toast = useToast();
  const [updateCoord, setUpdateCoord] = useStateBoolean(false);
  const [geoLocalizedAddress, setGeoLocalizedAddress] = useState<string | undefined>();
  const { provinces } = useContext(AdminContext);
  const geolocalizza = useCallback(() => {
    const address = {
      street: getValues("street"),
      civic_number: getValues("civic_number"),
      city: getValues("city"),
      province_id: getValues("province_id"),
      postal_code: getValues("postal_code"),
    };
    const addressStr = composeAddressString(address, provinces);

    axios.interceptors.request.eject(AuthAxiosInterceptor);
    axios.interceptors.request.eject(NoCacheAxiosInterceptor);
    geolocalize(addressStr)
      .then((response) => {
        const { lat, lng, resolvedAddress, type } = response;
        // @ts-ignore
        setValue(`latitude`, lat);
        // @ts-ignore
        setValue(`longitude`, lng);
        setGeoLocalizedAddress(resolvedAddress);
        toast(toastSuccess(`Indirizzo correttamente geolocalizzato (${type})`));
      })
      .catch((e) => {
        console.error(`Errore nella geolocalizzazione dell'indirizzo (${e})`);
        toast(
          toastFailure(
            'La geolocalizzazione è fallita! Sblocca i campi latitudine e logitudine con il pulsante "Modifica manualmente" per inserire le coordinate manualmente e poter salvare l’indirizzo'
          )
        );
      })
      .finally(() => {
        createAuthAxiosInterceptor();
        createNoCacheAxiosInterceptor();
      });
  }, [getValues, provinces, setGeoLocalizedAddress, setValue, toast]);
  return (
    <fieldset>
      <SimpleGrid columns={[1, null, 3]} spacing={"8px"}>
        <Controller
          name={"street"}
          control={control}
          render={({ field }) => <FInput label={"Via"} error={errors.street} required={true} {...field} />}
        />
        <Controller
          name={"civic_number"}
          control={control}
          render={({ field }) => (
            <FInput label={"Numero civico"} error={errors.civic_number} maxLength={20} {...field} />
          )}
        />
        <Controller
          name={"city"}
          control={control}
          render={({ field }) => <FInput label={"Città"} error={errors.city} required={true} {...field} />}
        />
      </SimpleGrid>

      <SimpleGrid columns={[1, null, 2]} spacing={"8px"}>
        <Controller
          name={"postal_code"}
          control={control}
          render={({ field }) => <FInput label={"Cap"} maxLength={5} error={errors.postal_code} {...field} />}
        />
        <Controller
          name={"province_id"}
          control={control}
          render={({ field }) => (
            <FSelectS
              {...field}
              label={"Provincia"}
              error={errors.province_id as FieldError | undefined}
              options={provinces}
              required={true}
            />
          )}
        />
      </SimpleGrid>

      <SimpleGrid columns={[1, null, 2]} spacing={"8px"}>
        <div>
          <Text mb={2}>
            {geoLocalizedAddress ? (
              <>
                <Icon as={FaMapMarkerAlt} className="inline-icon" /> {geoLocalizedAddress}
              </>
            ) : (
              <>&nbsp;</>
            )}
          </Text>
          <HStack>
            <Button leftIcon={<Icon as={FaMapMarkerAlt} />} onClick={geolocalizza}>
              Geolocalizza
            </Button>
            <Button leftIcon={<Icon as={FaEdit} />} onClick={setUpdateCoord}>
              Modifica manualmente
            </Button>
          </HStack>
        </div>

        <SimpleGrid columns={2} spacing={"8px"}>
          <Controller
            name={"latitude"}
            control={control}
            render={({ field }) => (
              <FNumber
                label={"Latitudine"}
                error={errors.latitude}
                readonly={!updateCoord}
                required={true}
                {...field}
              />
            )}
          />
          <Controller
            name={"longitude"}
            control={control}
            render={({ field }) => (
              <FNumber
                label={"Longitudine"}
                error={errors.longitude}
                readonly={!updateCoord}
                required={true}
                {...field}
              />
            )}
          />
        </SimpleGrid>
      </SimpleGrid>
    </fieldset>
  );
};

export default GeolocalizedAddressFields;
