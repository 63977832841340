import React from "react";
import { Box } from "@chakra-ui/react";

interface IProps {
  loading: boolean;
  error?: string;
}

// TODO: Utilizzare uno spinner per il loading?
// TODO: Visualizzare il messaggio d'errore in maniera carina?
const Loading: React.FC<IProps> = ({ children, error = "", loading }) => {
  return <Box mt={6}>{loading ? "Loading..." : error ? error : children}</Box>;
};

export default Loading;
