import React from "react";
import { Flex, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ADMIN_PATH } from "../../../config/paths";

interface IProps {
  path?: Record<string, string>;
}

const Crumbs: React.FC<IProps> = ({ path = {} }) => {
  const crumbs = Object.entries(path);
  const lastIndex = crumbs.length - 1;
  return (
    <Flex
      position={{ md: "fixed" }}
      bg="#ffffff"
      minH="2rem"
      w="100%"
      marginTop={{ md: "-3rem" }}
      boxShadow="md"
      zIndex="49"
      // borderBottom="1px"
      // borderBottomColor="brand.border"
    >
      <Breadcrumb spacing="8px" separator={">"} my={2} px={6}>
        <BreadcrumbItem key={-1} isCurrentPage={lastIndex === -1}>
          <BreadcrumbLink as={Link} to={ADMIN_PATH}>
            Dashboard
          </BreadcrumbLink>
        </BreadcrumbItem>

        {Object.entries(path).map(([label, to], index) => (
          <BreadcrumbItem key={index} isCurrentPage={index === lastIndex}>
            <BreadcrumbLink as={Link} to={to}>
              {label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Flex>
  );
};

export default Crumbs;
