import { FSelectOption } from "../../components/FormElements/types";

interface IN<T> extends Object {
  id?: T;
  name?: string;
  status?: string;
}

function toOption<T>(option: IN<T>): FSelectOption<T> {
  return {
    value: option.id!,
    label: option.name ?? "",
    isDisabled: Boolean(option.status !== undefined && option.status !== "published"),
  };
}

export default toOption;
