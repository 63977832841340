import React, { useCallback, useMemo, useRef, useState } from "react";
import itemsApi from "../../../../shared/api/itemsApi";
import {
  DataTable,
  DataTableCallbackRef,
  DefaultColumnFilter,
  DeleteButton,
  DeleteModal,
  EditButton,
  ExtLinkButton,
  FetchDataParams,
  Pagination,
} from "../../../../components/DataTable";
import { Column, useAsyncDebounce } from "react-table";
import { NEW, PP_PATH } from "../../../../shared/lib/path-utils";
import { serializeFilters } from "../../../../shared/lib/serializeFilters";
import { WithMeta } from "../../../../types/WithMeta";
import { ItemsProducts } from "../../../../generated/axios";
import { AxiosResponse } from "axios";
import { Heading, HStack, Icon } from "@chakra-ui/react";
import FlagList from "../../../../components/FlagList";
import { formatDateTimeStr } from "../../../../config/intl";
import { IFlags } from "../../../../types/IFlags";
import { FaAppleAlt } from "react-icons/fa";

interface IRecord extends IFlags {
  id: number;
  status?: string;
  name?: string;
  product_id?: {
    name: string;
  };
  price: number;
  unit_id?: {
    name: string;
  };
  production_capacity: number;
  // production_capacity_unit_id?: {
  //   name: string;
  // };
  file_url?: string;
  source_id?: {
    name: string;
  };
  platform_id?: {
    name: string;
  };
  date_created: string;
  date_updated?: string;
}

const columns: Column<IRecord>[] = [
  {
    Header: "Id",
    accessor: "id", // accessor is the "key" in the data
    Filter: DefaultColumnFilter,
    width: "85px",
  },
  {
    Header: "Nome",
    accessor: "name",
    Filter: DefaultColumnFilter,
    width: undefined,
  },
  {
    id: "product_id.name",
    Header: "Prodotto",
    accessor: (row) => row.product_id?.name,
    Filter: DefaultColumnFilter,
    //disableFilters: true,
    disableSortBy: true,
    width: undefined,
  },
  {
    Header: "Prezzo",
    accessor: (row) => (row.price !== null ? Number(row.price).toFixed(4) : null),
    Filter: DefaultColumnFilter,
    width: "120px",
  },
  {
    id: "unit_id.name",
    Header: "Unità di misura",
    accessor: (row) => row.unit_id?.name,
    Filter: DefaultColumnFilter,
    //disableFilters: true,
    disableSortBy: true,
    width: undefined,
  },
  {
    Header: "Capacità produttiva",
    accessor: "production_capacity",
    Filter: DefaultColumnFilter,
    width: undefined,
  },
  // {
  //   id: "production_capacity_unit_id.name",
  //   Header: "Unità di misura",
  //   accessor: (row) => row.production_capacity_unit_id?.name,
  //   Filter: DefaultColumnFilter,
  //   //disableFilters: true,
  //   disableSortBy: true,
  //   width: undefined,
  // },
  {
    id: "source_id.name",
    Header: "Fonte",
    accessor: (row) => row.source_id?.name,
    Filter: DefaultColumnFilter,
    //disableFilters: true,
    disableSortBy: true,
    width: undefined,
  },
  {
    id: "platform_id.name",
    Header: "Piattaforma",
    accessor: (row) => row.platform_id?.name,
    Filter: DefaultColumnFilter,
    //disableFilters: true,
    disableSortBy: true,
    width: undefined,
  },
  {
    Header: "Flags",
    accessor: (row) => row,
    disableSortBy: true,
    disableFilters: true,
    Cell: FlagList,
    width: "220px",
  },
  {
    id: "date_created",
    Header: "Data creazione",
    accessor: (row) => formatDateTimeStr(row.date_created),
    disableFilters: true,
    width: "165px",
  },
  {
    id: "date_updated",
    Header: "Data modifica",
    accessor: (row) => formatDateTimeStr(row.date_updated),
    disableFilters: true,
    width: "165px",
  },
  {
    id: "actions",
    Header: "Azioni",
    disableFilters: true,
    disableSortBy: true,
    accessor: (row) => row,
    width: "150px",
  },
];

const doDelete = (id: number) =>
  itemsApi.updateSingleItemsProductsProducersAddresses(id, ["status"], undefined, { status: "archived" });

interface IProps {
  id: number;
}

const ProducerProducts: React.FC<IProps> = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [toDelete, setToDelete] = useState<IRecord | undefined>(undefined);
  const ppPath = PP_PATH(String(id));

  const memoizedColumns = useMemo(() => {
    const actionColumn = columns.find((column) => column.id === "actions");
    if (actionColumn) {
      // @ts-ignore
      actionColumn.Cell = ({ value }: { value: IRecord }) => (
        <HStack spacing="20px">
          <EditButton id={value.id} path={ppPath} />
          {value.file_url ? (
            <ExtLinkButton url={value.file_url} target="_blank" tooltip={"Vai a URL prodotto"} />
          ) : null}
          <DeleteButton value={value} onClick={setToDelete} />
        </HStack>
      );
    }
    return columns;
  }, [ppPath, setToDelete]);

  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    numPages: 0,
    totalRecords: 0,
  });

  const fetchData = useCallback(
    ({ filters, globalFilter, pageIndex, pageSize, sortBy }: FetchDataParams<IRecord>) => {
      const sort = sortBy.map((c) => `${c.desc ? "-" : ""}${c.id}`);
      setLoading(true);
      itemsApi
        .readItemsProductsProducersAddresses(
          [
            "id",
            "status",
            "name",
            "product_id.name",
            "price",
            "unit_id.name",
            "production_capacity",
            "platform_id.name",
            // "production_capacity_unit_id.name",
            "file_url",
            "source_id.name",
            "is_biologic",
            "is_dop",
            "is_igp",
            "is_stg",
            "is_integrated_fight",
            "is_social_cooperative",
            "is_equosolidal",
            "is_msc",
            "is_aqua",
            "is_local",
            "is_pat",
            "is_halal",
            "is_social",
            "is_fao_37_27",
            "is_kosher",
            "date_created",
            "date_updated",
          ],
          pageSize,
          "filter_count",
          pageIndex * pageSize,
          sort,
          serializeFilters({ producer_id: id, status: "published" }, filters, {
            id: "_eq",
            producer_id: "_eq",
            is_biologic: "_eq",
            is_dop: "_eq",
            is_igp: "_eq",
            is_stg: "_eq",
            is_integrated_fight: "_eq",
            is_social_cooperative: "_eq",
            is_equosolidal: "_eq",
            is_msc: "_eq",
            is_aqua: "_eq",
            is_local: "_eq",
            is_pat: "_eq",
            is_halal: "_eq",
            is_social: "_eq",
            is_fao_37_27: "_eq",
            is_kosher: "_eq",
          }),
          globalFilter
        )
        .then((response: AxiosResponse<WithMeta<ItemsProducts>>) => {
          const data = response.data.data;
          const totalRecords = response.data.meta?.filter_count || 0;
          setData(data || []);
          setPagination({
            page: pageIndex,
            numPages: Math.ceil(totalRecords / pageSize),
            totalRecords,
          });
        })
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
    },
    [id, setData, setPagination, setLoading]
  );
  const fetchDataDebounced = useAsyncDebounce(fetchData, 100);

  const closeDeleteModal = useCallback(() => setToDelete(undefined), [setToDelete]);
  const ref = useRef<DataTableCallbackRef>(null);
  return (
    <>
      <Heading as="h2" size="lg" mt="8">
        <Icon as={FaAppleAlt} className="inline-icon" /> Prodotti del produttore
      </Heading>
      <DataTable
        callbacksRef={ref}
        columns={memoizedColumns}
        data={data}
        fetchData={fetchDataDebounced}
        labelButtonNew={"Nuovo Prodotto del Produttore"}
        loading={loading}
        newButtonPath={NEW(ppPath)}
        pagination={pagination}
      />
      <DeleteModal
        afterDelete={ref.current?.fetchData}
        close={closeDeleteModal}
        doDelete={doDelete}
        id={toDelete?.id || 0}
        title={"Elimina prodotto del produttore"}
      >
        Vuoi eliminare il prodotto {toDelete?.product_id?.name || ""}?
      </DeleteModal>
    </>
  );
};

export default ProducerProducts;
