import React, { useCallback, useMemo, useRef, useState } from "react";
import itemsApi from "../../../shared/api/itemsApi";
import {
  DataTable,
  DataTableCallbackRef,
  DefaultColumnFilter,
  DeleteButton,
  DeleteModal,
  EditButton,
  FetchDataParams,
  Pagination,
} from "../../../components/DataTable";
import { Column, useAsyncDebounce } from "react-table";
import { NEW, PA_PATH } from "../../../shared/lib/path-utils";
import { serializeFilters } from "../../../shared/lib/serializeFilters";
import { WithMeta } from "../../../types/WithMeta";
import { ItemsPlatforms } from "../../../generated/axios";
import { AxiosResponse } from "axios";
import { Heading, HStack, Icon } from "@chakra-ui/react";
import { formatDateTimeStr } from "../../../config/intl";
import { FaMapMarkedAlt } from "react-icons/fa";

interface IRecord {
  id: number;
  street?: string;
  civic_number?: string;
  city?: string;
  postal_code?: string;
  province_id?: {
    id: number;
    name: string;
    code: string;
  };
  latitude?: number | null;
  longitude?: number | null;
  date_created: string;
  date_updated?: string;
}

const columns: Column<IRecord>[] = [
  {
    Header: "Id",
    accessor: "id", // accessor is the "key" in the data
    Filter: DefaultColumnFilter,
    width: "85px",
  },
  {
    Header: "Via",
    accessor: "street",
    Filter: DefaultColumnFilter,
    width: undefined,
  },
  {
    Header: "N°",
    accessor: "civic_number",
    Filter: DefaultColumnFilter,
    width: undefined,
  },
  {
    Header: "CAP",
    accessor: "postal_code",
    Filter: DefaultColumnFilter,
    width: undefined,
  },
  {
    Header: "Città",
    accessor: "city",
    Filter: DefaultColumnFilter,
    width: undefined,
  },
  {
    Header: "Provincia",
    accessor: (row) => row.province_id?.code,
    disableFilters: true,
    width: undefined,
  },
  {
    Header: "Latitudine",
    accessor: "latitude",
    disableFilters: true,
    width: undefined,
  },
  {
    Header: "Longitudine",
    accessor: "longitude",
    disableFilters: true,
    width: undefined,
  },
  {
    id: "date_created",
    Header: "Data creazione",
    accessor: (row) => formatDateTimeStr(row.date_created),
    disableFilters: true,
    width: "165px",
  },
  {
    id: "date_updated",
    Header: "Data modifica",
    accessor: (row) => formatDateTimeStr(row.date_updated),
    disableFilters: true,
    width: "165px",
  },
  {
    id: "actions",
    Header: "Azioni",
    disableFilters: true,
    disableSortBy: true,
    accessor: (row) => row,
    width: "150px",
  },
];

const doDelete = (id: number) =>
  itemsApi.updateSingleItemsPlatformAddresses(id, ["status"], undefined, { status: "archived" });

interface IProps {
  id: number;
}

const PlatformAddresses: React.FC<IProps> = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [toDelete, setToDelete] = useState<IRecord | undefined>(undefined);

  const paPath = PA_PATH(String(id));

  const memoizedColumns = useMemo(() => {
    const actionColumn = columns.find((column) => column.id === "actions");
    if (actionColumn) {
      // @ts-ignore
      actionColumn.Cell = ({ value }: { value: IRecord }) => (
        <HStack spacing="20px">
          <EditButton id={value.id} path={paPath} />
          <DeleteButton value={value} onClick={setToDelete} />
        </HStack>
      );
    }
    return columns;
  }, [paPath, setToDelete]);

  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    numPages: 0,
    totalRecords: 0,
  });

  const fetchData = useCallback(
    ({ filters, globalFilter, pageIndex, pageSize, sortBy }: FetchDataParams<IRecord>) => {
      const sort = sortBy.map((c) => `${c.desc ? "-" : ""}${c.id}`);
      setLoading(true);
      itemsApi
        .readItemsPlatformAddresses(
          [
            "id",
            "street",
            "civic_number",
            "postal_code",
            "city",
            "province_id.id",
            "province_id.name",
            "province_id.code",
            "latitude",
            "longitude",
            "date_created",
            "date_updated",
          ],
          pageSize,
          "filter_count",
          pageIndex * pageSize,
          sort,
          serializeFilters({ platform_id: id, status: "published" }, filters, {
            id: "_eq",
          }),
          globalFilter
        )
        .then((response: AxiosResponse<WithMeta<ItemsPlatforms>>) => {
          const data = response.data.data;
          const totalRecords = response.data.meta?.filter_count || 0;
          setData(data || []);
          setPagination({
            page: pageIndex,
            numPages: Math.ceil(totalRecords / pageSize),
            totalRecords,
          });
        })
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
    },
    [id, setData, setPagination, setLoading]
  );
  const fetchDataDebounced = useAsyncDebounce(fetchData, 100);

  const closeDeleteModal = useCallback(() => setToDelete(undefined), [setToDelete]);

  const ref = useRef<DataTableCallbackRef>(null);

  return (
    <>
      <Heading as="h2" size="lg" mt="8">
        <Icon as={FaMapMarkedAlt} className="inline-icon" /> Indirizzi della piattaforma
      </Heading>
      <DataTable
        callbacksRef={ref}
        columns={memoizedColumns}
        data={data}
        fetchData={fetchDataDebounced}
        labelButtonNew={"Nuovo indirizzo della piattaforma"}
        loading={loading}
        newButtonPath={NEW(paPath)}
        pagination={pagination}
      />
      <DeleteModal
        afterDelete={ref.current?.fetchData}
        close={closeDeleteModal}
        doDelete={doDelete}
        id={toDelete?.id || 0}
        title={"Elimina indirizzo della piattaforma"}
      >
        Vuoi eliminare l'indirizzo {toDelete?.id || ""}?
      </DeleteModal>
    </>
  );
};

export default PlatformAddresses;
