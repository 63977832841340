import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Content, Crumbs, PageTitle } from "../Layout";
import { USERS_PATH } from "../../../config/paths";
import { EDIT } from "../../../shared/lib/path-utils";
import breadcrumbs from "./breadcrumbs";
import UserEditForm from "./UserEditForm";
import usersApi from "../../../shared/api/usersApi";
import Loading from "../../../components/Loading";
import { IUser } from "./types";
import { UsersStatusEnum } from "../../../generated/axios";
import { FaUser } from "react-icons/fa";
import { Icon } from "@chakra-ui/react";

const newUser: IUser = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  role: "",
  status: UsersStatusEnum.Active,
};

interface IRouteParams {
  id: string;
}

interface IProps {}

const User: React.FC<IProps> = () => {
  const [user, setItemUser] = useState<IUser | undefined>(undefined);

  const [error, setError] = useState("");
  const {
    params: { id },
  } = useRouteMatch<IRouteParams>();
  const isNew = id === "new";
  const title = isNew ? "Nuovo utente" : "Modifica utente";
  const crumbs = {
    ...breadcrumbs,
    [title]: EDIT(USERS_PATH, id),
  };

  useEffect(() => {
    if (id === "new") {
      setItemUser(newUser);
    } else {
      usersApi
        .getUser(id, ["first_name", "last_name", "email", "role", "status"])
        .then((response) => {
          const item = (response.data.data || {}) as IUser;
          item.password = "";
          setItemUser(item);
        })
        .catch((e) => {
          setItemUser({}); // End loading
          setError(String(e));
        });
    }
  }, [id, setItemUser, setError]);

  return (
    <>
      <Crumbs path={crumbs} />
      <Content>
        <PageTitle>
          <Icon as={FaUser} className="inline-icon" /> {title}
        </PageTitle>
        <Loading loading={!user} error={error}>
          <UserEditForm id={id} item={user!} />
        </Loading>
      </Content>
    </>
  );
};

export default User;
