import React, { useCallback, useMemo, useRef, useState } from "react";
import { Content, Crumbs, PageTitle } from "../Layout";
import usersApi from "../../../shared/api/usersApi";
import {
  DataTable,
  DataTableCallbackRef,
  DefaultColumnFilter,
  DeleteButton,
  DeleteModal,
  EditButton,
  FetchDataParams,
  Pagination,
} from "../../../components/DataTable";
import { Column, useAsyncDebounce } from "react-table";
import breadcrumbs from "./breadcrumbs";
import { USERS_PATH } from "../../../config/paths";
import { NEW } from "../../../shared/lib/path-utils";
import { serializeFilters } from "../../../shared/lib/serializeFilters";
import { WithMeta } from "../../../types/WithMeta";
import { Users as User, UsersStatusEnum } from "../../../generated/axios";
import { AxiosResponse } from "axios";
import { HStack, Icon } from "@chakra-ui/react";
import { FaUsers } from "react-icons/fa";

interface IRecord {
  id: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  role?: { name: string };
  status: UsersStatusEnum;
}

const columns: Column<IRecord>[] = [
  {
    Header: "Nome",
    accessor: "first_name",
    Filter: DefaultColumnFilter,
    width: undefined,
  },
  {
    Header: "Cognome",
    accessor: "last_name",
    Filter: DefaultColumnFilter,
    width: undefined,
  },
  {
    Header: "Email",
    accessor: "email",
    Filter: DefaultColumnFilter,
    width: undefined,
  },
  {
    Header: "Ruolo",
    accessor: (row) => row.role?.name,
    disableFilters: true,
    disableSortBy: true,
    width: undefined,
  },
  {
    Header: "Stato",
    accessor: "status",
    disableFilters: true,
    disableSortBy: true,
    width: undefined,
  },
  {
    id: "actions",
    Header: "Azioni",
    disableFilters: true,
    disableSortBy: true,
    accessor: (row) => row,
    width: "150px",
  },
];

const newButtonPath = NEW(USERS_PATH);

const doDelete = (id: string) => usersApi.updateUser(id, ["status"], undefined, { status: UsersStatusEnum.Deleted });

interface IProps {}

const Users: React.FC<IProps> = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [toDelete, setToDelete] = useState<IRecord | undefined>(undefined);

  const memoizedColumns = useMemo(() => {
    const actionColumn = columns.find((column) => column.id === "actions");
    if (actionColumn) {
      // @ts-ignore
      actionColumn.Cell = ({ value }: { value: IRecord }) => (
        <HStack spacing="20px">
          <EditButton id={value.id} path={USERS_PATH} />
          <DeleteButton value={value} onClick={setToDelete} />
        </HStack>
      );
    }
    return columns;
  }, [setToDelete]);

  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    numPages: 0,
    totalRecords: 0,
  });

  const fetchData = useCallback(
    ({ filters, globalFilter, pageIndex, pageSize, sortBy }: FetchDataParams<IRecord>) => {
      const sort = sortBy.map((c) => `${c.desc ? "-" : ""}${c.id}`);
      setLoading(true);
      usersApi
        .getUsers(
          ["id", "first_name", "last_name", "email", "role.name", "status"],
          pageSize,
          pageIndex * pageSize,
          "filter_count",
          sort,
          serializeFilters({ status: UsersStatusEnum.Active }, filters),
          globalFilter
        )
        .then((response: AxiosResponse<WithMeta<User>>) => {
          const data = response.data.data;
          const totalRecords = response.data.meta?.filter_count || 0;
          setData(data || []);
          setPagination({
            page: pageIndex,
            numPages: Math.ceil(totalRecords / pageSize),
            totalRecords,
          });
        })
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
    },
    [setData, setPagination, setLoading]
  );
  const fetchDataDebounced = useAsyncDebounce(fetchData, 100);

  const closeDeleteModal = useCallback(() => setToDelete(undefined), [setToDelete]);

  const ref = useRef<DataTableCallbackRef>(null);

  return (
    <>
      <Crumbs path={breadcrumbs} />
      <Content>
        <PageTitle>
          <Icon as={FaUsers} className="inline-icon" /> Lista utenti
        </PageTitle>
        <DataTable
          callbacksRef={ref}
          columns={memoizedColumns}
          data={data}
          fetchData={fetchDataDebounced}
          labelButtonNew={"Nuovo Utente"}
          loading={loading}
          newButtonPath={newButtonPath}
          pagination={pagination}
        />
      </Content>
      <DeleteModal
        afterDelete={ref.current?.fetchData}
        close={closeDeleteModal}
        doDelete={doDelete}
        id={toDelete?.id || ""}
        title={"Elimina utente"}
      >
        Vuoi eliminare l'utente {toDelete?.first_name || ""} {toDelete?.last_name || ""}?
      </DeleteModal>
    </>
  );
};

export default Users;
