import React from "react";
import { FieldError } from "react-hook-form";
import { AsyncPaginate } from "react-select-async-paginate";
import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { FSelectOption } from "./types";
import { LoadOptions } from "react-select-async-paginate/ts/types";
import { fSelectCustomStyle } from "./fSelectCustomStyle";
import FLabel from "./FLabel";

interface IProps {
  additional?: unknown;
  error?: FieldError;
  isMulti?: boolean;
  label: string;
  loadOptions: LoadOptions<FSelectOption<number>, unknown>;
  name: string;
  required?: boolean;
}

const FSelectAsync = React.forwardRef<any, IProps>(
  ({ additional, error, isMulti, label, loadOptions, name, required, ...rest }, ref) => {
    return (
      <FormControl mb="4" isInvalid={Boolean(error)}>
        <FLabel name={name} label={label} required={required} />
        <AsyncPaginate
          selectRef={ref}
          id={name}
          isClearable
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          // cacheOptions
          // defaultOptions
          getOptionValue={(option) => String(option.value)}
          // getOptionLabel={(option) => option.label}
          additional={additional}
          loadOptions={loadOptions}
          // components={{ NoOptionsMessage }}
          styles={fSelectCustomStyle}
          {...rest}
        />
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    );
  }
);

export default FSelectAsync;
