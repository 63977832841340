import React from "react";
import { FormLabel } from "@chakra-ui/react";

interface IProps {
  label: string;
  name: string;
  required?: boolean;
}

const FLabel: React.FC<IProps> = ({ label, name, required }) => {
  return (
    <FormLabel htmlFor={name}>
      {label}
      {required ? " (*)" : ""}
    </FormLabel>
  );
};

export default FLabel;
