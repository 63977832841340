import React from "react";
import { Divider, Heading } from "@chakra-ui/react";

interface IProps {}

const PageTitle: React.FC<IProps> = ({ children }) => {
  return (
    <>
      <Heading as="h1" size="lg" mb="4">
        {children}
      </Heading>
      <Divider mb={4} />
    </>
  );
};

export default PageTitle;
