import axios from "axios";
import { API_SERVER } from "../../config/constants";

export function downloadFile(asset: string, filename = "unknown") {
  return axios({
    url: `${API_SERVER}/assets/${asset}?download`,
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const element = document.createElement("a");
    element.href = url;
    element.setAttribute("download", filename);
    document.body.appendChild(element);
    element.click();
    element.remove();
    window.URL.revokeObjectURL(url);
  });
}
