import React from "react";
import { Icon } from "@chakra-ui/react";
import { FaSortAmountDown, FaSortAmountDownAlt } from "react-icons/all";
import { HeaderGroup } from "react-table";

interface IProps<D extends object> {
  column: HeaderGroup<D>;
}

function ColumnSortIcon<D extends object>({ column: { isSorted, isSortedDesc } }: IProps<D>) {
  return <span> {isSorted ? <Icon as={isSortedDesc ? FaSortAmountDown : FaSortAmountDownAlt} /> : null}</span>;
}

export default ColumnSortIcon;
