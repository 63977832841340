import React, { useCallback, useRef, useState } from "react";
import { Content, Crumbs, PageTitle } from "../Layout";
import itemsApi from "../../../shared/api/itemsApi";
import {
  DataTable,
  DataTableCallbackRef,
  DefaultColumnFilter,
  FetchDataParams,
  Pagination,
} from "../../../components/DataTable";
import { Column, useAsyncDebounce } from "react-table";
import breadcrumbs from "./breadcrumbs";
import { IMPORTS_PATH } from "../../../config/paths";
import { NEW } from "../../../shared/lib/path-utils";
import { serializeFilters } from "../../../shared/lib/serializeFilters";
import { WithMeta } from "../../../types/WithMeta";
import { Files, ItemsImports } from "../../../generated/axios";
import { AxiosResponse } from "axios";
import { Icon } from "@chakra-ui/react";
import { FaFileImport } from "react-icons/fa";
import { formatDateTimeStr } from "../../../config/intl";
import { DownloadFile } from "../../../components/DownloadFile";

interface IRecord {
  id: string;
  type_id?: { name: string };
  import_file?: Files;
  log_file?: Files | null;
  status?: string;
  date_created: string;
  date_updated?: string;
}

const columns: Column<IRecord>[] = [
  {
    Header: "Id",
    accessor: "id", // accessor is the "key" in the data
    Filter: DefaultColumnFilter,
    width: "85px",
  },
  {
    Header: "Tipo",
    id: "type_id.name",
    Filter: DefaultColumnFilter,
    width: undefined,
    accessor: (row) => row.type_id?.name,
  },
  {
    Header: "Import file",
    accessor: "import_file",
    disableFilters: true,
    disableSortBy: true,
    width: undefined,
    Cell: ({ value }) => {
      const asset = (value as Files)?.filename_disk;
      const title = (value as Files)?.title;
      return asset ? <DownloadFile asset={asset} filename={asset} title={title} /> : "";
    },
  },
  {
    Header: "Log file",
    accessor: "log_file",
    disableFilters: true,
    disableSortBy: true,
    width: undefined,
    Cell: ({ value }) => {
      const asset = (value as Files)?.filename_disk;
      return asset ? <DownloadFile asset={asset} filename={asset} /> : "";
    },
  },
  {
    Header: "Stato",
    accessor: "status",
    disableFilters: true,
    disableSortBy: true,
    width: undefined,
  },
  {
    id: "date_created",
    Header: "Data creazione",
    accessor: (row) => formatDateTimeStr(row.date_created),
    disableFilters: true,
    width: "165px",
  },
  {
    id: "date_updated",
    Header: "Data modifica",
    accessor: (row) => formatDateTimeStr(row.date_updated),
    disableFilters: true,
    width: "165px",
  },
];

const newButtonPath = NEW(IMPORTS_PATH);

interface IProps {}

const Imports: React.FC<IProps> = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);

  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    numPages: 0,
    totalRecords: 0,
  });

  const fetchData = useCallback(
    ({ filters, globalFilter, pageIndex, pageSize, sortBy }: FetchDataParams<IRecord>) => {
      const sort = sortBy.map((c) => `${c.desc ? "-" : ""}${c.id}`);
      setLoading(true);
      itemsApi
        .readItemsImports(
          ["id", "type_id.name", "log_file.*", "import_file.*", "date_created", "date_updated", "status"],
          pageSize,
          "filter_count",
          pageIndex * pageSize,
          sort,
          serializeFilters({}, filters),
          globalFilter
        )
        .then((response: AxiosResponse<WithMeta<ItemsImports>>) => {
          const dataRecords = response.data.data;
          const totalRecords = response.data.meta?.filter_count || 0;
          setData(dataRecords || []);
          setPagination({
            page: pageIndex,
            numPages: Math.ceil(totalRecords / pageSize),
            totalRecords,
          });
        })
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
    },
    [setData, setPagination, setLoading]
  );
  const fetchDataDebounced = useAsyncDebounce(fetchData, 100);

  const ref = useRef<DataTableCallbackRef>(null);

  return (
    <>
      <Crumbs path={breadcrumbs} />
      <Content>
        <PageTitle>
          <Icon as={FaFileImport} className="inline-icon" /> Lista import
        </PageTitle>
        <DataTable
          callbacksRef={ref}
          columns={columns}
          data={data}
          fetchData={fetchDataDebounced}
          labelButtonNew={"Nuovo Import"}
          loading={loading}
          newButtonPath={newButtonPath}
          refreshButton={true}
          pagination={pagination}
        />
      </Content>
    </>
  );
};

export default Imports;
