import React from "react";
import { FieldError } from "react-hook-form";
import { FormControl, FormErrorMessage, Textarea } from "@chakra-ui/react";
import FLabel from "./FLabel";

interface IProps {
  error?: FieldError;
  label: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  value?: null | string;
}

const FTextarea = React.forwardRef<HTMLTextAreaElement, IProps>(
  ({ label, name, error, placeholder, required, value, ...rest }, ref) => {
    return (
      <FormControl mb="4" isInvalid={Boolean(error)}>
        <FLabel name={name} label={label} required={required} />
        <Textarea
          ref={ref}
          id={name}
          resize="vertical"
          isInvalid={Boolean(error)}
          placeholder={placeholder}
          value={value === null ? "" : value}
          {...rest}
        />
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    );
  }
);

export default FTextarea;
