import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { EDIT, PA_PATH } from "../shared/lib/path-utils";
import { AddEditLink } from "./AddEditLink";

interface IProps {
  id: number;
  platform: {
    id: number;
    name: string;
  };
}

const PlatformContact: React.FC<IProps> = ({ id, platform }) => {
  if (!platform) return null;
  return (
    <Box>
      <Text>
        <AddEditLink text={<strong>{platform.name}</strong>} path={EDIT(PA_PATH(String(platform.id)), String(id))} />
      </Text>
    </Box>
  );
};

export default PlatformContact;
