import * as React from "react";
import { FSelectOption } from "../components/FormElements/types";

export interface ILoggedUserState {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
}

export interface IProvincesWithRegion {
  id: number;
  name: string;
  region_id: {
    id: number;
    name: string;
  };
}

export interface IAdminContext {
  actions: {
    init: () => void;
  };
  certifications: FSelectOption<number>[];
  loggedUser: ILoggedUserState;
  provinces: FSelectOption<number>[];
  provincesWithRegion: IProvincesWithRegion[];
  regions: FSelectOption<number>[];
  sources: FSelectOption<number>[];
  units: FSelectOption<number>[];
}

export const initialLoggedUserState: ILoggedUserState = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  role: "",
};

const AdminContext = React.createContext<IAdminContext>({
  actions: {
    init: () => {},
  },
  certifications: [],
  loggedUser: initialLoggedUserState,
  provinces: [],
  provincesWithRegion: [],
  regions: [],
  sources: [],
  units: [],
});

export default AdminContext;
