import React from "react";
import { Content, Crumbs, PageTitle } from "../Layout";
import { EDIT } from "../../../shared/lib/path-utils";
import { USERS_PATH } from "../../../config/paths";
import breadcrumbs from "./breadcrumbs";
import Loading from "../../../components/Loading";
import ImportNewForm from "./ImportNewForm";
import { FaFileImport } from "react-icons/fa";
import { Icon } from "@chakra-ui/react";

interface IProps {}

const Import: React.FC<IProps> = () => {
  const title = "Nuovo import";
  const crumbs = {
    ...breadcrumbs,
    [title]: EDIT(USERS_PATH, "new"),
  };

  return (
    <>
      <Crumbs path={crumbs} />
      <Content>
        <PageTitle>
          <Icon as={FaFileImport} className="inline-icon" /> {title}
        </PageTitle>
        <Loading loading={false} error={""}>
          <ImportNewForm />
        </Loading>
      </Content>
    </>
  );
};

export default Import;
