import React, { useContext, useEffect } from "react";
import { useStateBoolean } from "../../../../shared/hooks/useStateBoolean";
import { useHistory } from "react-router-dom";
import { Button, HStack, SimpleGrid, useToast } from "@chakra-ui/react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FInput from "../../../../components/FormElements/FInput";
import FSaveButton from "../../../../components/FormElements/FSaveButton";
import FSaveAndExitButton from "../../../../components/FormElements/FSaveAndExitButton";
import { IProducerProduct } from "./types";
import {
  INTEGER_MGS,
  INVALID_URL_MSG,
  MIN_MSG,
  NUMBER_MGS,
  REQUIRED_MGS,
} from "../../../../components/FormElements/validationMessages";
import itemsApi, { loadProducts } from "../../../../shared/api/itemsApi";
import { PRODUCERS_PATH } from "../../../../config/paths";
import { toastFailure, toastSuccess } from "../../../../shared/lib/toast";
import FSelectAsync from "../../../../components/FormElements/FSelectAsync";
import FNumber from "../../../../components/FormElements/FNumber";
import AdminContext from "../../../../context/AdminContext";
import FSelectS from "../../../../components/FormElements/FSelectS";
import { toId } from "../../../../shared/lib/id-utils";
import { ItemsProductsProducersAddresses } from "../../../../generated/axios";
import { emptyToNull, URL_REGEX, validSelectedOption } from "../../../../shared/lib/validation-utils";
import { EDIT, PP_PATH } from "../../../../shared/lib/path-utils";
import ProductFlags from "../../../../components/ProductFlags";
import DeletedAlert from "../../../../components/DeletedAlert";
import { useProducerPlatformOptions } from "../../../../shared/hooks/useProducerPlatformOptions";

const schema = yup.object().shape({
  name: yup.string().required(REQUIRED_MGS),
  product_id: yup
    .object()
    .typeError(REQUIRED_MGS)
    .required(REQUIRED_MGS)
    .test("product_id_required", REQUIRED_MGS, validSelectedOption),

  source_id: yup.object().nullable(),
  platform_id: yup.object().nullable(),
  price: yup.number().transform(emptyToNull).nullable().typeError(NUMBER_MGS).min(0, MIN_MSG),
  unit_id: yup.object().nullable(),
  production_capacity: yup
    .number()
    .transform(emptyToNull)
    .nullable()
    .typeError(NUMBER_MGS)
    .integer(INTEGER_MGS)
    .min(0, MIN_MSG),
  production_capacity_unit_id: yup.object().nullable(),
  file_url: yup.string().nullable().matches(URL_REGEX, INVALID_URL_MSG),

  is_biologic: yup.boolean(),
  is_dop: yup.boolean(),
  is_igp: yup.boolean(),
  is_stg: yup.boolean(),
  is_integrated_fight: yup.boolean(),
  is_social_cooperative: yup.boolean(),
  is_equosolidal: yup.boolean(),
  is_msc: yup.boolean(),
  is_aqua: yup.boolean(),
  is_local: yup.boolean(),
  is_pat: yup.boolean(),
  is_halal: yup.boolean(),
  is_social: yup.boolean(),
  is_fao_37_27: yup.boolean(),
  is_kosher: yup.boolean(),
});

// Need for resetting nesting object!
const resetValue = (item: IProducerProduct) => {
  return { ...item };
};

interface IProps {
  id: number;
  id_pp: string;
  item: IProducerProduct;
}

const ProducerProductEditForm: React.FC<IProps> = ({ id, id_pp, item }) => {
  const [exit, setExit] = useStateBoolean(false);
  const toast = useToast();
  const history = useHistory();
  const { sources, units } = useContext(AdminContext);
  const [platforms, isLoadingPlatforms] = useProducerPlatformOptions(id);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<IProducerProduct>({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  const address_id = item.address_id;

  const onSubmit = (values: IProducerProduct) => {
    const item: ItemsProductsProducersAddresses = {
      producer_id: id,
      address_id,
      status: values.status,
      name: values.name,
      product_id: values.product_id ? toId(values.product_id) : undefined,
      price: values.price !== null ? Number(values.price) : values.price,
      unit_id: values.unit_id ? toId(values.unit_id) : undefined,
      production_capacity: values.production_capacity,
      production_capacity_unit_id: values.production_capacity_unit_id
        ? toId(values.production_capacity_unit_id)
        : undefined,
      source_id: values.source_id ? toId(values.source_id) : undefined,
      platform_id: values.platform_id ? toId(values.platform_id) : undefined,
      note: values.note,
      file_url: values.file_url,
      is_biologic: values.is_biologic,
      is_dop: values.is_dop,
      is_igp: values.is_igp,
      is_stg: values.is_stg,
      is_integrated_fight: values.is_integrated_fight,
      is_social_cooperative: values.is_social_cooperative,
      is_equosolidal: values.is_equosolidal,
      is_msc: values.is_msc,
      is_aqua: values.is_aqua,
      is_local: values.is_local,
      is_pat: values.is_pat,
      is_halal: values.is_halal,
      is_social: values.is_social,
      is_fao_37_27: values.is_fao_37_27,
      is_kosher: values.is_kosher,
    };
    const apiCall =
      id_pp === "new"
        ? itemsApi.createItemsProductsProducersAddresses(undefined, item)
        : itemsApi.updateSingleItemsProductsProducersAddresses(
            Number(id_pp),
            [
              "address_id",
              "name",
              "product_id",
              "price",
              "unit_id",
              "production_capacity",
              "production_capacity_unit_id",
              "source_id",
              "platform_id",
              "note",
              "file_url",
              "is_biologic",
              "is_dop",
              "is_igp",
              "is_stg",
              "is_integrated_fight",
              "is_social_cooperative",
              "is_equosolidal",
              "is_msc",
              "is_aqua",
              "is_local",
              "is_pat",
              "is_halal",
              "is_social",
              "is_fao_37_27",
              "is_kosher",
            ],
            undefined,
            item
          );
    return apiCall
      .then((response) => {
        toast(toastSuccess("Prodotto del produttore salvato con successo"));
        if (exit) history.push(EDIT(PRODUCERS_PATH, String(id)));
        else if (id_pp === "new") {
          const responseId = (response.data.data as unknown as ItemsProductsProducersAddresses)?.id;
          responseId && history.push(EDIT(PP_PATH(String(id)), String(responseId)));
        }
      })
      .catch((e: any) => {
        toast(toastFailure("Errore nel salvataggio del prodotto del produttore", String(e)));
      });
  };

  const [note, product] = watch(["note", "product_id"]);

  useEffect(() => {
    const name = `${product?.label ?? ""} - ${note ?? ""}`;
    setValue("name", name);
  }, [note, product, setValue]);

  return (
    <>
      <DeletedAlert status={item.status}>Prodotto del produttore cancellato!</DeletedAlert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={"product_id"}
          control={control}
          render={({ field }) => (
            <FSelectAsync
              {...field}
              label={"Prodotto"}
              error={errors.product_id as FieldError | undefined}
              loadOptions={loadProducts}
              required={true}
            />
          )}
        />

        <Controller
          name={"note"}
          control={control}
          render={({ field }) => <FInput label={"Note"} error={errors.note} {...field} />}
        />

        <Controller
          name={"file_url"}
          control={control}
          render={({ field }) => <FInput label={"File URL"} error={errors.file_url} {...field} />}
        />

        <SimpleGrid columns={[1, null, 2, null, 4]} spacing={"8px"}>
          <Controller
            name={"price"}
            control={control}
            render={({ field }) => <FNumber label={"Prezzo"} precision={4} error={errors.price} {...field} />}
          />
          <Controller
            name={`unit_id`}
            control={control}
            render={({ field }) => (
              <FSelectS
                {...field}
                label={"Unità di misura"}
                error={errors.unit_id as FieldError | undefined}
                options={units}
              />
            )}
          />
          <Controller
            name={"production_capacity"}
            control={control}
            render={({ field }) => (
              <FNumber
                label={"Capacità produttiva (settimanale)"}
                precision={0}
                error={errors.production_capacity}
                {...field}
              />
            )}
          />
          <Controller
            name={`production_capacity_unit_id`}
            control={control}
            render={({ field }) => (
              <FSelectS
                {...field}
                label={"Unità di misura"}
                error={errors.production_capacity_unit_id as FieldError | undefined}
                options={units}
              />
            )}
          />
        </SimpleGrid>

        <SimpleGrid columns={[1, null, 2]} spacing={"8px"}>
          <Controller
            name={`source_id`}
            control={control}
            render={({ field }) => (
              <FSelectS
                {...field}
                label={"Fonte"}
                error={errors.source_id as FieldError | undefined}
                options={sources}
              />
            )}
          />

          <Controller
            name={"platform_id"}
            control={control}
            render={({ field }) => (
              <FSelectS
                {...field}
                label={"Piattaforma"}
                error={errors.platform_id as FieldError | undefined}
                isLoading={isLoadingPlatforms}
                options={platforms}
              />
            )}
          />
        </SimpleGrid>

        <SimpleGrid columns={[2, null, 4]} spacing={"8px"} mb={4}>
          <ProductFlags control={control} errors={errors} />
        </SimpleGrid>

        <SimpleGrid columns={1} spacing={"8px"}>
          <Controller
            name={"name"}
            control={control}
            render={({ field }) => (
              <FInput label={"Nome"} error={errors.name} readonly={true} required={true} {...field} />
            )}
          />
        </SimpleGrid>

        <HStack mt="6">
          <Button onClick={() => reset(resetValue(item))}>Reset</Button>
          <FSaveButton disabled={isSubmitting && exit} isLoading={isSubmitting && !exit} />
          <FSaveAndExitButton disabled={isSubmitting && !exit} isLoading={isSubmitting && exit} onClick={setExit} />
        </HStack>
      </form>
    </>
  );
};

export default ProducerProductEditForm;
