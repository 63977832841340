import React from "react";
import { Button, Icon, Tooltip } from "@chakra-ui/react";
import { FaEdit } from "react-icons/all";
import { Link } from "react-router-dom";
import { EDIT } from "../../shared/lib/path-utils";

interface IProps<T> {
  id: T;
  path: string;
}

const EditButton: React.FC<IProps<number | string>> = ({ id, path }) => {
  return (
    <Tooltip label="Modifica" aria-label="Modifica">
      <Button as={Link} to={EDIT(path, String(id))} variant="edit">
        <Icon as={FaEdit} />
      </Button>
    </Tooltip>
  );
};

export default EditButton;
