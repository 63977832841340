import { ISearchResult, MarkerType, SearchType } from "../../containers/Admin/Search/types";
import { insideFunctionFactory } from "./insideFunctionFactory";
import { IGoogleCoords } from "../../types/ICoords";

export interface ISvgMarkerIcon {
  fillColor: string;
  fillOpacity: number;
  // labelOrigin?: google.maps.Point;
  path: string;
  scale: number;
  strokeColor: string;
  strokeWeight: number;
  tip: string;
}

const markerIcon = (color: string, tip: string) => ({
  fillColor: color,
  fillOpacity: 1,
  // path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0", // con cerchietto
  path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z", // senza cerchietto
  // path: "M 0,0 -1,-2 V -43 H 1 V -2 z M 1,-40 H 30 V -20 H 1 z", // bandierina
  scale: 1,
  strokeColor: "#000",
  strokeWeight: 1,
  tip,
});

export const markers: Record<string, ISvgMarkerIcon> = {
  a: markerIcon("deeppink", "Consegna diretta"),
  b: markerIcon("darkviolet", "Almeno 1 indirizzo di spedizione dentro il raggio di ricerca"),
  c: markerIcon("yellow", "Tutti gli indirizzi di spedizione fuori dal raggio di ricerca"),
  d: markerIcon("red", "Senza raggio di ricerca"),
  e: markerIcon("DeepSkyBlue", "Indirizzo piattaforma"),
  // labelOrigin: new google.maps.Point(0, 6), // Sotto al pin
  // labelOrigin: new google.maps.Point(0, -27),
};

export const getMarkerType = (
  record: ISearchResult,
  searchType: SearchType,
  center?: IGoogleCoords,
  km?: number
): MarkerType => {
  if (center !== undefined && km !== undefined) {
    if (!record.platform_id) return "a"; // Prodotto con consegna diretta
    const addresses = record.platform_id.platform_addresses?.filter((a) => a.status === "published") || [];
    const inside = insideFunctionFactory(center, km);
    for (let i = 0; i < addresses.length; i++) {
      if (inside(addresses[i])) return "b"; // Almeno un indirizzo dentro il raggio di ricerca
    }
    return "c"; // Tutti gli indirizzi fuori dal raggio di ricerca
  } else {
    return "d"; // Senza raggio di ricerca
  }
};
