import axios from "axios";
import { GOOGLE_MAPS_API_KEY } from "../../config/constants";
import { IGoogleCoords } from "../../types/ICoords";
import { FSelectOption } from "../../components/FormElements/types";

interface IResult extends IGoogleCoords {
  type: string;
}

interface BasicAddress {
  street?: string;
  civic_number?: string | null;
  city?: string;
  province_id?: null | FSelectOption<number>;
  postal_code?: string;
}

export const composeAddressString = (address: BasicAddress, provinces: FSelectOption<number>[]) => {
  const street = address?.street || "";
  const civic_number = address?.civic_number || "";
  const city = address?.city || "";
  const postal_code = address?.postal_code || "";
  const province = provinces.find((p) => p.value === address?.province_id?.value);
  let addressStr = `${postal_code} ${street} ${civic_number}, ${city}, ${province?.label || ""}`;
  return addressStr.replace(/^[ ,]+|[ ,]+$/g, "");
};

export const geolocalize = (address: string): Promise<IResult> => {
  const params = new URLSearchParams({ address, key: GOOGLE_MAPS_API_KEY });
  const url = `https://maps.googleapis.com/maps/api/geocode/json?${params.toString()}`;
  return axios.get(url).then((response) => {
    const { results, status } = response.data;
    const result = Array.isArray(results) ? results[0] : results;
    if (status === "OK" && result?.geometry?.location) {
      const { location, location_type } = result.geometry;
      return {
        ...location,
        type: location_type,
        resolvedAddress: result.formatted_address,
      };
    } else throw new Error(status);
  });
};
