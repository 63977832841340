import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { SimpleDataTable } from "../../../components/DataTable";
import { Column } from "react-table";
import { ISearchResult, ISetDataSearchResults } from "./types";
import { valueWithUnit } from "../../../shared/lib/valueWithUnit";
import Address from "../../../components/Address";
import FlagList from "../../../components/FlagList";
import ProducerContact from "../../../components/ProducerContact";
import { AddEditLink } from "../../../components/AddEditLink";
import { EDIT, PP_PATH } from "../../../shared/lib/path-utils";
import CountResults from "./CountResults";

const shippingColumns: Column<ISearchResult>[] = [
  {
    Header: "Produttore",
    accessor: (row) => <ProducerContact producer={row.producer_id} />,
    disableSortBy: true,
    disableFilters: true,
    width: undefined,
  },
  {
    Header: "Indirizzo produzione",
    accessor: (row) => <Address address={row.address_id} />,
    disableSortBy: true,
    disableFilters: true,
    width: undefined,
  },
  {
    id: "product_id.name",
    Header: "Prodotto",
    accessor: (row) => row.product_id?.name,
    disableSortBy: true,
    disableFilters: true,
    width: undefined,
  },
  {
    id: "product_id.category_id.name",
    Header: "Categoria Prodotto",
    accessor: (row) => row.product_id?.category_id?.name,
    disableSortBy: true,
    disableFilters: true,
    width: undefined,
  },
  {
    Header: "Nome",
    accessor: (row) => row,
    disableSortBy: true,
    disableFilters: true,
    width: undefined,
    Cell: ({ value }: { value: ISearchResult }) => (
      <AddEditLink text={value.name} path={EDIT(PP_PATH(String(value.producer_id?.id)), String(value.id))} />
    ),
  },
  {
    Header: "Prezzo",
    accessor: (row) =>
      valueWithUnit(
        row.price !== null && row.price !== undefined && row.price !== 0 ? row.price.toFixed(4) : undefined,
        row.unit_id?.name
      ),
    disableSortBy: true,
    disableFilters: true,
    width: undefined,
  },
  {
    Header: "Capacità produttiva",
    accessor: (row) =>
      valueWithUnit(
        row.production_capacity === 0 ? undefined : row.production_capacity,
        row.production_capacity_unit_id?.name
      ),
    disableSortBy: true,
    disableFilters: true,
    width: undefined,
  },
  {
    Header: "Piattaforma",
    accessor: (row) => row.platform_id?.name,
    disableSortBy: true,
    disableFilters: true,
    width: undefined,
  },
  {
    Header: "Indirizzo piattaforma",
    accessor: (row) =>
      row.platform_id?.platform_addresses?.[0] ? <Address address={row.platform_id.platform_addresses[0]} /> : null,
    disableSortBy: true,
    disableFilters: true,
    width: undefined,
  },
  {
    Header: "Fonte",
    accessor: (row) => row.source_id?.name,
    disableSortBy: true,
    disableFilters: true,
    width: undefined,
  },
  {
    Header: "Flags",
    accessor: (row) => row,
    disableSortBy: true,
    disableFilters: true,
    Cell: FlagList,
    width: "220px",
  },
];
const standardColumns: Column<ISearchResult>[] = shippingColumns.filter((c) => c.Header !== "Indirizzo piattaforma");

interface IProps {
  data?: ISetDataSearchResults;
}

const SearchResults: React.FC<IProps> = ({ data }) => {
  if (data === undefined) return null;
  const { results, numProducers, numProducts, searchType } = data;
  if (results.length === 0) {
    return (
      <Box mt={8}>
        <Text>Nessun prodotto trovato!</Text>
      </Box>
    );
  } else {
    const Counter = <CountResults producers={numProducers} products={numProducts} />;
    return (
      <Box mt={8}>
        {Counter}
        <SimpleDataTable columns={searchType === "shipping" ? shippingColumns : standardColumns} data={results} />
        {Counter}
      </Box>
    );
  }
};

export default SearchResults;
