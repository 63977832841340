import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Content, Crumbs, PageTitle } from "../../Layout";
import { PRODUCERS_PATH } from "../../../../config/paths";
import { EDIT, PP_PATH } from "../../../../shared/lib/path-utils";
import breadcrumbs from "../breadcrumbs";
import ProducerProductEditForm from "./ProducerProductEditForm";
import itemsApi, { getProducerProductionAddress } from "../../../../shared/api/itemsApi";
import Loading from "../../../../components/Loading";
import { IProducerInfo, IProducerProduct } from "./types";
import {
  ItemsPlatforms,
  ItemsProducers,
  ItemsProducts,
  ItemsProductsProducersAddresses,
  ItemsSources,
  ItemsUnits,
} from "../../../../generated/axios";
import toOption from "../../../../shared/lib/toOption";
import Address from "../../../../components/Address";
import { IBasicAddress } from "../../../../types/IBasicAddress";
import { FaAppleAlt } from "react-icons/fa";
import { Icon } from "@chakra-ui/react";

const newItem: IProducerProduct = {
  status: "published",
  name: "",
  note: "",
  file_url: "",
  is_biologic: false,
  is_dop: false,
  is_igp: false,
  is_stg: false,
  is_integrated_fight: false,
  is_social_cooperative: false,
  is_equosolidal: false,
  is_msc: false,
  is_aqua: false,
  is_local: false,
  is_pat: false,
  is_halal: false,
  is_social: false,
  is_fao_37_27: false,
  is_kosher: false,
};

interface IRouteParams {
  id: string;
  id_pp: string;
}

interface IProps {}

const ProducerProduct: React.FC<IProps> = () => {
  const [item, setItem] = useState<IProducerProduct | undefined>(undefined);
  const [producerInfo, setProducerInfo] = useState<IProducerInfo | undefined>(undefined);

  const [error, setError] = useState("");
  const {
    params: { id, id_pp },
  } = useRouteMatch<IRouteParams>();
  const isNew = id_pp === "new";
  const title = isNew ? "Nuovo prodotto del produttore" : "Modifica prodotto del produttore";
  const crumbs = {
    ...breadcrumbs,
    "Modifica produttore": EDIT(PRODUCERS_PATH, id),
    [title]: EDIT(PP_PATH(id), id_pp),
  };

  useEffect(() => {
    if (id_pp === "new") {
      getProducerProductionAddress(Number(id))
        .then((response) => {
          setItem({ ...newItem, address_id: response.address.id });
          setProducerInfo(response);
        })
        .catch((e) => {
          setItem(newItem); // End loading
          setError(String(e));
        });
    } else {
      itemsApi
        .readSingleItemsProductsProducersAddresses(Number(id_pp), [
          "status",
          "producer_id.name",
          "address_id.id",
          "address_id.street",
          "address_id.civic_number",
          "address_id.postal_code",
          "address_id.city",
          "address_id.province_id.id",
          "address_id.province_id.name",
          "address_id.province_id.code",
          "name",
          "product_id.id",
          "product_id.name",
          "price",
          "unit_id.id",
          "unit_id.name",
          "production_capacity",
          "production_capacity_unit_id.id",
          "production_capacity_unit_id.name",
          "source_id.id",
          "source_id.name",
          "platform_id.id",
          "platform_id.name",
          "note",
          "file_url",
          "is_biologic",
          "is_dop",
          "is_igp",
          "is_stg",
          "is_integrated_fight",
          "is_social_cooperative",
          "is_equosolidal",
          "is_msc",
          "is_aqua",
          "is_local",
          "is_pat",
          "is_halal",
          "is_social",
          "is_fao_37_27",
          "is_kosher",
        ])
        .then((response) => {
          const data = response.data.data! as unknown as ItemsProductsProducersAddresses;
          if (!data.product_id) throw new Error("Prodotto del produttore senza riferimento ad un prodotto!");
          const address = data.address_id as IBasicAddress;
          const producer = data.producer_id as ItemsProducers;
          const item: IProducerProduct = {
            id: Number(id_pp),
            status: data.status,
            address_id: address?.id as number | undefined,
            name: data.name,
            product_id: toOption(data.product_id as ItemsProducts),
            price: data.price !== null ? data.price?.toFixed(4) : null,
            unit_id: data.unit_id ? toOption(data.unit_id as ItemsUnits) : null,
            production_capacity: data.production_capacity,
            production_capacity_unit_id: data.production_capacity_unit_id
              ? toOption(data.production_capacity_unit_id as ItemsUnits)
              : null,
            source_id: data.source_id ? toOption(data.source_id as ItemsSources) : null,
            platform_id: data.platform_id ? toOption(data.platform_id as ItemsPlatforms) : null,
            note: data.note,
            file_url: data.file_url,
            is_biologic: data.is_biologic,
            is_dop: data.is_dop,
            is_igp: data.is_igp,
            is_stg: data.is_stg,
            is_integrated_fight: data.is_integrated_fight,
            is_social_cooperative: data.is_social_cooperative,
            is_equosolidal: data.is_equosolidal,
            is_msc: data.is_msc,
            is_aqua: data.is_aqua,
            is_local: data.is_local,
            is_pat: data.is_pat,
            is_halal: data.is_halal,
            is_social: data.is_social,
            is_fao_37_27: data.is_fao_37_27,
            is_kosher: data.is_kosher,
          };
          setItem(item);
          setProducerInfo({ name: producer?.name || "", address });
        })
        .catch((e) => {
          setItem(newItem); // End loading
          setError(String(e));
        });
    }
  }, [id, id_pp, setItem, setError]);

  return (
    <>
      <Crumbs path={crumbs} />
      <Content>
        <PageTitle>
          <Icon as={FaAppleAlt} className="inline-icon" /> {title} {producerInfo?.name}
        </PageTitle>
        <Address address={producerInfo?.address} />
        <Loading loading={!item} error={error}>
          <ProducerProductEditForm id={Number(id)} id_pp={id_pp} item={item!} />
        </Loading>
      </Content>
    </>
  );
};

export default ProducerProduct;
