import React from "react";
import { Route, Switch } from "react-router-dom";
import { IMPORTS_PATH } from "../../../config/paths";
import Import from "./Import";
import Imports from "./Imports";

interface IProps {}

const ImportsRoutes: React.FC<IProps> = () => {
  return (
    <Switch>
      <Route path={`${IMPORTS_PATH}/new`} exact>
        <Import />
      </Route>
      <Route path={IMPORTS_PATH} exact>
        <Imports />
      </Route>
    </Switch>
  );
};

export default ImportsRoutes;
