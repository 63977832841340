import React from "react";
import { Control } from "react-hook-form/dist/types/form";
import { FieldError } from "react-hook-form/dist/types/errors";
import { IFlags } from "../types/IFlags";
import { Controller, DeepMap } from "react-hook-form";
import FCheckbox from "./FormElements/FCheckbox";

interface IProps<T extends IFlags> {
  control: Control<T>;
  errors: DeepMap<T, FieldError>;
}

function ProductFlags<T extends IFlags>(props: IProps<T>) {
  const { control, errors } = props as unknown as IProps<IFlags>;
  return (
    <>
      <Controller
        name={"is_biologic"}
        control={control}
        render={({ field }) => <FCheckbox label={"Biologico"} error={errors.is_biologic} {...field} />}
      />
      <Controller
        name={"is_dop"}
        control={control}
        render={({ field }) => <FCheckbox label={"DOP"} error={errors.is_dop} {...field} />}
      />
      <Controller
        name={"is_igp"}
        control={control}
        render={({ field }) => <FCheckbox label={"IGP"} error={errors.is_igp} {...field} />}
      />
      <Controller
        name={"is_stg"}
        control={control}
        render={({ field }) => <FCheckbox label={"STG"} error={errors.is_stg} {...field} />}
      />
      <Controller
        name={"is_integrated_fight"}
        control={control}
        render={({ field }) => <FCheckbox label={"Lotta integrata"} error={errors.is_integrated_fight} {...field} />}
      />
      <Controller
        name={"is_social_cooperative"}
        control={control}
        render={({ field }) => (
          <FCheckbox label={"Cooperativa sociale"} error={errors.is_social_cooperative} {...field} />
        )}
      />
      <Controller
        name={"is_equosolidal"}
        control={control}
        render={({ field }) => <FCheckbox label={"Equosolidale"} error={errors.is_equosolidal} {...field} />}
      />
      <Controller
        name={"is_msc"}
        control={control}
        render={({ field }) => <FCheckbox label={"MSC"} error={errors.is_msc} {...field} />}
      />
      <Controller
        name={"is_aqua"}
        control={control}
        render={({ field }) => <FCheckbox label={"AQUA"} error={errors.is_aqua} {...field} />}
      />
      <Controller
        name={"is_local"}
        control={control}
        render={({ field }) => <FCheckbox label={"Prod. Locale"} error={errors.is_local} {...field} />}
      />
      <Controller
        name={"is_pat"}
        control={control}
        render={({ field }) => <FCheckbox label={"PAT"} error={errors.is_pat} {...field} />}
      />
      <Controller
        name={"is_halal"}
        control={control}
        render={({ field }) => <FCheckbox label={"Halal"} error={errors.is_halal} {...field} />}
      />
      <Controller
        name={"is_social"}
        control={control}
        render={({ field }) => <FCheckbox label={"Sociale"} error={errors.is_social} {...field} />}
      />
      <Controller
        name={"is_fao_37_27"}
        control={control}
        render={({ field }) => <FCheckbox label={"FAO 37/27"} error={errors.is_fao_37_27} {...field} />}
      />
      <Controller
        name={"is_kosher"}
        control={control}
        render={({ field }) => <FCheckbox label={"Kosher"} error={errors.is_kosher} {...field} />}
      />
    </>
  );
}

export default ProductFlags;
