import React from "react";
import { Stack, Text } from "@chakra-ui/react";

const LoginFooter: React.FC = () => {
  const year = new Date().getFullYear();
  return (
    <Stack isInline marginTop="1rem" fontWeight="500" fontSize="sm">
      <Text color="secondary.link">&copy; {year} Dussmann &mdash; All rights reserved</Text>
    </Stack>
  );
};

export default LoginFooter;
