import * as yup from "yup";

export const REQUIRED_MGS = "Campo obbligatorio";
export const INAVALID_EMAIL_MSG = "Email non valida";
export const INVALID_WEBSITE_MSG = "Indirizzo web non valido";
export const INVALID_URL_MSG = "URL non valida";
export const NUMBER_MGS = "Deve essere un numero";
export const INTEGER_MGS = "Sono ammessi solo numeri interi";
// eslint-disable-next-line no-template-curly-in-string
export const MIN_MSG = "Valore minimo ${min}";
// eslint-disable-next-line no-template-curly-in-string
export const MAX_MSG = "Valore massimo ${max}";
// eslint-disable-next-line no-template-curly-in-string
export const MIN_LENGTH_MSG = "Minimo ${min} caratteri";
// eslint-disable-next-line no-template-curly-in-string
export const MAX_LENGTH_MSG = "Massimo ${max} caratteri";

yup.setLocale({
  mixed: {
    notType: REQUIRED_MGS,
    required: REQUIRED_MGS,
  },
  string: {
    email: INAVALID_EMAIL_MSG,
    min: MIN_LENGTH_MSG,
    max: MAX_LENGTH_MSG,
  },
  number: {
    notType: NUMBER_MGS,
    min: MIN_MSG,
    max: MAX_MSG,
  },
});

export default yup;

export const present = (value?: any) => Boolean(value) || value === 0;
export const empty = (value?: any) => !present(value);
