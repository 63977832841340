import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { PRODUCTS_PATH } from "../../../config/paths";
import Products from "./Products";
import Product from "./Product";

interface IProps {}

const ProductsRoutes: React.FC<IProps> = () => {
  const { pathname } = useLocation();
  return (
    <Switch>
      <Route path={PRODUCTS_PATH} exact>
        <Products />
      </Route>
      <Route path={`${PRODUCTS_PATH}/:id(new|\\d+)`} exact>
        <Product key={pathname} />
      </Route>
    </Switch>
  );
};

export default ProductsRoutes;
