import { extendTheme } from "@chakra-ui/react";

const radii = {
  none: "0",
  sm: "0",
  base: "0",
  md: "0",
  lg: "0",
  xl: "0",
  "2xl": "0",
  "3xl": "0",
  // full: "0",
};

const Button = {
  baseStyle: {
    backgroundColor: "brand.background",
  },
  variants: {
    primary: {
      backgroundColor: "brand.primary",
      color: "white",
    },
    secondary: {
      color: "brand.default",
    },
    edit: {
      height: "30px",
      fontWeight: "normal",
      backgroundColor: "blue.500",
      color: "#fff",
      fontSize: "14px",
    },
    delete: {
      height: "30px",
      fontWeight: "normal",
      backgroundColor: "orange.500",
      color: "#fff",
      fontSize: "14px",
    },
  },
};

const Table = {
  baseStyle: {},
};

const Input = {
  baseStyle: {
    field: {
      _readOnly: {
        backgroundColor: "gray.50",
      },
    },
  },
};

const NumberInput = {
  baseStyle: {
    field: {
      _readOnly: {
        backgroundColor: "gray.50",
      },
    },
  },
};

const styles = {
  global: {
    // styles for the `body`
    body: {
      backgroundColor: "brand.mainContainerBgColor",
    },
    nav: {
      fontFamily: "Roboto Condensed",
      fontWeight: "700",
      color: "#626262",
    },
  },
};

const fonts = {
  body: "Arial,Helvetica,sans-serif",
  heading: "Arial,Helvetica,sans-serif",
  mono: "Menlo, monospace",
};

const colors = {
  // brand: {
  //   900: "#1a365d",
  //   800: "#153e75",
  //   700: "#2a69ac",
  // },

  red: {
    "50": "#FCE9E9",
    "100": "#F6C0C2",
    "200": "#F1989A",
    "300": "#EB7073",
    "400": "#E6474C",
    "500": "#E01F24",
    "600": "#B3191D",
    "700": "#861316",
    "800": "#5A0C0F",
    "900": "#2D0607",
  },
  gray: {
    "50": "#F3F3F1",
    "100": "#DEDED9",
    "200": "#C9C9C0",
    "300": "#B4B4A7",
    "400": "#9E9E8E",
    "500": "#898976",
    "600": "#6E6E5E",
    "700": "#525247",
    "800": "#37372F",
    "900": "#1B1B18",
  },

  brand: {
    default: "#333",
    primary: "#E01F24",
    secondary: "#626262",
    background: "#f3f3f1",
    border: "#ccc",
    mainContainerBgColor: "#fff",
  },

  // Unsed
  main: {
    "50": "#FCE9E9",
    "100": "#F6C0C2",
    "200": "#F1989A",
    "300": "#EB7073",
    "400": "#E6474C",
    "500": "#E01F24",
    "600": "#B3191D",
    "700": "#861316",
    "800": "#5A0C0F",
    "900": "#2D0607",
  },
  // Used
  secondary: {
    background: "#FFFFFF",
    link: "#4A5568",
    card: "#ffffff",
    inputHelper: "#CBD5E0",
  },
  // Unsed
  navItem: {
    50: "#F7FAFC",
    100: "#EDF2F7",
    400: "#A0AEC0",
    500: "#718096",
    600: "#4A5568",
  },
};

const theme = extendTheme({
  colors,
  radii,
  fonts,
  styles,
  components: {
    Table,
    Button,
    Input,
    NumberInput,
  },
});

export default theme;
