import React from "react";
import { APP_NAME } from "../../../config/constants";
// @ts-ignore
import ReactExport from "react-export-excel"; // Library without typescript support!
import { formatDateTime } from "../../../config/intl";
import { ISearchData, ISearchResult, SearchType } from "./types";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type Col = ISearchResult;

interface IProps {
  dataSet: Col[];
  searchData: ISearchData[];
  searchType: SearchType;
}

const ExportResults: React.FC<IProps> = ({ dataSet, searchData, searchType }) => {
  const date = formatDateTime(new Date()).replace(/[^0-9-]+/g, "-");
  return (
    <ExcelFile filename={`${date} Dussmann ${APP_NAME}`}>
      <ExcelSheet data={dataSet} name="Prodotti">
        <ExcelColumn label={"FORNITORE"} value={(col: Col) => col.producer_id?.name} />
        <ExcelColumn label={"SITO WEB"} value={(col: Col) => col.producer_id?.website} />
        <ExcelColumn label={"TELEFONO"} value={(col: Col) => col.producer_id?.phone} />
        <ExcelColumn label={"NOME REFERENTE"} value={(col: Col) => col.producer_id?.referent_name} />
        <ExcelColumn label={"EMAIL REFERENTE"} value={(col: Col) => col.producer_id?.referent_email} />
        {/*<ExcelColumn label={"INDIRIZZO"} value={} />*/}
        {/*<ExcelColumn label={"CIVICO"} value={} />*/}
        {/*<ExcelColumn label={"CAP"} value={} />*/}
        {/*<ExcelColumn label={"CITTA"} value={} />*/}
        {/*<ExcelColumn label={"PROVINCIA"} value={} />*/}
        <ExcelColumn label={"INDIRIZZO PRODUZIONE"} value={(col: Col) => col.address_id?.street} />
        <ExcelColumn label={"CIVICO PRODUZIONE"} value={(col: Col) => col.address_id?.civic_number} />
        <ExcelColumn label={"CAP PRODUZIONE"} value={(col: Col) => col.address_id?.postal_code} />
        <ExcelColumn label={"CITTÀ PRODUZIONE"} value={(col: Col) => col.address_id?.city} />
        <ExcelColumn label={"PROVINCIA PRODUZIONE"} value={(col: Col) => col.address_id?.province_id?.code} />
        <ExcelColumn label={"REGIONE PRODUZIONE"} value={(col: Col) => col.address_id?.province_id?.region_id?.name} />

        <ExcelColumn label={"CATEGORIA"} value={(col: Col) => col.product_id?.category_id?.name} />
        <ExcelColumn label={"PRODOTTO"} value={(col: Col) => col.product_id?.name} />
        <ExcelColumn label={"NOTE RELAZIONE"} value={"note"} />
        <ExcelColumn label={"BIOLOGICO"} value={(col: Col) => (col.is_biologic ? "X" : "")} />
        <ExcelColumn label={"DOP"} value={(col: Col) => (col.is_dop ? "X" : "")} />
        <ExcelColumn label={"IGP"} value={(col: Col) => (col.is_igp ? "X" : "")} />
        <ExcelColumn label={"STG"} value={(col: Col) => (col.is_stg ? "X" : "")} />
        <ExcelColumn label={"LOTTA INTEGRATA"} value={(col: Col) => (col.is_integrated_fight ? "X" : "")} />
        <ExcelColumn label={"COOP.ERATIVA SOCIALE"} value={(col: Col) => (col.is_social_cooperative ? "X" : "")} />
        <ExcelColumn label={"EQUOSOLIDALE"} value={(col: Col) => (col.is_equosolidal ? "X" : "")} />
        <ExcelColumn label={"MSC"} value={(col: Col) => (col.is_msc ? "X" : "")} />
        <ExcelColumn label={"AQUA"} value={(col: Col) => (col.is_aqua ? "X" : "")} />
        <ExcelColumn label={"PRODOTTO LOCALE"} value={(col: Col) => (col.is_local ? "X" : "")} />
        <ExcelColumn label={"PAT"} value={(col: Col) => (col.is_pat ? "X" : "")} />
        <ExcelColumn label={"HALAL"} value={(col: Col) => (col.is_halal ? "X" : "")} />
        <ExcelColumn label={"SOCIALE"} value={(col: Col) => (col.is_social ? "X" : "")} />
        <ExcelColumn label={"FAO 37/27"} value={(col: Col) => (col.is_fao_37_27 ? "X" : "")} />
        <ExcelColumn label={"KOSHER"} value={(col: Col) => (col.is_kosher ? "X" : "")} />

        <ExcelColumn
          label={"CERTIFICAZIONI"}
          value={(col: Col) => col.producer_id?.certifications?.map((c) => c.certification_id?.name).join(";")}
        />
        <ExcelColumn
          label={"DA PRODUTTORE CON CONSEGNA DIRETTA"}
          value={(col: Col) => (col.producer_id?.direct_delivery ? "X" : "")}
        />
        <ExcelColumn label={"UTILIZZA PIATTAFORMA"} value={(col: Col) => (col.producer_id?.is_wholesaler ? "X" : "")} />
        <ExcelColumn label={"PIATTAFORMA"} value={(col: Col) => col.platform_id?.name} />
        <ExcelColumn
          label={"INDIRIZZO PIATTAFORMA"}
          value={(col: Col) =>
            searchType === "shipping" ? col.platform_id?.platform_addresses?.[0]?.street : undefined
          }
        />
        <ExcelColumn
          label={"CIVICO PIATTAFORMA"}
          value={(col: Col) =>
            searchType === "shipping" ? col.platform_id?.platform_addresses?.[0]?.civic_number : undefined
          }
        />
        <ExcelColumn
          label={"CAP PIATTAFORMA"}
          value={(col: Col) =>
            searchType === "shipping" ? col.platform_id?.platform_addresses?.[0]?.postal_code : undefined
          }
        />
        <ExcelColumn
          label={"CITTA PIATTAFORMA"}
          value={(col: Col) => (searchType === "shipping" ? col.platform_id?.platform_addresses?.[0]?.city : undefined)}
        />
        <ExcelColumn
          label={"PROVINCIA PIATTAFORMA"}
          value={(col: Col) =>
            searchType === "shipping" ? col.platform_id?.platform_addresses?.[0]?.province_id?.code : undefined
          }
        />
        <ExcelColumn
          label={"REGIONE PIATTAFORMA"}
          value={(col: Col) =>
            searchType === "shipping"
              ? col.platform_id?.platform_addresses?.[0]?.province_id?.region_id?.name
              : undefined
          }
        />
        <ExcelColumn label={"FONTE"} value={(col: Col) => col.source_id?.name} />
        <ExcelColumn label={"PREZZO"} value={"price"} />
        <ExcelColumn label={"PREZZO UM"} value={(col: Col) => col.unit_id?.name} />
        <ExcelColumn label={"CAPACITÀ PRODUTTIVA"} value={"production_capacity"} />
        <ExcelColumn label={"CAPACITÀ PRODUTTIVA UM"} value={(col: Col) => col.production_capacity_unit_id?.name} />
        <ExcelColumn label={"FILE URL"} value={"file_url"} />
      </ExcelSheet>
      <ExcelSheet data={searchData} name="Parametri di ricerca">
        <ExcelColumn label={"CAMPO"} value={(col: any[]) => col[0]} />
        <ExcelColumn label={"OPERATORE"} value={(col: any[]) => col[1]} />
        <ExcelColumn label={"ETICHETTA"} value={(col: any[]) => col[2]} />
        <ExcelColumn label={"VALORE"} value={(col: any[]) => col[3]} />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ExportResults;
