import React, { useState } from "react";
import { Content, Crumbs, PageTitle } from "../Layout";
import { ISetDataSearchResults } from "./types";
import { SEARCH_PATH } from "../../../config/paths";
import SearchForm from "./SearchForm";
import SearchResults from "./SearchResults";
import { FaSearch } from "react-icons/fa";
import { Icon } from "@chakra-ui/react";

const breadcrumbs = {
  Ricerca: SEARCH_PATH,
};

interface IProps {}

const Search: React.FC<IProps> = () => {
  const [data, setData] = useState<undefined | ISetDataSearchResults>(undefined);
  return (
    <>
      <Crumbs path={breadcrumbs} />
      <Content>
        <PageTitle>
          <Icon as={FaSearch} className="inline-icon" /> Ricerca
        </PageTitle>
        <SearchForm setData={setData} />
        <SearchResults data={data} />
      </Content>
    </>
  );
};

export default Search;
