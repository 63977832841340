import React from "react";
import { useTable, Column } from "react-table";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

interface IProps<D extends object> {
  columns: Column<D>[];
  data: D[];
}

function SimpleDataTable<D extends object>({ columns, data }: IProps<D>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <Box border="1px solid #ccc" backgroundColor="white" sx={{ overflowX: "auto" }}>
      <Table size="sm" {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()} p={2} verticalAlign={"bottom"} w={column.width}>
                  {column.render("Header")}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} py={2}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>;
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
}

export default SimpleDataTable;
