import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { PLATFORMS_PATH } from "../../../config/paths";
import Platform from "./Platform";
import PlatformAddress from "./PlatformAddresses/PlatformAddress";
import Platforms from "./Platforms";

interface IProps {}

const PlatformsRoutes: React.FC<IProps> = () => {
  const { pathname } = useLocation();
  return (
    <Switch>
      <Route path={PLATFORMS_PATH} exact>
        <Platforms />
      </Route>
      <Route path={`${PLATFORMS_PATH}/:id(new|\\d+)`} exact>
        <Platform key={pathname} />
      </Route>
      <Route path={`${PLATFORMS_PATH}/:id(\\d+)/address/:id_address(new|\\d+)`} exact>
        <PlatformAddress key={pathname} />
      </Route>
    </Switch>
  );
};

export default PlatformsRoutes;
