import React from "react";
import { Route, Switch } from "react-router-dom";
import { SEARCH_PATH } from "../../../config/paths";
import Search from "./Search";

interface IProps {}

const SearchRoutes: React.FC<IProps> = () => {
  return (
    <Switch>
      <Route path={SEARCH_PATH} exact>
        <Search />
      </Route>
    </Switch>
  );
};

export default SearchRoutes;
