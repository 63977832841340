import React from "react";
import { FieldError } from "react-hook-form";
import Select from "react-select";
import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { FSelectOption } from "./types";
import { fSelectCustomStyle } from "./fSelectCustomStyle";
import FLabel from "./FLabel";

interface IProps {
  error?: FieldError;
  isLoading?: boolean;
  isMulti?: boolean;
  label: string;
  options: FSelectOption<string | number>[];
  name: string;
  required?: boolean;
}

const FSelectS: React.FC<IProps> = React.forwardRef<any, IProps>(
  ({ error, isLoading, isMulti, label, options, name, required, ...rest }, ref) => {
    return (
      <FormControl mb="4" isInvalid={Boolean(error)}>
        <FLabel name={name} label={label} required={required} />
        <Select
          ref={ref}
          id={name}
          isClearable
          isSearchable
          isMulti={isMulti}
          isLoading={isLoading}
          options={options}
          styles={fSelectCustomStyle}
          {...rest}
        />
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    );
  }
);

export default FSelectS;
