import React from "react";
import { FieldError } from "react-hook-form";
import { IconType } from "react-icons/lib";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import FLabel from "./FLabel";

interface IProps {
  acceptedFileTypes: string;
  disabled?: boolean;
  error?: FieldError;
  iconLeft?: IconType;
  label: string;
  name: string;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  value: any;
}

const FUpload = React.forwardRef<HTMLInputElement, IProps>(
  (
    { acceptedFileTypes, disabled, error, iconLeft, label, name, placeholder, readonly, required, value, ...rest },
    ref
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    React.useImperativeHandle(ref, () => inputRef.current!);
    return (
      <FormControl mb="4" isInvalid={Boolean(error)}>
        <FLabel name={name} label={label} required={required} />
        <InputGroup>
          {iconLeft ? <InputLeftElement pointerEvents="none" children={<Icon as={iconLeft} />} /> : null}
          <input
            type="file"
            accept={acceptedFileTypes}
            name={name}
            ref={inputRef}
            {...rest}
            style={{ display: "none" }}
          />
          <Input
            type="text"
            isDisabled={disabled}
            isReadOnly={true}
            placeholder={placeholder}
            value={value ?? ""}
            onClick={() => inputRef.current?.click()}
            style={{ backgroundColor: "white" }}
          />
          <InputRightElement width={"7rem"}>
            <Button h="1.75rem" size="sm" my={0} onClick={() => inputRef.current?.click()}>
              Scegli file
            </Button>
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    );
  }
);

export default FUpload;
