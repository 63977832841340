import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { USERS_PATH } from "../../../config/paths";
import Users from "./Users";
import User from "./User";

interface IProps {}

const UsersRoutes: React.FC<IProps> = () => {
  const { pathname } = useLocation();
  return (
    <Switch>
      <Route path={USERS_PATH} exact>
        <Users />
      </Route>
      <Route path={`${USERS_PATH}/:id(new|[\\w-]+)`} exact>
        <User key={pathname} />
      </Route>
    </Switch>
  );
};

export default UsersRoutes;
