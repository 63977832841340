import React from "react";
import { useHistory } from "react-router-dom";
import { useStateBoolean } from "../../../shared/hooks/useStateBoolean";
import { Button, HStack, useToast } from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FInput from "../../../components/FormElements/FInput";
import FSaveButton from "../../../components/FormElements/FSaveButton";
import FSaveAndExitButton from "../../../components/FormElements/FSaveAndExitButton";
import { IPlatform } from "./types";
import { REQUIRED_MGS } from "../../../components/FormElements/validationMessages";
import itemsApi from "../../../shared/api/itemsApi";
import { PLATFORMS_PATH } from "../../../config/paths";
import { toastFailure, toastSuccess } from "../../../shared/lib/toast";
import { EDIT } from "../../../shared/lib/path-utils";
import { ItemsPlatforms } from "../../../generated/axios";
import PlatformAddresses from "./PlatformAddresses";
import DeletedAlert from "../../../components/DeletedAlert";

const schema = yup.object().shape({
  name: yup.string().required(REQUIRED_MGS),
});

// Need for resetting nesting object!
const resetValue = (item: IPlatform) => {
  return { ...item };
};

interface IProps {
  id: string;
  item: IPlatform;
}

const PlatformEditForm: React.FC<IProps> = ({ id, item }) => {
  const [exit, setExit] = useStateBoolean(false);
  const toast = useToast();
  const history = useHistory();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<IPlatform>({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  const onSubmit = (values: IPlatform) => {
    const item = { name: values.name, status: values.status };
    const apiCall =
      id === "new"
        ? itemsApi.createItemsPlatforms(undefined, item)
        : itemsApi.updateSingleItemsPlatforms(Number(id), ["name"], undefined, item);
    return apiCall
      .then((response) => {
        toast(toastSuccess("Piattaforma salvata con successo"));
        if (exit) history.push(PLATFORMS_PATH);
        else if (id === "new") {
          const responseId = (response.data.data as unknown as ItemsPlatforms)?.id;
          responseId && history.push(EDIT(PLATFORMS_PATH, String(responseId)));
        }
      })
      .catch((e: any) => {
        toast(toastFailure("Errore nel salvataggio della piattaforma", String(e)));
      });
  };

  return (
    <>
      <DeletedAlert status={item.status}>Piattaforma cancellata!</DeletedAlert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={"name"}
          control={control}
          render={({ field }) => <FInput label={"Nome"} error={errors.name} required={true} {...field} />}
        />

        <HStack mt="6">
          <Button onClick={() => reset(resetValue(item))}>Reset</Button>
          <FSaveButton disabled={isSubmitting && exit} isLoading={isSubmitting && !exit} />
          <FSaveAndExitButton disabled={isSubmitting && !exit} isLoading={isSubmitting && exit} onClick={setExit} />
        </HStack>
      </form>
      {id && id !== "new" ? <PlatformAddresses id={Number(id)} /> : null}
    </>
  );
};

export default PlatformEditForm;
