import React from "react";
import { ADMIN_PATH, LOGIN_PATH } from "../../config/paths";
import { Redirect } from "react-router-dom";

interface IProps {
  auth: boolean;
}

export const CheckAuth: React.FC<IProps> = ({ auth }) => {
  const url = auth ? ADMIN_PATH : LOGIN_PATH;
  return <Redirect to={url} />;
};

export default CheckAuth;
