import React from "react";
import { Button } from "@chakra-ui/react";

interface IProps {
  disabled?: boolean;
  isLoading: boolean;
  onClick?: () => void;
}

const FSaveButton: React.FC<IProps> = ({ disabled, isLoading, onClick }) => {
  return (
    <Button
      type="submit"
      disabled={disabled}
      isLoading={isLoading}
      loadingText={"Salva"}
      colorScheme="main"
      onClick={onClick}
    >
      Salva
    </Button>
  );
};

export default FSaveButton;
