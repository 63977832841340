import React from "react";
import { FieldError } from "react-hook-form";
import { FormControl, FormErrorMessage, Checkbox } from "@chakra-ui/react";

interface IProps {
  error?: FieldError;
  label: string;
  name: string;
  required?: boolean;
  value?: null | boolean;
}

const FCheckbox = React.forwardRef<HTMLInputElement, IProps>(
  ({ label, name, error, required, value, ...rest }, ref) => {
    return (
      <FormControl isInvalid={Boolean(error)}>
        <Checkbox ref={ref} type="text" id={name} isChecked={Boolean(value)} {...rest}>
          {label}
          {required ? " (*)" : ""}
        </Checkbox>
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    );
  }
);

export default FCheckbox;
