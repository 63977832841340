import React from "react";
import { FieldError } from "react-hook-form";
import { FormControl, FormErrorMessage, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { FSelectOption } from "./types";

interface IProps {
  error?: FieldError;
  name: string;
  options: FSelectOption<string | number>[];
  placeholder?: string;
  required?: boolean;
  value?: any;
}

const FRadio = React.forwardRef<HTMLInputElement, IProps>(
  ({ error, name, options = [], placeholder, required, value, ...rest }, ref) => {
    return (
      <FormControl mb="4" isInvalid={Boolean(error)}>
        <RadioGroup ref={ref} id={name} placeholder={placeholder} value={value === null ? "" : value} {...rest}>
          <Stack direction="row">
            {options.map((option, index) => (
              <Radio key={index} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    );
  }
);

export default FRadio;
