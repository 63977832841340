import { AUTHORIZED_START_PATH } from "../../config/paths";
import history from "./history";

interface LoginData {
  access_token?: string;
  expires?: number;
  refresh_token?: string;
}

const login = (data: LoginData) => {
  const { access_token = "", refresh_token } = data;
  sessionStorage.setItem("access_token", access_token);
  if (refresh_token) sessionStorage.setItem("refresh_token", refresh_token);
  history.push(AUTHORIZED_START_PATH);
};

export default login;
