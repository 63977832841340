import React from "react";
import { Box, Icon, Link, Text } from "@chakra-ui/react";
import { IBasicProducerContact } from "../types/IBasicProducerContact";
import { FaEnvelope, FaLink, FaPhoneAlt } from "react-icons/all";
import { EDIT } from "../shared/lib/path-utils";
import { AddEditLink } from "./AddEditLink";
import { PRODUCERS_PATH } from "../config/paths";

interface IProps {
  producer?: IBasicProducerContact;
}

const ProducerContact: React.FC<IProps> = ({ producer }) => {
  if (!producer) return null;
  return (
    <Box>
      <Text>
        <AddEditLink text={<strong>{producer.name}</strong>} path={EDIT(PRODUCERS_PATH, String(producer?.id))} />
      </Text>
      {producer.referent_name ? <Text>{producer.referent_name}</Text> : null}
      {producer.referent_email ? (
        <Text>
          <Link className={"no-breakable"} href={"mailto:" + producer.referent_email}>
            <Icon as={FaEnvelope} /> {producer.referent_email}
          </Link>
        </Text>
      ) : null}
      {producer.phone ? (
        <Text>
          <Link className={"no-breakable"} href={"tel:" + producer.phone}>
            <Icon as={FaPhoneAlt} /> {producer.phone}
          </Link>
        </Text>
      ) : null}
      {producer.website ? (
        <Text>
          <Link className={"no-breakable"} href={producer.website}>
            <Icon as={FaLink} /> {producer.website}
          </Link>
        </Text>
      ) : null}
    </Box>
  );
};

export default ProducerContact;
