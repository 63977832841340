import { FSelectOption } from "../../components/FormElements/types";

interface IN<T> extends FSelectOption<T>, Object {}

export function toId<T>(option: IN<T>): T {
  return option.value;
}

// Mantiene tutti i campi extra dell'option
// Es: campo id (se presente). Utile ad esempio per gestire la modifica delle relazioni N-M esistenti.
export function value2key<T>(newKey: string, option: IN<T>) {
  const newObject: any = {};
  Object.assign(newObject, option, { [newKey]: option["value"] });
  delete newObject["value"];
  delete newObject["label"];
  return newObject;
}
