import { LOGIN_PATH } from "../../config/paths";
import { SEARCH_STORAGE_KEY } from "../../config/constants";

const logout = (gotoLoginPage = false) => {
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("refresh_token");
  sessionStorage.removeItem(SEARCH_STORAGE_KEY);
  if (gotoLoginPage) {
    window.location.href = LOGIN_PATH;
  }
};

export default logout;
