import React from "react";
import { Icon } from "@chakra-ui/react";
import { FaCheck, FaTimes } from "react-icons/all";

interface IProps {
  value: boolean;
}

function CheckboxCell({ value }: IProps) {
  return <Icon as={value ? FaCheck : FaTimes} color={value ? "green.500" : "red.500"} />;
}

export default CheckboxCell;
