import React from "react";
import { ISvgMarkerIcon } from "../../../shared/lib/markers";

interface IProps extends ISvgMarkerIcon {
  viewBox: string;
  width: string;
}

export const SvgMarkerIcon: React.FC<IProps> = ({
  fillColor,
  fillOpacity,
  path,
  scale,
  strokeColor,
  strokeWeight,
  viewBox,
  width,
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} width={width}>
      <path
        d={path}
        stroke={strokeColor}
        strokeWidth={strokeWeight}
        fill={fillColor}
        opacity={fillOpacity}
        scale={scale}
      />
    </svg>
  );
};

export default SvgMarkerIcon;
