import React from "react";
import { Button, ModalBody, ModalFooter, useToast } from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REQUIRED_MGS } from "../../../components/FormElements/validationMessages";
import itemsApi from "../../../shared/api/itemsApi";
import { toastFailure, toastSuccess } from "../../../shared/lib/toast";
import FInput from "../../../components/FormElements/FInput";
import FSaveButton from "../../../components/FormElements/FSaveButton";
import { UseFormSetValue } from "react-hook-form/dist/types/form";
import { IProduct } from "./types";
import toOption from "../../../shared/lib/toOption";
import { ItemsProductCategories } from "../../../generated/axios";

interface IFormValues {
  status?: string;
  name?: string;
}

const schema = yup.object().shape({
  name: yup.string().required(REQUIRED_MGS),
});

interface IProps {
  onClose: () => void;
  setValue: UseFormSetValue<IProduct>;
}

export const NewProductCategoryModalForm: React.FC<IProps> = ({ onClose, setValue }) => {
  const toast = useToast();
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: { name: "", status: "published" },
  });

  const onSubmit = (values: IFormValues) => {
    return itemsApi
      .createItemsProductCategories(undefined, values)
      .then((response) => {
        const category = toOption<number>(response.data.data! as unknown as ItemsProductCategories);
        setValue("category_id", category);
        onClose();
        toast(toastSuccess("Categoria creata con successo"));
      })
      .catch((e: any) => {
        toast(toastFailure("Errore nella creazione della categoria", String(e)));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody pb={6}>
        <Controller
          name={"name"}
          control={control}
          render={({ field }) => (
            <FInput autoFocus={true} label={"Categoria merceologica"} error={errors.name} required={true} {...field} />
          )}
        />
      </ModalBody>

      <ModalFooter>
        <Button onClick={onClose} mr={3}>
          Annulla
        </Button>
        <FSaveButton isLoading={isSubmitting} />
      </ModalFooter>
    </form>
  );
};

export default NewProductCategoryModalForm;
