import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { IBasicAddress } from "../types/IBasicAddress";

interface IProps {
  address?: IBasicAddress;
}

const Address: React.FC<IProps> = ({ address }) => {
  if (!address) return null;
  return (
    <Box>
      <Text>
        {address.street} {address.civic_number}
      </Text>
      <Text>
        {address.postal_code} {address.city} ({address.province_id?.code})
      </Text>
    </Box>
  );
};

export default Address;
