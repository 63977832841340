import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { PRODUCERS_PATH } from "../../../config/paths";
import Producers from "./Producers";
import Producer from "./Producer";
import ProducerProduct from "./ProducerProducts/ProducerProduct";

interface IProps {}

const ProducersRoutes: React.FC<IProps> = () => {
  const { pathname } = useLocation();
  return (
    <Switch>
      <Route path={PRODUCERS_PATH} exact>
        <Producers />
      </Route>
      <Route path={`${PRODUCERS_PATH}/:id(new|\\d+)`} exact>
        <Producer key={pathname} />
      </Route>
      <Route path={`${PRODUCERS_PATH}/:id(\\d+)/pp/:id_pp(new|\\d+)`} exact>
        <ProducerProduct key={pathname} />
      </Route>
    </Switch>
  );
};

export default ProducersRoutes;
