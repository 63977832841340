import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Content, Crumbs, PageTitle } from "../Layout";
import { EDIT } from "../../../shared/lib/path-utils";
import { PLATFORMS_PATH } from "../../../config/paths";
import breadcrumbs from "./breadcrumbs";
import itemsApi from "../../../shared/api/itemsApi";
import Loading from "../../../components/Loading";
import PlatformEditForm from "./PlatformEditForm";
import { FaProjectDiagram } from "react-icons/fa";
import { IPlatform } from "./types";
import { Icon } from "@chakra-ui/react";
import { ItemsPlatforms } from "../../../generated/axios";

const newItem: IPlatform = {
  name: "",
  status: "published",
};

interface IRouteParams {
  id: string;
}

interface IProps {}

const Platform: React.FC<IProps> = () => {
  const [item, setItem] = useState<IPlatform | undefined>(undefined);
  const [error, setError] = useState("");
  const {
    params: { id },
  } = useRouteMatch<IRouteParams>();
  const isNew = id === "new";
  const title = isNew ? "Nuova piattaforma" : "Modifica piattaforma";
  const crumbs = {
    ...breadcrumbs,
    [title]: EDIT(PLATFORMS_PATH, id),
  };

  useEffect(() => {
    if (id === "new") {
      setItem(newItem);
    } else {
      itemsApi
        .readSingleItemsPlatforms(Number(id), ["name", "status"])
        .then((response) => {
          const data = response.data.data! as unknown as ItemsPlatforms;
          const item: IPlatform = {
            name: data.name,
            status: data.status,
          };
          setItem(item);
        })
        .catch((e) => {
          setItem(newItem); // End loading
          setError(String(e));
        });
    }
  }, [id, setItem, setError]);

  return (
    <>
      <Crumbs path={crumbs} />
      <Content>
        <PageTitle>
          <Icon as={FaProjectDiagram} className="inline-icon" /> {title}
        </PageTitle>
        <Loading loading={!item} error={error}>
          <PlatformEditForm id={id} item={item!} />
        </Loading>
      </Content>
    </>
  );
};

export default Platform;
