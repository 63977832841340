import { IProvincesWithRegion } from "../../context/AdminContext";
import { FSelectOption } from "../../components/FormElements/types";
import toOption from "./toOption";

const sortByLabel = <T extends { label: string }>(a: T, b: T) => {
  if (a.label < b.label) return -1;
  else if (a.label > b.label) return 1;
  else return 0;
};

export const provincesOptions = (
  provincesWithRegions: IProvincesWithRegion[],
  region_id?: null | FSelectOption<number>
): FSelectOption<number>[] => {
  const provinces = region_id
    ? provincesWithRegions.filter((p) => p.region_id.id === region_id.value)
    : provincesWithRegions;
  return provinces
    .map((p) => ({
      value: p.id,
      label: p.name,
    }))
    .sort(sortByLabel);
};

export const regionsOptions = (
  provincesWithRegions: IProvincesWithRegion[],
  province_id?: null | FSelectOption<number>
): FSelectOption<number>[] => {
  const provinces = province_id ? provincesWithRegions.filter((p) => p.id === province_id.value) : provincesWithRegions;
  const regions: Record<string, FSelectOption<number>> = {};
  provinces.forEach((p) => {
    regions[String(p.region_id.id)] = { value: p.region_id.id, label: p.region_id.name };
  });
  return Object.values(regions).sort(sortByLabel);
};

export const getRegion = (
  provincesWithRegions: IProvincesWithRegion[],
  province_id: FSelectOption<number>
): null | FSelectOption<number> => {
  const province = provincesWithRegions.find((p) => p.id === province_id.value);
  return province ? toOption(province.region_id) : null;
};
