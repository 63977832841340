import React from "react";
import { Icon } from "@chakra-ui/react";
import { FaEdit } from "react-icons/all";
import { Link } from "react-router-dom";

interface IProps {
  text?: string | React.ReactNode;
  path?: string;
}

export const AddEditLink: React.FC<IProps> = ({ text, path }) => {
  return path ? (
    <Link to={path}>
      <Icon as={FaEdit} className="inline-icon" /> {text}
    </Link>
  ) : (
    <>text</>
  );
};
