import React, { useContext } from "react";
import { Content, Crumbs, PageTitle } from "./Layout";
import AdminContext from "../../context/AdminContext";
import { Icon, Text } from "@chakra-ui/react";
import { FaTachometerAlt } from "react-icons/fa";

interface IProps {}

const Dashboard: React.FC<IProps> = () => {
  const { loggedUser } = useContext(AdminContext);
  return (
    <>
      <Crumbs />
      <Content>
        <PageTitle>
          <Icon as={FaTachometerAlt} className="inline-icon" /> Dashboard
        </PageTitle>
        <Text mt={6}>
          Buongiorno {loggedUser.first_name} {loggedUser.last_name}!
        </Text>
      </Content>
    </>
  );
};

export default Dashboard;
