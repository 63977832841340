import React from "react";
import { Box } from "@chakra-ui/react";

interface IProps {}

const Content: React.FC<IProps> = ({ children }) => {
  return (
    <Box m="6" p="6" bg="white" boxShadow="md">
      {children}
    </Box>
  );
};

export default Content;
