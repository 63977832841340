import { ExtendedFilters } from "../../../../shared/lib/serializeFilters";
import { ISearch } from "../types";

export function toFilters(values: ISearch): ExtendedFilters<ISearch> {
  const filters: ExtendedFilters<ISearch> = [];
  if (values.product_id?.value) filters.push({ id: "product_id", value: values.product_id.value, operator: "_eq" });
  if (values.category_id?.value)
    filters.push({ id: "product_id.category_id", value: values.category_id.value, operator: "_eq" });
  if (values.product) filters.push({ id: "name", value: values.product, operator: "_contains" });
  if (values.price_from) filters.push({ id: "price", value: values.price_from, operator: "_gte" });
  if (values.price_to) filters.push({ id: "price", value: values.price_to, operator: "_lte" });
  if (values.is_biologic) filters.push({ id: "is_biologic", value: values.is_biologic, operator: "_eq" });
  if (values.is_dop) filters.push({ id: "is_dop", value: values.is_dop, operator: "_eq" });
  if (values.is_igp) filters.push({ id: "is_igp", value: values.is_igp, operator: "_eq" });
  if (values.is_stg) filters.push({ id: "is_stg", value: values.is_stg, operator: "_eq" });
  if (values.is_integrated_fight)
    filters.push({ id: "is_integrated_fight", value: values.is_integrated_fight, operator: "_eq" });
  if (values.is_social_cooperative)
    filters.push({ id: "is_social_cooperative", value: values.is_social_cooperative, operator: "_eq" });
  if (values.is_equosolidal) filters.push({ id: "is_equosolidal", value: values.is_equosolidal, operator: "_eq" });
  if (values.is_msc) filters.push({ id: "is_msc", value: values.is_msc, operator: "_eq" });
  if (values.is_aqua) filters.push({ id: "is_aqua", value: values.is_aqua, operator: "_eq" });
  if (values.is_local) filters.push({ id: "is_local", value: values.is_local, operator: "_eq" });
  if (values.is_pat) filters.push({ id: "is_pat", value: values.is_pat, operator: "_eq" });
  if (values.is_halal) filters.push({ id: "is_halal", value: values.is_halal, operator: "_eq" });
  if (values.is_social) filters.push({ id: "is_social", value: values.is_social, operator: "_eq" });
  if (values.is_fao_37_27) filters.push({ id: "is_fao_37_27", value: values.is_fao_37_27, operator: "_eq" });
  if (values.is_kosher) filters.push({ id: "is_kosher", value: values.is_kosher, operator: "_eq" });
  if (values.certifications) {
    values.certifications.forEach((certification) => {
      filters.push({
        id: "producer_id.certifications.certification_id",
        value: certification.value,
        operator: "_eq",
      });
    });
  }
  if (values.platform_id?.value) filters.push({ id: "platform_id", value: values.platform_id.value, operator: "_eq" });
  if (values.source_id?.value) filters.push({ id: "source_id", value: values.source_id.value, operator: "_eq" });
  if (values.is_wholesaler)
    filters.push({ id: "producer_id.is_wholesaler", value: values.is_wholesaler, operator: "_eq" });
  if (values.direct_delivery)
    filters.push({ id: "producer_id.direct_delivery", value: values.direct_delivery, operator: "_eq" });
  // Both condition (on region and province_id) breaks Directus query building
  if (values.region_id?.value && !values.province_id?.value)
    filters.push({ id: "address_id.province_id.region_id", value: values.region_id.value, operator: "_eq" });
  if (values.province_id?.value)
    filters.push({ id: "address_id.province_id", value: values.province_id.value, operator: "_eq" });
  return filters;
}
