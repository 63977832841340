import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import LoginFooter from "./LoginFooter";

import "./Layout.css";

const LoginContainer: React.FC = ({ children }) => {
  return (
    <Flex
      bg="brand.background"
      minHeight="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box width={{ base: "90%", md: "400px" }} bg="white" boxShadow="md" p={5}>
        {children}
      </Box>
      <LoginFooter />
    </Flex>
  );
};

export default LoginContainer;
