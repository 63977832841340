import * as React from "react";

export interface IAuthContext {
  auth: boolean;
  setAuth: (auth: boolean) => void;
}

const AuthContext = React.createContext<IAuthContext>({ auth: false, setAuth: () => {} });

export default AuthContext;
