import { ISearchResult, SearchType } from "../types";
import { getDistance } from "../../../../shared/lib/insideFunctionFactory";
import { IGoogleCoords } from "../../../../types/ICoords";

export const filterOnPlatformAddress = (
  results: ISearchResult[],
  searchType: SearchType,
  center?: IGoogleCoords,
  km?: number
) => {
  if (searchType === "shipping") {
    const splittedResults: ISearchResult[] = [];

    results.forEach((result) => {
      (result.platform_id?.platform_addresses || [])
        .filter((address) => address.status === "published")
        .forEach((address) => {
          const distance = center ? getDistance(center, address.latitude, address.longitude) : undefined;
          if (distance === undefined || km === undefined || distance <= km * 1000) {
            splittedResults.push({
              ...result,
              platform_id: {
                ...result.platform_id!,
                platform_addresses: [address],
              },
              distance,
            });
          }
        });
    });
    return splittedResults;
  } else if (center) {
    return results.map((r) => ({
      ...r,
      distance: getDistance(center, r.address_id?.latitude, r.address_id?.longitude),
    }));
  } else {
    return results;
  }
};
