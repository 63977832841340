import React, { useCallback, useState } from "react";
import { FaDownload } from "react-icons/all";
import { Icon, Spinner, useToast } from "@chakra-ui/react";
import { downloadFile } from "../shared/api/downloadFile";
import { toastFailure } from "../shared/lib/toast";

interface IProps {
  asset: string;
  filename?: string;
  title?: string;
}

export const DownloadFile: React.FC<IProps> = ({ asset, filename, title }) => {
  const toast = useToast();
  const [downloading, setDownloading] = useState(false);
  const onClick = useCallback(() => {
    setDownloading(true);
    downloadFile(asset, filename)
      .catch((e) => {
        toast(toastFailure("Errore nel download del file", String(e)));
      })
      .finally(() => setDownloading(false));
  }, [asset, filename, setDownloading, toast]);
  return downloading ? (
    <div>
      <Spinner size={"sm"} color={"brand.primary"} thickness="3px" /> {title ?? asset}
    </div>
  ) : (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      <Icon as={FaDownload} /> {title ?? asset}
    </div>
  );
};
