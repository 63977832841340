import React from "react";
import { FieldError } from "react-hook-form";
import { FormControl, FormErrorMessage, Input } from "@chakra-ui/react";
import FLabel from "./FLabel";

interface IProps {
  autoFocus?: boolean;
  disabled?: boolean;
  error?: FieldError;
  label: string;
  maxLength?: number;
  name: string;
  readonly?: boolean;
  required?: boolean;
  value: any;
}

const FInput = React.forwardRef<HTMLInputElement, IProps>(
  ({ autoFocus = false, label, maxLength = 255, name, disabled, error, readonly, required, value, ...rest }, ref) => {
    return (
      <FormControl mb="4" isInvalid={Boolean(error)}>
        <FLabel name={name} label={label} required={required} />
        <Input
          ref={ref}
          type="text"
          id={name}
          autoFocus={autoFocus}
          isDisabled={disabled}
          isReadOnly={readonly}
          maxLength={maxLength}
          value={value === null ? "" : value}
          {...rest}
          backgroundColor="white"
        />
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    );
  }
);

export default FInput;
