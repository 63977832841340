import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useToast } from "@chakra-ui/react";
import AdminContext, {
  ILoggedUserState,
  initialLoggedUserState,
  IProvincesWithRegion,
} from "../../context/AdminContext";
import usersApi from "../../shared/api/usersApi";
import { toastFailure } from "../../shared/lib/toast";
import itemsApi from "../../shared/api/itemsApi";
import { FSelectOption } from "../../components/FormElements/types";
import { Roles, Users } from "../../generated/axios";
import toOption from "../../shared/lib/toOption";
import { useStateLoading } from "../../shared/hooks/useStateLoading";
import InitialLoading from "./Layout/InitialLoading";
import { provincesOptions, regionsOptions } from "../../shared/lib/provinces-regions";

interface IProps {}

const AdminGlobalStore: React.FC<IProps> = ({ children }) => {
  const toast = useToast();
  const [loggedUser, setInitialLoggedUser, loadingUser] = useStateLoading<ILoggedUserState>(initialLoggedUserState);

  const [certifications, setCertifications] = useState<FSelectOption<number>[]>([]);
  const [provinces, setProvinces] = useState<FSelectOption<number>[]>([]);
  const [provincesWithRegion, setProvincesWithRegion] = useState<IProvincesWithRegion[]>([]);
  const [regions, setRegions] = useState<FSelectOption<number>[]>([]);
  const [sources, setSources] = useState<FSelectOption<number>[]>([]);
  const [units, setUnits] = useState<FSelectOption<number>[]>([]);

  const loadLoggedUserData = useCallback(() => {
    usersApi
      .getMe(["id", "first_name", "last_name", "email", "role.name"])
      .then((response) => {
        const data: Users = response.data.data!;
        const loggedUser = {
          id: data.id ?? "",
          first_name: data.first_name ?? "",
          last_name: data.last_name ?? "",
          email: data.email ?? "",
          role: data.role ? (data.role as Roles).name ?? "" : "",
        };
        setInitialLoggedUser(loggedUser);
      })
      .catch((e) => {
        toast(toastFailure("Errore caricamento dati utente", String(e)));
      });
  }, [setInitialLoggedUser, toast]);

  const loadCertifications = useCallback(() => {
    itemsApi
      .readItemsCertifications(["id", "name"], -1, undefined, 0, ["name"])
      .then((response) => {
        const certifications: FSelectOption<number>[] = response.data.data?.map(toOption) || [];
        setCertifications(certifications);
      })
      .catch((e) => {
        toast(toastFailure("Errore caricamento dati certificazioni", String(e)));
      });
  }, [setCertifications, toast]);

  const loadProvincesWithRegion = useCallback(() => {
    itemsApi
      .readItemsProvinces(["id", "name", "region_id.id", "region_id.name"], -1, undefined, 0, ["name"])
      .then((response) => {
        const provincesWithRegion = (response.data.data || []) as IProvincesWithRegion[];
        setProvincesWithRegion(provincesWithRegion);
        setProvinces(provincesOptions(provincesWithRegion));
        setRegions(regionsOptions(provincesWithRegion));
      })
      .catch((e) => {
        toast(toastFailure("Errore caricamento dati province e regioni", String(e)));
      });
  }, [setProvinces, setRegions, setProvincesWithRegion, toast]);

  const loadSources = useCallback(() => {
    itemsApi
      .readItemsSources(["id", "name"], -1, undefined, 0, ["name"])
      .then((response) => {
        const sources: FSelectOption<number>[] = response.data.data?.map(toOption) || [];
        setSources(sources);
      })
      .catch((e) => {
        toast(toastFailure("Errore caricamento dati delle fonti", String(e)));
      });
  }, [setSources, toast]);

  const loadUnits = useCallback(() => {
    itemsApi
      .readItemsUnits(["id", "name"], -1, undefined, 0, ["name"])
      .then((response) => {
        const units: FSelectOption<number>[] = response.data.data?.map(toOption) || [];
        setUnits(units);
      })
      .catch((e) => {
        toast(toastFailure("Errore caricamento dati unità di misura", String(e)));
      });
  }, [setUnits, toast]);

  const actions = useMemo(() => {
    return {
      init: () => {},
    };
  }, []);

  useEffect(loadLoggedUserData, [loadLoggedUserData]);

  useEffect(loadCertifications, [loadCertifications]);
  useEffect(loadProvincesWithRegion, [loadProvincesWithRegion]);
  useEffect(loadSources, [loadSources]);
  useEffect(loadUnits, [loadUnits]);

  return (
    <AdminContext.Provider
      value={{
        actions,
        certifications,
        loggedUser,
        provinces,
        provincesWithRegion,
        regions,
        sources,
        units,
      }}
    >
      {loadingUser ? <InitialLoading /> : children}
    </AdminContext.Provider>
  );
};

export default AdminGlobalStore;
