import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import authApi from "../../shared/api/authApi";
import "./Login.css";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Stack,
  Flex,
} from "@chakra-ui/react";
import { FaLock, FaRegEnvelope } from "react-icons/fa";
import { LoginContainer } from "./Layout";
import AuthContext from "../../context/AuthContext";
import { createUnauthorizedAxiosInterceptor, UnauthorizedAxiosInterceptor } from "../../shared/api/axiosInterceptors";
import axios from "axios";
import logout from "../../shared/api/logout";
import login from "../../shared/api/login";
import logo from "../../assets/dussmann_logo.svg";
import { APP_NAME } from "../../config/constants";

interface IFormFields {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const { setAuth } = useContext(AuthContext);
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<IFormFields>();

  const onSubmit = (values: IFormFields) => {
    axios.interceptors.response.eject(UnauthorizedAxiosInterceptor);
    return authApi
      .login(values)
      .then((response) => {
        const data = response.data?.data;
        if (data?.access_token) {
          setAuth(true);
          login(data);
        } else {
          setError("password", { message: "Si è verificato un errore!" });
        }
      })
      .catch(() => {
        logout(false);
        setError("password", { message: "Password errata, riprova!" });
      })
      .finally(() => {
        createUnauthorizedAxiosInterceptor();
      });
  };

  return (
    <LoginContainer>
      <Flex align={"flex-start"} marginBottom="1.5rem" justifyContent={"space-between"}>
        <div>
          <Heading marginBottom="0.5rem">Login</Heading>
          <Text fontWeight={"bold"}>{APP_NAME}</Text>
        </div>
        <Image boxSize="120px" src={logo} alt={"Dussmann"} mr={"-4px"} />
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} marginBottom="1rem">
          <FormControl isInvalid={Boolean(errors.email)}>
            <FormLabel htmlFor="email">Email Address</FormLabel>
            <InputGroup>
              <InputLeftElement children={<Icon as={FaRegEnvelope} color="secondary.inputHelper" />} />
              <Input
                // focusBorderColor="main.500"
                type="email"
                id="email"
                placeholder="name@example.com"
                {...register("email", { required: "Campo obbligatorio" })}
              />
            </InputGroup>
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={Boolean(errors.password)}>
            <Stack justifyContent="space-between" isInline>
              <FormLabel htmlFor="password">Password</FormLabel>
            </Stack>
            <InputGroup>
              <InputLeftElement children={<Icon as={FaLock} color="secondary.inputHelper" />} />
              <Input
                // focusBorderColor="main.500"
                id="password"
                type="password"
                placeholder="Enter your password"
                {...register("password", { required: "Campo obbligatorio" })}
              />
            </InputGroup>
            <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
        <Stack marginBottom="1rem">
          <Button
            type="submit"
            isLoading={isSubmitting}
            loadingText={"Login"}
            // colorScheme="main"
          >
            Login
          </Button>
        </Stack>
      </form>
    </LoginContainer>
  );
};

export default Login;
