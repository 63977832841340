import React, { useCallback, useMemo, useRef, useState } from "react";
import { Content, Crumbs, PageTitle } from "../Layout";
import itemsApi from "../../../shared/api/itemsApi";
import {
  DataTable,
  DataTableCallbackRef,
  DefaultColumnFilter,
  DeleteButton,
  DeleteModal,
  EditButton,
  FetchDataParams,
  Pagination,
} from "../../../components/DataTable";
import { Column, useAsyncDebounce } from "react-table";
import breadcrumbs from "./breadcrumbs";
import { PRODUCTS_PATH } from "../../../config/paths";
import { NEW } from "../../../shared/lib/path-utils";
import { serializeFilters } from "../../../shared/lib/serializeFilters";
import { WithMeta } from "../../../types/WithMeta";
import { ItemsProducts } from "../../../generated/axios";
import { AxiosResponse } from "axios";
import { HStack, Icon } from "@chakra-ui/react";
import { formatDateTimeStr } from "../../../config/intl";
import { FaAppleAlt } from "react-icons/fa";

interface IRecord {
  id: number;
  name?: string;
  category_id: {
    name: string;
  };
  date_created: string;
  date_updated?: string;
}

const columns: Column<IRecord>[] = [
  {
    Header: "Id",
    accessor: "id", // accessor is the "key" in the data
    Filter: DefaultColumnFilter,
    width: "85px",
  },
  {
    Header: "Nome",
    accessor: "name",
    Filter: DefaultColumnFilter,
    width: undefined,
  },
  {
    id: "category_id.name",
    Header: "Categoria",
    accessor: (row) => row.category_id?.name,
    Filter: DefaultColumnFilter,
    //disableFilters: true,
    disableSortBy: true,
    width: undefined,
  },
  {
    id: "date_created",
    Header: "Data creazione",
    accessor: (row) => formatDateTimeStr(row.date_created),
    disableFilters: true,
    width: "165px",
  },
  {
    id: "date_updated",
    Header: "Data modifica",
    accessor: (row) => formatDateTimeStr(row.date_updated),
    disableFilters: true,
    width: "165px",
  },
  {
    id: "actions",
    Header: "Azioni",
    disableFilters: true,
    disableSortBy: true,
    accessor: (row) => row,
    width: "150px",
  },
];

const newButtonPath = NEW(PRODUCTS_PATH);

const doDelete = (id: number) => itemsApi.updateSingleItemsProducts(id, ["status"], undefined, { status: "archived" });

interface IProps {}

const Products: React.FC<IProps> = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [toDelete, setToDelete] = useState<undefined | IRecord>(undefined);

  const memoizedColumns = useMemo(() => {
    const actionColumn = columns.find((column) => column.id === "actions");
    if (actionColumn) {
      // @ts-ignore
      actionColumn.Cell = ({ value }: { value: IRecord }) => (
        <HStack spacing="20px">
          <EditButton id={value.id} path={PRODUCTS_PATH} />
          <DeleteButton value={value} onClick={setToDelete} />
        </HStack>
      );
    }
    return columns;
  }, [setToDelete]);

  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    numPages: 0,
    totalRecords: 0,
  });

  const fetchData = useCallback(
    ({ filters, globalFilter, pageIndex, pageSize, sortBy }: FetchDataParams<IRecord>) => {
      const sort = sortBy.map((c) => `${c.desc ? "-" : ""}${c.id}`);
      setLoading(true);
      itemsApi
        .readItemsProducts(
          ["id", "name", "category_id.name", "date_created", "date_updated"],
          pageSize,
          "filter_count",
          pageIndex * pageSize,
          sort,
          serializeFilters({ status: "published" }, filters, { id: "_eq" }),
          globalFilter
        )
        .then((response: AxiosResponse<WithMeta<ItemsProducts>>) => {
          const data = response.data.data;
          const totalRecords = response.data.meta?.filter_count || 0;
          setData(data || []);
          setPagination({
            page: pageIndex,
            numPages: Math.ceil(totalRecords / pageSize),
            totalRecords,
          });
        })
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
    },
    [setData, setPagination, setLoading]
  );
  const fetchDataDebounced = useAsyncDebounce(fetchData, 100);

  const closeDeleteModal = useCallback(() => setToDelete(undefined), [setToDelete]);

  const ref = useRef<DataTableCallbackRef>(null);

  return (
    <>
      <Crumbs path={breadcrumbs} />
      <Content>
        <PageTitle>
          <Icon as={FaAppleAlt} className="inline-icon" /> Lista prodotti
        </PageTitle>
        <DataTable
          callbacksRef={ref}
          columns={memoizedColumns}
          data={data}
          fetchData={fetchDataDebounced}
          labelButtonNew={"Nuovo Prodotto"}
          loading={loading}
          newButtonPath={newButtonPath}
          pagination={pagination}
        />
      </Content>
      <DeleteModal
        afterDelete={ref.current?.fetchData}
        close={closeDeleteModal}
        doDelete={doDelete}
        id={toDelete?.id || 0}
        title={"Elimina prodotto"}
      >
        Vuoi eliminare il prodotto {toDelete?.name || ""}?
      </DeleteModal>
    </>
  );
};

export default Products;
