import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useStateBoolean } from "../../../shared/hooks/useStateBoolean";
import {
  Button,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FInput from "../../../components/FormElements/FInput";
import FCheckbox from "../../../components/FormElements/FCheckbox";
import FSaveButton from "../../../components/FormElements/FSaveButton";
import FSaveAndExitButton from "../../../components/FormElements/FSaveAndExitButton";
import { IProducer } from "./types";
import {
  INAVALID_EMAIL_MSG,
  INVALID_WEBSITE_MSG,
  NUMBER_MGS,
  REQUIRED_MGS,
} from "../../../components/FormElements/validationMessages";
import itemsApi from "../../../shared/api/itemsApi";
import { PRODUCERS_PATH } from "../../../config/paths";
import { toastFailure, toastSuccess } from "../../../shared/lib/toast";
import AdminContext from "../../../context/AdminContext";
import FSelectS from "../../../components/FormElements/FSelectS";
import { ItemsProducers, ItemsProducersCertifications, ItemsProducersPlatforms } from "../../../generated/axios";
import { value2key } from "../../../shared/lib/id-utils";
import AddressFields from "./AddressFields";
import ProducerProducts from "./ProducerProducts/ProducerProducts";
import { URL_REGEX, validSelectedOption } from "../../../shared/lib/validation-utils";
import NewPlatformModalForm from "./NewPlatformModalForm";
import { FaPlus } from "react-icons/all";
import { EDIT } from "../../../shared/lib/path-utils";
import FTextarea from "../../../components/FormElements/FTextarea";
import DeletedAlert from "../../../components/DeletedAlert";
import { usePlatformOptions } from "../../../shared/hooks/usePlatformOptions";

const schema = yup.object().shape({
  name: yup.string().required(REQUIRED_MGS),
  phone: yup.string().nullable(),
  website: yup.string().nullable().matches(URL_REGEX, INVALID_WEBSITE_MSG),
  referent_name: yup.string().nullable(),
  referent_email: yup.string().nullable().email(INAVALID_EMAIL_MSG),
  is_wholesaler: yup.boolean(),
  direct_delivery: yup.boolean(),
  addresses: yup.array().of(
    yup.object().shape({
      street: yup.string().required(REQUIRED_MGS),
      city: yup.string().required(REQUIRED_MGS),
      postal_code: yup.string().required(REQUIRED_MGS),
      province_id: yup
        .object()
        .typeError(REQUIRED_MGS)
        .required(REQUIRED_MGS)
        .test("province_id_required", REQUIRED_MGS, validSelectedOption),
      latitude: yup.number().typeError(NUMBER_MGS).required(REQUIRED_MGS),
      longitude: yup.number().typeError(NUMBER_MGS).required(REQUIRED_MGS),
    })
  ),
  certifications: yup.array().nullable(),
  platforms: yup.array().nullable(),
});

// Need for resetting nesting object!
const resetValue = (item: IProducer): IProducer => {
  return {
    ...item,
    addresses: item.addresses ? [...item.addresses] : [],
    certifications: item.certifications ? [...item.certifications] : [],
    platforms: item.platforms ? [...item.platforms] : [],
  };
};

interface IProps {
  id: string;
  item: IProducer;
}

const ProducerEditForm: React.FC<IProps> = ({ id, item }) => {
  const [exit, setExit] = useStateBoolean(false);
  const toast = useToast();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { certifications } = useContext(AdminContext);
  const [platforms] = usePlatformOptions();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<IProducer>({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  const onSubmit = (values: IProducer) => {
    const item: ItemsProducers = {
      name: values.name,
      status: values.status,
      phone: values.phone,
      website: values.website,
      referent_name: values.referent_name,
      referent_email: values.referent_email,
      is_wholesaler: values.is_wholesaler,
      direct_delivery: values.direct_delivery,
      addresses: values.addresses.map((address) => ({
        ...address,
        latitude: address.latitude ?? undefined,
        longitude: address.longitude ?? undefined,
        province_id: address.province_id?.value ?? 0,
      })),
      certifications: values.certifications.map(
        (c) => value2key("certification_id", c) as ItemsProducersCertifications
      ),
      platforms: values.platforms.map((p) => value2key("platforms_id", p) as ItemsProducersPlatforms),
      note: values.note,
    };
    const apiCall =
      id === "new"
        ? itemsApi.createItemsProducers(undefined, item)
        : itemsApi.updateSingleItemsProducers(
            Number(id),
            [
              "name",
              "phone",
              "website",
              "referent_name",
              "referent_email",
              "is_wholesaler",
              "direct_delivery",
              "addresses",
              "certifications",
              "platforms",
              "note",
            ],
            undefined,
            item
          );
    return apiCall
      .then((response) => {
        toast(toastSuccess("Produttore salvato con successo"));
        if (exit) history.push(PRODUCERS_PATH);
        else if (id === "new") {
          const responseId = (response.data.data as unknown as ItemsProducers)?.id;
          responseId && history.push(EDIT(PRODUCERS_PATH, String(responseId)));
        }
      })
      .catch((e: any) => {
        toast(toastFailure("Errore nel salvataggio del produttore", String(e)));
      });
  };

  return (
    <>
      <DeletedAlert status={item.status}>Produttore cancellato!</DeletedAlert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={"name"}
          control={control}
          render={({ field }) => <FInput label={"Nome"} error={errors.name} required={true} {...field} />}
        />

        <SimpleGrid columns={[1, 1, 2]} spacing={"8px"}>
          <Controller
            name={"phone"}
            control={control}
            render={({ field }) => <FInput label={"Telefono"} error={errors.phone} {...field} />}
          />
          <Controller
            name={"website"}
            control={control}
            render={({ field }) => <FInput label={"Sito"} error={errors.website} {...field} />}
          />
        </SimpleGrid>

        <SimpleGrid columns={[1, 1, 2]} spacing={"8px"}>
          <Controller
            name={"referent_name"}
            control={control}
            render={({ field }) => <FInput label={"Referente (Nome)"} error={errors.referent_name} {...field} />}
          />
          <Controller
            name={"referent_email"}
            control={control}
            render={({ field }) => <FInput label={"Referente (Email)"} error={errors.referent_email} {...field} />}
          />
        </SimpleGrid>

        <SimpleGrid columns={[1, 2, 2, 4]} spacing={"8px"} mb={4}>
          <Controller
            name={"is_wholesaler"}
            control={control}
            render={({ field }) => <FCheckbox label={"Utilizza Piattaforma"} error={errors.is_wholesaler} {...field} />}
          />
          <Controller
            name={"direct_delivery"}
            control={control}
            render={({ field }) => <FCheckbox label={"Consegna diretta"} error={errors.direct_delivery} {...field} />}
          />
        </SimpleGrid>

        <HStack mt={4}>
          <Controller
            name={"platforms"}
            control={control}
            render={({ field }) => (
              <FSelectS
                {...field}
                isMulti={true}
                label={"Piattaforme"}
                error={errors.platforms as FieldError | undefined}
                options={platforms}
              />
            )}
          />

          <div className={"chakra-form-control"}>
            <Text mb={2} className={"chakra-form__label"}>
              &nbsp;
            </Text>
            <Button mb={4} leftIcon={<Icon as={FaPlus} />} onClick={onOpen} w={"18em"}>
              Crea Nuova Piattaforma
            </Button>
          </div>
        </HStack>

        <Controller
          name={"certifications"}
          control={control}
          render={({ field }) => (
            <FSelectS
              {...field}
              error={errors.certifications as FieldError | undefined}
              isMulti={true}
              label={"Certificazioni"}
              options={certifications}
            />
          )}
        />

        <Controller
          name={"note"}
          control={control}
          render={({ field }) => <FTextarea label={"Note"} error={errors.note} {...field} />}
        />

        <Heading as="h2" size="md" mt="6" mb="2">
          Sede legale
        </Heading>

        <AddressFields control={control} errors={errors} getValues={getValues} i={0} setValue={setValue} />

        <Heading as="h2" size="md" mt="6" mb="2">
          Indirizzo del produttore
        </Heading>

        <AddressFields control={control} errors={errors} getValues={getValues} i={1} setValue={setValue} />

        <HStack mt={6}>
          <Button onClick={() => reset(resetValue(item))}>Reset</Button>
          <FSaveButton disabled={isSubmitting && exit} isLoading={isSubmitting && !exit} />
          <FSaveAndExitButton disabled={isSubmitting && !exit} isLoading={isSubmitting && exit} onClick={setExit} />
        </HStack>
      </form>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Aggiungi Piattaforma</ModalHeader>
          <ModalCloseButton />
          <NewPlatformModalForm getValues={getValues} onClose={onClose} setValue={setValue} />
        </ModalContent>
      </Modal>
      {id && id !== "new" ? <ProducerProducts id={Number(id)} /> : null}
    </>
  );
};

export default ProducerEditForm;
