import { storageGet, storageSet } from "./storage";
import { SEARCH_STORAGE_KEY } from "../../config/constants";

export function setSearch<T extends object>(obj: T) {
  storageSet<T>(SEARCH_STORAGE_KEY, obj);
}

export function getSearch<T>() {
  return storageGet<T>(SEARCH_STORAGE_KEY);
}
