import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Alert,
  AlertIcon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  CloseButton,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";

interface IProps<T> {
  afterDelete?: () => void;
  close: () => void;
  doDelete: (id: T) => Promise<any>;
  id: T;
  title: string;
}

function DeleteModal<T>({ afterDelete, close, doDelete, id, title, children }: React.PropsWithChildren<IProps<T>>) {
  const isOpen = Boolean(id);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState("");
  const cancelRef = useRef<HTMLButtonElement>(null);

  const deleteAndClose = useCallback(() => {
    setIsDeleting(true);
    doDelete(id)
      .then(() => {
        close();
        if (afterDelete) afterDelete();
      })
      .catch((error) => setError(String(error)))
      .finally(() => setIsDeleting(false));
  }, [id, setIsDeleting, doDelete, afterDelete, close, setError]);

  useEffect(() => setError(""), [isOpen, setError]);

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={close}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            {children}
            {error ? (
              <Alert status="error">
                <AlertIcon />
                {error}
                <CloseButton position="absolute" right="8px" top="8px" />
              </Alert>
            ) : null}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={close}>
              Annulla
            </Button>
            <Button onClick={deleteAndClose} isLoading={isDeleting} loadingText={"Elimina"} colorScheme="main" ml={3}>
              Elimina
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default DeleteModal;
