import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import {
  ADMIN_PATH,
  IMPORTS_PATH,
  PLATFORMS_PATH,
  PRODUCERS_PATH,
  PRODUCTS_PATH,
  SEARCH_PATH,
  USERS_PATH,
} from "../../config/paths";
import { ADMIN_ROLE, SEARCH_ROLE } from "../../config/roles";
import AdminContext from "../../context/AdminContext";
import Dashboard from "./Dashboard";
import ImportsRoutes from "./Imports/ImportsRoutes";
import PlatformsRoutes from "./Platforms/PlatformsRoutes";
import ProducersRoutes from "./Producers/ProducersRoutes";
import ProductsRoutes from "./Products/ProductsRoutes";
import SearchRoutes from "./Search/SearchRoutes";
import UsersRoutes from "./Users/UsersRoutes";

interface IProps {}

const AdminRoutes: React.FC<IProps> = () => {
  const {
    loggedUser: { role },
  } = useContext(AdminContext);
  return (
    <Switch>
      <Route path={PRODUCTS_PATH}>
        <ProductsRoutes />
      </Route>
      <Route path={PLATFORMS_PATH}>
        <PlatformsRoutes />
      </Route>
      <Route path={PRODUCERS_PATH}>
        <ProducersRoutes />
      </Route>
      {[ADMIN_ROLE, SEARCH_ROLE].includes(role) ? (
        <Route path={SEARCH_PATH}>
          <SearchRoutes />
        </Route>
      ) : null}
      {role === ADMIN_ROLE ? (
        <Route path={USERS_PATH}>
          <UsersRoutes />
        </Route>
      ) : null}
      {role === ADMIN_ROLE ? (
        <Route path={IMPORTS_PATH}>
          <ImportsRoutes />
        </Route>
      ) : null}
      <Route path={ADMIN_PATH} exact>
        <Dashboard />
      </Route>
    </Switch>
  );
};

export default AdminRoutes;
