import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import breadcrumbs from "../breadcrumbs";
import { EDIT, PA_PATH } from "../../../../shared/lib/path-utils";
import { PLATFORMS_PATH } from "../../../../config/paths";
import itemsApi, { getPlatformName } from "../../../../shared/api/itemsApi";
import { ItemsPlatformAddresses, ItemsPlatforms, ItemsProvinces } from "../../../../generated/axios";
import toOption from "../../../../shared/lib/toOption";
import { Content, Crumbs, PageTitle } from "../../Layout";
import { Icon } from "@chakra-ui/react";
import { FaMapMarkedAlt } from "react-icons/fa";
import Loading from "../../../../components/Loading";
import PlatformAdddressEditForm from "./PlatformAddressEditForm";
import { IPlatformAddress } from "./types";

const newItem: IPlatformAddress = {
  status: "published",
  platform_id: null,
  street: "",
  civic_number: "",
  city: "",
  province_id: null,
  postal_code: "",
  latitude: null,
  longitude: null,
};

interface IRouteParams {
  id: string;
  id_address: string;
}

interface IProps {}

const PlatformAddress: React.FC<IProps> = () => {
  const [item, setItem] = useState<IPlatformAddress | undefined>(undefined);
  const [platformName, setPlatformName] = useState("");
  const [error, setError] = useState("");
  const {
    params: { id, id_address },
  } = useRouteMatch<IRouteParams>();
  const isNew = id_address === "new";
  const title = isNew ? "Nuovo indirizzo della piattaforma" : "Modifica indirizzo della piattaforma";
  const crumbs = {
    ...breadcrumbs,
    "Modifica piattaforma": EDIT(PLATFORMS_PATH, id),
    [title]: EDIT(PA_PATH(id), id_address),
  };

  useEffect(() => {
    if (id_address === "new") {
      getPlatformName(Number(id))
        .then((name) => {
          setItem({ ...newItem, platform_id: Number(id) });
          setPlatformName(name);
        })
        .catch((e) => {
          setItem({ ...newItem, platform_id: Number(id) }); // End loading
          setError(String(e));
        });
    } else {
      itemsApi
        .readSingleItemsPlatformAddresses(Number(id_address), [
          "status",
          "platform_id.id",
          "platform_id.name",
          "id",
          "street",
          "civic_number",
          "postal_code",
          "city",
          "province_id.id",
          "province_id.name",
          "province_id.code",
          "latitude",
          "longitude",
        ])
        .then((response) => {
          const data = response.data.data! as unknown as ItemsPlatformAddresses;
          const platform = data.platform_id as ItemsPlatforms;
          if (!platform.id) throw new Error("Indirizzo piattaforma senza riferimento ad una piattaforma!");
          const item: IPlatformAddress = {
            id: Number(id_address),
            status: data.status,
            street: data.street,
            civic_number: data.civic_number,
            city: data.city,
            postal_code: data.postal_code,
            province_id: toOption(data.province_id as ItemsProvinces),
            latitude: data.latitude,
            longitude: data.longitude,
          };
          setItem(item);
          setPlatformName(platform.name || "");
        })
        .catch((e) => {
          setItem(newItem); // End loading
          setError(String(e));
        });
    }
  }, [id, id_address, setItem, setError]);

  return (
    <>
      <Crumbs path={crumbs} />
      <Content>
        <PageTitle>
          <Icon as={FaMapMarkedAlt} className="inline-icon" /> {title} {platformName}
        </PageTitle>
        <Loading loading={!item} error={error}>
          <PlatformAdddressEditForm id={Number(id)} id_address={id_address} item={item!} />
        </Loading>
      </Content>
    </>
  );
};

export default PlatformAddress;
