import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { LOGIN_PATH } from "../config/paths";

export interface IProps extends RouteProps {
  auth: boolean;
}

const GuardedRoute: React.FC<IProps> = ({ auth, ...routeProps }: IProps) => {
  if (auth) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to={{ pathname: LOGIN_PATH }} />;
  }
};

export default GuardedRoute;
