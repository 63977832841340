import React from "react";
import { Button, Icon, Link, Tooltip } from "@chakra-ui/react";
import { FaExternalLinkAlt } from "react-icons/all";

interface IProps {
  url: string;
  target?: string;
  tooltip: string;
}

const ExtLinkButton: React.FC<IProps> = ({ url, target, tooltip }) => {
  return (
    <Tooltip label={tooltip} aria-label={tooltip}>
      <Button as={Link} href={url} target={target} variant="edit">
        <Icon as={FaExternalLinkAlt} />
      </Button>
    </Tooltip>
  );
};

export default ExtLinkButton;
