import React from "react";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import LoginFooter from "../../Auth/Layout/LoginFooter";

interface IProps {}

const InitialLoading: React.FC<IProps> = () => {
  return (
    <Flex
      bg="secondary.background"
      minHeight="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box width={"xm"}>
        <Spinner size={"xl"} color={"brand.primary"} thickness="5px" />
      </Box>
      <LoginFooter />
    </Flex>
  );
};

export default InitialLoading;
