import { UseToastOptions } from "@chakra-ui/toast/dist/types/use-toast";

export const toastSuccess = (title: string, description?: string): UseToastOptions => ({
  title,
  description,
  position: "top-right",
  status: "success",
  duration: 3000,
  isClosable: true,
});

export const toastFailure = (title: string, description?: string): UseToastOptions => ({
  title,
  description,
  position: "top-right",
  status: "error",
  duration: 5000,
  isClosable: true,
});
