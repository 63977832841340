import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup, { present } from "../../../components/FormElements/validationMessages";
import { Box, Button, HStack, Icon, SimpleGrid, Text, useToast } from "@chakra-ui/react";
import FSelectAsync from "../../../components/FormElements/FSelectAsync";
import itemsApi, { loadProductCategories, loadProductCategory, loadProducts } from "../../../shared/api/itemsApi";
import FSelectS from "../../../components/FormElements/FSelectS";
import AdminContext from "../../../context/AdminContext";
import FCheckbox from "../../../components/FormElements/FCheckbox";
import FNumber from "../../../components/FormElements/FNumber";
import FInput from "../../../components/FormElements/FInput";
import { getSearch, setSearch } from "../../../shared/lib/search";
import { IPOI, ISearch, ISearchResult, ISetDataSearchResults, SearchType } from "./types";
import { ExtendedFilters, serializeFilters } from "../../../shared/lib/serializeFilters";
import { geolocalize } from "../../../shared/api/geolocalization";
import axios from "axios";
import {
  AuthAxiosInterceptor,
  createAuthAxiosInterceptor,
  createNoCacheAxiosInterceptor,
  NoCacheAxiosInterceptor,
} from "../../../shared/api/axiosInterceptors";
import { toastFailure } from "../../../shared/lib/toast";
import { emptyToNull } from "../../../shared/lib/validation-utils";
import ProductFlags from "../../../components/ProductFlags";
import { useStateBoolean } from "../../../shared/hooks/useStateBoolean";
import ExportResults from "./ExportResults";
import { onlyUnique } from "../../../shared/lib/onlyUnique";
import { SEARCH_STORAGE_KEY } from "../../../config/constants";
import MapResults from "./MapResults";
import { toFilters } from "./lib/toFilters";
import { toSearchData } from "./lib/toSearchData";
import { IGoogleCoords } from "../../../types/ICoords";
import { FaMapMarkerAlt } from "react-icons/all";
import { getRegion, provincesOptions, regionsOptions } from "../../../shared/lib/provinces-regions";
import FRadio from "../../../components/FormElements/FRadio";
import { searchTypeOptions } from "./SearchTypeOptions";
import { filterOnPlatformAddress } from "./lib/filterOnPlatformAddress";
import { usePlatformOptions } from "../../../shared/hooks/usePlatformOptions";
import { getMarkerType } from "../../../shared/lib/markers";

const compareResults = (a: ISearchResult, b: ISearchResult) => {
  const producerNameA = a.producer_id?.name ?? "";
  const producerNameB = b.producer_id?.name ?? "";
  if (producerNameA < producerNameB) return -1;
  else if (producerNameA > producerNameB) return 1;
  else {
    const producerIdA = a.producer_id?.id ?? 0;
    const producerIdB = b.producer_id?.id ?? 0;
    if (producerIdA < producerIdB) return -1;
    else if (producerIdA > producerIdB) return 1;
    else {
      const nameA = a.name ?? "";
      const nameB = b.name ?? "";
      if (nameA < nameB) return -1;
      else if (nameA > nameB) return 1;
      else return 0;
    }
  }
};

const compareByDistance = (a: ISearchResult, b: ISearchResult) => {
  const distanceA = a.distance ?? Infinity;
  const distanceB = b.distance ?? Infinity;
  if (distanceA < distanceB) return -1;
  else if (distanceA > distanceB) return 1;
  else return compareResults(a, b);
};

const schema = yup.object().shape(
  {
    price_from: yup.number().transform(emptyToNull).nullable(),
    price_to: yup
      .number()
      .transform(emptyToNull)
      .nullable()
      .when("price_from", (priceFrom: number, schema) =>
        schema.test({
          test: (priceTo: number) => !present(priceFrom) || !present(priceTo) || priceTo >= priceFrom,
          message: 'Deve essere >= del "Prezzo da"',
        })
      ),
    address: yup.string().ensure().when("km", { is: present, then: yup.string().required() }),
    km: yup.number().transform(emptyToNull).nullable().when("address", { is: present, then: yup.number().required() }),
  },
  [
    ["address", "km"],
    ["price_from", "price_to"],
  ]
);

const searchFields = [
  "id",
  "name",
  "product_id.name",
  "product_id.category_id.name",
  "price",
  "unit_id.name",
  "production_capacity",
  "production_capacity_unit_id.name",
  "source_id.name",

  "platform_id.name",
  "platform_id.platform_addresses.status",
  "platform_id.platform_addresses.id",
  "platform_id.platform_addresses.street",
  "platform_id.platform_addresses.civic_number",
  "platform_id.platform_addresses.postal_code",
  "platform_id.platform_addresses.city",
  "platform_id.platform_addresses.province_id.id",
  "platform_id.platform_addresses.province_id.code",
  "platform_id.platform_addresses.province_id.name",
  "platform_id.platform_addresses.province_id.region_id.name", // Usato in Export
  "platform_id.platform_addresses.latitude",
  "platform_id.platform_addresses.longitude",

  "producer_id.id",
  "producer_id.name",
  "producer_id.phone",
  "producer_id.website",
  "producer_id.referent_name",
  "producer_id.referent_email",
  "producer_id.certifications.certification_id.name",
  "producer_id.direct_delivery",
  "producer_id.is_wholesaler",
  "producer_id.platforms.platforms_id",

  "address_id.id",
  "address_id.street",
  "address_id.civic_number",
  "address_id.postal_code",
  "address_id.city",
  "address_id.province_id.id",
  "address_id.province_id.name",
  "address_id.province_id.code",
  "address_id.province_id.region_id.name", // Usato in Export
  "address_id.latitude",
  "address_id.longitude",

  "note",
  "file_url",

  "is_biologic",
  "is_dop",
  "is_igp",
  "is_stg",
  "is_integrated_fight",
  "is_social_cooperative",
  "is_equosolidal",
  "is_msc",
  "is_aqua",
  "is_local",
  "is_pat",
  "is_halal",
  "is_social",
  "is_fao_37_27",
  "is_kosher",
];

const defaultValues: ISearch = {
  product_id: null,
  category_id: null,
  product: "",
  province_id: null,
  region_id: null,
  certifications: [],
  is_wholesaler: undefined,
  direct_delivery: undefined,
  platform_id: null,
  source_id: null,
  price_from: undefined,
  price_to: undefined,
  address: "",
  km: null,
  search_type: "standard",

  is_biologic: undefined,
  is_dop: undefined,
  is_igp: undefined,
  is_stg: undefined,
  is_integrated_fight: undefined,
  is_social_cooperative: undefined,
  is_equosolidal: undefined,
  is_msc: undefined,
  is_aqua: undefined,
  is_local: undefined,
  is_pat: undefined,
  is_halal: undefined,
  is_social: undefined,
  is_fao_37_27: undefined,
  is_kosher: undefined,
};

const noResults: ISearchResult[] = [];
const noPOI: IPOI[] = [];

interface IProps {
  setData: (results?: ISetDataSearchResults) => void;
}

const SearchForm: React.FC<IProps> = ({ setData }) => {
  const toast = useToast();
  const { certifications, provincesWithRegion, sources } = useContext(AdminContext);
  const [platforms, isLoadingPlatforms] = usePlatformOptions();
  const [searchValues, setSearchValues] = useState<
    (ISearch & { key: string; center?: IGoogleCoords; exporting: boolean }) | undefined
  >(undefined);
  const [exportedData, setExportedData] = useState<ISearchResult[]>(noResults);
  const [exporting, setExporting, unsetExporting] = useStateBoolean(false);
  const [poi, setPOI] = useState<IPOI[]>(noPOI);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    trigger,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<ISearch>({
    resolver: yupResolver(schema),
    defaultValues: { ...defaultValues, ...getSearch<ISearch>() },
  });

  const [address, category_id, km, product_id, province_id, region_id, search_type] = watch([
    "address",
    "category_id",
    "km",
    "product_id",
    "province_id",
    "region_id",
    "search_type",
  ]);

  const addressLabel = searchTypeOptions.find((o) => o.value === search_type)?.label || "Indirizzo";

  const provinces = useMemo(() => provincesOptions(provincesWithRegion, region_id), [provincesWithRegion, region_id]);
  const regions = useMemo(() => regionsOptions(provincesWithRegion, province_id), [provincesWithRegion, province_id]);

  // Set category_id when set a product_id
  useEffect(() => {
    if (product_id) {
      loadProductCategory(product_id)
        .then((category) => setValue("category_id", category))
        .catch((e) => {
          toast(toastFailure("Errore caricamento categoria merceologica", String(e)));
        });
    }
  }, [product_id, setValue, toast]);

  // Reset product_id when clear category_id
  useEffect(() => {
    if (!category_id) {
      setValue("product_id", null);
    }
  }, [category_id, setValue]);

  // Set region_id when set a province_id
  useEffect(() => {
    if (province_id) {
      const region = getRegion(provincesWithRegion, province_id);
      if (region) setValue("region_id", region);
    }
  }, [province_id, setValue, provincesWithRegion]);

  // Reset province_id when clear region_id
  useEffect(() => {
    if (!region_id) {
      setValue("province_id", null);
    }
  }, [region_id, setValue]);

  const onReset = useCallback(() => {
    reset(defaultValues);
    sessionStorage.removeItem(SEARCH_STORAGE_KEY);
    setData(undefined);
    setSearchValues(undefined);
    setExportedData(noResults);
    setPOI(noPOI);
  }, [reset, setData, setSearchValues, setExportedData, setPOI]);

  const withProximity = (
    filters: ExtendedFilters<ISearch>,
    searchType: SearchType,
    center: IGoogleCoords,
    km: number
  ) => {
    const { lat, lng } = center;
    return itemsApi
      .searchItemsProductsProducersAddresses(
        searchFields,
        -1,
        undefined,
        undefined,
        ["name"],
        serializeFilters({ status: "published" }, filters),
        undefined,
        lat,
        lng,
        km,
        searchType
      )
      .then((response) => {
        let results = response.data.data as unknown as ISearchResult[];
        results = filterOnPlatformAddress(results, searchType, center, km);
        results.sort(compareByDistance);
        setData({
          results,
          numProducers: results.map((record) => record.producer_id?.id).filter(onlyUnique).length,
          numProducts: results.map((record) => record.id).filter(onlyUnique).length,
          searchType,
        });
        setExportedData(exporting ? results : noResults);
      })
      .catch((e) => {
        toast(toastFailure(`Errore nella ricerca (${e})`));
      })
      .finally(() => unsetExporting());
  };

  const withoutProximity = (
    filters: ExtendedFilters<ISearch>,
    searchType: SearchType,
    setResults: boolean,
    center?: IGoogleCoords,
    km?: number
  ) => {
    return itemsApi
      .searchItemsProductsProducersAddresses(
        searchFields,
        -1,
        undefined,
        undefined,
        ["name"],
        serializeFilters({ status: "published" }, filters),
        undefined
      )
      .then((response) => {
        const records = response.data.data as unknown as ISearchResult[];
        if (setResults) {
          const results = filterOnPlatformAddress(records, searchType);
          results.sort(compareResults);
          setData({
            results,
            numProducers: results.map((record) => record.producer_id?.id).filter(onlyUnique).length,
            numProducts: results.map((record) => record.id).filter(onlyUnique).length,
            searchType,
          });
          setExportedData(exporting ? results : noResults);
        }
        const poi: Record<number, IPOI> = {};
        records.forEach((record) => {
          if (
            record.address_id?.id &&
            record.producer_id?.id &&
            record.address_id.latitude &&
            record.address_id.longitude
          ) {
            poi[record.producer_id.id] = {
              // l'id del POI è quello del producer!
              ...record.address_id,
              ...record.producer_id,
              platforms: record.producer_id.platforms.map((p) => p.platforms_id),
              coords: {
                lat: record.address_id.latitude,
                lng: record.address_id.longitude,
              },
              markerType: getMarkerType(record, searchType, center, km),
            };
          }
        });
        setPOI(Object.values(poi));
      })
      .catch((e) => {
        toast(toastFailure(`Errore nella ricerca (${e})`));
      })
      .finally(() => setResults && unsetExporting());
  };

  const getCoords = async (address?: string, km?: number | null): Promise<IGoogleCoords | undefined> => {
    let coords: IGoogleCoords | undefined = undefined;
    if (address && km) {
      axios.interceptors.request.eject(AuthAxiosInterceptor);
      axios.interceptors.request.eject(NoCacheAxiosInterceptor);
      await geolocalize(address)
        .then((response) => {
          const { lat, lng, resolvedAddress } = response;
          coords = { lat, lng, resolvedAddress };
        })
        .catch((e) => {
          toast(toastFailure(`Errore nella geolocalizzazione dell'indirizzo (${e})`));
        })
        .finally(() => {
          createAuthAxiosInterceptor();
          createNoCacheAxiosInterceptor();
        });
    }
    return coords;
  };

  const onSubmit = async (values: ISearch) => {
    setSearch<ISearch>(values); // Store values in sessionStorage
    setData(undefined);
    setExportedData(noResults);
    setPOI(noPOI);
    const filters = toFilters(values);
    const center = await getCoords(values.address, values.km);
    const searchType = values.search_type;
    setSearchValues({ ...values, key: String(new Date().getTime()), center, exporting });
    if (center && values.km) {
      return Promise.all([
        withoutProximity(filters, searchType, false, center, values.km),
        withProximity(filters, searchType, center, values.km),
      ]);
    } else {
      return withoutProximity(filters, searchType, true);
    }
  };

  // Trigger validation on km & address
  useEffect(() => {
    if (!km || !address) {
      trigger(["km", "address"]);
    }
  }, [km, address, trigger]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SimpleGrid columns={[1, 1, 3, null, null, 4]} spacing={"8px"}>
        <Controller
          name={"product_id"}
          control={control}
          render={({ field }) => (
            <FSelectAsync
              {...field}
              key={category_id?.value || 0} // Force as new component when change category_id
              label={"Prodotto"}
              error={errors.product_id as FieldError | undefined}
              loadOptions={loadProducts}
              additional={{ category_id }}
            />
          )}
        />

        <Controller
          name={"category_id"}
          control={control}
          render={({ field }) => (
            <FSelectAsync
              {...field}
              key={product_id?.value || 0} // Force as new component when change product_id
              label={"Categoria merceologica"}
              error={errors.category_id as FieldError | undefined}
              loadOptions={loadProductCategories}
              additional={{ product_id }}
            />
          )}
        />
        <Controller
          name={"product"}
          control={control}
          render={({ field }) => <FInput label={"Nome prodotto"} error={errors.product} {...field} />}
        />
      </SimpleGrid>

      <SimpleGrid columns={[1, null, null, 2, 2]} spacing={"16px"}>
        <Box>
          <SimpleGrid columns={[2]} spacing={"8px"}>
            <Controller
              name={"price_from"}
              control={control}
              render={({ field }) => <FNumber label={"Prezzo da"} precision={4} error={errors.price_from} {...field} />}
            />
            <Controller
              name={"price_to"}
              control={control}
              render={({ field }) => <FNumber label={"Prezzo a"} precision={4} error={errors.price_to} {...field} />}
            />
          </SimpleGrid>

          <SimpleGrid columns={[2, 2, 3, 2, 3, 4]} spacing={"8px"} mb={4}>
            <ProductFlags control={control} errors={errors} />
          </SimpleGrid>

          <SimpleGrid columns={[2, null, null, 1, 2]} spacing={"8px"}>
            <Controller
              name={"certifications"}
              control={control}
              render={({ field }) => (
                <FSelectS
                  {...field}
                  error={errors.certifications as FieldError | undefined}
                  isMulti={true}
                  label={"Certificazioni"}
                  options={certifications}
                />
              )}
            />

            <Controller
              name={"platform_id"}
              control={control}
              render={({ field }) => (
                <FSelectS
                  {...field}
                  isMulti={false}
                  label={"Piattaforma"}
                  error={errors.platform_id as FieldError | undefined}
                  isLoading={isLoadingPlatforms}
                  options={platforms}
                />
              )}
            />
          </SimpleGrid>

          <SimpleGrid columns={[2]} spacing={"8px"} mb={4}>
            <Controller
              name={"is_wholesaler"}
              control={control}
              render={({ field }) => <FCheckbox label={"Utilizza Piattaforma"} error={errors.is_wholesaler} {...field} />}
            />
            <Controller
              name={"direct_delivery"}
              control={control}
              render={({ field }) => <FCheckbox label={"Consegna diretta"} error={errors.direct_delivery} {...field} />}
            />
          </SimpleGrid>

          <SimpleGrid columns={[2]} spacing={"8px"} mb={4}>
            <Controller
              name={`source_id`}
              control={control}
              render={({ field }) => (
                <FSelectS
                  {...field}
                  label={"Fonte"}
                  error={errors.source_id as FieldError | undefined}
                  options={sources}
                />
              )}
            />
          </SimpleGrid>

          <SimpleGrid columns={[2]} spacing={"8px"}>
            <Controller
              name={`region_id`}
              control={control}
              render={({ field }) => (
                <FSelectS
                  {...field}
                  // @ts-ignore
                  // onChange={(e) => {
                  //   if (e) setValue("province_id", null);
                  //   field.onChange(e);
                  // }}
                  label={"Regione"}
                  error={errors.region_id as FieldError | undefined}
                  options={regions}
                />
              )}
            />
            <Controller
              name={`province_id`}
              control={control}
              render={({ field }) => (
                <FSelectS
                  {...field}
                  label={"Provincia"}
                  error={errors.province_id as FieldError | undefined}
                  options={provinces}
                />
              )}
            />
          </SimpleGrid>

          <SimpleGrid spacing={"8px"}>
            <Controller
              name={"search_type"}
              control={control}
              render={({ field }) => <FRadio error={errors.search_type} options={searchTypeOptions} {...field} />}
            />
          </SimpleGrid>

          <SimpleGrid columns={[2]} spacing={"8px"}>
            <Controller
              name={"address"}
              control={control}
              render={({ field }) => <FInput label={addressLabel} error={errors.address} {...field} />}
            />
            <Controller
              name={"km"}
              control={control}
              render={({ field }) => (
                <FNumber label={"Raggio (in km)"} error={errors.km as FieldError | undefined} {...field} />
              )}
            />
          </SimpleGrid>

          {searchValues?.center?.resolvedAddress ? (
            <Text>
              <Icon as={FaMapMarkerAlt} className="inline-icon" /> {searchValues?.center?.resolvedAddress}
            </Text>
          ) : null}

          <HStack mt="6">
            <Button onClick={onReset} disabled={isSubmitting}>
              Reset
            </Button>
            <Button
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting && !exporting}
              loadingText={"Cerca"}
              colorScheme="main"
            >
              Cerca
            </Button>
            <Button
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting && exporting}
              loadingText={"Esporta in Excel"}
              colorScheme="main"
              value={"exit"}
              onClick={setExporting}
            >
              Esporta in Excel
            </Button>

            {searchValues && searchValues.exporting && exportedData.length ? (
              <ExportResults
                dataSet={exportedData}
                searchType={searchValues.search_type}
                searchData={toSearchData(searchValues)}
              />
            ) : null}
          </HStack>
        </Box>

        <Box minH={"480px"}>
          <MapResults
            key={searchValues?.key || "initial"}
            poi={poi}
            radius={searchValues?.km ? Number(searchValues?.km) : undefined}
            center={searchValues?.center}
          />
        </Box>
      </SimpleGrid>
    </form>
  );
};

export default SearchForm;
