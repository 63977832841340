import { useCallback, useEffect } from "react";
import { useStateLoading } from "./useStateLoading";
import { useToast } from "@chakra-ui/react";
import { FSelectOption } from "../../components/FormElements/types";
import { loadPlatforms } from "../api/itemsApi";
import { toastFailure } from "../lib/toast";

type HookResult = [platforms: FSelectOption<number>[], isLoading: boolean, reload: () => void];

export function usePlatformOptions(): HookResult {
  const toast = useToast();
  const [platforms, setPlatforms, isLoading] = useStateLoading<FSelectOption<number>[]>([]);

  const reload = useCallback(() => {
    loadPlatforms()
      .then((platforms) => {
        setPlatforms(platforms);
      })
      .catch((e) => {
        toast(toastFailure("Errore caricamento dati piattaforme", String(e)));
      });
  }, [toast, setPlatforms]);

  useEffect(reload, [reload]);

  return [platforms, isLoading, reload];
}
