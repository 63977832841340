import React, { useCallback, useContext, useState } from "react";
import { useStateBoolean } from "../../../shared/hooks/useStateBoolean";
import { Button, HStack, Icon, SimpleGrid, Text, useToast } from "@chakra-ui/react";
import { Control, Controller, FieldError, FieldErrors, UseFormGetValues } from "react-hook-form";
import FInput from "../../../components/FormElements/FInput";
import FSelectS from "../../../components/FormElements/FSelectS";
import { FaEdit, FaMapMarkerAlt } from "react-icons/all";
import FNumber from "../../../components/FormElements/FNumber";
import AdminContext from "../../../context/AdminContext";
import { IAddress, IProducer } from "./types";
import { composeAddressString, geolocalize } from "../../../shared/api/geolocalization";
import { toastFailure, toastSuccess } from "../../../shared/lib/toast";
import {
  AuthAxiosInterceptor,
  createAuthAxiosInterceptor,
  createNoCacheAxiosInterceptor,
  NoCacheAxiosInterceptor,
} from "../../../shared/api/axiosInterceptors";
import axios from "axios";
import { UseFormSetValue } from "react-hook-form/dist/types/form";

interface IProps {
  control: Control<IProducer>;
  errors: FieldErrors<IProducer>;
  getValues: UseFormGetValues<IProducer>;
  i: 0 | 1;
  setValue: UseFormSetValue<IProducer>;
}

const AddressFields: React.FC<IProps> = ({ control, errors, getValues, i, setValue }) => {
  const toast = useToast();
  const [updateCoord, setUpdateCoord] = useStateBoolean(false);
  const [geoLocalizedAddress, setGeoLocalizedAddress] = useState<string | undefined>();
  const { provinces } = useContext(AdminContext);
  const geolocalizza = useCallback(() => {
    const addresses: IAddress[] = getValues("addresses");
    const addressStr = composeAddressString(addresses[i], provinces);

    axios.interceptors.request.eject(AuthAxiosInterceptor);
    axios.interceptors.request.eject(NoCacheAxiosInterceptor);
    geolocalize(addressStr)
      .then((response) => {
        const { lat, lng, resolvedAddress, type } = response;
        // @ts-ignore
        setValue(`addresses[${i}].latitude`, lat);
        // @ts-ignore
        setValue(`addresses[${i}].longitude`, lng);
        setGeoLocalizedAddress(resolvedAddress);
        toast(toastSuccess(`Indirizzo correttamente geolocalizzato (${type})`));
      })
      .catch((e) => {
        console.error(`Errore nella geolocalizzazione dell'indirizzo (${e})`);
        toast(
          toastFailure(
            'La geolocalizzazione è fallita! Sblocca i campi latitudine e logitudine con il pulsante "Modifica manualmente" per inserire le coordinate manualmente e poter salvare l’indirizzo'
          )
        );
      })
      .finally(() => {
        createAuthAxiosInterceptor();
        createNoCacheAxiosInterceptor();
      });
  }, [getValues, i, provinces, setGeoLocalizedAddress, setValue, toast]);
  return (
    <fieldset name={`addresses[${i}]`} key={`addresses[${i}]`}>
      <SimpleGrid columns={[1, null, 3]} spacing={"8px"}>
        <Controller
          // @ts-ignore
          name={`addresses[${i}].street`}
          control={control}
          render={({ field }) => (
            <FInput label={"Via"} error={errors.addresses?.[i]?.street} required={true} {...field} />
          )}
        />
        <Controller
          // @ts-ignore
          name={`addresses[${i}].civic_number`}
          control={control}
          render={({ field }) => (
            <FInput label={"Numero civico"} error={errors.addresses?.[i]?.civic_number} maxLength={20} {...field} />
          )}
        />
        <Controller
          // @ts-ignore
          name={`addresses[${i}].city`}
          control={control}
          render={({ field }) => (
            <FInput label={"Città"} error={errors.addresses?.[i]?.city} required={true} {...field} />
          )}
        />
      </SimpleGrid>

      <SimpleGrid columns={[1, null, 2]} spacing={"8px"}>
        <Controller
          // @ts-ignore
          name={`addresses[${i}].postal_code`}
          control={control}
          render={({ field }) => (
            <FInput label={"Cap"} maxLength={5} error={errors.addresses?.[i]?.postal_code} {...field} />
          )}
        />
        <Controller
          // @ts-ignore
          name={`addresses[${i}].province_id`}
          control={control}
          render={({ field }) => (
            <FSelectS
              {...field}
              label={"Provincia"}
              error={errors.addresses?.[i]?.province_id as FieldError | undefined}
              options={provinces}
              required={true}
            />
          )}
        />
      </SimpleGrid>

      <SimpleGrid columns={[1, null, 2]} spacing={"8px"}>
        <div>
          <Text mb={2}>
            {geoLocalizedAddress ? (
              <>
                <Icon as={FaMapMarkerAlt} className="inline-icon" /> {geoLocalizedAddress}
              </>
            ) : (
              <>&nbsp;</>
            )}
          </Text>
          <HStack>
            <Button leftIcon={<Icon as={FaMapMarkerAlt} />} onClick={geolocalizza}>
              Geolocalizza
            </Button>
            <Button leftIcon={<Icon as={FaEdit} />} onClick={setUpdateCoord}>
              Modifica manualmente
            </Button>
          </HStack>
        </div>

        <SimpleGrid columns={2} spacing={"8px"}>
          <Controller
            // @ts-ignore
            name={`addresses[${i}].latitude`}
            control={control}
            render={({ field }) => (
              <FNumber
                label={"Latitudine"}
                error={errors.addresses?.[i]?.latitude}
                readonly={!updateCoord}
                required={true}
                {...field}
              />
            )}
          />
          <Controller
            // @ts-ignore
            name={`addresses[${i}].longitude`}
            control={control}
            render={({ field }) => (
              <FNumber
                label={"Longitudine"}
                error={errors.addresses?.[i]?.longitude}
                readonly={!updateCoord}
                required={true}
                {...field}
              />
            )}
          />
        </SimpleGrid>
      </SimpleGrid>
    </fieldset>
  );
};

export default AddressFields;
