import React from "react";
import { FieldError } from "react-hook-form";
import {
  FormControl,
  FormErrorMessage,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import FLabel from "./FLabel";

interface IProps {
  autoFocus?: boolean;
  error?: FieldError;
  label: string;
  name: string;
  precision?: number;
  readonly?: boolean;
  required?: boolean;
  value: any;
}

const FNumber = React.forwardRef<HTMLInputElement, IProps>(
  ({ autoFocus = false, error, label, name, precision, readonly, required, value, ...rest }, ref) => {
    return (
      <FormControl mb="4" isInvalid={Boolean(error)}>
        <FLabel name={name} label={label} required={required} />
        <NumberInput
          id={name}
          precision={precision}
          backgroundColor="white"
          isReadOnly={readonly}
          {...rest}
          value={value === null ? "" : value}
        >
          <NumberInputField ref={ref} autoFocus={autoFocus} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    );
  }
);

export default FNumber;
