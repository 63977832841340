import React from "react";
import { Tag, Wrap } from "@chakra-ui/react";
import { IFlags } from "../types/IFlags";

const FlagList: React.FC<{ value: IFlags }> = ({ value }) => {
  return (
    <Wrap>
      {value.is_biologic ? <Tag id="is_biologic">Biologico</Tag> : null}
      {value.is_dop ? <Tag id="is_dop">DOP</Tag> : null}
      {value.is_igp ? <Tag id="is_igp">IGP</Tag> : null}
      {value.is_stg ? <Tag id="is_stg">STG</Tag> : null}
      {value.is_integrated_fight ? <Tag id="is_integrated_fight">Lotta integrata</Tag> : null}
      {value.is_social_cooperative ? <Tag id="is_social_cooperative">Cooperativa sociale</Tag> : null}
      {value.is_equosolidal ? <Tag id="is_equosolidal">Equosolidale</Tag> : null}
      {value.is_msc ? <Tag id="is_msc">MSC</Tag> : null}
      {value.is_aqua ? <Tag id="is_aqua">AQUA</Tag> : null}
      {value.is_local ? <Tag id="is_local">Locale</Tag> : null}
      {value.is_pat ? <Tag id="is_pat">PAT</Tag> : null}
      {value.is_halal ? <Tag id="is_halal">Halal</Tag> : null}
      {value.is_social ? <Tag id="is_social">Sociale</Tag> : null}
      {value.is_fao_37_27 ? <Tag id="is_fao_37_27">FAO 37/27</Tag> : null}
      {value.is_kosher ? <Tag id="is_kosher">Kosher</Tag> : null}
    </Wrap>
  );
};

export default FlagList;
