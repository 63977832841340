import React from "react";
import { FieldError } from "react-hook-form";
import { FormControl, FormErrorMessage, Select } from "@chakra-ui/react";
import { FSelectOption } from "./types";
import FLabel from "./FLabel";

interface IProps {
  error?: FieldError;
  label: string;
  name: string;
  options: FSelectOption<string | number>[];
  placeholder?: string;
  required?: boolean;
  value?: any;
}

const FSelect = React.forwardRef<HTMLSelectElement, IProps>(
  ({ label, name, error, options = [], placeholder, required, value, ...rest }, ref) => {
    return (
      <FormControl mb="4" isInvalid={Boolean(error)}>
        <FLabel name={name} label={label} required={required} />
        <Select ref={ref} id={name} placeholder={placeholder} value={value === null ? "" : value} {...rest}>
          <option value="" />
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    );
  }
);

export default FSelect;
