import React, { useCallback, useEffect, useState } from "react";
import { Circle, GoogleMap, InfoWindow, Marker, MarkerClusterer, useJsApiLoader } from "@react-google-maps/api";
import { IGoogleCoords } from "../../../types/ICoords";
import { IPlatformPOI, IPOI } from "./types";
import { GOOGLE_MAPS_API_KEY } from "../../../config/constants";
import { loadPlatformsAddresses } from "../../../shared/api/itemsApi";
import { markers } from "../../../shared/lib/markers";
import { Tooltip } from "@chakra-ui/react";
import Address from "../../../components/Address";
import PlatformContact from "../../../components/PlatformContact";
import ProducerContact from "../../../components/ProducerContact";
import SvgMarkerIcon from "./SvgMarkerIcon";
import "./MapResults.css";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const circleStyle = {
  strokeColor: "#E62238",
  strokeOpacity: 1,
  strokeWeight: 2,
  fillColor: "#ffffff",
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
};

const rome: IGoogleCoords = {
  lat: 41.9027835,
  lng: 12.4963655,
};

interface IProps {
  center?: IGoogleCoords;
  radius?: number;
  poi: IPOI[];
}

const MapResults: React.FC<IProps> = ({ center = rome, poi, radius }) => {
  const [platformAddressesPoi, setPlatformAddresses] = useState<IPlatformPOI[]>([]);
  const [platformAddressesCounter, setPlatformAddressesCounter] = useState<number | null>(null);
  const [map, setMap] = useState<any>(null);
  const [circle, setCircle] = useState<any>(null);
  const [open, setOpen] = useState<number | null>(null);
  const [openPlatformAddress, setOpenPlatformAddress] = useState<number | null>(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const onLoad = useCallback((map: any) => setMap(map), [setMap]);
  const onLoadCircle = useCallback((circle: any) => setCircle(circle), [setCircle]);

  // fitBounds POI
  useEffect(() => {
    if (map && !radius && poi.length) {
      const bounds = new google.maps.LatLngBounds();
      poi.forEach((poi) => {
        bounds.extend(poi.coords);
      });
      map.fitBounds(bounds);
    }
  }, [map, radius, poi]);

  // fitBounds circle
  useEffect(() => {
    if (map && circle) {
      map.fitBounds(circle.getBounds());
    }
  }, [map, circle]);

  // Add legend
  useEffect(() => {
    if (map) {
      const legend = document.getElementById("legend") as HTMLElement;
      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(legend);
    }
  }, [map]);

  const onMarkerClick = (id: number) => {
    setOpen(id);
    setPlatformAddressesCounter(null);
    setOpenPlatformAddress(null); // Close InfoWindow
    setPlatformAddresses([]); // Remove visualization of previous platformAddressesPoi
    const platformsIds = poi.find((p) => p.id === id)?.platforms || [];
    if (platformsIds.length > 0) {
      loadPlatformsAddresses(platformsIds).then((pPoi) => {
        setPlatformAddresses(pPoi);
        setPlatformAddressesCounter(pPoi.length);
      });
    } else {
      setPlatformAddressesCounter(0);
    }
  };

  if (!isLoaded) return null;
  return (
    <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={10} onLoad={onLoad}>
      <MarkerClusterer>
        {(clusterer) =>
          poi.map((poi) => (
            <Marker
              key={poi.id}
              clusterer={clusterer}
              onClick={() => onMarkerClick(poi.id)}
              position={poi.coords}
              icon={poi.markerType ? markers[poi.markerType] : undefined}
            >
              {open && open === poi.id ? (
                <InfoWindow position={poi.coords} onCloseClick={() => setOpen(null)}>
                  <div>
                    <ProducerContact producer={poi} />
                    <Address address={poi} />
                    <p>
                      {platformAddressesCounter === null ? "-" : platformAddressesCounter} indirizzi di spedizione delle
                      piattaforme
                    </p>
                  </div>
                </InfoWindow>
              ) : null}
            </Marker>
          ))
        }
      </MarkerClusterer>
      {platformAddressesPoi.map((poi) => (
        <Marker
          key={poi.id}
          onClick={() => setOpenPlatformAddress(poi.id)}
          position={poi.coords}
          icon={markers["e"]} // labelOrigin: new google.maps.Point(poi.coords.lat - 1, poi.coords.lng),
          label={poi.platform_id.name}
        >
          {openPlatformAddress && openPlatformAddress === poi.id ? (
            <InfoWindow position={poi.coords} onCloseClick={() => setOpenPlatformAddress(null)}>
              <div>
                <PlatformContact id={poi.id} platform={poi.platform_id} />
                <Address address={poi} />
              </div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
      {radius ? <Circle onLoad={onLoadCircle} center={center} options={circleStyle} radius={radius * 1000} /> : null}
      {radius ? (
        <Marker
          key={0}
          onClick={() => setOpen(0)}
          position={center}
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            scale: 4,
            strokeColor: "#E62238",
            strokeWeight: 2,
          }}
        >
          {open === 0 ? (
            <InfoWindow position={center} onCloseClick={() => setOpen(null)}>
              <div>{center?.resolvedAddress}</div>
            </InfoWindow>
          ) : null}
        </Marker>
      ) : null}
      <div id="legend">
        {Object.entries(markers).map(([key, marker]) => (
          <Tooltip key={key} label={marker.tip}>
            <div className={"legend-icon"}>
              <SvgMarkerIcon viewBox="-11 -41 22 42" width="18px" {...marker} />
            </div>
          </Tooltip>
        ))}
      </div>
    </GoogleMap>
  );
};

export default React.memo(MapResults);
