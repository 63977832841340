import React, { useCallback, useEffect, useImperativeHandle } from "react";
import { useTable, usePagination, useFilters, useGlobalFilter, useSortBy, Column, SortingRule } from "react-table";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Icon,
  NumberInput,
  NumberInputField,
  Select,
  Stack,
  Table,
  Tbody,
  Tfoot,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  HStack,
} from "@chakra-ui/react";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight, FaPlus, FaRedo } from "react-icons/all";
import ColumnSortIcon from "./ColumnSortIcon";
import { DataTableCallbackRef, FetchDataParams, Pagination } from "./index";
import GlobalFilter from "./GlobalFilter";

const defaultSortBy = [{ id: "id", desc: true }];

interface IProps<D extends object> {
  columns: Column<D>[];
  data: D[];
  fetchData: (params: FetchDataParams<D>) => void;
  initialSortBy?: Array<SortingRule<D>>;
  labelButtonNew?: string;
  loading: boolean;
  newButtonPath?: string;
  refreshButton?: boolean;
  pagination: Pagination;
  callbacksRef?: React.ForwardedRef<DataTableCallbackRef>;
}

function DataTable<D extends object>({
  columns,
  data,
  fetchData,
  initialSortBy = defaultSortBy,
  labelButtonNew = "Nuovo",
  loading,
  newButtonPath,
  refreshButton,
  pagination,
  callbacksRef,
}: IProps<D>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { filters, globalFilter, pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: pagination.page, pageSize: 10, sortBy: initialSortBy },
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      manualGlobalFilter: true,
      pageCount: pagination.numPages,
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetFilters: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // Listen for changes in pagination and use the state to fetch our new data
  const fetchDataCallback = useCallback(() => {
    fetchData({ pageIndex, pageSize, sortBy, filters, globalFilter });
  }, [fetchData, pageIndex, pageSize, sortBy, filters, globalFilter]);
  useEffect(fetchDataCallback, [fetchDataCallback]);

  useImperativeHandle(callbacksRef, () => ({ fetchData: fetchDataCallback }));

  return (
    <>
      <HStack my={4} justify="space-between">
        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} gotoPage={gotoPage} />
        <Box />
        <HStack>
          {refreshButton ? (
            <Button leftIcon={<Icon as={FaRedo} />} onClick={() => fetchDataCallback()} variant="secondary">
              Aggiorna
            </Button>
          ) : null}
          {newButtonPath ? (
            <Button leftIcon={<Icon as={FaPlus} />} as={Link} to={newButtonPath} variant="primary">
              {labelButtonNew}
            </Button>
          ) : null}
        </HStack>
      </HStack>

      <Box mt={8} border="1px solid #ccc" backgroundColor="white" sx={{ overflowX: "auto" }}>
        <Table size="sm" {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps()} p={2} verticalAlign={"bottom"} w={column.width}>
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")} <ColumnSortIcon column={column} />
                    </div>

                    {/* Render the columns filter UI */}
                    <div>{column.canFilter ? column.render("Filter") : null}</div>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()} py={2}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>;
                  })}
                </Tr>
              );
            })}
          </Tbody>
          <Tfoot>
            <Tr>
              <Td colSpan={10000}>
                <div className="pagination">
                  <Stack direction={["column", "row"]} alignItems={["flex-end", "center"]}>
                    <Stack direction={["column", "row"]}>
                      <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        <Icon as={FaAngleDoubleLeft} />
                      </Button>{" "}
                      <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <Icon as={FaAngleLeft} />
                      </Button>{" "}
                      <Button onClick={() => nextPage()} disabled={!canNextPage}>
                        <Icon as={FaAngleRight} />
                      </Button>{" "}
                      <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <Icon as={FaAngleDoubleRight} />
                      </Button>
                    </Stack>

                    <Text minW={"10em"} align={"center"}>
                      {loading ? "Loading..." : `${pagination.totalRecords} records`}
                    </Text>

                    <Text minW={"10em"} align={"center"}>
                      Pag. <strong>{pageIndex + 1}</strong> di <strong>{pageOptions.length}</strong>
                    </Text>

                    <Stack direction={["column", "row"]} flexGrow={1} align={"center"}>
                      <Text>Vai a pagina: </Text>
                      <NumberInput
                        w={"8em"}
                        defaultValue={pageIndex + 1}
                        min={1}
                        onChange={(value) => {
                          const page = value ? Number(value) - 1 : 0;
                          gotoPage(page);
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </Stack>

                    <Stack direction={["column", "row"]} align={"right"}>
                      <Select
                        w={"10em"}
                        value={pageSize}
                        onChange={(e) => {
                          gotoPage(0);
                          setPageSize(Number(e.target.value));
                        }}
                      >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            Mostra {pageSize}
                          </option>
                        ))}
                      </Select>
                    </Stack>
                  </Stack>
                </div>
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      </Box>
    </>
  );
}

export default DataTable;
