import React, { useState } from "react";
import { Input } from "@chakra-ui/react";
import { useAsyncDebounce } from "react-table";

interface IProps {
  globalFilter?: string;
  gotoPage: (pageIndex: number) => void;
  setGlobalFilter: (value?: string) => void;
}

const GlobalFilter: React.FC<IProps> = ({ globalFilter, gotoPage, setGlobalFilter }) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <Input
      value={value || ""}
      onChange={(e) => {
        gotoPage(0);
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Ricerca in tutti i campi...`}
      maxW="500px"
      backgroundColor="white"
    />
  );
};

export default GlobalFilter;
