import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Content, Crumbs, PageTitle } from "../Layout";
import { PRODUCTS_PATH } from "../../../config/paths";
import { EDIT } from "../../../shared/lib/path-utils";
import breadcrumbs from "./breadcrumbs";
import ProductEditForm from "./ProductEditForm";
import itemsApi from "../../../shared/api/itemsApi";
import Loading from "../../../components/Loading";
import { IProduct } from "./types";
import { ItemsProductCategories, ItemsProducts } from "../../../generated/axios";
import toOption from "../../../shared/lib/toOption";
import { Icon } from "@chakra-ui/react";
import { FaAppleAlt } from "react-icons/fa";

const newItem: IProduct = {
  name: "",
  status: "published",
  category_id: null,
};

interface IRouteParams {
  id: string;
}

interface IProps {}

const Product: React.FC<IProps> = () => {
  const [item, setItem] = useState<IProduct | undefined>(undefined);
  const [error, setError] = useState("");
  const {
    params: { id },
  } = useRouteMatch<IRouteParams>();
  const isNew = id === "new";
  const title = isNew ? "Nuovo prodotto" : "Modifica prodotto";
  const crumbs = {
    ...breadcrumbs,
    [title]: EDIT(PRODUCTS_PATH, id),
  };

  useEffect(() => {
    if (id === "new") {
      setItem(newItem);
    } else {
      itemsApi
        .readSingleItemsProducts(Number(id), ["name", "category_id.id", "category_id.name", "status"])
        .then((response) => {
          const data = response.data.data! as unknown as ItemsProducts;
          const item: IProduct = {
            name: data.name,
            category_id: toOption(data.category_id as ItemsProductCategories),
            status: data.status,
          };
          setItem(item);
        })
        .catch((e) => {
          setItem(newItem); // End loading
          setError(String(e));
        });
    }
  }, [id, setItem, setError]);

  return (
    <>
      <Crumbs path={crumbs} />
      <Content>
        <PageTitle>
          <Icon as={FaAppleAlt} className="inline-icon" /> {title}
        </PageTitle>
        <Loading loading={!item} error={error}>
          <ProductEditForm id={id} item={item!} />
        </Loading>
      </Content>
    </>
  );
};

export default Product;
